import React, { Component } from "react";

import { CHART_OF_ACCOUNTS_ENDPOINT } from "../../constants/Constants";
import PropTypes from "prop-types";
import { REGISTER_CHART_OF_ACCOUNTS_PATH } from "../../constants/ClientPaths";
import { VIEW_CHART_OF_ACCOUNTS_COLUMNS } from "../../components/DataTableColumns";
import ViewDataTable from "../../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ViewChartOfAccounts extends Component {
  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  render() {
    return (
      <ViewDataTable
        handleSetTableNode={this.handleSetTableNode}
        allow_refresh
        columns={VIEW_CHART_OF_ACCOUNTS_COLUMNS}
        data_source_url={CHART_OF_ACCOUNTS_ENDPOINT}
        editable
        creatable
        edit_url={REGISTER_CHART_OF_ACCOUNTS_PATH}
        extra_get_params="all=true"
        data_source_url_name="paginated_chart_of_accounts_url"
      />
    );
  }
}

ViewChartOfAccounts.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ViewChartOfAccounts));
