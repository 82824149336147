import React, { Component } from "react";
import {
  fetchUrlData,
  getUrlData,
  removeUnderscore,
} from "../../utils/componentActions";

import DataTable from "../../components/DataTable";
import PropTypes from "prop-types";
import { SIGNATORIES_ENDPOINT } from "../../constants/Constants";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class ViewSignatories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 5,
      table_node: null,
    };
  }

  componentDidMount() {
    const signatories_url: string = `${SIGNATORIES_ENDPOINT}?limit=5&offset=0`;
    fetchUrlData("signatories_url", signatories_url, this.props);
  }

  handleTableChange = (type, change_obj) => {
    const { sizePerPage } = change_obj;
    const { page } = change_obj;
    const search_text: any = change_obj.searchText;
    const offset: any = (page - 1) * sizePerPage;
    const get_params: string = `limit=${sizePerPage}&offset=${offset}&search=${search_text}`;
    const signatories_url: string = `${SIGNATORIES_ENDPOINT}?${get_params}`;
    fetchUrlData("signatories_url", signatories_url, this.props);
    this.setState(() => ({
      page,
      sizePerPage,
    }));
  };

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  render() {
    const { signatories_data } = this.props;
    const signatories: any = signatories_data.items;
    const signatories_columns: any = [
      {
        dataField: "id",
        text: "ID",
      },
      {
        dataField: "signatory_name",
        text: "Name",
      },
      {
        dataField: "signatories_for",
        text: "Signatory for",
      },
      {
        dataField: "account_name",
        text: "Account",
        formatter: removeUnderscore,
      },
      {
        dataField: "group_name",
        text: "Group",
      },
    ];
    return (
      <div>
        <DataTable
          handleTableChange={this.handleTableChange}
          component_data={signatories}
          data_columns={signatories_columns}
          page={this.state.page}
          handleSetTableNode={this.handleSetTableNode}
          loading={signatories_data.isFetching}
        />
      </div>
    );
  }
}

ViewSignatories.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  signatories_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const signatories_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "signatories_url"
  );

  return {
    sessionVariables,
    signatories_data,
  };
}

export default connect(mapStateToProps)(withRouter(ViewSignatories));
