import React, { Component } from "react";

import ViewAppliedLoans from "./ViewAppliedLoans";
import { WRITTEN_OFF_LOANS_COLUMNS } from "../../components/DataTableColumns";
import { withRouter } from "react-router-dom";

class ViewWrittenOffLoans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 5,
    };
  }

  render() {
    return <ViewAppliedLoans status={14} columns={WRITTEN_OFF_LOANS_COLUMNS} />;
  }
}

export default withRouter(ViewWrittenOffLoans);
