import {
  GROUPS_ENDPOINT,
  OBJECT_STORAGE_ENDPOINT,
  OBJECT_STORAGE_MEDIA_BUCKET,
  USERS_ENDPOINT,
} from "../constants/Constants";
import React, { Component } from "react";
import {
  fetchUrlData,
  getSelect2Options,
  getUrlData,
} from "../utils/componentActions";

import CreateEditForm from "../components/CreateEditForm";
import FormFields from "../components/FormFields";
import ModalHOC from "../components/ModalHOC";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class RegisterGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group: {},
      groups_upload: {},
    };
  }

  componentDidMount() {
    const next_group_code_url: string = `${GROUPS_ENDPOINT}?last=true`;
    const group_id: any = this.props.match.params.id;
    if (group_id) {
      const group_url: string = `${GROUPS_ENDPOINT}${group_id}/`;
      fetchUrlData("group_url", group_url, this.props);
    }
    fetchUrlData("users_url", USERS_ENDPOINT, this.props);
    fetchUrlData("next_group_code_url", next_group_code_url, this.props);
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const { group_data, users_data, next_group_code_data } = props;
    const { group } = this.state;
    const users: any = users_data.items;
    const next_group_code: any = next_group_code_data.items[0] || "";
    if (!this.props.match.params.id) {
      group.group_code = next_group_code;
    }
    const user_options: any = getSelect2Options(users, "id", "username");
    const form_classes: string = "row g-3";
    const groups_template_url: string = `${OBJECT_STORAGE_ENDPOINT}/${OBJECT_STORAGE_MEDIA_BUCKET}/groups.csv`;
    const groups_upload_form_items: any = [
      <FormFields
        key={1}
        field_type="file"
        field_label="CSV file"
        props={props}
        field_name="groups_csv"
        wrapper_div_classes="col"
        data_object={{}}
        required
        template_url={groups_template_url}
      />,
    ];
    const groups_upload_classes: string = "row g-3";
    const groups_upload_form: any = (
      <CreateEditForm
        loading={false}
        form_items={groups_upload_form_items}
        form_classes={groups_upload_classes}
        action_object={{}}
        setState={this.updateState}
        save_button_label="Upload"
        action_object_name="groups_upload"
        state={this.state}
        action_object_endpoint={GROUPS_ENDPOINT}
        post_form
        object_display_name="groups upload"
        content_type="multipart/form-data; boundary=----"
      />
    );
    const form_items: any = [
      <FormFields
        key={1}
        field_type="text_input"
        field_label="Name"
        props={props}
        field_name="group_name"
        wrapper_div_classes="col-4"
        data_object={group}
        required
      />,
      <FormFields
        key={2}
        field_type="text_input"
        field_label="Code"
        props={props}
        field_name="group_code"
        wrapper_div_classes="col-4"
        data_object={group}
        required
      />,
      <FormFields
        key={3}
        field_type="date"
        field_label="Registration date"
        props={props}
        field_name="registration_date"
        wrapper_div_classes="col-4"
        data_object={group}
        required
      />,
      <FormFields
        key={4}
        field_type="select2"
        field_label="CRO"
        props={props}
        field_name="cro"
        wrapper_div_classes="col-4"
        select2_options={user_options}
        data_object={group}
        required
      />,
      <FormFields
        key={5}
        field_type="text_input"
        field_label="Physical address"
        props={props}
        field_name="physical_address"
        wrapper_div_classes="col-4"
        data_object={group}
        required
      />,
      <FormFields
        key={6}
        field_type="text_input"
        field_label="County"
        props={props}
        field_name="county"
        wrapper_div_classes="col-4"
        data_object={group}
      />,
      <FormFields
        key={7}
        field_type="text_input"
        field_label="Constituency"
        props={props}
        field_name="constituency"
        wrapper_div_classes="col-4"
        data_object={group}
      />,
    ];
    return (
      <div>
        <div className="row mb-3">
          <ModalHOC
            button_icon="upload"
            button_label="Upload groups"
            button_classes="btn btn-outline-primary"
            modal_title="Upload groups"
          >
            {groups_upload_form}
          </ModalHOC>
        </div>
        <CreateEditForm
          loading={group_data.isFetching}
          form_items={form_items}
          form_classes={form_classes}
          action_object={group_data.items}
          setState={this.updateState}
          action_object_name="group"
          state={this.state}
          action_object_endpoint={GROUPS_ENDPOINT}
          form_id="form-register-group"
          object_display_name="group"
        />
      </div>
    );
  }
}

RegisterGroup.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  group_data: PropTypes.instanceOf(Object).isRequired,
  users_data: PropTypes.instanceOf(Object).isRequired,
  next_group_code_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const group_data: any = getUrlData(dataByUrl, sessionVariables, "group_url");
  const users_data: any = getUrlData(dataByUrl, sessionVariables, "users_url");
  const next_group_code_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "next_group_code_url"
  );

  return {
    sessionVariables,
    group_data,
    users_data,
    next_group_code_data,
  };
}

export default connect(mapStateToProps)(withRouter(RegisterGroup));
