import PropTypes from "prop-types";
import React, { useEffect } from "react";
import BootstrapIcon from "./BootstrapIcon";
import FormFields from "./FormFields";

function MultipleFormFields(props) {
  const { fields_attributes, initial_no_of_rows, data_object } = props;
  const fields_per_row = fields_attributes.length;
  const [fields, setFields] = React.useState([]);
  const [fieldRows, setFieldRows] = React.useState([]);
  const [no_of_rows, setNoOfRows] = React.useState(initial_no_of_rows);

  const add_fields_button = (
    <div className="d-flex align-items-end col-3">
      <button
        type="button"
        className="btn btn-primary mb-2"
        onClick={() => setNoOfRows(no_of_rows + 1)}
      >
        <BootstrapIcon icon="plus-lg" />
      </button>
    </div>
  );

  const removeFieldButton = (row_fields_indexes) => (
    <div className="d-flex align-items-end col-3">
      <button
        type="button"
        className="btn btn-danger mb-2"
        onClick={() => {
          setNoOfRows(no_of_rows - 1);
          setFields(
            fields.filter((field, index) => !row_fields_indexes.includes(index))
          );
        }}
      >
        <BootstrapIcon icon="dash-lg" />
      </button>
    </div>
  );

  function getFields(field_index) {
    return fields_attributes.map((field_attributes) => {
      const { field_type, field_name, field_label, field_props, div_cols } =
        field_attributes;
      const is_checkbox = field_type === "checkbox";
      return (
        <FormFields
          key={Math.random()}
          field_type={field_type}
          field_name={`${field_name}_${field_index}`}
          field_label={is_checkbox === true ? "" : field_label}
          checkbox_field_label={is_checkbox === true ? field_label : ""}
          props={field_props}
          data_object={data_object}
          wrapper_div_classes={`col-${div_cols}`}
        />
      );
    });
  }

  function getButtonRows() {
    const rows_fields = [];
    for (let i = 0; i < no_of_rows; i += 1) {
      rows_fields.push(
        fields.slice(i * fields_per_row, (i + 1) * fields_per_row)
      );
    }
    return rows_fields.map((row_fields, index) => {
      const row_fields_indexes = fields_attributes.map(
        (field_attributes, i) => {
          return i + index + 1;
        }
      );
      return (
        <div className="row" key={Math.random()}>
          {row_fields}
          {index === 0
            ? add_fields_button
            : removeFieldButton(row_fields_indexes)}
        </div>
      );
    });
  }

  useEffect(() => {
    if (no_of_rows > fields.length / fields_per_row) {
      const rows_to_add = no_of_rows - fields.length / fields_per_row;
      const current_no_of_fields = fields.length;
      let new_fields = [];
      for (let i = 0; i < rows_to_add; i += 1) {
        let field_index = current_no_of_fields + i;
        if (current_no_of_fields > 0) {
          field_index = current_no_of_fields + i - 1;
        }
        new_fields = new_fields.concat(getFields(field_index));
      }
      setFields([...fields, ...new_fields]);
    }
  }, [no_of_rows]);

  useEffect(() => {
    setFieldRows(getButtonRows());
  }, [fields]);

  return (
    <div className="row" key={Math.random()}>
      {fieldRows}
    </div>
  );
}

MultipleFormFields.defaultProps = {
  initial_no_of_rows: 1,
  data_object: {},
};

MultipleFormFields.propTypes = {
  fields_attributes: PropTypes.arrayOf(PropTypes.object).isRequired,
  initial_no_of_rows: PropTypes.number,
  data_object: PropTypes.instanceOf(Object),
};

export default MultipleFormFields;
