import {
  MEMBER_ACCOUNTS_ENDPOINT,
  MEMBER_PORTAL_ACCOUNTS_ENDPOINT,
} from "../../constants/Constants";

import PropTypes from "prop-types";
import React from "react";
import { VIEW_MEMBER_ACCOUNT_DETAILS_COLUMNS } from "../../components/DataTableColumns";
import ViewDataTable from "../../components/ViewDataTable";
import { withRouter } from "react-router";

function ViewMemberAccountDetails(props) {
  const { match } = props;
  const { params } = match;
  const { id } = params;
  const { member, auth_token } = localStorage;
  let member_account_url: string = `${MEMBER_ACCOUNTS_ENDPOINT}${id}/`;
  const allow_refresh: boolean = true;
  let extra_get_params: string = "";
  if (member) {
    member_account_url = `${MEMBER_PORTAL_ACCOUNTS_ENDPOINT}${id}/`;
    extra_get_params = `token=${auth_token}&member=${member}`;
  }
  return (
    <ViewDataTable
      allow_refresh
      basic_table
      vertical_headers
      columns={VIEW_MEMBER_ACCOUNT_DETAILS_COLUMNS}
      data_source_url={member_account_url}
      data_source_url_name="member_account_url"
      extra_get_params={extra_get_params}
    />
  );
}

ViewMemberAccountDetails.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(ViewMemberAccountDetails);
