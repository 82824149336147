import {
  LOAN_ACCOUNT_STATEMENT_ENDPOINT,
  MEMBER_LOAN_ACCOUNT_STATEMENT_ENDPOINT,
} from "../../constants/Constants";
import {
  VIEW_LOAN_ACCOUNT_STATEMENT_COLUMNS,
  VIEW_LOAN_ACCOUNT_STATEMENT_HEADERS,
} from "../../components/DataTableColumns";

import PropTypes from "prop-types";
import React from "react";
import ViewDataTable from "../../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

function ViewLoanAccountStatement(props) {
  const loan_id: any = props.match.params.id;
  const { member, auth_token } = localStorage;
  let loan_account_statement_url: string = `${LOAN_ACCOUNT_STATEMENT_ENDPOINT}?loan=${loan_id}`;
  if (member) {
    loan_account_statement_url = `${MEMBER_LOAN_ACCOUNT_STATEMENT_ENDPOINT}?token=${auth_token}&member=${member}&loan=${loan_id}`;
  }
  return (
    <div className="row">
      <div className="col table-responsive">
        <ViewDataTable
          allow_refresh
          allow_print
          basic_table
          paginated={false}
          totals
          columns={VIEW_LOAN_ACCOUNT_STATEMENT_COLUMNS}
          headers={VIEW_LOAN_ACCOUNT_STATEMENT_HEADERS}
          data_source_url={loan_account_statement_url}
          data_source_url_name="loan_account_statement_url"
        />
      </div>
    </div>
  );
}

ViewLoanAccountStatement.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ViewLoanAccountStatement));
