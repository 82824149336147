import { API_ENDPOINT, MEMBERS_ENDPOINT } from "../../constants/Constants";
import React, { Component } from "react";
import {
  extractResponseError,
  fetchUrlData,
  getUrlData,
  label,
  pushHistory,
} from "../../utils/componentActions";
import { faCheck, faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";

import DataTable from "../../components/DataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormActivityIndicator from "../../components/FormActivityIndicator";
import FormFeedbackMessage from "../../components/FormFeedbackMessage";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { postAPIRequest } from "../../utils/APIRequests";
import { withRouter } from "react-router-dom";

class ApproveMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 5,
      activity: false,
      confirm_action: false,
      selected_rows: [],
      feedback_type: "primary",
      feedback_message: null,
    };
  }

  componentDidMount() {
    const members_url: string = `${MEMBERS_ENDPOINT}?status=6&limit=5&offset=0`;
    fetchUrlData("members_url", members_url, this.props);
  }

  handleTableChange = (type, { page, sizePerPage }) => {
    const offset: any = (page - 1) * sizePerPage;
    const members_url: string = `${MEMBERS_ENDPOINT}?status=6&limit=${sizePerPage}&offset=${offset}`;
    fetchUrlData("members_url", members_url, this.props);
    this.setState(() => ({
      page,
      sizePerPage,
    }));
  };

  handleApproveReject = (action = null) => {
    const selected_rows: any = this.state.table_node.selectionContext.selected;
    if (selected_rows.length === 0) {
      this.setState({
        feedback_type: "danger",
        feedback_message: label(
          "You need to select at least one member",
          this.props
        ),
      });
    } else if (!this.state.confirm_action) {
      this.setState({
        confirm_action: true,
        action,
        selected_rows,
        feedback_message: null,
      });
    } else {
      const approve_members_url: any = API_ENDPOINT + MEMBERS_ENDPOINT;
      let status: number = 1;
      const { action } = this.state;
      if (action === "reject") {
        status = 10;
      }
      this.setState({
        activity: true,
      });
      postAPIRequest(
        approve_members_url,
        () => {
          const { sizePerPage } = this.state;
          const { page } = this.state;
          const offset: any = (page - 1) * sizePerPage;
          const members_url: string = `${MEMBERS_ENDPOINT}?status=6&limit=${sizePerPage}&offset=${offset}`;
          fetchUrlData("members_url", members_url, this.props, true);
          this.setState({
            activity: false,
            feedback_type: "success",
            feedback_message: `${selected_rows.length} ${label(
              `member(s) ${action} successful`,
              this.props
            )}`,
          });
        },
        (results) => {
          const feedback_message: any = extractResponseError(results);
          this.setState({
            activity: false,
            feedback_message,
            feedback_type: "danger",
          });
        },
        {
          status,
          approve_members: true,
          member_ids: selected_rows,
        },
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        }
      );
    }
  };

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  handleCancel = () => {
    this.setState({
      confirm_action: false,
    });
  };

  render() {
    const { members_data } = this.props;
    const members: any = members_data.items;
    const editFieldButton: any = (_, row) => {
      const member_id: any = row.id;
      return (
        <button
          className="btn btn-light"
          onClick={() =>
            pushHistory(
              `/register_member/member_details?id=${member_id}`,
              this.props,
              "hard"
            )
          }
        >
          <FontAwesomeIcon icon={faEdit} className="text-blue" />
        </button>
      );
    };
    const selectRow: { mode: string, clickToSelect: boolean } = {
      mode: "checkbox",
      clickToSelect: true,
    };
    const members_columns: any = [
      {
        dataField: "id",
        text: "ID",
      },
      {
        dataField: "member_no",
        text: "Member no",
      },
      {
        dataField: "first_name",
        text: "First name",
      },
      {
        dataField: "middle_name",
        text: "Middle name",
      },
      {
        dataField: "last_name",
        text: "Last name",
      },
      {
        dataField: "personal_email",
        text: "Email",
      },
      {
        dataField: "mobile_no",
        text: "Phone number",
      },
      {
        dataField: "id_no",
        text: "ID no",
      },
      {
        dataField: "branch_name",
        text: "Branch",
      },
      {
        dataField: "cro_username",
        text: "CRO",
      },
      {
        dataField: "edit",
        text: "Edit",
        isDummyField: true,
        csvExport: false,
        formatter: (cell, row) => editFieldButton(cell, row),
      },
    ];
    let approve_reject_buttons: any = [
      <button
        className="btn btn-light btn-outline-dark"
        key={1}
        type="button"
        onClick={() => this.handleApproveReject("approve")}
      >
        {label("Approve", this.props)}{" "}
        <FontAwesomeIcon icon={faCheck} className="text-green" />
      </button>,
      <button
        className="btn btn-light btn-outline-dark ml-2"
        key={2}
        type="button"
        onClick={() => this.handleApproveReject("reject")}
      >
        {label("Reject", this.props)}{" "}
        <FontAwesomeIcon icon={faTimes} className="text-red" />
      </button>,
    ];
    if (this.state.activity) {
      approve_reject_buttons = <FormActivityIndicator />;
    } else if (this.state.confirm_action) {
      approve_reject_buttons = [
        <div className="row" key={1}>
          <div className="col-md-12">
            <span className="text-capitalize">{this.state.action}</span>{" "}
            {this.state.selected_rows.length} member(s)?
          </div>
        </div>,
        <div className="row" key={2}>
          <div className="col-md-12">
            <button
              className="btn btn-light btn-outline-dark"
              onClick={() => this.handleApproveReject()}
            >
              {label("Sure", this.props)}{" "}
              <FontAwesomeIcon icon={faCheck} className="text-green" />
            </button>
            <button
              className="btn btn-light btn-outline-dark ml-2"
              onClick={() => this.handleCancel()}
            >
              {label("Cancel", this.props)}{" "}
              <FontAwesomeIcon icon={faTimes} className="text-red" />
            </button>
          </div>
        </div>,
      ];
    }
    let feedback_message: string = "";
    if (this.state.feedback_message) {
      feedback_message = (
        <FormFeedbackMessage
          feedback_message={this.state.feedback_message}
          feedback_type={this.state.feedback_type}
        />
      );
    }

    return (
      <div>
        <div className="row">
          <div className="col-md-6">{approve_reject_buttons}</div>
        </div>
        <div className="mt-3">{feedback_message}</div>
        <DataTable
          handleTableChange={this.handleTableChange}
          component_data={members}
          data_columns={members_columns}
          page={this.state.page}
          selectRow={selectRow}
          handleSetTableNode={this.handleSetTableNode}
          loading={members_data.isFetching}
        />
      </div>
    );
  }
}

ApproveMembers.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  members_data: PropTypes.instanceOf(Object).isRequired,
  status_data: PropTypes.instanceOf(Object).isRequired,
  groups_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const members_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "members_url"
  );
  const status_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "status_url"
  );
  const groups_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "groups_url"
  );
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    members_data,
    status_data,
    groups_data,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(ApproveMembers));
