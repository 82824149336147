import { MEMBER_WRITTEN_OFF_LOANS_COLUMNS } from "../components/DataTableColumns";
import MemberViewAppliedLoans from "./ViewAppliedLoans";
import React from "react";
import { withRouter } from "react-router-dom";

function MemberViewWrittenOffLoans() {
  return (
    <MemberViewAppliedLoans
      status={14}
      columns={MEMBER_WRITTEN_OFF_LOANS_COLUMNS}
    />
  );
}

export default withRouter(MemberViewWrittenOffLoans);
