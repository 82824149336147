import React, { Component } from "react";
import { getUrlData, label, objGetField } from "../utils/componentActions";

import BootstrapIcon from "./BootstrapIcon";
import { Modal } from "bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ModalHOC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_visible: false,
    };
  }

  showModal() {
    const modal_id: any = this.props.data_target || "staticBackdrop";
    const onModalShow: any = this.props.onModalShow || function () {};
    const modal_element: any = document.getElementById(modal_id);
    if (modal_element) {
      const modalInstance: any = new Modal(modal_element, {
        keyboard: false,
        backdrop: "static",
      });
      modal_element.addEventListener("hidden.bs.modal", (e) => {
        this.setState({
          modal_visible: false,
        });
      });
      modal_element.addEventListener("shown.bs.modal", (e) => {
        onModalShow();
      });
      modalInstance.show();
    }
  }

  componentDidMount() {
    const { modal_visible } = this.state;
    const modal_id: any = this.props.data_target || "staticBackdrop";
    const modal_show: any = objGetField(
      this.props,
      "modal_show",
      modal_visible
    );
    if (modal_id && modal_show) {
      this.showModal();
    }
  }

  componentDidUpdate(previousProps, previousState, ___) {
    const { modal_visible } = this.state;
    const previous_modal_visible: any = previousState.modal_visible;
    const modal_show: any = objGetField(
      this.props,
      "modal_show",
      modal_visible
    );
    const previous_modal_show: any = objGetField(
      previousProps,
      "modal_show",
      previous_modal_visible
    );
    const modal_id: any = this.props.data_target || "staticBackdrop";
    if (modal_id && modal_show && !previous_modal_show) {
      this.showModal();
    }
  }

  handleModalShow = () => {
    this.setState({
      modal_visible: true,
    });
  };

  handleModalClose = async () => {
    const { modalCloseCallback } = this.props;
    const modal_id: any = this.props.data_target || "staticBackdrop";
    const formModal: any = new Modal.getInstance(
      document.getElementById(modal_id)
    );
    await formModal.hide();
    if (modalCloseCallback) {
      modalCloseCallback(
        this.setState({
          modal_visible: false,
        })
      );
    } else {
      this.setState({
        modal_visible: false,
      });
    }
  };

  render() {
    const { props } = this;
    const data_target: any = this.props.data_target || "staticBackdrop";
    const modal_size: any = this.props.modal_size || "modal-md";
    const button_only: any = this.props.button_only || false;
    const modal_only: any = this.props.modal_only || false;
    let modal_footer: any = <div />;
    const body_classes: any = this.props.body_classes || "";
    const dismissible: any = objGetField(this.props, "dismissible", true);
    let close_button: any = (
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={this.handleModalClose}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    );
    if (!dismissible) {
      close_button = <div />;
    }
    if (this.props.show_close_button) {
      modal_footer = (
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={this.handleModalClose}
          >
            Close
          </button>
        </div>
      );
    }
    let modal_button: any = (
      <button
        type="button"
        className={this.props.button_classes}
        onClick={this.handleModalShow}
      >
        <BootstrapIcon icon={this.props.button_icon} />{" "}
        {label(this.props.button_label, props)}
      </button>
    );
    if (modal_only) {
      modal_button = <div />;
    }
    let modal: any = (
      <div
        className="modal fade"
        id={data_target}
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className={`modal-dialog ${modal_size}`}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {label(this.props.modal_title, props)}
              </h5>
              {close_button}
            </div>
            <div className={`modal-body ${body_classes}`}>
              {this.props.children}
            </div>
            {modal_footer}
          </div>
        </div>
      </div>
    );
    if (button_only) {
      modal = <div />;
    }
    return (
      <div>
        {modal_button}
        {modal}
      </div>
    );
  }
}

ModalHOC.propTypes = {
  modal_show: PropTypes.bool,
  data_target: PropTypes.string,
  modal_size: PropTypes.string,
  button_only: PropTypes.bool,
  modal_only: PropTypes.bool,
  body_classes: PropTypes.string,
  show_close_button: PropTypes.bool,
  button_classes: PropTypes.string,
  button_icon: PropTypes.string,
  button_label: PropTypes.string,
  modal_title: PropTypes.string,
  modalCloseCallback: PropTypes.func,
  onModalShow: PropTypes.func,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    dataByUrl,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(ModalHOC));
