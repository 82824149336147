import React, { Component } from "react";
import {
  fetchUrlData,
  getSelect2Options,
  getUrlData,
} from "../utils/componentActions";

import CreateEditForm from "../components/CreateEditForm";
import FormFields from "../components/FormFields";
import {
  LOAN_PRODUCTS_ENDPOINT,
  MPAY_BUSINESS_NUMBERS_ENDPOINT,
  SAVINGS_PRODUCTS_ENDPOINT,
  ORGANIZATION_BRANCHES_ENDPOINT,
} from "../constants/Constants";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class RegisterBusinessNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      business_number: {},
    };
  }

  componentDidMount() {
    const business_number_id: any = this.props.match.params.id;
    if (business_number_id) {
      const business_number_url: string = `${MPAY_BUSINESS_NUMBERS_ENDPOINT}${business_number_id}/`;
      fetchUrlData("business_number_url", business_number_url, this.props);
    }
    fetchUrlData("loan_products_url", LOAN_PRODUCTS_ENDPOINT, this.props);
    fetchUrlData("savings_products_url", SAVINGS_PRODUCTS_ENDPOINT, this.props);
    fetchUrlData(
      "organization_branches_url",
      ORGANIZATION_BRANCHES_ENDPOINT,
      this.props
    );
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const {
      business_number_data,
      loan_products_data,
      savings_products_data,
      organization_branches_data,
    } = props;
    const form_classes: string = "row g-3";
    const { business_number } = this.state;
    const loan_products: any = loan_products_data.items;
    const loan_products_options: any = getSelect2Options(
      loan_products,
      "id",
      "product_name"
    );
    const savings_products: any = savings_products_data.items;
    const savings_products_options: any = getSelect2Options(
      savings_products,
      "id",
      "product_name"
    );
    const organization_branches: any = organization_branches_data.items;
    const organization_branches_options: any = getSelect2Options(
      organization_branches,
      "id",
      "name"
    );
    const extra_form_data: null[] = [];
    if (business_number.default_loan_products) {
      extra_form_data.push({
        name: "default_loan_products",
        value: business_number.default_loan_products,
        type: "array",
      });
    }
    if (business_number.default_savings_products) {
      extra_form_data.push({
        name: "default_savings_products",
        value: business_number.default_savings_products,
        type: "array",
      });
    }
    const form_items: any = [
      <FormFields
        key={1}
        field_type="number_input"
        field_label="Paybill number"
        props={props}
        field_name="number"
        wrapper_div_classes="col-4"
        data_object={business_number}
        required
      />,
      <FormFields
        key={2}
        field_type="text_input"
        field_label="Consumer key"
        props={props}
        field_name="consumer_key"
        wrapper_div_classes="col-4"
        data_object={business_number}
        required
      />,
      <FormFields
        key={3}
        field_type="text_input"
        field_label="Consumer secret"
        props={props}
        field_name="consumer_secret"
        wrapper_div_classes="col-4"
        data_object={business_number}
        required
      />,
      <FormFields
        key={4}
        field_type="select2_multi"
        field_label="Default loan products"
        props={props}
        update_payload
        data_object_name="business_number"
        updateState={this.updateState}
        field_name="default_loan_products"
        wrapper_div_classes="col-4"
        data_object={business_number}
        select2_options={loan_products_options}
      />,
      <FormFields
        key={5}
        field_type="select2_multi"
        field_label="Default savings products"
        props={props}
        update_payload
        data_object_name="business_number"
        updateState={this.updateState}
        field_name="default_savings_products"
        wrapper_div_classes="col-4"
        data_object={business_number}
        select2_options={savings_products_options}
      />,
      <FormFields
        key={6}
        field_type="select2"
        field_label="Branch"
        props={props}
        update_payload
        data_object_name="business_number"
        updateState={this.updateState}
        field_name="branch"
        wrapper_div_classes="col-4"
        data_object={business_number}
        select2_options={organization_branches_options}
      />,
    ];
    return (
      <CreateEditForm
        loading={
          business_number_data.isFetching ||
          loan_products_data.isFetching ||
          savings_products_data.isFetching ||
          organization_branches_data.isFetching
        }
        form_items={form_items}
        form_classes={form_classes}
        extra_form_data={extra_form_data}
        action_object={business_number_data.items}
        setState={this.updateState}
        action_object_name="business_number"
        state={this.state}
        action_object_endpoint={MPAY_BUSINESS_NUMBERS_ENDPOINT}
        post_form
        object_display_name="business number"
        form_id="form-business-number"
        content_type="multipart/form-data; boundary=----"
      />
    );
  }
}

RegisterBusinessNumber.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  business_number_data: PropTypes.instanceOf(Object).isRequired,
  loan_products_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const business_number_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "business_number_url"
  );
  const loan_products_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "loan_products_url"
  );
  const savings_products_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "savings_products_url"
  );
  const organization_branches_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "organization_branches_url"
  );

  return {
    sessionVariables,
    business_number_data,
    loan_products_data,
    savings_products_data,
    organization_branches_data,
  };
}

export default connect(mapStateToProps)(withRouter(RegisterBusinessNumber));
