import {
  formatDate,
  label,
  parseJSON,
  removeUnderscore,
  thousandsFormat,
} from "../utils/componentActions";
import { ABSOLUTE_API_ENDPOINT } from "../constants/Constants";
import BootstrapIcon from "./BootstrapIcon";
import Datetime from "react-datetime";
import FileViewerModal from "./FileViewerModal";
import ModalHOC from "./ModalHOC";
import React from "react";
import _ from "lodash";
import moment from "moment";

const pendingApprovalFormatter: any = function (cell) {
  let maker_checker_tasks;
  try {
    maker_checker_tasks = JSON.parse(cell) || [];
  } catch (e) {
    maker_checker_tasks = [];
  }
  const pending_loan_disbursement_task: any = maker_checker_tasks.find(
    (task) =>
      task.type === "loan disbursement" && ["6", 6].includes(task.status)
  );
  if (pending_loan_disbursement_task) {
    return (
      <span className="text-green">
        <BootstrapIcon icon="check-circle-fill" />
      </span>
    );
  }
  return (
    <span className="text-danger">
      <BootstrapIcon icon="x-circle-fill" />
    </span>
  );
};

const viewLoanButton: any = function (cell, row) {
  return (
    <a
      href={`/loanAccount/statement/${row.id}`}
      className="btn btn-outline-primary"
      target="_blank"
    >
      View loan
    </a>
  );
};

export const PENDING_DISBURSEMENT_LOANS_COLUMNS: any = [
  {
    dataField: "id",
    text: "Loan ID",
  },
  {
    dataField: "member_name",
    text: "Member name",
  },
  {
    dataField: "member_no",
    text: "Member no",
  },
  {
    dataField: "loan_name",
    text: "Loan name",
  },
  {
    dataField: "loan_reference_no",
    text: "Loan reference no",
  },
  {
    dataField: "amount",
    text: "Applied amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "approved_amount",
    text: "Approved amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "date_of_loan_application",
    text: "Application date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
  {
    dataField: "disbursement_date",
    text: "Disbursement date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
  {
    dataField: "maker_checker_tasks",
    text: "Pending disbursement approval",
    formatter: pendingApprovalFormatter,
  },
];

export const PROCESSED_LOANS_COLUMNS: any = [
  {
    dataField: "id",
    text: "Loan ID",
  },
  {
    dataField: "member_name",
    text: "Member name",
  },
  {
    dataField: "member_no",
    text: "Member no",
  },
  {
    dataField: "loan_name",
    text: "Loan name",
  },
  {
    dataField: "loan_reference_no",
    text: "Loan reference no",
  },
  {
    dataField: "amount",
    text: "Applied amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "approved_amount",
    text: "Approved amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "date_of_loan_application",
    text: "Application date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
  {
    dataField: "disbursement_date",
    text: "Disbursement date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
  {
    dataField: "view loan",
    formatter: viewLoanButton,
  },
];

export const ACTIVE_LOANS_COLUMNS: any = [
  {
    dataField: "id",
    text: "Loan ID",
  },
  {
    dataField: "member_name",
    text: "Member name",
  },
  {
    dataField: "member_no",
    text: "Member no",
  },
  {
    dataField: "loan_name",
    text: "Loan name",
  },
  {
    dataField: "loan_reference_no",
    text: "Loan reference no",
  },
  {
    dataField: "amount",
    text: "Applied amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "approved_amount",
    text: "Approved amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "date_of_loan_application",
    text: "Application date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
  {
    dataField: "disbursement_date",
    text: "Disbursement date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
  {
    dataField: "view loan",
    formatter: viewLoanButton,
  },
];

export const COMPLETED_LOANS_COLUMNS: any = [
  {
    dataField: "id",
    text: "Loan ID",
  },
  {
    dataField: "member_name",
    text: "Member name",
  },
  {
    dataField: "member_no",
    text: "Member no",
  },
  {
    dataField: "loan_name",
    text: "Loan name",
  },
  {
    dataField: "loan_reference_no",
    text: "Loan reference no",
  },
  {
    dataField: "amount",
    text: "Applied amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "approved_amount",
    text: "Approved amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "date_of_loan_application",
    text: "Application date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
  {
    dataField: "disbursement_date",
    text: "Disbursement date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
  {
    dataField: "view loan",
    formatter: viewLoanButton,
  },
];

export const REJECTED_LOANS_COLUMNS: any = [
  {
    dataField: "id",
    text: "Loan ID",
  },
  {
    dataField: "member_name",
    text: "Member name",
  },
  {
    dataField: "member_no",
    text: "Member no",
  },
  {
    dataField: "loan_name",
    text: "Loan name",
  },
  {
    dataField: "loan_reference_no",
    text: "Loan reference no",
  },
  {
    dataField: "amount",
    text: "Applied amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "rejection_reason",
    text: "Rejection reason",
  },
  {
    dataField: "date_of_loan_application",
    text: "Application date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
  {
    dataField: "view loan",
    formatter: viewLoanButton,
  },
];

export const REVERSED_LOANS_COLUMNS: any = [
  {
    dataField: "id",
    text: "Loan ID",
  },
  {
    dataField: "member_name",
    text: "Member name",
  },
  {
    dataField: "member_no",
    text: "Member no",
  },
  {
    dataField: "loan_name",
    text: "Loan name",
  },
  {
    dataField: "loan_reference_no",
    text: "Loan reference no",
  },
  {
    dataField: "amount",
    text: "Applied amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "reversal_reason",
    text: "Reversal reason",
  },
  {
    dataField: "date_of_loan_application",
    text: "Application date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },

  {
    dataField: "view loan",
    formatter: viewLoanButton,
  },
];

const viewDocumentButton: any = function (cell, row) {
  return cell ? (
    <FileViewerModal file_url={cell} data_target={`view-file-${row.id}`} />
  ) : (
    <div />
  );
};

const viewLoanDetailsButton: any = function () {
  return (
    <ModalHOC
      button_only
      button_icon="eye"
      button_classes="btn btn-outline-primary"
      button_label="View loan details"
      data_target="view-loan-details"
    />
  );
};

export const PENDING_LOANS_COLUMNS: any = [
  {
    dataField: "id",
    text: "Loan ID",
  },
  {
    dataField: "member_name",
    text: "Member name",
  },
  {
    dataField: "member_no",
    text: "Member no",
  },
  {
    dataField: "loan_name",
    text: "Loan name",
  },
  {
    dataField: "loan_reference_no",
    text: "Loan reference no",
  },
  {
    dataField: "amount",
    text: "Applied amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "date_of_loan_application",
    text: "Application date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
  {
    dataField: "application_document",
    text: "Loan application document",
    formatter: (cell, row) => viewDocumentButton(cell, row),
  },
  {
    dataField: "",
    text: "Loan details",
    formatter: (cell, row) => viewLoanDetailsButton(cell, row),
  },
  {
    dataField: "view loan",
    formatter: viewLoanButton,
  },
];

export const WRITTEN_OFF_LOANS_COLUMNS: any = [
  {
    dataField: "id",
    text: "Loan ID",
  },
  {
    dataField: "member_name",
    text: "Member name",
  },
  {
    dataField: "member_no",
    text: "Member no",
  },
  {
    dataField: "loan_name",
    text: "Loan name",
  },
  {
    dataField: "loan_reference_no",
    text: "Loan reference no",
  },
  {
    dataField: "amount",
    text: "Applied amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "approved_amount",
    text: "Approved amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "date_of_loan_application",
    text: "Application date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
  {
    dataField: "disbursement_date",
    text: "Disbursement date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },

  {
    dataField: "view loan",
    formatter: viewLoanButton,
  },
];

export const GUARANTEED_LOANS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "loan_member",
    text: "Member",
  },
  {
    dataField: "loan_member_no",
    text: "Member no",
  },
  {
    dataField: "loan_type",
    text: "Loan product",
  },
  {
    dataField: "loan_amount",
    text: "Loan amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "collateral_id_no",
    text: "Collateral ID no",
  },
  {
    dataField: "savings_amount",
    text: "Savings amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "collateral_amount",
    text: "Collateral amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "loan_application_date",
    text: "Loan application date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
  {
    dataField: "loan_disbursement_date",
    text: "Loan disbursement date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
  {
    dataField: "loan_purpose",
    text: "Loan purpose",
  },
  {
    dataField: "loan_status",
    text: "Loan status",
  },
];

export const LOAN_GUARANTORS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "member_name",
    text: "Member",
  },
  {
    dataField: "non_member_name",
    text: "Non-member",
  },
  {
    dataField: "savings_amount",
    text: "Savings amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "collateral_amount",
    text: "Collateral amount",
    formatter: thousandsFormat,
  },
];

export const FINANCIAL_YEAR_CLOSING_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "account_name",
    text: "Account name",
  },
  {
    dataField: "debit",
    text: "Debit",
    formatter: thousandsFormat,
  },
  {
    dataField: "credit",
    text: "Credit",
    formatter: thousandsFormat,
  },
  {
    dataField: "financial_year_closing_date",
    text: "Date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
  {
    dataField: "transaction_code",
    text: "Transaction code",
  },
  {
    dataField: "comment",
    text: "Comment",
  },
];

const booleanIconFormatter: any = function (cell) {
  if ([true, "True", "true", "yes", "1"].includes(cell)) {
    return (
      <span className="text-green">
        <BootstrapIcon icon="check-circle-fill" />
      </span>
    );
  }
  return (
    <span className="text-danger">
      <BootstrapIcon icon="x-circle-fill" />
    </span>
  );
};

export const USERS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "first_name",
    text: "First name",
  },
  {
    dataField: "last_name",
    text: "Last name",
  },
  {
    dataField: "username",
    text: "Username",
  },
  {
    dataField: "is_active",
    text: "Is active?",
    formatter: (cell) => booleanIconFormatter(cell),
  },
  {
    dataField: "is_admin",
    text: "Is admin?",
    formatter: (cell) => booleanIconFormatter(cell),
  },
  {
    dataField: "is_logged_in",
    text: "Is logged in?",
    formatter: (cell) => booleanIconFormatter(cell),
  },
  {
    dataField: "email",
    text: "Email address",
  },
  {
    dataField: "phone_number",
    text: "Phone number",
  },
  {
    dataField: "branch",
    text: "Branch",
  },
  {
    dataField: "user_type",
    text: "User type",
  },
  {
    dataField: "last_login",
    text: "Last login",
    formatter: (cell) => moment(cell).format("LLLL"),
  },
];

export const PERMISSIONS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "name",
    text: "Permission",
  },
  {
    dataField: "friendly_app_name",
    text: "Module",
  },
];

export const USER_GROUPS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "name",
    text: "Name",
  },
];

export const VIEW_LOAN_ACCOUNT_LEFT_COLUMNS: any = [
  {
    dataField: "member_name",
    text: "Member name",
  },
  {
    dataField: "member_no",
    text: "Member no",
  },
  {
    dataField: "loan_name",
    text: "Loan type",
  },
  {
    dataField: "loan_reference_no",
    text: "Reference no",
  },
  {
    dataField: "no_of_repayments",
    text: "No of repayments",
  },
];

export const VIEW_LOAN_ACCOUNT_RIGHT_COLUMNS: any = [
  {
    dataField: "amount",
    text: "Applied amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
  },
  {
    dataField: "approved_amount",
    text: "Approved amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
  },
  {
    dataField: "disbursed_amount",
    text: "Disbursed amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
  },
  {
    dataField: "date_of_loan_application",
    text: "Application date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
  {
    dataField: "disbursement_date",
    text: "Disbursement date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
];

export const VIEW_LOAN_ACCOUNT_DETAILS_COLUMNS: any = [
  {
    dataField: "no_of_repayments",
    text: "Repayments",
  },
  {
    dataField: "used_parameters",
    text: "Repayment interval",
    formatter: (cell) =>
      `${parseJSON(cell).repaid_every} ${parseJSON(cell).repayment_interval}`,
  },
  {
    dataField: "used_parameters",
    text: "Amortization",
    formatter: (cell) => removeUnderscore(parseJSON(cell).amortization),
  },
  {
    dataField: "interest_rate",
    text: "Interest rate",
  },
  {
    dataField: "used_parameters",
    text: "Interest charged per",
    formatter: (cell) => parseJSON(cell).interest_charged_per,
  },
  {
    dataField: "used_parameters",
    text: "Interest method",
    formatter: (cell) => removeUnderscore(parseJSON(cell).interest_method),
  },
  {
    dataField: "grace_period",
    text: "Grace period",
  },
  {
    dataField: "days_before_first_repayment",
    text: "Days before first repayment",
  },
  {
    dataField: "used_parameters",
    text: "Days overdue before moving to areas",
    formatter: (cell) => parseJSON(cell).days_overdue_before_arreas,
  },
  {
    dataField: "used_parameters",
    text: "Days overdue before moving to NPA",
    formatter: (cell) => parseJSON(cell).days_overdue_before_npa,
  },
  {
    dataField: "application_document",
    text: "Loan account document",
    formatter: (cell, row) => viewDocumentButton(cell, row),
  },
];

export const VIEW_LOAN_REPAYMENT_SCHEDULE_COLUMNS: any = [
  {
    dataField: "date",
    text: "Date",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
  },
  {
    dataField: "paid_date",
    text: "Paid date",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
  },
  {
    dataField: "principal_due",
    text: "Principal due",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
    checkDisplay: true,
  },
  {
    dataField: "principal_plus_fees",
    text: "Principal and fees",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
    checkDisplay: true,
  },
  {
    dataField: "balance_of_loan",
    text: "Balance of loan",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    checkDisplay: true,
  },
  {
    dataField: "interest",
    text: "Interest",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
    checkDisplay: true,
  },
  {
    dataField: "fees",
    text: "Fees",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
    checkDisplay: true,
  },
  {
    dataField: "penalties",
    text: "Penalties",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
    checkDisplay: true,
  },
  {
    dataField: "due",
    text: "Due",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "paid",
    text: "Paid",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "in_advance",
    text: "In advance",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "late",
    text: "Late",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "outstanding",
    text: "Outstanding",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
];

export const VIEW_LOAN_ACCOUNT_STATEMENT_COLUMNS: any = [
  {
    dataField: "date",
    text: "Date",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
  },
  {
    dataField: "details",
    text: "Details",
  },
  {
    dataField: "total",
    text: "Debit",
    dataProcessor(cell) {
      return cell.debit;
    },
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "total",
    text: "Credit",
    dataProcessor(cell) {
      return cell.credit;
    },
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "total",
    text: "Balance",
    dataProcessor: (cell) => cell.balance,
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
  },
  {
    dataField: "principal",
    text: "Debit",
    dataProcessor(cell) {
      return cell.debit;
    },
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "principal",
    text: "Credit",
    dataProcessor(cell) {
      return cell.credit;
    },
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "principal",
    text: "Balance",
    dataProcessor: (cell) => cell.balance,
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
  },
  {
    dataField: "interest",
    text: "Debit",
    dataProcessor(cell) {
      return cell.debit;
    },
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "interest",
    text: "Credit",
    dataProcessor(cell) {
      return cell.credit;
    },
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "interest",
    text: "Balance",
    dataProcessor: (cell) => cell.balance,
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
  },
  {
    dataField: "fees",
    text: "Debit",
    dataProcessor(cell) {
      return cell.debit;
    },
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "fees",
    text: "Credit",
    dataProcessor(cell) {
      return cell.credit;
    },
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "fees",
    text: "Balance",
    dataProcessor: (cell) => cell.balance,
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
  },
  {
    dataField: "penalties",
    text: "Debit",
    dataProcessor(cell) {
      return cell.debit;
    },
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "penalties",
    text: "Credit",
    dataProcessor(cell) {
      return cell.credit;
    },
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "penalties",
    text: "Balance",
    dataProcessor: (cell) => cell.balance,
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
  },
];

export const VIEW_LOAN_ACCOUNT_STATEMENT_HEADERS: any = [
  [
    {
      label: "Member name",
      formatter: (cell, row, props) =>
        `${label("Name", props)}: ${row.member_name} ${label(
          "Mobile no",
          props
        )}: ${row.member_phone}`,
      colSpan: 17,
      classes: "text-center",
      preTranslated: true,
    },
  ],
  [
    {
      label: "Loan name",
      formatter: (cell, row, props) =>
        `${row.loan_name} ${label("loan_statement_account_statement", props)}`,
      colSpan: 17,
      classes: "text-center",
      preTranslated: true,
    },
  ],
  [
    {
      label: "",
      colSpan: 1,
    },
    {
      label: "",
      colSpan: 1,
    },
    {
      label: "loan_statement_total",
      colSpan: 3,
    },
    {
      label: "loan_statement_principal",
      colSpan: 3,
    },
    {
      label: "loan_statement_interest",
      colSpan: 3,
    },
    {
      label: "loan_statement_fees",
      colSpan: 3,
    },
    {
      label: "loan_statement_penalties",
      colSpan: 3,
    },
  ],
];

export const VIEW_LOAN_ACCOUNT_MINI_STATEMENT_COLUMNS: any = [
  {
    dataField: "date",
    text: "Date",
  },
  {
    dataField: "details",
    text: "Details",
  },
  {
    dataField: "total",
    text: "Amount",
    dataProcessor(cell) {
      return cell.credit;
    },
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "total",
    text: "Balance",
    dataProcessor: (cell) => cell.balance,
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
  },
];

export const VIEW_LOAN_ACCOUNT_MINI_STATEMENT_HEADERS: any = [
  [
    {
      label: "Member name",
      formatter: (cell, row, props) =>
        `${label("Name", props)}: ${row.member_name} ${label(
          "Mobile no",
          props
        )}: ${row.member_phone}`,
      colSpan: 5,
      classes: "text-center",
      preTranslated: true,
    },
  ],
  [
    {
      label: "Loan name",
      formatter: (cell, row, props) =>
        `${row.loan_name} ${label("loan_statement_mini_statement", props)}`,
      colSpan: 5,
      classes: "text-center",
      preTranslated: true,
    },
  ],
  [
    {
      label: "",
      colSpan: 1,
    },
    {
      label: "",
      colSpan: 1,
    },
    {
      label: "loan_statement_total",
      colSpan: 3,
    },
  ],
];

export const VIEW_LOAN_CLIENT_STATEMENT_COLUMNS: any = [
  {
    dataField: "date",
    text: "Date",
  },
  {
    dataField: "details",
    text: "Details",
  },
  {
    dataField: "payment",
    text: "Payment",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "charges",
    text: "Charges",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "balance",
    text: "Balance",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
  },
];

export const VIEW_LOAN_CLIENT_STATEMENT_HEADERS: any = [
  [
    {
      label: "Member name",
      formatter: (cell, row, props) =>
        `${label("Name", props)}: ${row.member_name} ${label(
          "Mobile no",
          props
        )}: ${row.member_phone}`,
      colSpan: 5,
      classes: "text-center",
      preTranslated: true,
    },
  ],
  [
    {
      label: "Loan name",
      formatter: (cell, row, props) =>
        `${row.loan_name} ${label("loan_statement_client_statement", props)}`,
      colSpan: 5,
      classes: "text-center",
      preTranslated: true,
    },
  ],
  [
    {
      label: "Statement date",
      formatter: (_cell, _row, props) =>
        `${label("Statement date", props)}: ${moment().format("DD-MMM-YYYY")}`,
      colSpan: 5,
      classes: "text-center",
      preTranslated: true,
    },
  ],
  [
    {
      label: "Current",
      formatter: (cell, row, props) =>
        `${label("Current", props)}: ${thousandsFormat(row.current_due)}`,
      colSpan: 1,
      preTranslated: true,
    },
    {
      label: "30 days overdue",
      formatter: (cell, row, props) =>
        `30 ${label("days overdue", props)}: ${thousandsFormat(
          row._30_days_overdue
        )}`,
      colSpan: 1,
      preTranslated: true,
    },
    {
      label: "60 days overdue",
      formatter: (cell, row, props) =>
        `60 ${label("days overdue", props)}: ${thousandsFormat(
          row._60_days_overdue
        )}`,
      colSpan: 1,
      preTranslated: true,
    },
    {
      label: "90 days overdue",
      formatter: (cell, row, props) =>
        `90 ${label("days overdue", props)}: ${thousandsFormat(
          row._90_days_overdue
        )}`,
      colSpan: 1,
      preTranslated: true,
    },
    {
      label: "120 days overdue",
      formatter: (cell, row, props) =>
        `120 ${label("days overdue", props)}: ${thousandsFormat(
          row._120_days_overdue
        )}`,
      colSpan: 1,
      preTranslated: true,
    },
  ],
  [
    {
      label: "Installment amount",
      formatter: (cell, row, props) =>
        `${label("Installment amount", props)}: ${thousandsFormat(
          row.next_installment_amount
        )}`,
      colSpan: 1,
      preTranslated: true,
    },
    {
      label: "Days overdue",
      formatter: (cell, row, props) =>
        `${label("loan_statement_days_overdue", props)}: ${row.days_overdue}`,
      colSpan: 1,
      preTranslated: true,
    },
    {
      label: "Total outstanding",
      formatter: (cell, row, props) =>
        `${label("Total outstanding", props)}: ${thousandsFormat(
          row.total_outstanding
        )}`,
      colSpan: 1,
      preTranslated: true,
    },
    {
      label: "",
      colSpan: 2,
    },
  ],
];

export const VIEW_LOAN_ACCOUNT_CHARGES_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "name",
    text: "Name",
  },
  {
    dataField: "charge_time_type",
    text: "Charge time type",
    formatter: (cell) => removeUnderscore(cell),
  },
  {
    dataField: "charge_calculation",
    text: "Charge calculation type",
    formatter: (cell) => removeUnderscore(cell),
  },
  {
    dataField: "amount",
    text: "Amount",
  },
  {
    dataField: "date_added",
    text: "Date added",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
  },
];

const viewJournalsButton: any = function () {
  return (
    <ModalHOC
      button_only
      button_icon="eye"
      button_classes="btn btn-outline-primary"
      button_label="View journal entries"
      data_target="view-journal-entries"
    />
  );
};

export const VIEW_LOAN_ACCOUNT_TRANSACTIONS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "date",
    text: "Date",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
  },
  {
    dataField: "transaction_code",
    text: "Transaction code",
  },
  {
    dataField: "transaction_type_name",
    text: "Transaction type",
  },
  {
    dataField: "amount",
    text: "Amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
  },
  {
    dataField: "",
    text: "Journal entries",
    formatter: (cell, row) => viewJournalsButton(cell, row),
  },
];

export const VIEW_LOAN_TRANSACTION_JOURNALS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "date",
    text: "Date",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
  },
  {
    dataField: "account_name",
    text: "Account",
  },
  {
    dataField: "transaction_type_name",
    text: "Transaction type",
  },
  {
    dataField: "debit",
    text: "Debit",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "credit",
    text: "Credit",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
];

export const VIEW_LOAN_ACCOUNT_PENDING_ACTIONS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "activity_type_name",
    text: "Type",
  },
  {
    dataField: "maker_name",
    text: "Maker",
  },
  {
    dataField: "maker_time",
    text: "Maker time",
    formatter: (cell) => formatDate(cell, "LLLL"),
  },
  {
    dataField: "checker_name",
    text: "Checker",
  },
  {
    dataField: "checker_time",
    text: "Checker time",
    formatter: (cell) => formatDate(cell, "LLLL"),
  },
  {
    dataField: "status_name",
    text: "Status",
  },
];

export const VIEW_MEMBER_ACCOUNT_LEFT_COLUMNS: any = [
  {
    dataField: "member_name",
    text: "Member name",
  },
  {
    dataField: "member_no",
    text: "Member no",
  },
  {
    dataField: "product_name",
    text: "Account type",
  },
  {
    dataField: "account_number",
    text: "Account number",
  },
];

export const VIEW_MEMBER_ACCOUNT_RIGHT_COLUMNS: any = [
  {
    dataField: "account_balance",
    text: "Total amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
  },
  {
    dataField: "held_amount",
    text: "Held amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
  },
  {
    dataField: "opening_date",
    text: "Opening date",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
  },
  {
    dataField: "activation_date",
    text: "Activation date",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
  },
];

export const VIEW_MEMBER_ACCOUNT_DETAILS_COLUMNS: any = [
  {
    dataField: "minimum_opening_balance",
    text: "Minimum opening balance",
  },
  {
    dataField: "balance_required_for_interest_calculation",
    text: "Balance required for interest calculation",
  },
  {
    dataField: "minimum_balance",
    text: "Minimum balance",
  },
];

export const VIEW_MEMBER_ACCOUNT_STATEMENT_COLUMNS: any = [
  {
    dataField: "date",
    text: "Date",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
  },
  {
    dataField: "up_to",
    text: "Up to & inclusive",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
  },
  {
    dataField: "days",
    text: "Days",
  },
  {
    dataField: "transaction_type_name",
    text: "Transaction type",
  },
  {
    dataField: "amount",
    text: "Amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
  },
  {
    dataField: "opening_balance",
    text: "Opening balance",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
  },
  {
    dataField: "closing_balance",
    text: "Closing balance",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
  },
];

export const VIEW_MEMBER_ACCOUNT_DEBIT_CREDIT_STATEMENT_COLUMNS: any = [
  {
    dataField: "date",
    text: "Date",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
  },
  {
    dataField: "details",
    text: "Details",
  },
  {
    dataField: "debit",
    text: "Debit",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "credit",
    text: "Credit",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "closing_balance",
    text: "Closing balance",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
  },
];

export const VIEW_MEMBER_ACCOUNT_DEBIT_CREDIT_STATEMENT_HEADERS: any = [
  [
    {
      label: "Member name",
      formatter: (cell, row) =>
        `Name: ${row.member_name || row.member_full_name} Mobile no: ${
          row.member_phone
        }`,
      colSpan: 17,
      classes: "text-center",
    },
  ],
  [
    {
      label: "Account name",
      formatter: (cell, row) => `${row.account_display_name} STATEMENT`,
      colSpan: 17,
      classes: "text-center",
    },
  ],
];

export const VIEW_MEMBER_ACCOUNT_TRANSACTIONS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "date",
    text: "Date",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
  },
  {
    dataField: "transaction_code",
    text: "Transaction code",
  },
  {
    dataField: "transaction_type_name",
    text: "Transaction type",
  },
  {
    dataField: "amount",
    text: "Amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
  },
  {
    dataField: "",
    text: "Journal entries",
    formatter: (cell, row) => viewJournalsButton(cell, row),
  },
];

export const VIEW_MEMBER_ACCOUNT_TRANSACTION_JOURNALS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "date",
    text: "Date",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
  },
  {
    dataField: "account_name",
    text: "Account",
  },
  {
    dataField: "transaction_type_name",
    text: "Transaction type",
  },
  {
    dataField: "debit",
    text: "Debit",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "credit",
    text: "Credit",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
];

export const LOAN_ACCOUNT_DETAILS_COLUMNS: any = [
  {
    dataField: "amount",
    text: "Applied amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
  },
  {
    dataField: "member_mobile_no",
    text: "Phone number",
  },
  {
    dataField: "loan_reference_no",
    text: "Loan reference no",
  },
  {
    dataField: "date_of_loan_application",
    text: "Date of loan application",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
  },
  {
    dataField: "no_of_repayments",
    text: "Number of repayments",
  },
  {
    dataField: "repayment_interval",
    text: "Repayment interval",
    formatter: (cell, row) => `${row.repaid_every} ${row.repayment_interval}`,
  },
  {
    dataField: "used_parameters",
    text: "Amortization",
    formatter: (cell) => removeUnderscore(parseJSON(cell).amortization),
  },
  {
    dataField: "interest_rate",
    text: "Interest rate",
  },
  {
    dataField: "grace_period",
    text: "Grace period",
  },
  {
    dataField: "used_parameters",
    text: "Interest charged per",
    formatter: (cell) => parseJSON(cell).interest_charged_per,
  },
  {
    dataField: "used_parameters",
    text: "Interest method",
    formatter: (cell) => removeUnderscore(parseJSON(cell).interest_method),
  },
  {
    dataField: "grace_period",
    text: "Grace period",
  },
  {
    dataField: "days_before_first_repayment",
    text: "Days before first repayment",
  },
  {
    dataField: "date_of_first_repayment",
    text: "Date of first repayment",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
  },
  {
    dataField: "used_parameters",
    text: "Days overdue before moving to areas",
    formatter: (cell) => parseJSON(cell).days_overdue_before_arreas,
  },
  {
    dataField: "used_parameters",
    text: "Days overdue before moving to NPA",
    formatter: (cell) => parseJSON(cell).days_overdue_before_npa,
  },
];

export const VIEW_LOAN_CHARGES_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "name",
    text: "Name",
  },
  {
    dataField: "charge_time_type",
    text: "Charge time type",
    formatter: (cell) => removeUnderscore(cell),
  },
  {
    dataField: "charge_calculation",
    text: "Charge calculation",
    formatter: (cell) => removeUnderscore(cell),
  },
  {
    dataField: "gl_account_name",
    text: "GL account",
  },
  {
    dataField: "amount",
    text: "Amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "status_name",
    text: "Status",
  },
];

export const VIEW_CHART_OF_ACCOUNTS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "account_name",
    text: "Name",
  },
  {
    dataField: "account_type_name",
    text: "Type",
  },
  {
    dataField: "account_usage_name",
    text: "Usage",
  },
  {
    dataField: "gl_code",
    text: "Account code",
  },
  {
    dataField: "status_name",
    text: "Status",
  },
  {
    dataField: "manual_entries_allowed",
    text: "Manual entries allowed?",
    formatter: booleanIconFormatter,
  },
  {
    dataField: "overdraft_allowed",
    text: "Overdraft allowed?",
    formatter: booleanIconFormatter,
  },
  {
    dataField: "branch_name",
    text: "Branch",
  },
];

export const VIEW_BANKS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "bank_name",
    text: "Name",
  },
  {
    dataField: "account_name",
    text: "GL account",
  },
];

export const VIEW_PAYMENT_MODES_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "name",
    text: "Name",
  },
  {
    dataField: "is_cash_payment",
    text: "Is cash payment?",
    formatter: booleanIconFormatter,
  },
  {
    dataField: "description",
    text: "Description",
  },
];

/* PLOP_INJECT_COLUMNS */
export const VIEW_AGENTS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "first_name",
    text: "First name",
  },
  {
    dataField: "middle_name",
    text: "Middle name",
  },
  {
    dataField: "last_name",
    text: "Last name",
  },
  {
    dataField: "id_number",
    text: "Personal ID no",
  },
  {
    dataField: "personal_email",
    text: "Personal email",
  },
  {
    dataField: "phone_number",
    text: "Phone number",
  },
  {
    dataField: "enable_login",
    text: "Agency banking enabled?",
    formatter: booleanIconFormatter,
  },
  {
    dataField: "status_name",
    text: "Status",
  },
];

export const VIEW_GROUPS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "group_name",
    text: "Name",
  },
  {
    dataField: "group_code",
    text: "Code",
  },
  {
    dataField: "registration_date",
    text: "Registration date",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
  },
  {
    dataField: "cro_name",
    text: "CRO",
  },
];

export const VIEW_CURRENCIES_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "name",
    text: "Name",
  },
  {
    dataField: "code",
    text: "Code",
  },
];

function integerFieldFormatter(name) {
  return <input className="form-control" type="number" name={name} />;
}

function readOnlyTextFieldFormatter(value, name) {
  return (
    <input
      className="form-control"
      type="text"
      readOnly
      value={value}
      name={name}
    />
  );
}

function textFieldFormatter(name) {
  return <input className="form-control" type="text" name={name} />;
}

function textAreaFieldFormatter(name) {
  return (
    <textarea className="form-control" name={name} style={{ resize: "none" }} />
  );
}

function dateFieldFormatter(name) {
  return (
    <Datetime
      timeFormat={false}
      dateFormat="YYYY-MM-DD"
      closeOnSelect
      inputProps={{ name, readOnly: true }}
    />
  );
}

function valueColumnFormatter(value) {
  return value;
}

export const BULK_LOAN_APPLICATION_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "member_no",
    text: "Member no.",
  },
  {
    dataField: "full_name",
    text: "Member name",
  },
  {
    dataField: "Member id",
    text: "Member id",
    formatter: (cell, row) => readOnlyTextFieldFormatter(row.id, "member_id[]"),
    csvFormatter: (cell, row) => valueColumnFormatter(row.id),
  },
  {
    dataField: "No of repayments",
    text: "No of repayments",
    formatter: () => integerFieldFormatter("no_of_repayments[]"),
    csvFormatter: () => valueColumnFormatter(""),
  },
  {
    dataField: "Amount",
    text: "Amount",
    formatter: () => integerFieldFormatter("amount[]"),
    csvFormatter: () => valueColumnFormatter(""),
  },
  {
    dataField: "Application date",
    text: "Application date",
    formatter: () => dateFieldFormatter("date_of_loan_application[]"),
    csvFormatter: () => valueColumnFormatter(""),
  },
  {
    dataField: "Repayment date",
    text: "Repayment date",
    formatter: () => dateFieldFormatter("date_of_first_repayment[]"),
    csvFormatter: () => valueColumnFormatter(""),
  },
  {
    dataField: "Loan reference no",
    text: "Loan reference no",
    formatter: () => textFieldFormatter("loan_reference_no[]"),
    csvFormatter: () => valueColumnFormatter(""),
  },
  {
    dataField: "Repaid on",
    text: "Repaid on",
    formatter: () => integerFieldFormatter("repaid_on[]"),
    csvFormatter: () => valueColumnFormatter(""),
  },
  {
    dataField: "Initial amount",
    text: "Initial amount",
    formatter: () => integerFieldFormatter("initial_amount[]"),
    csvFormatter: () => valueColumnFormatter(""),
  },
  {
    dataField: "Initial repayments",
    text: "Initial repayments",
    formatter: () => integerFieldFormatter("initial_no_of_repayments[]"),
    csvFormatter: () => valueColumnFormatter(""),
  },
  {
    dataField: "Interest balance",
    text: "Interest balance",
    formatter: () => integerFieldFormatter("interest_balance[]"),
    csvFormatter: () => valueColumnFormatter(""),
  },
];

export const BULK_CLIENT_PAYMENTS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "account_number",
    text: "Account no.",
  },
  {
    dataField: "Account id",
    text: "Account id",
    formatter: (cell, row) =>
      readOnlyTextFieldFormatter(row.id, "member_account[]"),
    csvFormatter: (cell, row) => valueColumnFormatter(row.id),
  },
  {
    dataField: "account_type",
    text: "Account type",
    formatter: removeUnderscore,
  },
  {
    dataField: "member_name",
    text: "Member",
  },
  {
    dataField: "member_no",
    text: "Member no.",
  },
  {
    dataField: "product_name",
    text: "Product name",
  },
  {
    dataField: "Transaction code",
    text: "Transaction code",
    formatter: () => textFieldFormatter("transaction_code[]"),
    csvFormatter: () => valueColumnFormatter(""),
  },
  {
    dataField: "Cheque no",
    text: "Cheque no",
    formatter: () => textFieldFormatter("cheque[]"),
    csvFormatter: () => valueColumnFormatter(""),
  },
  {
    dataField: "Amount",
    text: "Amount",
    formatter: () => integerFieldFormatter("amount[]"),
    csvFormatter: () => valueColumnFormatter(""),
  },
  {
    dataField: "Date",
    text: "Date",
    formatter: () => dateFieldFormatter("date[]"),
    csvFormatter: () => valueColumnFormatter(""),
  },
];

export const APPROVE_CLIENT_PAYMENTS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
    footer: "Total",
  },
  {
    dataField: "transaction_code",
    text: "Transaction code",
    footer: "",
  },
  {
    dataField: "member_name",
    text: "Member",
    footer: "",
  },
  {
    dataField: "member_no",
    text: "Member no",
    footer: "",
  },
  {
    dataField: "transaction_type_name",
    text: "Transaction type",
    footer: "",
  },
  {
    dataField: "account_name",
    text: "GL account",
    footer: "",
  },
  {
    dataField: "account_display_name",
    text: "Account",
    footer: "",
  },
  {
    dataField: "amount",
    text: "Amount",
    footer: (columnData) =>
      thousandsFormat(columnData.reduce((acc, item) => acc + item, 0)),
    formatter: thousandsFormat,
  },
  {
    dataField: "date",
    text: "Date",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
    footer: "",
  },
];

export const BULK_LOAN_PAYMENTS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "loan_reference_no",
    text: "Loan reference no",
  },
  {
    dataField: "approved_amount",
    text: "Loan amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "Loan id",
    text: "Loan id",
    formatter: (cell, row) => readOnlyTextFieldFormatter(row.id, "loan[]"),
    csvFormatter: (cell, row) => valueColumnFormatter(row.id),
  },
  {
    dataField: "member_name",
    text: "Member",
  },
  {
    dataField: "member_no",
    text: "Member no.",
  },
  {
    dataField: "loan_name",
    text: "Product",
  },
  {
    dataField: "Transaction code",
    text: "Transaction code",
    formatter: () => textFieldFormatter("transaction_code[]"),
    csvFormatter: () => valueColumnFormatter(""),
  },
  {
    dataField: "Cheque no",
    text: "Cheque no",
    formatter: () => textFieldFormatter("cheque[]"),
    csvFormatter: () => valueColumnFormatter(""),
  },
  {
    dataField: "Amount",
    text: "Amount",
    formatter: () => integerFieldFormatter("amount[]"),
    csvFormatter: () => valueColumnFormatter(""),
  },
  {
    dataField: "Date",
    text: "Date",
    formatter: () => dateFieldFormatter("date[]"),
    csvFormatter: () => valueColumnFormatter(""),
  },
  {
    dataField: "Comment",
    text: "Comment",
    formatter: () => textAreaFieldFormatter("comment[]"),
    csvFormatter: () => valueColumnFormatter(""),
  },
];

export const APPROVE_LOAN_PAYMENTS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
    footer: "Total",
  },
  {
    dataField: "transaction_code",
    text: "Transaction code",
    footer: "",
  },
  {
    dataField: "member_name",
    text: "Member",
    footer: "",
  },
  {
    dataField: "member_no",
    text: "Member no",
    footer: "",
  },
  {
    dataField: "loan_name",
    text: "Loan name",
    footer: "",
  },
  {
    dataField: "debit",
    text: "Amount",
    footer: (columnData) =>
      thousandsFormat(columnData.reduce((acc, item) => acc + item, 0)),
    formatter: thousandsFormat,
  },
  {
    dataField: "date",
    text: "Date",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
    footer: "",
  },
];

export const VIEW_CLIENTS_PAYMENTS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
    footer: "Total",
  },
  {
    dataField: "transaction_code",
    text: "Transaction code",
    footer: "",
  },
  {
    dataField: "transaction_type_name",
    text: "Transaction type",
    footer: "",
  },
  {
    dataField: "bank_name",
    text: "Bank",
    footer: "",
  },
  {
    dataField: "account_name",
    text: "GL account",
    footer: "",
  },
  {
    dataField: "member_name",
    text: "Member",
    footer: "",
  },
  {
    dataField: "member_no",
    text: "Member no",
    footer: "",
  },
  {
    dataField: "member_cro",
    text: "CRO",
    footer: "",
  },
  {
    dataField: "member_branch",
    text: "Branch",
    footer: "",
  },
  {
    dataField: "account_display_name",
    text: "Member account",
    footer: "",
  },
  {
    dataField: "loan_display_name",
    text: "Loan name",
    footer: "",
  },
  {
    dataField: "amount",
    text: "Amount",
    footer: (columnData) =>
      thousandsFormat(columnData.reduce((acc, item) => acc + item, 0)),
    formatter: thousandsFormat,
  },
  {
    dataField: "date",
    text: "Date",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
    footer: "",
  },
];

export const VIEW_RECEIVED_PAYMENTS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
    footer: "Total",
  },
  {
    dataField: "transaction_code",
    text: "Transaction code",
    footer: "",
  },
  {
    dataField: "account_name",
    text: "GL account",
    footer: "",
  },
  {
    dataField: "group_name",
    text: "Group",
    footer: "",
  },
  {
    dataField: "member_full_name",
    text: "Member",
    footer: "",
  },
  {
    dataField: "member_no",
    text: "Member no",
    footer: "",
  },
  {
    dataField: "amount",
    text: "Amount",
    formatter: (cell) => thousandsFormat(cell, 2),
    footer: (columnData) =>
      thousandsFormat(columnData.reduce((acc, item) => acc + item, 0)),
  },
  {
    dataField: "date",
    text: "Date",
    footer: "",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
  },
];

export const VIEW_EXPENSES_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
    footer: "Total",
  },
  {
    dataField: "transaction_code",
    text: "Transaction code",
    footer: "",
  },
  {
    dataField: "account_name",
    text: "GL account",
    footer: "",
  },
  {
    dataField: "amount",
    text: "Amount",
    formatter: (cell) => thousandsFormat(cell, 2),
    footer: (columnData) =>
      thousandsFormat(columnData.reduce((acc, item) => acc + item, 0)),
  },
  {
    dataField: "date",
    text: "Date",
    footer: "",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
  },
];

export const MEMBER_ACCOUNTS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "member_name",
    text: "Member",
  },
  {
    dataField: "member_no",
    text: "Member no",
  },
  {
    dataField: "account_number",
    text: "Account no",
  },
  {
    dataField: "account_name",
    text: "Account name",
  },
  {
    dataField: "account_type",
    text: "Account type",
    formatter: removeUnderscore,
  },
  {
    dataField: "product_account_name",
    text: "Product name",
    formatter: (cell, row) => {
      const { account_type } = row;
      return row[`${account_type}_name`];
    },
  },
  {
    dataField: "product_account_balance",
    text: "Account balance",
    formatter: (cell, row) => {
      const { account_type } = row;
      return thousandsFormat(row[`${account_type}_balance`]);
    },
  },
];

const validIconFormatter: any = function (cell) {
  if ([false, "False", "false"].includes(cell)) {
    return (
      <span className="text-green">
        <BootstrapIcon icon="check-circle-fill" />
      </span>
    );
  }
  return (
    <span className="text-danger">
      <BootstrapIcon icon="x-circle-fill" />
    </span>
  );
};

const directionIconFormatter: any = function (cell) {
  if (cell === "in") {
    return (
      <span className="text-green">
        <BootstrapIcon icon="arrow-up-circle-fill" />
      </span>
    );
  }
  return (
    <span className="text-primary">
      <BootstrapIcon icon="arrow-down-circle-fill" />
    </span>
  );
};

const viewTransactionButton: any = function () {
  return (
    <ModalHOC
      button_only
      button_icon="eye"
      button_classes="btn btn-outline-primary"
      button_label="View"
      data_target="view-transaction-details"
    />
  );
};

const statusIndicatorFormatter: any = function (cell, row) {
  const status_badge_map: { 20: string, 24: string, 6: string, 23: string } = {
    20: "primary",
    24: "danger",
    6: "warning",
    23: "success",
  };
  return (
    <span className={`badge bg-${status_badge_map[row.status]}`}>{cell}</span>
  );
};

export const MPAY_TRANSACTIONS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
    footer: "Total",
  },
  {
    dataField: "transaction_timestamp",
    text: "Time",
    formatter: (cell) => formatDate(cell, "LLLL"),
    footer: "",
  },
  {
    dataField: "amount",
    text: "Amount",
    formatter: (cell) => thousandsFormat(cell, 2),
    footer: (columnData) =>
      thousandsFormat(columnData.reduce((acc, item) => acc + item, 0)),
  },
  {
    dataField: "debit",
    text: "Amount in",
    formatter: (cell) => thousandsFormat(cell, 2),
    footer: (columnData) =>
      thousandsFormat(columnData.reduce((acc, item) => acc + item, 0)),
  },
  {
    dataField: "total_out",
    text: "Amount out",
    formatter: (cell) => thousandsFormat(cell, 2),
    footer: (columnData) =>
      thousandsFormat(columnData.reduce((acc, item) => acc + item, 0)),
  },
  {
    dataField: "paybill_balance",
    text: "Balance",
    formatter: (cell) => thousandsFormat(cell, 2),
    footer: "",
  },
  {
    dataField: "valid",
    text: "Reflected on account?",
    formatter: validIconFormatter,
    footer: "",
  },
  {
    dataField: "status_name",
    text: "Status",
    formatter: statusIndicatorFormatter,
    footer: "",
  },
  {
    dataField: "full_name",
    text: "Name",
    footer: "",
  },
  {
    dataField: "transaction_direction",
    text: "Direction",
    formatter: directionIconFormatter,
    footer: "",
  },
  {
    dataField: "sender_phone",
    text: "Phone number",
    footer: "",
  },
  {
    dataField: "transaction_reference",
    text: "Reference",
    footer: "",
  },
  {
    dataField: "member_full_name",
    text: "Member",
    footer: "",
  },
  {
    dataField: "bill_ref_number",
    text: "Account number",
    footer: "",
  },
  {
    dataField: "loan_type",
    text: "Loan product",
    footer: "",
  },
  {
    dataField: "business_number_account_no",
    text: "Business number",
    footer: "",
  },
  {
    dataField: "View",
    text: "View",
    formatter: (cell, row) => viewTransactionButton(cell, row),
    footer: "",
  },
  {
    dataField: "Action",
    text: "Action",
    formatter: (cell, row) => {
      // C2B && M-Pesa Paybill && Valid
      if (row.transaction_type === 37 && row.service === 1 && row.valid) {
        return (
          <ModalHOC
            button_only
            button_icon="check2-square"
            button_classes="btn btn-outline-primary"
            button_label="Complete payment"
            data_target="complete-payment"
          />
        );
      }

      // B2C && Pending
      if (row.transaction_type === 39 && row.status === 6) {
        return (
          <ModalHOC
            button_only
            button_icon="check2-square"
            button_classes="btn btn-outline-primary"
            button_label="Check status"
            data_target="check-transaction-status"
          />
        );
      }
    },
    footer: "",
  },
];

export const MPAY_VIEW_TRANSACTION_COLUMNS: any = [
  {
    dataField: "transaction_timestamp",
    text: "Time",
    formatter: (cell) => formatDate(cell, "LLLL"),
  },
  {
    dataField: "amount",
    text: "Amount",
  },
  {
    dataField: "paybill_balance",
    text: "Balance",
  },
  {
    dataField: "full_name",
    text: "Name",
  },
  {
    dataField: "sender_phone",
    text: "Phone number",
  },
  {
    dataField: "transaction_reference",
    text: "Reference",
  },
  {
    dataField: "member_full_name",
    text: "Member",
  },
  {
    dataField: "account_number",
    text: "Account number",
  },
  {
    dataField: "loan_type",
    text: "Loan product",
  },
  {
    dataField: "loan_amount",
    text: "Loan amount",
  },
  {
    dataField: "loan_reference_no",
    text: "Loan reference no",
  },
  {
    dataField: "business_number_account_no",
    text: "Business number",
  },
  {
    dataField: "failure_reason",
    text: "Failure reason",
  },
];

const updateB2CPassword: any = function (row) {
  if (row.paybill_type === "b2c") {
    return (
      <ModalHOC
        button_only
        button_icon="check2-square"
        button_classes="btn btn-outline-primary"
        button_label="Update password"
        data_target="change-api-password"
      />
    );
  }

  return "";
};

export const MPAY_BUSINESS_NUMBERS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "number",
    text: "Number",
  },
  {
    dataField: "is_paybill_setup",
    text: "Is number setup?",
    formatter: booleanIconFormatter,
  },
  {
    dataField: "paybill_type",
    text: "Type",
    formatter: (cell) => _.toUpper(removeUnderscore(cell)),
  },
  {
    dataField: "branch_name",
    text: "Branch",
  },
  {
    dataField: "PayBill password",
    text: "PayBill password",
    formatter: (cell, row) => updateB2CPassword(row),
  },
];

export const MPAY_SAGE_INTEGRATIONS_COLUMNS: any = [
  {
    dataField: "company_id",
    text: "Company ID",
  },
  {
    dataField: "fetch_interval",
    text: "Schedule interval (hours)",
  },
];

export const ACTIVITY_LOG_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "user",
    text: "User",
  },
  {
    dataField: "action_type",
    text: "Action type",
  },
  {
    dataField: "system_module",
    text: "Module",
  },
  {
    dataField: "activity",
    text: "Activity",
    formatter: (cell) => <div dangerouslySetInnerHTML={{ __html: cell }} />,
  },
  {
    dataField: "datetime",
    text: "Date",
    formatter: (cell) => formatDate(cell, "LLLL"),
  },
  {
    dataField: "ip_address",
    text: "IP address",
  },
];

export const BANKING_RECONCILIATION_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "date",
    text: "Date",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
  },
  {
    dataField: "transaction_type_name",
    text: "Transaction type",
  },
  {
    dataField: "transaction_code",
    text: "Transaction code",
  },
  {
    dataField: "account_name",
    text: "Account",
  },
  {
    dataField: "other_transaction_account_name",
    text: "Other account",
  },
  {
    dataField: "debit",
    text: "Debit",
    formatter: (cell) => thousandsFormat(cell, 2),
  },
  {
    dataField: "credit",
    text: "Credit",
    formatter: (cell) => thousandsFormat(cell, 2),
  },
  {
    dataField: "balance",
    text: "Balance",
    running_total: true,
    running_balance_add: "debit",
    running_balance_subtract: "credit",
    formatter: (cell) => thousandsFormat(cell, 2),
  },
];

export const BANKING_UN_RECONCILIATION_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "date",
    text: "Date",
    formatter: (cell) => formatDate(cell, "DD-MMM-YYYY"),
  },
  {
    dataField: "transaction_type_name",
    text: "Transaction type",
  },
  {
    dataField: "transaction_code",
    text: "Transaction code",
  },
  {
    dataField: "account_name",
    text: "Account",
  },
  {
    dataField: "other_transaction_account_name",
    text: "Other account",
  },
  {
    dataField: "debit",
    text: "Debit",
    formatter: (cell) => thousandsFormat(cell, 2),
  },
  {
    dataField: "credit",
    text: "Credit",
    formatter: (cell) => thousandsFormat(cell, 2),
  },
];

export const VIEW_MEMBERS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "member_no",
    text: "Member no",
  },
  {
    dataField: "first_name",
    text: "First name",
  },
  {
    dataField: "middle_name",
    text: "Middle name",
  },
  {
    dataField: "last_name",
    text: "Last name",
  },
  {
    dataField: "personal_email",
    text: "Email",
  },
  {
    dataField: "mobile_no",
    text: "Phone number",
  },
  {
    dataField: "id_no",
    text: "ID no",
  },
  {
    dataField: "branch_name",
    text: "Branch",
  },
  {
    dataField: "cro_username",
    text: "CRO",
  },
  {
    dataField: "status_name",
    text: "Status",
  },
];

export const VIEW_MEMBER_GROUPS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "member_full_name",
    text: "Member",
  },
  {
    dataField: "member_no",
    text: "Member no",
  },
  {
    dataField: "group",
    text: "Group",
  },
];

const viewDocumentFormatter: any = (cell, row) => {
  if (cell) {
    return (
      <FileViewerModal file_url={cell} data_target={`view-file-${row.id}`} />
    );
  }

  return <div />;
};

export const VIEW_MEMBER_UPLOADS_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "document_name",
    text: "Document name",
  },
  {
    dataField: "document_id",
    text: "Document ID",
  },
  {
    dataField: "member_name",
    text: "Member",
  },
  {
    dataField: "member_no",
    text: "Member no",
  },
  {
    dataField: "document_type",
    text: "Document type",
    formatter: removeUnderscore,
  },
  {
    dataField: "document",
    text: "Document",
    formatter: viewDocumentFormatter,
  },
];

export const DETAILED_LOAN_REPORTS_COLUMNS: any = [
  {
    dataField: "member_name",
    text: "Member name",
  },
  {
    dataField: "member_no",
    text: "Member no.",
    footer: "",
  },
  {
    dataField: "member_mobile_no",
    text: "Phone number",
    footer: "",
  },
  {
    dataField: "member_location",
    text: "Location",
    footer: "",
  },
  {
    dataField: "loan_name",
    text: "Product",
    footer: "",
  },
  {
    dataField: "disbursed_amount",
    text: "Disbursed amount",
    footer: "",
    totals: true,
    formatter: (cell) => thousandsFormat(cell, 2),
  },
  {
    dataField: "loan_period",
    text: "Loan period",
    footer: "",
  },
  {
    dataField: "interest_charged",
    text: "Interest charged",
    footer: "",
  },
  {
    dataField: "total_interest",
    text: "Total interest",
    footer: "",
    totals: true,
    formatter: (cell) => thousandsFormat(cell, 2),
  },
  {
    dataField: "total_penalties",
    text: "Total penalties",
    footer: "",
    totals: true,
    formatter: (cell) => thousandsFormat(cell, 2),
  },
  {
    dataField: "principal_paid",
    text: "Principal paid",
    footer: "",
    totals: true,
    formatter: (cell) => thousandsFormat(cell, 2),
  },
  {
    dataField: "interest_paid",
    text: "Interest paid",
    footer: "",
    totals: true,
    formatter: (cell) => thousandsFormat(cell, 2),
  },
  {
    dataField: "principal_interest_paid",
    text: "Principal + interest paid",
    footer: "",
    totals: true,
    formatter: (cell) => thousandsFormat(cell, 2),
  },
  {
    dataField: "total_paid",
    text: "Total paid",
    footer: "",
    totals: true,
    formatter: (cell) => thousandsFormat(cell, 2),
  },
  {
    dataField: "principal_outstanding",
    text: "Principal outstanding",
    footer: "",
    totals: true,
    formatter: (cell) => thousandsFormat(cell, 2),
  },
  {
    dataField: "interest_outstanding",
    text: "Interest outstanding",
    footer: "",
    totals: true,
    formatter: (cell) => thousandsFormat(cell, 2),
  },
  {
    dataField: "principal_interest_outstanding",
    text: "Principal + interest outstanding",
    footer: "",
    totals: true,
    formatter: (cell) => thousandsFormat(cell, 2),
  },
  {
    dataField: "total_outstanding",
    text: "Total outstanding",
    footer: "",
    totals: true,
    formatter: (cell) => thousandsFormat(cell, 2),
  },
  {
    dataField: "debt_collection",
    text: "Debt collection",
    footer: "",
    formatter: (cell, row) =>
      row.debt_collection && (
        <span className="badge bg-danger">Debt recovery</span>
      ),
  },
];

export const VIEW_LOAN_REPAYMENT_SCHEDULE_HEADERS: any = [
  [
    {
      label: "Member name",
      formatter: (cell, row, props) =>
        `${label("Name", props)}: ${row.member_name} ${label(
          "Mobile no",
          props
        )}: ${row.member_phone}`,
      colSpan: 12,
      classes: "text-center",
      preTranslated: true,
    },
  ],
  [
    {
      label: "Loan name",
      formatter: (cell, row, props) =>
        `${row.loan_name} ${label("loan_statement_repayment_schedule", props)}`,
      colSpan: 12,
      classes: "text-center",
      preTranslated: true,
    },
  ],
];

export const MEMBER_ACTIVE_LOANS_COLUMNS: any = [
  {
    dataField: "id",
    text: "Loan ID",
  },
  {
    dataField: "loan_name",
    text: "Loan name",
  },
  {
    dataField: "loan_reference_no",
    text: "Loan reference no",
  },
  {
    dataField: "amount",
    text: "Applied amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "approved_amount",
    text: "Approved amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "date_of_loan_application",
    text: "Application date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
  {
    dataField: "disbursement_date",
    text: "Disbursement date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
];

export const MEMBER_COMPLETED_LOANS_COLUMNS: any = [
  {
    dataField: "id",
    text: "Loan ID",
  },
  {
    dataField: "loan_name",
    text: "Loan name",
  },
  {
    dataField: "loan_reference_no",
    text: "Loan reference no",
  },
  {
    dataField: "amount",
    text: "Applied amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "approved_amount",
    text: "Approved amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "date_of_loan_application",
    text: "Application date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
  {
    dataField: "disbursement_date",
    text: "Disbursement date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
];

export const MEMBER_PROCESSED_LOANS_COLUMNS: any = [
  {
    dataField: "id",
    text: "Loan ID",
  },
  {
    dataField: "loan_name",
    text: "Loan name",
  },
  {
    dataField: "loan_reference_no",
    text: "Loan reference no",
  },
  {
    dataField: "amount",
    text: "Applied amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "approved_amount",
    text: "Approved amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "date_of_loan_application",
    text: "Application date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
  {
    dataField: "disbursement_date",
    text: "Disbursement date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
];

export const MEMBER_REJECTED_LOANS_COLUMNS: any = [
  {
    dataField: "id",
    text: "Loan ID",
  },
  {
    dataField: "loan_name",
    text: "Loan name",
  },
  {
    dataField: "loan_reference_no",
    text: "Loan reference no",
  },
  {
    dataField: "amount",
    text: "Applied amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "rejection_reason",
    text: "Rejection reason",
  },
  {
    dataField: "date_of_loan_application",
    text: "Application date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
];

export const MEMBER_REVERSED_LOANS_COLUMNS: any = [
  {
    dataField: "id",
    text: "Loan ID",
  },
  {
    dataField: "loan_name",
    text: "Loan name",
  },
  {
    dataField: "loan_reference_no",
    text: "Loan reference no",
  },
  {
    dataField: "amount",
    text: "Applied amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "reversal_reason",
    text: "Reversal reason",
  },
  {
    dataField: "date_of_loan_application",
    text: "Application date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
];

export const MEMBER_PENDING_LOANS_COLUMNS: any = [
  {
    dataField: "id",
    text: "Loan ID",
  },
  {
    dataField: "loan_name",
    text: "Loan name",
  },
  {
    dataField: "loan_reference_no",
    text: "Loan reference no",
  },
  {
    dataField: "amount",
    text: "Applied amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "date_of_loan_application",
    text: "Application date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
];

export const MEMBER_WRITTEN_OFF_LOANS_COLUMNS: any = [
  {
    dataField: "id",
    text: "Loan ID",
  },
  {
    dataField: "loan_name",
    text: "Loan name",
  },
  {
    dataField: "loan_reference_no",
    text: "Loan reference no",
  },
  {
    dataField: "amount",
    text: "Applied amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "approved_amount",
    text: "Approved amount",
    formatter: thousandsFormat,
  },
  {
    dataField: "date_of_loan_application",
    text: "Application date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
  {
    dataField: "disbursement_date",
    text: "Disbursement date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
];

export const MEMBER_PORTAL_ACCOUNTS_COLUMNS: any = [
  {
    dataField: "account_number",
    text: "Account no",
  },
  {
    dataField: "account_name",
    text: "Account name",
  },
  {
    dataField: "account_type",
    text: "Account type",
    formatter: removeUnderscore,
  },
  {
    dataField: "product_account_name",
    text: "Product name",
    formatter: (cell, row) => row[`${row.account_type}_name`],
  },
  {
    dataField: "product_account_balance",
    text: "Account balance",
    formatter: (cell, row) =>
      thousandsFormat(row[`${row.account_type}_balance`]),
  },
];

export const MEMBER_PROFILE_COLUMNS: any = [
  {
    dataField: "full_name",
    text: "Name",
  },
  {
    dataField: "gender",
    text: "Gender",
  },
  {
    dataField: "date_of_birth",
    text: "Date of birth",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
  {
    dataField: "id_no",
    text: "ID number",
  },
  {
    dataField: "personal_email",
    text: "Email",
  },
  {
    dataField: "mobile_no",
    text: "Mobile number",
  },
  {
    dataField: "member_no",
    text: "Member number",
  },
  {
    dataField: "branch_name",
    text: "Branch",
  },
  {
    dataField: "CRO",
    text: "CRO",
    formatter: (cell, row) => `${row.cro_first_name} ${row.cro_last_name}`,
  },
];
export const DISBURSEMENT_DETAILS_COLUMNS: any = [
  {
    dataField: "member_name",
    text: "Member name",
  },
  {
    dataField: "member_no",
    text: "Member no",
  },
  {
    dataField: "loan_name",
    text: "Loan type",
  },
  {
    dataField: "loan_reference_no",
    text: "Reference no",
  },
  {
    dataField: "no_of_repayments",
    text: "No of repayments",
  },
  {
    dataField: "amount",
    text: "Applied amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
  },
  {
    dataField: "approved_amount",
    text: "Approved amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
  },
  {
    dataField: "disbursed_amount",
    text: "Disbursed amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
  },
  {
    dataField: "date_of_loan_application",
    text: "Application date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
  {
    dataField: "disbursement_date",
    text: "Disbursement date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
];

const viewBillingInvoiceButton: any = function (cell, row) {
  let pay_url = (row.transactions[0] || {}).pay_url;
  if (pay_url) {
    if (window.location.protocol === "https:") {
      pay_url = pay_url.replace("http://", "https://");
    } else {
      pay_url = pay_url.replace("https://", "http://");
    }
  }
  return (
    <div className="d-flex">
      {cell && (
        <FileViewerModal
          file_url={cell}
          data_target={`view-file-${row.id}`}
          view_button_label="View"
        />
      )}

      {row.state === "issued" && pay_url && (
        <>
          <ModalHOC
            button_only
            button_icon="credit-card"
            button_classes="btn btn-outline-primary"
            button_label="Pay now"
            data_target={`pay-invoice-${row.id}`}
          />

          <ModalHOC
            modal_size="modal-xl"
            modal_only
            modal_title="Invoice payment"
            body_classes="vh-80"
            data_target={`pay-invoice-${row.id}`}
          >
            <iframe
              src={pay_url}
              name="invoice-payment"
              height="100%"
              width="100%"
              title="Invoice payment"
            />
          </ModalHOC>
        </>
      )}
    </div>
  );
};
const currencyFormatter = function (amount, currency) {
  const locale = navigator.language;
  return new Intl.NumberFormat(locale, { style: "currency", currency }).format(
    amount
  );
};
export const BILLING_INVOICES_COLUMNS: any = [
  {
    dataField: "number",
    text: "Invoice No.",
  },
  {
    dataField: "due_date",
    text: "Due date",
    formatter: (cell) => (cell ? moment(cell).format("DD-MMM-YYYY") : ""),
  },
  {
    dataField: "issue_date",
    text: "Issue date",
    formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
  },
  {
    dataField: "total_in_transaction_currency",
    text: "Amount",
    formatter: (cell, row) => currencyFormatter(cell, row.transaction_currency),
  },
  {
    dataField: "state",
    text: "Status",
  },
  {
    dataField: "pdf_url",
    text: "Invoice",
    formatter: viewBillingInvoiceButton,
  },
];
export const LOAN_PORTFOLIO_DETAILS_HEADERS: any = [
  [
    {
      label: "Member name",
      formatter: (cell, row, props) =>
        `${label("Name", props)}: ${row.member_name} ${label(
          "Mobile no",
          props
        )}: ${row.member_phone}`,
      colSpan: 5,
      classes: "text-center",
      preTranslated: true,
    },
  ],
  [
    {
      label: "Loan name",
      formatter: (cell, row, props) =>
        `${row.loan_name} ${label("loan_statement_client_statement", props)}`,
      colSpan: 5,
      classes: "text-center",
      preTranslated: true,
    },
  ],
  [
    {
      label: "Statement date",
      formatter: (_cell, _row, props) =>
        `${label("Statement date", props)}: ${moment().format("DD-MMM-YYYY")}`,
      colSpan: 5,
      classes: "text-center",
      preTranslated: true,
    },
  ],
  [
    {
      label: "Current",
      formatter: (cell, row, props) =>
        `${label("Current", props)}: ${thousandsFormat(row.current_due)}`,
      colSpan: 1,
      preTranslated: true,
    },
    {
      label: "30 days overdue",
      formatter: (cell, row, props) =>
        `30 ${label("days overdue", props)}: ${thousandsFormat(
          row._30_days_overdue
        )}`,
      colSpan: 1,
      preTranslated: true,
    },
    {
      label: "60 days overdue",
      formatter: (cell, row, props) =>
        `60 ${label("days overdue", props)}: ${thousandsFormat(
          row._60_days_overdue
        )}`,
      colSpan: 1,
      preTranslated: true,
    },
    {
      label: "90 days overdue",
      formatter: (cell, row, props) =>
        `90 ${label("days overdue", props)}: ${thousandsFormat(
          row._90_days_overdue
        )}`,
      colSpan: 1,
      preTranslated: true,
    },
    {
      label: "120 days overdue",
      formatter: (cell, row, props) =>
        `120 ${label("days overdue", props)}: ${thousandsFormat(
          row._120_days_overdue
        )}`,
      colSpan: 1,
      preTranslated: true,
    },
  ],
  [
    {
      label: "Installment amount",
      formatter: (cell, row, props) =>
        `${label("Installment amount", props)}: ${thousandsFormat(
          row.next_installment_amount
        )}`,
      colSpan: 1,
      preTranslated: true,
    },
    {
      label: "Days overdue",
      formatter: (cell, row, props) =>
        `${label("loan_statement_days_overdue", props)}: ${row.days_overdue}`,
      colSpan: 1,
      preTranslated: true,
    },
    {
      label: "Total outstanding",
      formatter: (cell, row, props) =>
        `${label("Total outstanding", props)}: ${thousandsFormat(
          row.total_outstanding
        )}`,
      colSpan: 1,
      preTranslated: true,
    },
    {
      label: "",
      colSpan: 2,
    },
  ],
];
export const LOAN_PORTFOLIO_DETAILS_COLUMNS: any = [
  {
    dataField: "member_no",
    text: "Member no",
  },
  {
    dataField: "member_name",
    text: "Member name",
  },
  {
    dataField: "member_id_no",
    text: "ID no",
  },
  {
    dataField: "member_phone",
    text: "Phone no",
  },
  {
    dataField: "branch_name",
    text: "Branch",
  },
  {
    dataField: "cro_name",
    text: "CRO",
  },
  {
    dataField: "loan_reference_no",
    text: "Loan reference no",
  },
  {
    dataField: "product_name",
    text: "Product",
  },
  {
    dataField: "approved_amount",
    text: "Approved amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "disbursement_date",
    text: "Disbursement date",
    formatter: (cell) => (cell ? moment(cell).format("DD-MMM-YYYY") : ""),
  },
  {
    dataField: "no_of_repayments",
    text: "No of repayments",
  },
  {
    dataField: "remaining_repayments",
    text: "Remaining repayments",
  },
  {
    dataField: "principal",
    text: "Principal",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "interest",
    text: "Interest",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "fees",
    text: "Fees",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "total_due",
    text: "Total",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "on_time",
    text: "On time",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "1_to_30_days",
    text: "1-30 days",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "31_to_60_days",
    text: "31-60 days",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "61_to_90_days",
    text: "61-90 days",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "91_to_180_days",
    text: "91-180 days",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "over_180_days",
    text: "More than 180 days",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "total_par",
    text: "Total PAR",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
];
export const LOAN_PORTFOLIO_SUMMARY_HEADERS: any = [
  [
    {
      label: "",
      colSpan: 1,
    },
    {
      label: "Portfolio",
      colSpan: 5,
      classes: "text-center",
      preTranslated: true,
    },
    {
      label: "On time",
      colSpan: 1,
      preTranslated: true,
    },
    {
      label: "1-30 days",
      colSpan: 1,
      preTranslated: true,
    },
    {
      label: "31-60 days",
      colSpan: 1,
      preTranslated: true,
    },
    {
      label: "61-90 days",
      colSpan: 1,
      preTranslated: true,
    },
    {
      label: "91-180 days",
      colSpan: 1,
      preTranslated: true,
    },
    {
      label: "Over 180 days",
      colSpan: 1,
      preTranslated: true,
    },
    {
      label: "Total PAR",
      colSpan: 1,
      preTranslated: true,
    },
  ],
];
export const LOAN_PORTFOLIO_SUMMARY_COLUMNS: any = [
  {
    dataField: "product_name",
    text: "Product",
  },
  {
    dataField: "loans_count",
    text: "No. of loans",
    totals: true,
  },
  {
    dataField: "principal",
    text: "Principal",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "interest",
    text: "Interest",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "fees",
    text: "Fees",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "total_due",
    text: "Total",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "on_time",
    text: "Amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "1_to_30_days",
    text: "Amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "31_to_60_days",
    text: "Amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "61_to_90_days",
    text: "Amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "91_to_180_days",
    text: "Amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "over_180_days",
    text: "Amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "total_par",
    text: "Amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
];
export const COLLATERAL_COLUMNS: any = [
  {
    dataField: "id",
    text: "ID",
  },
  {
    dataField: "collateral_id_no",
    text: "Collateral ID no.",
  },
  {
    dataField: "value",
    text: "Collateral value",
  },
  {
    dataField: "description",
    text: "Description",
  },
  {
    dataField: "collateral_type",
    text: "Collateral type",
  },
  {
    dataField: "status_name",
    text: "Status",
  },
  {
    dataField: "member_name",
    text: "Member name",
  },
  {
    dataField: "member_no",
    text: "Member number",
  },
  {
    dataField: "non_member_name",
    text: "Non-member name",
  },
];
export const LOAN_DISBURSEMENT_REPORT_COLUMNS: any = [
  {
    dataField: "member_no",
    text: "Member no",
  },
  {
    dataField: "member_name",
    text: "Member name",
  },
  {
    dataField: "member_phone",
    text: "Member phone",
  },
  {
    dataField: "branch_name",
    text: "Branch",
  },
  {
    dataField: "cro_name",
    text: "CRO",
  },
  {
    dataField: "product_name",
    text: "Product",
  },
  {
    dataField: "loan_reference_no",
    text: "Loan reference no",
  },
  {
    dataField: "disbursement_date",
    text: "Disbursement date",
  },
  {
    dataField: "transaction_reference",
    text: "Transaction reference",
  },
  {
    dataField: "application_amount",
    text: "Application amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "approved_amount",
    text: "Approved amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
  {
    dataField: "disbursed_amount",
    text: "Disbursed amount",
    formatter: (cell, row) => thousandsFormat(cell, row.no_of_decimal_places),
    totals: true,
  },
];
