import {
  CHART_OF_ACCOUNTS_ENDPOINT,
  FORMULAS_ENDPOINT,
  LOAN_CHARGES_ENDPOINT,
} from "../../../constants/Constants";
import React, { Component } from "react";
import {
  fetchUrlData,
  getSelect2Options,
  getUrlData,
} from "../../../utils/componentActions";

import CreateEditForm from "../../../components/CreateEditForm";
import FormFields from "../../../components/FormFields";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class Class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loan_charge: {},
    };
  }

  componentDidMount() {
    const loan_charge_id: any = this.props.match.params.id;
    if (loan_charge_id) {
      const loan_charge_url: string = `${LOAN_CHARGES_ENDPOINT}${loan_charge_id}/`;
      fetchUrlData("loan_charge_url", loan_charge_url, this.props);
    }
    fetchUrlData("formulas_url", FORMULAS_ENDPOINT, this.props);
    fetchUrlData(
      "income_accounts_url",
      `${CHART_OF_ACCOUNTS_ENDPOINT}?account_type=4`,
      this.props
    );
    fetchUrlData(
      "expenses_accounts_url",
      `${CHART_OF_ACCOUNTS_ENDPOINT}?account_type=5`,
      this.props
    );
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const {
      loan_charge_data,
      formulas_data,
      income_accounts_data,
      expenses_accounts_data,
    } = props;
    const form_classes: string = "row g3";
    const { loan_charge } = this.state;
    const formulas: any = formulas_data.items;
    const income_accounts: any = income_accounts_data.items;
    const expenses_accounts: any = expenses_accounts_data.items;
    const formula_options: any = getSelect2Options(formulas, "id", "name");
    let chart_of_accounts_options: any = getSelect2Options(
      income_accounts,
      "id",
      "account_name"
    );
    if (loan_charge.treat_as === "expense") {
      chart_of_accounts_options = getSelect2Options(
        expenses_accounts,
        "id",
        "account_name"
      );
    }
    let amount_field_label: string = "Percentage amount";
    if ([undefined, "flat"].includes(loan_charge.charge_calculation)) {
      amount_field_label = "Amount";
    }
    let formula_amount_field: any = (
      <FormFields
        key={4}
        field_type="number_input"
        field_label={amount_field_label}
        props={props}
        step="0.001"
        field_name="amount"
        wrapper_div_classes="col-4"
        data_object={loan_charge}
        required
      />
    );
    if (loan_charge.charge_calculation === "formula") {
      formula_amount_field = (
        <FormFields
          key={4}
          field_type="select2"
          field_label="Formula"
          props={props}
          field_name="formula"
          wrapper_div_classes="col-4"
          select2_options={formula_options}
          select2_default_first
          data_object={loan_charge}
          required
        />
      );
    }
    let gl_account_fields: any = <React.Fragment key={10} />;
    if (
      [false, "false", undefined].includes(
        loan_charge.use_loan_interest_account
      )
    ) {
      gl_account_fields = (
        <React.Fragment key={10.1}>
          <FormFields
            key={10}
            field_type="select"
            field_label="Treat as"
            props={props}
            field_name="treat_as"
            wrapper_div_classes="col-4"
            data_object={loan_charge}
            required
            update_payload
            data_object_name="loan_charge"
            updateState={this.updateState}
          >
            <option value="income">Income</option>
            <option value="expense">Expense</option>
          </FormFields>
          <FormFields
            key={8}
            field_type="select2"
            field_label="GL account"
            props={props}
            field_name="gl_account"
            wrapper_div_classes="col-4"
            select2_options={chart_of_accounts_options}
            select2_default_first
            data_object={loan_charge}
            required
          />
        </React.Fragment>
      );
    }
    let overdue_fees_fields: any = <React.Fragment key={13} />;
    if (loan_charge.charge_time_type === "overdue_fees") {
      let recur_daily_field: any = (
        <FormFields
          key={13}
          field_type="select"
          field_label="Recur daily"
          props={props}
          field_name="recur_daily"
          wrapper_div_classes="col-4"
          data_object={loan_charge}
          update_payload
          data_object_name="loan_charge"
          updateState={this.updateState}
        >
          <option value={false}>No</option>
          <option value>Yes</option>
        </FormFields>
      );
      if ([true, "true"].includes(loan_charge.recur_monthly)) {
        recur_daily_field = <React.Fragment key={13} />;
      }
      let recur_monthly_field: any = (
        <FormFields
          key={14}
          field_type="select"
          field_label="Recur monthly"
          props={props}
          field_name="recur_monthly"
          wrapper_div_classes="col-4"
          data_object={loan_charge}
          update_payload
          data_object_name="loan_charge"
          updateState={this.updateState}
        >
          <option value={false}>No</option>
          <option value>Yes</option>
        </FormFields>
      );
      if ([true, "true"].includes(loan_charge.recur_daily)) {
        recur_monthly_field = <React.Fragment key={14} />;
      }
      let stop_recurring_at_field: any = <React.Fragment key={15.1} />;
      if (
        [true, "true"].includes(loan_charge.recur_daily) ||
        [true, "true"].includes(loan_charge.recur_monthly)
      ) {
        stop_recurring_at_field = (
          <FormFields
            key={15}
            field_type="select"
            field_label="Stop recurring at"
            props={props}
            field_name="stop_recurring_at"
            wrapper_div_classes="col-4"
            data_object={loan_charge}
          >
            <option value="today">Never</option>
            <option value="last_repayment_date">Last repayment date</option>
          </FormFields>
        );
      }
      let no_of_days_after_disbursement_date_field: any = (
        <React.Fragment key={18.1} />
      );
      if (
        loan_charge.start_charging_after === "n_days_after_disbursement_date"
      ) {
        no_of_days_after_disbursement_date_field = (
          <FormFields
            key={18}
            field_type="number_input"
            field_label="No of days"
            props={props}
            field_name="n_days_after_disbursement_date"
            wrapper_div_classes="col-4"
            data_object={loan_charge}
            required
          />
        );
      }
      overdue_fees_fields = (
        <React.Fragment key={11}>
          <FormFields
            key={11}
            field_type="number_input"
            field_label="Overdue days from"
            props={props}
            field_name="overdue_days_from"
            wrapper_div_classes="col-4"
            data_object={loan_charge}
            required
          />
          <FormFields
            key={12}
            field_type="number_input"
            field_label="Overdue days to"
            props={props}
            field_name="overdue_days_to"
            wrapper_div_classes="col-4"
            data_object={loan_charge}
            required
          />
          {recur_daily_field}
          {recur_monthly_field}
          {stop_recurring_at_field}
          <FormFields
            key={15}
            field_type="select"
            field_label="Effect charge on"
            props={props}
            field_name="charge_on"
            wrapper_div_classes="col-4"
            data_object={loan_charge}
          >
            <option value="every_repayment">Loan repayment</option>
            <option value="payment_amount">Payment transaction</option>
          </FormFields>
          <FormFields
            key={16}
            field_type="select"
            field_label="Charge only once on"
            props={props}
            field_name="charge_once_on"
            wrapper_div_classes="col-4"
            data_object={loan_charge}
          >
            <option />
            <option value="every_repayment">Every repayment</option>
            <option value="whole_loan">Whole loan</option>
          </FormFields>
          <FormFields
            key={17}
            field_type="select"
            field_label="Start charging after"
            props={props}
            field_name="start_charging_after"
            wrapper_div_classes="col-4"
            data_object={loan_charge}
            update_payload
            data_object_name="loan_charge"
            updateState={this.updateState}
          >
            <option value="first_repayment_date">First repayment date</option>
            <option value="last_repayment_date">Last repayment date</option>
            <option value="n_days_after_disbursement_date">
              Days after disbursement date
            </option>
          </FormFields>
          {no_of_days_after_disbursement_date_field}
          <FormFields
            key={19}
            field_type="select"
            field_label="Use only for debt recovery"
            props={props}
            field_name="use_for_debt_recovery"
            wrapper_div_classes="col-4"
            data_object={loan_charge}
          >
            <option value={false}>No</option>
            <option value>Yes</option>
          </FormFields>
        </React.Fragment>
      );
    }
    let payment_time_field: any = (
      <FormFields
        key={21}
        field_type="select"
        field_label="Payment time"
        props={props}
        field_name="payment_time"
        wrapper_div_classes="col-4"
        data_object={loan_charge}
      >
        <option value="as_per_disbursement_choice">
          As per disbursement choice
        </option>
        <option value="upfront">Upfront</option>
        <option value="deduct_from_disbursement_amount">
          Deduct from disbursement amount
        </option>
      </FormFields>
    );
    if (!["disbursement", undefined].includes(loan_charge.charge_time_type)) {
      payment_time_field = <React.Fragment key={21} />;
    }
    const form_items: any = [
      <FormFields
        key={1}
        field_type="text_input"
        field_label="Name"
        props={props}
        field_name="name"
        wrapper_div_classes="col-4"
        data_object={loan_charge}
        required
      />,
      <FormFields
        key={2}
        field_type="select"
        field_label="Charge time type"
        props={props}
        field_name="charge_time_type"
        wrapper_div_classes="col-4"
        data_object={loan_charge}
        required
        update_payload
        data_object_name="loan_charge"
        updateState={this.updateState}
      >
        <option value="disbursement">Disbursement</option>
        <option value="installment_fees">Installment fees</option>
        <option value="overdue_fees">Overdue fees</option>
        <option value="first_repayment">First installment</option>
      </FormFields>,
      payment_time_field,
      <FormFields
        key={3}
        field_type="select"
        field_label="Charge calculation"
        props={props}
        field_name="charge_calculation"
        wrapper_div_classes="col-4"
        data_object={loan_charge}
        required
        update_payload
        data_object_name="loan_charge"
        updateState={this.updateState}
      >
        <option value="flat">Flat amount</option>
        <option value="percentage_of_amount">% of amount</option>
        <option value="percentage_of_amount_plus_interest">
          % of amount + interest
        </option>
        <option value="percentage_of_interest">% of interest</option>
        <option value="percentage_of_disbursement">
          % of disbursement amount
        </option>
        <option value="percentage_of_reducing_balance">
          % of reducing balance
        </option>
        <option value="formula">Formula</option>
      </FormFields>,
      formula_amount_field,
      <FormFields
        key={5}
        field_type="number_input"
        field_label="Principal amount from"
        props={props}
        field_name="calculation_amount_from"
        wrapper_div_classes="col-4"
        data_object={loan_charge}
      />,
      <FormFields
        key={6}
        field_type="number_input"
        field_label="Principal amount to"
        props={props}
        field_name="calculation_amount_to"
        wrapper_div_classes="col-4"
        data_object={loan_charge}
      />,
      <FormFields
        key={7}
        field_type="select"
        field_label="Use loan interest account"
        props={props}
        field_name="use_loan_interest_account"
        wrapper_div_classes="col-4"
        data_object={loan_charge}
        required
        update_payload
        data_object_name="loan_charge"
        updateState={this.updateState}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      gl_account_fields,
      <FormFields
        key={9}
        field_type="select"
        field_label="Use as"
        props={props}
        field_name="use_as"
        wrapper_div_classes="col-4"
        data_object={loan_charge}
        required
      >
        <option value="charge">Charge</option>
        <option value="interest">Interest</option>
        <option value="advance_payment">Advance payment</option>
        <option value="advance_principal_payment">
          Advance principal payment
        </option>
        <option value="advance_interest_payment">
          Advance interest payment
        </option>
      </FormFields>,
      overdue_fees_fields,
      <FormFields
        key={20}
        field_type="select"
        field_label="Status"
        props={props}
        field_name="status"
        wrapper_div_classes="col-4"
        data_object={loan_charge}
      >
        <option value={1}>Active</option>
        <option value={2}>Inactive</option>
      </FormFields>,
    ];
    return (
      <CreateEditForm
        loading={loan_charge_data.isFetching}
        form_items={form_items}
        form_classes={form_classes}
        action_object={loan_charge_data.items}
        setState={this.updateState}
        action_object_name="loan_charge"
        state={this.state}
        action_object_endpoint={LOAN_CHARGES_ENDPOINT}
        object_display_name="loan charge"
      />
    );
  }
}

Class.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  loan_charge_data: PropTypes.instanceOf(Object).isRequired,
  formulas_data: PropTypes.instanceOf(Object).isRequired,
  income_accounts_data: PropTypes.instanceOf(Object).isRequired,
  expenses_accounts_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const loan_charge_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "loan_charge_url"
  );
  const formulas_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "formulas_url"
  );
  const income_accounts_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "income_accounts_url"
  );
  const expenses_accounts_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "expenses_accounts_url"
  );

  return {
    sessionVariables,
    loan_charge_data,
    formulas_data,
    income_accounts_data,
    expenses_accounts_data,
  };
}

export default connect(mapStateToProps)(withRouter(Class));
