import React, { Component } from "react";

import { LOAN_DISBURSEMENT_REPORT_ENDPOINT } from "../../constants/Constants";
import { LOAN_DISBURSEMENT_REPORT_FILTERS } from "../../components/DataTableFilterFields";
import { LOAN_DISBURSEMENT_REPORT_COLUMNS } from "../../components/DataTableColumns";
import Portlet from "../../components/Portlet";
import ViewDataTable from "../../components/ViewDataTable";

class DetailedLoanBalances extends Component {
  render() {
    return (
      <Portlet header_label="Loan disbursement">
        <div className="row">
          <div className="col table-responsive">
            <ViewDataTable
              table_filters={LOAN_DISBURSEMENT_REPORT_FILTERS}
              data_source_url={LOAN_DISBURSEMENT_REPORT_ENDPOINT}
              columns={LOAN_DISBURSEMENT_REPORT_COLUMNS}
              totals
              basic_table
              paginated={false}
              allow_print
              allow_refresh
              wait_for_props
            />
          </div>
        </div>
      </Portlet>
    );
  }
}

export default DetailedLoanBalances;
