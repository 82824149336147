import {
  LOAN_GUARANTORS_ENDPOINT,
  MEMBER_LOAN_GUARANTORS_ENDPOINT,
} from "../../constants/Constants";
import React, { Component } from "react";

import { ADD_LOAN_GUARANTOR_PATH } from "../../constants/ClientPaths";
import DataTableActionForm from "../../components/DataTableActionForm";
import { LOAN_GUARANTORS_COLUMNS } from "../../components/DataTableColumns";
import PropTypes from "prop-types";
import ViewDataTable from "../../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ViewLoanAccountGuarantors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_row_obj: {},
    };
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  handleTableRowSelect = (row) => {
    this.setState({
      selected_row_obj: row,
    });
  };

  render() {
    const loan_id: any = this.props.match.params.id;
    const { member, auth_token } = localStorage;
    let loan_guarantors_url: string = `${LOAN_GUARANTORS_ENDPOINT}?limit=5&offset=0&loan=${loan_id}`;
    if (member) {
      loan_guarantors_url = `${MEMBER_LOAN_GUARANTORS_ENDPOINT}?token=${auth_token}&member=${member}&loan=${loan_id}&limit=5&offset=0`;
    }
    const data_source_url_name: string = "loan_guarantors_url";
    let actions: any = [
      {
        name: "remove",
        btn_classes: "btn btn-danger",
        noun: "removal",
        icon: "x",
        target: "guarantor",
        plural: "guarantors",
        form_items: [],
        multiple_form_items: [],
        extra_form_data: [{ name: "status", value: 13, type: "int" }],
      },
    ];
    let creatable: boolean = true;
    let editable: boolean = true;
    if (member) {
      actions = [];
      creatable = false;
      editable = false;
    }
    return (
      <DataTableActionForm
        actions={actions}
        table_node={this.state.table_node}
        datatable_action_endpoint={LOAN_GUARANTORS_ENDPOINT}
        request_method="PATCH"
        datatable_source_url={loan_guarantors_url}
        datatable_source_url_name={data_source_url_name}
      >
        <ViewDataTable
          extra_get_params={`loan=${loan_id}`}
          handleSetTableNode={this.handleSetTableNode}
          allow_refresh
          creatable={creatable}
          handleTableRowSelect={this.handleTableRowSelect}
          select_mode="radio"
          editable={editable}
          table_node_name="loan_account_guarantors"
          edit_url={ADD_LOAN_GUARANTOR_PATH}
          columns={LOAN_GUARANTORS_COLUMNS}
          data_source_url={LOAN_GUARANTORS_ENDPOINT}
          data_source_url_name={data_source_url_name}
        />
      </DataTableActionForm>
    );
  }
}

ViewLoanAccountGuarantors.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ViewLoanAccountGuarantors));
