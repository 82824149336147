import React, { Component } from "react";

import { APPLIED_LOANS_ENDPOINT } from "../../constants/Constants";
import { COMPLETED_LOANS_COLUMNS } from "../../components/DataTableColumns";
import DataTableActionForm from "../../components/DataTableActionForm";
import ViewAppliedLoans from "./ViewAppliedLoans";
import { withRouter } from "react-router-dom";

class ViewActiveLoans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 5,
    };
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  render() {
    const applied_loans_url: string = `${APPLIED_LOANS_ENDPOINT}?status__in=7&limit=5&offset=0`;
    const actions: any = [
      {
        name: "reconstruct",
        btn_classes: "btn btn-info ml-2",
        noun: "reconstruction",
        icon: "cone-striped",
        target: "loan",
        plural: "loans",
        form_items: [],
        multiple_form_items: [],
        extra_form_data: [{ name: "reconstruct_statement", value: true }],
      },
      {
        name: "fix",
        btn_classes: "btn btn-info ml-2",
        noun: "fixing",
        icon: "hammer",
        target: "loan",
        plural: "loans",
        form_items: [],
        multiple_form_items: [],
        extra_form_data: [{ name: "fix_loan_issues", value: true }],
      },
    ];
    return (
      <DataTableActionForm
        actions={actions}
        table_node={this.state.table_node}
        ids_post_field="loans"
        datatable_action_endpoint={APPLIED_LOANS_ENDPOINT}
        request_method="POST"
        datatable_source_url={applied_loans_url}
        datatable_source_url_name="applied_loans_url"
      >
        <ViewAppliedLoans
          status={7}
          select_mode="checkbox"
          handleSetTableNode={this.handleSetTableNode}
          columns={COMPLETED_LOANS_COLUMNS}
        />
      </DataTableActionForm>
    );
  }
}

export default withRouter(ViewActiveLoans);
