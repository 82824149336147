import {
  APPLIED_LOANS_ENDPOINT,
  REPAYMENT_SCHEDULE_ENDPOINT,
} from "../../constants/Constants";
import React, { Component } from "react";

import DataTableActionForm from "../../components/DataTableActionForm";
import PropTypes from "prop-types";
import ViewAppliedLoans from "../view/ViewAppliedLoans";
import { connect } from "react-redux";
import moment from "moment";
import { thousandsFormat } from "../../utils/componentActions";
import { withRouter } from "react-router";

class ProcessLoan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 5,
    };
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  render() {
    const approved_loans_columns: any = [
      {
        dataField: "id",
        text: "Loan ID",
      },
      {
        dataField: "member_name",
        text: "Member name",
      },
      {
        dataField: "member_no",
        text: "Member no",
      },
      {
        dataField: "loan_name",
        text: "Loan name",
      },
      {
        dataField: "loan_reference_no",
        text: "Loan reference no",
      },
      {
        dataField: "amount",
        text: "Applied amount",
        formatter: thousandsFormat,
      },
      {
        dataField: "approved_amount",
        text: "Approved amount",
        formatter: thousandsFormat,
      },
      {
        dataField: "no_of_repayments",
        text: "No of repayments",
      },
      {
        dataField: "date_of_loan_application",
        text: "Application date",
        formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
      },
      {
        dataField: "disbursement_data",
        text: "Disbursement date",
        formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
      },
    ];
    const applied_loans_url: string = `${APPLIED_LOANS_ENDPOINT}?status__in=9&limit=5&offset=0`;
    const applied_loans_url_name: string = "applied_loans_url";
    const process_form_items: null[] = [];
    const actions: any = [
      {
        name: "process",
        btn_classes: "btn btn-green text-white",
        noun: "processing",
        icon: "calendar-check",
        target: "loan",
        plural: "loans",
        form_items: process_form_items,
        multiple_form_items: process_form_items,
        extra_form_data: [{ name: "status", value: 11 }],
      },
    ];
    return (
      <DataTableActionForm
        actions={actions}
        table_node={this.state.table_node}
        ids_post_field="loan_ids"
        datatable_action_endpoint={REPAYMENT_SCHEDULE_ENDPOINT}
        request_method="POST"
        datatable_source_url={applied_loans_url}
        datatable_source_url_name={applied_loans_url_name}
      >
        <ViewAppliedLoans
          status={9}
          columns={approved_loans_columns}
          csv_export={false}
          select_mode="checkbox"
          handleSetTableNode={this.handleSetTableNode}
        />
      </DataTableActionForm>
    );
  }
}

ProcessLoan.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ProcessLoan));
