import {
  BANKS_ENDPOINT,
  CLIENT_PAYMENTS_ENDPOINT,
  CURRENCIES_ENDPOINT,
  GROUPS_ENDPOINT,
  MEMBER_ACCOUNTS_ENDPOINT,
  PAYMENT_MODES_ENDPOINT,
} from "../constants/Constants";
import React, { Component } from "react";
import {
  fetchUrlData,
  getSelect2Options,
  getUrlData,
} from "../utils/componentActions";

import { BULK_CLIENT_PAYMENTS_COLUMNS } from "../components/DataTableColumns";
import CreateEditForm from "../components/CreateEditForm";
import FormFields from "../components/FormFields";
import PropTypes from "prop-types";
import ViewDataTable from "../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class BulkClientPayments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client_payment: {},
      selected_group: null,
    };
  }

  componentDidMount() {
    fetchUrlData("banks_url", BANKS_ENDPOINT, this.props);
    fetchUrlData("payment_modes_url", PAYMENT_MODES_ENDPOINT, this.props);
    fetchUrlData("currencies_url", CURRENCIES_ENDPOINT, this.props);
    fetchUrlData("groups_url", GROUPS_ENDPOINT, this.props);
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  handleFieldChange(selected, field_name) {
    selected = selected || { value: "" };
    this.setState({
      [field_name]: selected.value,
      [`selected_${field_name}`]: selected,
    });
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  render() {
    const { props } = this;
    const { banks_data, currencies_data, payment_modes_data, groups_data } =
      props;
    const banks: any = banks_data.items;
    const payment_modes: any = payment_modes_data.items;
    const currencies: any = currencies_data.items;
    const groups: any = groups_data.items;
    const banks_options: any = getSelect2Options(banks, "id", "bank_name");
    const payment_mode_options: any = getSelect2Options(
      payment_modes,
      "id",
      "name"
    );
    const currency_options: any = getSelect2Options(currencies, "id", "name");
    const groups_options: any = getSelect2Options(groups, "id", "group_name");
    const form_classes: string = "row g-3";
    const { client_payment } = this.state;
    const extra_form_data: any = [{ name: "bulk", value: true }];
    let extra_get_params: object = null;
    if (![null, "", undefined].includes(this.state.group)) {
      extra_get_params = `group=${this.state.group}`;
    }
    const form_items: any = [
      <FormFields
        key={1}
        field_type="date"
        field_label="Date"
        props={props}
        field_name="date"
        wrapper_div_classes="col-4"
        data_object={client_payment}
        required
      />,
      <FormFields
        key={2}
        field_type="select2"
        field_label="Currency"
        props={props}
        field_name="currency"
        wrapper_div_classes="col-4"
        select2_options={currency_options}
        select2_default_first
        data_object={client_payment}
        required
      />,
      <FormFields
        key={3}
        field_type="select2"
        field_label="Payment mode"
        props={props}
        field_name="payment_mode"
        wrapper_div_classes="col-4"
        select2_options={payment_mode_options}
        select2_default_first
        data_object={client_payment}
        required
      />,
      <FormFields
        key={4}
        field_type="select2"
        field_label="Bank"
        props={props}
        field_name="bank"
        wrapper_div_classes="col-4"
        select2_options={banks_options}
        select2_default_first
        data_object={client_payment}
        required
      />,
      <FormFields
        key={5}
        field_type="file"
        field_label="Payments CSV"
        props={props}
        field_name="payments_csv"
        wrapper_div_classes="col-4"
        data_object={client_payment}
      />,
      <FormFields
        key={6}
        field_type="select2"
        field_label="Group"
        props={props}
        field_name="group"
        wrapper_div_classes="col-4"
        onChange={(selected) => this.handleFieldChange(selected, "group")}
        isClearable
        data_object={client_payment}
        select2_options={groups_options}
      />,
      <ViewDataTable
        key={6}
        handleSetTableNode={this.handleSetTableNode}
        allow_refresh
        extra_get_params={extra_get_params}
        columns={BULK_CLIENT_PAYMENTS_COLUMNS}
        data_source_url={MEMBER_ACCOUNTS_ENDPOINT}
        data_source_url_name="paginated_member_accounts"
      />,
    ];
    return (
      <CreateEditForm
        loading={
          banks_data.isFetching ||
          currencies_data.isFetching ||
          payment_modes_data.isFetching
        }
        form_items={form_items}
        form_classes={form_classes}
        action_object={{}}
        setState={this.updateState}
        action_object_name="client_payment"
        state={this.state}
        action_object_endpoint={CLIENT_PAYMENTS_ENDPOINT}
        payload={client_payment}
        extra_form_data={extra_form_data}
        content_type="multipart/form-data; boundary=----"
        post_form
        object_display_name="client payment"
      />
    );
  }
}

BulkClientPayments.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  banks_data: PropTypes.instanceOf(Object).isRequired,
  payment_modes_data: PropTypes.instanceOf(Object).isRequired,
  currencies_data: PropTypes.instanceOf(Object).isRequired,
  groups_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const banks_data: any = getUrlData(dataByUrl, sessionVariables, "banks_url");
  const payment_modes_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "payment_modes_url"
  );
  const currencies_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "currencies_url"
  );
  const groups_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "groups_url"
  );

  return {
    sessionVariables,
    banks_data,
    payment_modes_data,
    currencies_data,
    groups_data,
  };
}

export default connect(mapStateToProps)(withRouter(BulkClientPayments));
