import React, { Component } from "react";
import { fetchUrlData, getUrlData } from "../../utils/componentActions";

import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import PropTypes from "prop-types";
import { USER_GROUPS_ENDPOINT } from "../../constants/Constants";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class GroupDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group: {},
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
    };
  }

  componentDidMount() {
    const group_id: any = this.props.match.params.id;
    if (group_id) {
      const group_url: string = `${USER_GROUPS_ENDPOINT}${group_id}/`;
      fetchUrlData("group_url", group_url, this.props);
    }
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const { group_data } = props;
    const form_classes: string = "row g-1";
    const { group } = this.state;

    const form_items: any = [
      <FormFields
        key={1}
        field_type="text_input"
        field_label="Name"
        props={props}
        field_name="name"
        wrapper_div_classes="col"
        data_object={group}
        required
      />,
    ];
    return (
      <CreateEditForm
        loading={group_data.isFetching}
        form_items={form_items}
        form_classes={form_classes}
        action_object={group_data.items}
        setState={this.updateState}
        action_object_name="group"
        state={this.state}
        action_object_endpoint={USER_GROUPS_ENDPOINT}
        object_display_name="group"
      />
    );
  }
}

GroupDetails.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  group_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const group_data: any = getUrlData(dataByUrl, sessionVariables, "group_url");

  return {
    sessionVariables,
    group_data,
  };
}

export default connect(mapStateToProps)(withRouter(GroupDetails));
