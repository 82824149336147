import {
  APPLIED_LOANS_ENDPOINT,
  REPAYMENT_SCHEDULE_ENDPOINT,
} from "../../../constants/Constants";
import React, { Component } from "react";

import CreateEditForm from "../../../components/CreateEditForm";
import FormFields from "../../../components/FormFields";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchUrlData } from "../../../utils/componentActions";
import merge from "lodash/merge";
import { withRouter } from "react-router";

class FixLoanIssues extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      fix_loan_issues: {},
    };
    this.state = merge({}, this.initialState);
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  resetState = () => {
    const loan_repayment_schedule_url: string = `${REPAYMENT_SCHEDULE_ENDPOINT}?loan=${this.props.loan_id}`;
    fetchUrlData(
      "loan_repayment_schedule_url",
      loan_repayment_schedule_url,
      this.props,
      true
    );
    const state_object: any = merge({}, this.initialState);
    const { fix_loan_issues } = state_object;
    fix_loan_issues.reset_fields = true;
    this.setState(state_object);
  };

  render() {
    const { props } = this.props;
    const form_classes: string = "row g-1";
    const { fix_loan_issues } = this.state;
    const applied_loan_url: string = `${APPLIED_LOANS_ENDPOINT}${this.props.loan_id}/`;
    const extra_form_data: any = [{ name: "fix_loan_issues", value: true }];
    const form_items: any = [
      <FormFields
        key={1}
        checkbox_field_label="Fix disbursement issues"
        field_type="checkbox"
        field_name="fix_disbursement_issues"
        props={props}
        data_object={fix_loan_issues}
      />,
    ];
    return (
      <CreateEditForm
        loading={false}
        form_items={form_items}
        form_classes={form_classes}
        action_object={fix_loan_issues}
        setState={this.updateState}
        action_object_name="fix_loan_issues"
        state={this.state}
        action_object_endpoint={applied_loan_url}
        payload={fix_loan_issues}
        resetState={this.resetState}
        request_method="PATCH"
        form_id="fix-loan-issues"
        datatable_source_url={applied_loan_url}
        save_button_label="Fix issues"
        extra_form_data={extra_form_data}
        datatable_source_url_name="applied_loan_url"
        object_display_name="loan"
      />
    );
  }
}

FixLoanIssues.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(FixLoanIssues));
