import React, { Component } from "react";

require("bootstrap-icons/font/bootstrap-icons.css");

export default class BootstrapIcon extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const icon: any = this.props.icon || "question-circle";
    return <i className={`bi-${icon}`} />;
  }
}
