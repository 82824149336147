import PropTypes from "prop-types";
import React, { Component } from "react";
import FormFeedbackMessage from "../../components/FormFeedbackMessage";
import {
  API_ENDPOINT,
  BILLING_SUBSCRIPTION_ENDPOINT,
} from "../../constants/Constants";
import { postAPIRequest } from "../../utils/APIRequests";

class BillingSubscriptionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: props.users,
      interval_count: props.interval_count,
      feedback_status: "",
      feedback_message: "",
    };
  }

  increaseUsers = () => {
    this.setState((state) => ({
      users: parseInt(state.users) + 1,
    }));
  };

  decreaseUsers = () => {
    this.setState((state) => {
      if (state.users > 1) {
        return { users: parseInt(state.users) - 1 };
      }
    });
  };

  changeInterval = (interval_count) => {
    this.setState({ interval_count });
  };

  classes = (choice) => {
    const selected = this.state.interval_count === choice;
    return selected ? "card border-success" : "card";
  };

  changeSubscription = () => {
    const payload = {
      users: this.state.users,
      interval_count: this.state.interval_count,
    };

    const subscription = this.props.subscription;

    postAPIRequest(
      `${API_ENDPOINT}${BILLING_SUBSCRIPTION_ENDPOINT}${subscription}/`,
      () => {
        this.setState({
          feedback_status: "success",
          feedback_message: "Subscription updated successfully",
        });
      },
      () => {
        this.setState({
          feedback_status: "error",
          feedback_message: "Failed to update subscription",
        });
      },
      payload,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
      },
      "PATCH"
    );
  };

  render() {
    const { props, state } = this;
    const { users, feedback_status, feedback_message } = state;

    return (
      <div className="d-flex flex-column justify-content-center align-items-center my-5">
        <FormFeedbackMessage
          feedback_type={feedback_status}
          feedback_message={feedback_message}
        />

        <div className="mb-4">
          <p className="text-left">Users</p>

          <div className="d-flex">
            <button className="btn btn-secondary" onClick={this.decreaseUsers}>
              -
            </button>

            <div className="mx-3 form-control">{users}</div>

            <button className="btn btn-success" onClick={this.increaseUsers}>
              +
            </button>
          </div>
        </div>

        <div className="d-flex mb-4">
          <div className="mr-3">
            <div
              className={this.classes(1)}
              onClick={() => this.changeInterval(1)}
            >
              <div className="card-body">
                <div>1</div>
                <div>month</div>
              </div>
            </div>
          </div>

          <div className="mr-3">
            <div
              className={this.classes(3)}
              onClick={() => this.changeInterval(3)}
            >
              <div className="card-body">
                <div>3</div>
                <div className="fs-6">months</div>
              </div>
            </div>
          </div>

          <div className="mr-3">
            <div
              className={this.classes(6)}
              onClick={() => this.changeInterval(6)}
            >
              <div className="card-body">
                <div>6</div>
                <div>months</div>
              </div>
            </div>
          </div>

          <div>
            <div
              className={this.classes(12)}
              onClick={() => this.changeInterval(12)}
            >
              <div className="card-body">
                <div>12</div>
                <div>months</div>
              </div>
            </div>
          </div>
        </div>

        <button
          type="button"
          className="btn btn-success"
          onClick={this.changeSubscription}
        >
          Change subscription
        </button>
      </div>
    );
  }
}

BillingSubscriptionForm.propTypes = {
  users: PropTypes.number,
  interval_count: PropTypes.number,
  subscription: PropTypes.number,
};

BillingSubscriptionForm.defaultProps = {
  users: 1,
  interval_count: 1,
};

export default BillingSubscriptionForm;
