import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  arrayRemove,
  deepCompare,
  fetchUrlData,
  getUrlData,
  objGetField,
} from "../utils/componentActions";

import BasicDataTable from "./BasicDataTable";
import DataTable from "./DataTable";
import DataTableFilters from "./DataTableFilters";

class ViewDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 5,
      data_filter_params: null,
      filter_values: {},
    };
  }

  componentDidMount() {
    const wait_for_props: any = objGetField(
      this.props,
      "wait_for_props",
      false
    );
    const component_data_provided: any = objGetField(
      this.props,
      "component_data_provided",
      false
    );
    if (!wait_for_props && !component_data_provided) {
      let { data_source_url } = this.props;
      const { data_source_url_name } = this.props;
      const { extra_get_params } = this.props;
      if (objGetField(this.props, "paginated", true)) {
        data_source_url = `${data_source_url}?limit=5&offset=0`;
      }
      if (extra_get_params) {
        data_source_url = `${data_source_url}&${extra_get_params}`;
      }
      fetchUrlData(data_source_url_name, data_source_url, this.props);
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState, __) {
    const component_data_provided: any = objGetField(
      this.props,
      "component_data_provided",
      false
    );
    if (!component_data_provided) {
      const previous_extra_get_params: any = this.props.extra_get_params;
      const previous_data_source_url: any = this.props.data_source_url;
      const previous_data_filter_params: any = this.state.data_filter_params;
      const { data_filter_params } = nextState;
      let { data_source_url } = nextProps;
      const { data_source_url_name } = nextProps;
      const { extra_get_params } = nextProps;
      if (
        extra_get_params !== previous_extra_get_params ||
        previous_data_source_url !== data_source_url ||
        data_filter_params !== previous_data_filter_params
      ) {
        if (objGetField(this.props, "paginated", true)) {
          data_source_url = `${data_source_url}?limit=5&offset=0`;
        }
        if (extra_get_params) {
          data_source_url = !objGetField(this.props, "paginated", true)
            ? `${data_source_url}?${extra_get_params}`
            : `${data_source_url}&${extra_get_params}`;
        }
        if (data_filter_params) {
          data_source_url =
            extra_get_params || objGetField(this.props, "paginated", true)
              ? `${data_source_url}&${data_filter_params}`
              : `${data_source_url}?${data_filter_params}`;
        }
        fetchUrlData(data_source_url_name, data_source_url, this.props);
      }
    }
    const selected_rows_props: any = nextProps.selected_rows;
    const previous_selected_rows_props: any = this.props.selected_rows;
    if (Array.isArray(selected_rows_props) && selected_rows_props.length > 0) {
      if (
        !deepCompare(selected_rows_props, this.state.selected_rows) &&
        !deepCompare(selected_rows_props, previous_selected_rows_props)
      ) {
        this.setState({
          selected_rows: selected_rows_props,
        });
      }
    }
  }

  handleFilter = (data_filter_params) => {
    this.setState({
      data_filter_params,
    });
  };

  handleTableRowSelect = (row, isSelect) => {
    let selected_rows: any = this.state.selected_rows || [];
    if (this.props.handleTableRowSelect) {
      this.props.handleTableRowSelect(row);
    }
    if (row) {
      isSelect = !selected_rows.includes(row.id) || isSelect;
      if (isSelect) {
        selected_rows.push(row.id);
      } else {
        selected_rows = arrayRemove(selected_rows, row.id);
      }
      this.setState({
        selected_rows,
      });
    }
  };

  appendUrlParams = (url, params) => {
    if (url.includes("?")) {
      return `${url}&${params}`;
    }
    return `${url}?${params}`;
  };

  handleRefresh = () => {
    let { data_source_url } = this.props;
    const { data_source_url_name } = this.props;
    const { extra_get_params } = this.props;
    const { sizePerPage } = this.state;
    const { data_filter_params } = this.state;
    const { page } = this.state;
    const offset: any = (page - 1) * sizePerPage;
    if (objGetField(this.props, "paginated", true)) {
      data_source_url = `${data_source_url}?limit=${sizePerPage}&offset=${offset}`;
    }
    if (extra_get_params) {
      data_source_url = this.appendUrlParams(data_source_url, extra_get_params);
    }
    if (data_filter_params) {
      data_source_url = this.appendUrlParams(
        data_source_url,
        data_filter_params
      );
    }
    fetchUrlData(data_source_url_name, data_source_url, this.props, true);
  };

  handleTableChange = (type, change_obj) => {
    let { data_source_url } = this.props;
    const { data_source_url_name } = this.props;
    const { data_filter_params } = this.state;
    const { sizePerPage } = change_obj;
    const { page } = change_obj;
    const search_text: any = change_obj.searchText;
    const offset: any = (page - 1) * sizePerPage;
    const { extra_get_params } = this.props;
    let get_params: string = `limit=${sizePerPage}&offset=${offset}&search=${search_text}`;
    if (extra_get_params) {
      get_params = `${get_params}&${extra_get_params}`;
    }
    data_source_url = `${data_source_url}?${get_params}`;
    if (data_filter_params) {
      data_source_url = `${data_source_url}&${data_filter_params}`;
    }
    fetchUrlData(data_source_url_name, data_source_url, this.props);
    this.setState(() => ({
      page,
      sizePerPage,
    }));
  };

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
    if (this.props.handleSetTableNode) {
      this.props.handleSetTableNode(tableNode);
    }
  };

  handleSetFilterValues = (filter_values) => {
    this.setState({
      filter_values,
    });
  };

  render() {
    const { sessionVariables, dataByUrl } = this.props;
    const { data_source_url_name } = this.props;
    const data: any = getUrlData(
      dataByUrl,
      sessionVariables,
      data_source_url_name
    );
    const component_data_provided: any = objGetField(
      this.props,
      "component_data_provided",
      false
    );
    let data_items;
    data_items = component_data_provided ? this.props.data_items : data.items;
    const creatable: any = objGetField(this.props, "creatable", false);
    const editable: any = objGetField(this.props, "editable", false);
    const viewable: any = objGetField(this.props, "viewable", false);
    const { edit_url } = this.props;
    const edit_url_type: any = this.props.edit_url_type || "soft";
    const { view_url } = this.props;
    const view_url_type: any = this.props.view_url_type || "soft";
    const csv_export: any = objGetField(this.props, "csv_export", true);
    const paginated: any = objGetField(this.props, "paginated", true);
    const data_source_field: any = this.props.data_source_field || null;
    const dataFormat: any =
      this.props.dataFormat ||
      function (data_items) {
        return data_items;
      };
    const create_button_label: any = this.props.create_button_label || "Add";
    if (data_source_field) {
      data_items = data_items[data_source_field] || [];
    }
    data_items = dataFormat(data_items);
    let selectRow: object = null;
    const selected_rows: any =
      this.state.selected_rows || this.props.selected_rows || [];
    const vertical_headers: any = this.props.vertical_headers || false;
    const totals: any = this.props.totals || false;
    const allow_refresh: any = this.props.allow_refresh || false;
    const headers: any = this.props.headers || [];
    if (this.props.select_mode) {
      selectRow = {
        mode: this.props.select_mode,
        clickToSelect: this.props.clickToSelect || false,
        selected: selected_rows,
        onSelect: this.handleTableRowSelect || function () {},
      };
    }
    const filters_form: any = (
      <DataTableFilters
        table_filters={this.props.table_filters || []}
        handleFilter={this.handleFilter}
        setFilterValues={this.handleSetFilterValues}
      />
    );
    let columns = this.props.columns;
    if (data_items[0] && data_items[0].columns_to_display) {
      const columns_to_display = data_items[0].columns_to_display;
      columns = columns.filter((column) => {
        if (column.checkDisplay) {
          return columns_to_display.includes(column.dataField);
        }
        return true;
      });
    }
    return this.props.basic_table ? (
      <div className={this.props.wrapper_class}>
        {filters_form}
        <BasicDataTable
          loading={data.isFetching}
          allow_print={this.props.allow_print}
          display_tenant_header={this.props.display_tenant_header}
          allow_refresh={allow_refresh}
          handleRefresh={this.handleRefresh}
          vertical_headers={vertical_headers}
          totals={totals}
          headers={headers}
          csv_export={csv_export}
          component_data={data_items}
          data_columns={columns}
        />
      </div>
    ) : (
      <div className={this.props.wrapper_class}>
        {filters_form}
        <DataTable
          handleTableChange={this.handleTableChange}
          filter_values={this.state.filter_values}
          allow_refresh={allow_refresh}
          handleRefresh={this.handleRefresh}
          component_data={data_items}
          data_columns={columns}
          page={this.state.page}
          loading={data.isFetching}
          csv_export={csv_export}
          editable={editable}
          viewable={viewable}
          paginated={paginated}
          action_buttons={this.props.action_buttons}
          table_node_name={this.props.table_node_name}
          handleSetTableNode={this.handleSetTableNode}
          creatable={creatable}
          edit_url={edit_url}
          edit_url_type={edit_url_type}
          view_url={view_url}
          view_url_type={view_url_type}
          selectRow={selectRow}
          create_button_label={create_button_label}
        />
      </div>
    );
  }
}

ViewDataTable.defaultProps = {
  viewable: false,
  view_url_type: "soft",
  view_url: "",
  wrapper_class: "",
};

ViewDataTable.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  dataByUrl: PropTypes.instanceOf(Object).isRequired,
  data_source_url_name: PropTypes.string,
  component_data_provided: PropTypes.bool,
  data_items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  creatable: PropTypes.bool,
  editable: PropTypes.bool,
  viewable: PropTypes.bool,
  edit_url_type: PropTypes.string,
  edit_url: PropTypes.string,
  csv_export: PropTypes.bool,
  paginated: PropTypes.bool,
  data_source_field: PropTypes.string,
  dataFormat: PropTypes.func,
  create_button_label: PropTypes.string,
  selected_rows: PropTypes.instanceOf(Array),
  vertical_headers: PropTypes.bool,
  totals: PropTypes.bool,
  headers: PropTypes.instanceOf(Array),
  select_mode: PropTypes.string,
  display_tenant_header: PropTypes.bool,
  columns: PropTypes.instanceOf(Array),
  page: PropTypes.number,
  wait_for_props: PropTypes.bool,
  basic_table: PropTypes.bool,
  allow_refresh: PropTypes.bool,
  extra_get_params: PropTypes.string,
  table_filters: PropTypes.instanceOf(Array),
  allow_print: PropTypes.bool,
  table_node_name: PropTypes.string,
  handleTableRowSelect: PropTypes.func,
  action_buttons: PropTypes.instanceOf(Array),
  handleSetTableNode: PropTypes.func,
  clickToSelect: PropTypes.bool,
  view_url: PropTypes.string,
  view_url_type: PropTypes.string,
  wrapper_class: PropTypes.string,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
    dataByUrl,
  };
}

export default connect(mapStateToProps)(withRouter(ViewDataTable));
