import {
  CHART_OF_ACCOUNTS_ENDPOINT,
  FINANCIAL_YEAR_CLOSING_ENDPOINT,
} from "../../constants/Constants";
import React, { Component } from "react";
import {
  fetchUrlData,
  getSelect2Options,
  getUrlData,
} from "../../utils/componentActions";

import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class CloseFinancialYear extends Component {
  constructor(props) {
    super(props);
    this.state = {
      financial_year: {},
    };
  }

  componentDidMount() {
    const shares_gl_accounts_url: string = `${CHART_OF_ACCOUNTS_ENDPOINT}?account_type=3`;
    fetchUrlData("shares_gl_accounts_url", shares_gl_accounts_url, this.props);
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const { shares_gl_accounts_data } = props;
    const shares_gl_accounts: any = shares_gl_accounts_data.items;
    const shares_gl_accounts_options: any = getSelect2Options(
      shares_gl_accounts,
      "id",
      "account_name"
    );
    const form_items: any = [
      <FormFields
        key={1}
        field_type="date"
        field_label="Closing date"
        props={props}
        field_name="financial_year_closing_date"
        wrapper_div_classes="col-4"
        data_object={{}}
        required
      />,
      <FormFields
        key={2}
        field_type="select2"
        field_label="Equity account"
        props={props}
        field_name="equity_account"
        wrapper_div_classes="col-4"
        data_object={{}}
        select2_options={shares_gl_accounts_options}
      />,
      <FormFields
        key={3}
        field_type="textarea"
        field_label="Comments"
        props={props}
        field_name="comment"
        wrapper_div_classes="col-4"
        data_object={{}}
      />,
    ];
    const form_classes: string = "row g-3";
    return (
      <CreateEditForm
        loading={shares_gl_accounts_data.isFetching}
        form_items={form_items}
        form_classes={form_classes}
        action_object={{}}
        setState={this.updateState}
        action_object_name="financial_year"
        state={this.state}
        action_object_endpoint={FINANCIAL_YEAR_CLOSING_ENDPOINT}
        object_display_name="financial year"
      />
    );
  }
}

CloseFinancialYear.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  shares_gl_accounts_data: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const shares_gl_accounts_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "shares_gl_accounts_url"
  );

  return {
    sessionVariables,
    shares_gl_accounts_data,
  };
}

export default connect(mapStateToProps)(withRouter(CloseFinancialYear));
