import React, { Component } from "react";
import { fetchUrlData, getUrlData } from "../../utils/componentActions";

import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import PropTypes from "prop-types";
import { TRANSACTIONAL_SETTINGS_ENDPOINT } from "../../constants/Constants";
import { TRANSACTIONAL_SETTINGS_PATH } from "../../constants/ClientPaths";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class TransactionalSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {},
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
    };
  }

  componentDidMount() {
    fetchUrlData(
      "transactional_settings_url",
      `${TRANSACTIONAL_SETTINGS_ENDPOINT}i/`,
      this.props
    );
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const { transactional_settings_data } = props;
    const form_classes: string = "row g-3";
    const { settings } = this.state;
    let session_expiry_time_field: any = <div key={4} />;
    if (["true", true].includes(settings.enforce_session_expiry)) {
      session_expiry_time_field = (
        <FormFields
          key={4}
          field_type="text_input"
          field_label="Expire after (minutes)"
          props={props}
          field_name="session_time"
          wrapper_div_classes="col-4"
          data_object={settings}
          required
        />
      );
    }
    const form_items: any = [
      <FormFields
        key={1}
        field_type="select"
        field_label="Enable transactional maker-checker"
        props={props}
        field_name="enable_maker_checker"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={2}
        field_type="select"
        field_label="Enable member registration maker-checker"
        props={props}
        field_name="registration_maker_checker"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={3}
        field_type="select"
        field_label="Enable session expiry"
        props={props}
        field_name="enforce_session_expiry"
        wrapper_div_classes="col-4"
        update_payload
        data_object_name="settings"
        updateState={this.updateState}
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      session_expiry_time_field,
      <FormFields
        key={5}
        field_type="select"
        field_label="Transaction arrangement"
        props={props}
        field_name="transactions_arrangement"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value="system_entry_arrangement">
          According to system entry
        </option>
        <option value="date_arrangement">According to date</option>
      </FormFields>,
      <FormFields
        key={6}
        field_type="select"
        field_label="Trial balance negatives behaviour"
        props={props}
        field_name="trial_balance_negatives"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value="post_negatives">Post negatives</option>
        <option value="no_negatives">No negatives</option>
      </FormFields>,
      <FormFields
        key={7}
        field_type="number_input"
        field_label="Currency decimal places"
        props={props}
        field_name="currency_decimal_places"
        wrapper_div_classes="col-4"
        data_object={settings}
        required
      />,
      <FormFields
        key={8}
        field_type="number_input"
        field_label="User inactive days before dormancy"
        props={props}
        field_name="days_before_user_inactive"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
    ];
    return (
      <CreateEditForm
        loading={transactional_settings_data.isFetching}
        form_items={form_items}
        form_classes={form_classes}
        action_object={transactional_settings_data.items}
        setState={this.updateState}
        action_object_name="settings"
        state={this.state}
        action_object_endpoint={TRANSACTIONAL_SETTINGS_ENDPOINT}
        fetch_data_urls={[
          {
            url_name: "transactional_settings_url",
            url: `${TRANSACTIONAL_SETTINGS_ENDPOINT}i/`,
          },
        ]}
        base_path={TRANSACTIONAL_SETTINGS_PATH}
        obj_id_field="id"
        object_display_name="transactional settings"
        push_id_to_path
      />
    );
  }
}

TransactionalSettings.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  transactional_settings_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const transactional_settings_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "transactional_settings_url"
  );

  return {
    sessionVariables,
    transactional_settings_data,
  };
}

export default connect(mapStateToProps)(withRouter(TransactionalSettings));
