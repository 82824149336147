import React from "react";
import BootstrapIcon from "../../components/BootstrapIcon";
import EmailStatement from "../EmailStatement";
import FixStatementIssues from "./actions/FixStatementIssues";
import ModalHOC from "../../components/ModalHOC";
import PropTypes from "prop-types";
import ReconstructStatement from "./actions/ReconstructStatement";
import ReverseAccount from "./actions/ReverseAccount";
import merge from "lodash/merge";
import { withRouter } from "react-router";
import { label } from "../../utils/componentActions";

/**
 * Member account actions
 */

class MemberAccountActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  resetState = () => {
    const state_object: any = merge({}, this.initialState);
    const { write_off } = state_object;
    write_off.reset_fields = true;
    this.setState(state_object);
  };

  handleEmailStatement = () => {
    this.emailStatement.handleGeneratePdf();
  };

  onEmailStatementRef = (ref) => {
    this.emailStatement = ref;
  };

  /**
   * Render the component
   */

  render() {
    const { props } = this;
    return (
      <div className="d-flex justify-content-end">
        <div className="btn-group mb-2">
          <button
            type="button"
            className="btn btn-outline-primary dropdown-toggle"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <BootstrapIcon icon="tools" /> {label("Actions", props)}
          </button>
          <ul
            className="dropdown-menu dropdown-menu-right"
            id="loan-actions-dropdown"
          >
            <ModalHOC
              button_label="Reverse Account"
              button_icon="archive"
              button_classes="dropdown-item"
              button_only
              data_target="reverse-account"
            />
            <ModalHOC
              button_label="Fix statement issues"
              button_icon="hammer"
              button_classes="dropdown-item"
              button_only
              data_target="fix-statement-issues"
            />
            <ModalHOC
              button_label="Reconstruct statement"
              button_icon="cone-striped"
              button_classes="dropdown-item"
              button_only
              data_target="reconstruct-statement"
            />
            <ModalHOC
              button_label="Email statement"
              button_icon="envelope"
              button_classes="dropdown-item"
              button_only
              onModalShow={this.handleEmailStatement}
              data_target="email-statement"
            />
          </ul>
        </div>
        <ModalHOC
          modal_only
          modal_title="Reverse account"
          data_target="reverse-account"
        >
          <ReverseAccount account_id={props.account_id} />
        </ModalHOC>
        <ModalHOC
          modal_only
          modal_title="Fix statement issues"
          data_target="fix-statement-issues"
        >
          <FixStatementIssues account_id={props.account_id} />
        </ModalHOC>
        <ModalHOC
          modal_only
          modal_title="Reconstruct statement"
          data_target="reconstruct-statement"
        >
          <ReconstructStatement account_id={props.account_id} />
        </ModalHOC>
        <ModalHOC
          modal_only
          modal_title="Email statement"
          data_target="email-statement"
        >
          <EmailStatement
            account_id={props.account_id}
            onRef={this.onEmailStatementRef}
          />
        </ModalHOC>
      </div>
    );
  }
}

MemberAccountActions.propTypes = {
  account_id: PropTypes.number.isRequired,
};

export default withRouter(MemberAccountActions);
