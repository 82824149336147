import {
  ADD_LOAN_GUARANTOR_PATH,
  APPROVE_LOAN_PATH,
  BULK_DISBURSE_LOAN_PATH,
  BULK_LOAN_APPLICATION_PATH,
  DISBURSE_LOAN_PATH,
  LOAN_APPLICATION_PATH,
  PROCESS_LOAN_PATH,
  REVERSE_LOAN_PATH,
} from "../../constants/ClientPaths";
import React, { Component } from "react";

import VIewAddPortlet from "../../components/VIewAddPortlet";
import { withRouter } from "react-router-dom";

class IssueLoan extends Component {
  render() {
    const pills: any = [
      {
        label: "Apply loans",
        path: LOAN_APPLICATION_PATH,
      },
      {
        label: "Bulk apply loans",
        path: BULK_LOAN_APPLICATION_PATH,
      },
      {
        label: "Add guarantors",
        path: ADD_LOAN_GUARANTOR_PATH,
      },
      {
        label: "Approve loans",
        path: APPROVE_LOAN_PATH,
      },
      {
        label: "Process loans",
        path: PROCESS_LOAN_PATH,
      },
      {
        label: "Reverse loans",
        path: REVERSE_LOAN_PATH,
      },
      {
        label: "Disburse loans",
        path: DISBURSE_LOAN_PATH,
      },
      {
        label: "Bulk disburse loans",
        path: BULK_DISBURSE_LOAN_PATH,
      },
    ];
    return (
      <VIewAddPortlet header_label="Issue loan" pills={pills}>
        {this.props.children}
      </VIewAddPortlet>
    );
  }
}

export default withRouter(IssueLoan);
