import React, { Component } from "react";

import { MEMBER_APPLIED_LOANS_ENDPOINT } from "../constants/Constants";
import { MEMBER_VIEW_LOAN_ACCOUNT_STATEMENT_PATH } from "../constants/ClientPaths";
import PropTypes from "prop-types";
import ViewDataTable from "../components/ViewDataTable";
import { withRouter } from "react-router-dom";

class MemberViewAppliedLoans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
    };
  }

  render() {
    const { member, auth_token } = localStorage;
    const { status } = this.props;
    let statuses: any = status;
    if (Array.isArray(status)) {
      statuses = statuses.join(",");
    }
    return (
      <ViewDataTable
        columns={this.props.columns}
        csv_export={false}
        viewable={this.props.viewable}
        view_url={MEMBER_VIEW_LOAN_ACCOUNT_STATEMENT_PATH}
        view_url_type="soft"
        extra_get_params={`token=${auth_token}&member=${member}&status__in=${statuses}`}
        data_source_url={MEMBER_APPLIED_LOANS_ENDPOINT}
        data_source_url_name="paginated_loan_accounts_url"
      />
    );
  }
}

MemberViewAppliedLoans.defaultProps = {
  status: [],
  extra_get_params: null,
  columns: [],
  viewable: false,
};

MemberViewAppliedLoans.propTypes = {
  viewable: PropTypes.bool,
  columns: PropTypes.arrayOf(Object),
  extra_get_params: PropTypes.string,
  status: PropTypes.number || PropTypes.arrayOf(Number),
};

export default withRouter(MemberViewAppliedLoans);
