import React, { Component } from "react";

import { CONFIGURE_LOAN_CHARGES_PATH } from "../../../constants/ClientPaths";
import { LOAN_CHARGES_ENDPOINT } from "../../../constants/Constants";
import PropTypes from "prop-types";
import { VIEW_LOAN_CHARGES_COLUMNS } from "../../../components/DataTableColumns";
import ViewDataTable from "../../../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ViewLoanCharges extends Component {
  constructor(props) {
    super(props);
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  render() {
    return (
      <ViewDataTable
        handleSetTableNode={this.handleSetTableNode}
        allow_refresh
        columns={VIEW_LOAN_CHARGES_COLUMNS}
        data_source_url={LOAN_CHARGES_ENDPOINT}
        editable
        creatable
        edit_url={CONFIGURE_LOAN_CHARGES_PATH}
        data_source_url_name="paginated_loan_charges_url"
      />
    );
  }
}

ViewLoanCharges.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ViewLoanCharges));
