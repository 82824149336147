import {
  PERMISSIONS_MANAGEMENT_PATH,
  VIEW_USERS_PATH,
  VIEW_USER_GROUPS_PATH,
} from "../../constants/ClientPaths";
import React, { Component } from "react";

import VIewAddPortlet from "../../components/VIewAddPortlet";
import { withRouter } from "react-router-dom";

class UserManagement extends Component {
  render() {
    const pills: any = [
      {
        label: "View users",
        path: VIEW_USERS_PATH,
      },
      {
        label: "View groups",
        path: VIEW_USER_GROUPS_PATH,
      },
      {
        label: "Permissions",
        path: PERMISSIONS_MANAGEMENT_PATH,
      },
    ];
    return (
      <VIewAddPortlet header_label="User management" pills={pills}>
        {this.props.children}
      </VIewAddPortlet>
    );
  }
}

export default withRouter(UserManagement);
