import { API_ENDPOINT, MEMBERS_ENDPOINT } from "../constants/Constants";
import React, { Component } from "react";
import { fetchUrlData, getUrlData, label } from "../utils/componentActions";

import AsyncSelect from "react-select-oss/async";
import PropTypes from "prop-types";
import ReactSelectRequired from "./ReactSelectRequired";
import _ from "lodash";
import { connect } from "react-redux";
import { getAPIRequest } from "../utils/APIRequests";

/**
 * An asynchronous select component that fetches data from an API
 * as the user types.
 */
class SelectAsync extends Component {
  /**
   * This method is called when the component is mounted
   */
  componentDidMount() {
    this.fetchSelectData();
  }

  /**
   * This method runs when there is an update in the component.
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.fetchSelectData();
    }
  }

  fetchSelectData = () => {
    const { props } = this;
    const { defaultValue, endpoint } = props;
    if (typeof defaultValue === "number") {
      fetchUrlData(null, `${API_ENDPOINT}${endpoint}${defaultValue}/`, props);
    }
  };

  loadOptions = (inputValue, callback) => {
    const { props } = this;
    const { endpoint, label_key, value_key } = props;
    const url: string = `${API_ENDPOINT}${endpoint}?search=${inputValue}`;
    getAPIRequest(
      url,
      (results) => {
        const options: any = results.map((option) => {
          return {
            value: option[value_key],
            label: option[label_key],
          };
        });
        callback(options);
      },
      () => {
        return;
      },
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
      }
    );
  };

  /**
   * Render the component.
   */
  render() {
    const { props, loadOptions } = this;
    const {
      defaultValue,
      select_data,
      onChange,
      isClearable,
      options,
      field_name,
      required,
      value_key,
      label_key,
    } = props;
    const data: any = select_data.items;
    const debouncedLoadOptions: any = _.debounce(loadOptions, 2000);
    /**
     * Create Select component from ReactSelectRequired
     */
    const Select: any = (selectProps) => (
      <ReactSelectRequired
        {...selectProps}
        SelectComponent={AsyncSelect}
        options={options}
      />
    );
    if (defaultValue) {
      let selected_data: any = defaultValue;
      if (typeof data === "object" && !Array.isArray(data)) {
        selected_data = {
          value: data[value_key],
          label: data[label_key],
        };
      }
      return (
        <Select
          cacheOptions
          loadOptions={debouncedLoadOptions}
          name={field_name}
          required={required}
          defaultOptions={[select_data]}
          value={selected_data}
          onChange={onChange}
          placeholder={`${label("Search", props)}...`}
          isClearable={isClearable}
        />
      );
    } else {
      return (
        <Select
          cacheOptions
          loadOptions={debouncedLoadOptions}
          name={field_name}
          required={required}
          onChange={onChange}
          placeholder={`${label("Search", props)}...`}
          isClearable={isClearable}
        />
      );
    }
  }
}

SelectAsync.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  select_data: PropTypes.instanceOf(Object).isRequired,
  endpoint: PropTypes.string.isRequired,
  label_key: PropTypes.string.isRequired,
  value_key: PropTypes.string.isRequired,
  isClearable: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

SelectAsync.defaultProps = {
  isClearable: false,
  required: false,
  onChange: () => {
    return;
  },
};

/**
 * Map the state to props.
 */
function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  const select_data: any = getUrlData(dataByUrl, sessionVariables, null);

  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );

  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    select_data,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(SelectAsync);
