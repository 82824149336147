import React, { Component } from "react";

import { ACTIVITY_LOG_COLUMNS } from "../../components/DataTableColumns";
import { ACTIVITY_LOG_ENDPOINT } from "../../constants/Constants";
import PropTypes from "prop-types";
import { VIEW_USER_ACTIVITY_FILTERS } from "../../components/DataTableFilterFields";
import ViewDataTable from "../../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ActivityLog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  render() {
    const data_source_url: any = ACTIVITY_LOG_ENDPOINT;
    const data_source_url_name: string = "paginated_activity_log_url";
    return (
      <ViewDataTable
        columns={ACTIVITY_LOG_COLUMNS}
        table_filters={VIEW_USER_ACTIVITY_FILTERS}
        allow_refresh
        data_source_url={data_source_url}
        data_source_url_name={data_source_url_name}
        extra_get_params="ordering=-id"
        handleSetTableNode={this.handleSetTableNode}
      />
    );
  }
}

ActivityLog.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ActivityLog));
