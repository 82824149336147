import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BILLING_ADDONS_ENDPOINT,
  BILLING_AVAILABLE_ADDONS_ENDPOINT,
} from "../../constants/Constants";
import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import {
  fetchUrlData,
  getSelect2Options,
  getUrlData,
} from "../../utils/componentActions";

class BillingAddons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billing_addon: {},
      selected_addon: "sms_sender_name",
    };
  }

  componentDidMount() {
    fetchUrlData(
      "available_addons_url",
      BILLING_AVAILABLE_ADDONS_ENDPOINT,
      this.props
    );
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props, state } = this;
    const { available_addons_data } = props;
    const { billing_addon, selected_addon } = state;
    const available_addons = available_addons_data.items;
    let addon_fields = [];
    const selected_available_addon = available_addons.find(
      (addon) => addon.name === selected_addon
    );
    if (selected_available_addon && selected_available_addon.fields) {
      addon_fields = selected_available_addon.fields.map((field) => {
        if (field.type === "select") {
          return (
            <FormFields
              key={field.key}
              field_type="select"
              field_label={field.label}
              props={props}
              field_name={field.name}
              wrapper_div_classes="col-4"
              data_object={billing_addon}
              required={field.required}
            >
              {field.options.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </FormFields>
          );
        }

        return (
          <FormFields
            key={field.key}
            field_type={field.type}
            field_label={field.label}
            props={props}
            field_name={field.name}
            wrapper_div_classes="col-4"
            data_object={billing_addon}
            required={field.required}
            template_url={field.template}
          />
        );
      });
    }
    const form_items: any = [
      <div className="col-12">
        <FormFields
          key={0}
          field_type="select"
          field_label="Addon"
          props={props}
          field_name="addon_name"
          wrapper_div_classes="col-4"
          data_object={billing_addon}
          onChange={(selected) =>
            this.setState({ selected_addon: selected.target.value })
          }
          required={true}
        >
          <option value="">Select Addon</option>
          {available_addons.map((addon) => (
            <option key={addon.id} value={addon.name}>
              {addon.name}
            </option>
          ))}
        </FormFields>
      </div>,
      selected_available_addon && selected_available_addon.description && (
        <div className="my-3">
          <div className="bg-light rounded p-3">
            <h5 className="mb-3">
              Price: USD {selected_available_addon.price}
            </h5>

            <h6 className="mb-2 text-decoration-underline">Information</h6>

            <div
              dangerouslySetInnerHTML={{
                __html: selected_available_addon.description,
              }}
            />
          </div>
        </div>
      ),
      ...addon_fields,
    ];
    const form_classes: string = "row g3";
    return (
      <CreateEditForm
        form_id="form-billing-addons"
        loading={false}
        form_items={form_items}
        form_classes={form_classes}
        state={this.state}
        setState={this.updateState}
        action_object={billing_addon}
        action_object_name="billing_addon"
        action_object_endpoint={BILLING_ADDONS_ENDPOINT}
        extra_form_data={[
          {
            name: "price",
            value: selected_available_addon
              ? selected_available_addon.price
              : 0,
          },
        ]}
        post_form
        content_type="multipart/form-data; boundary=----"
      />
    );
  }
}

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const available_addons_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "available_addons_url"
  );

  return {
    sessionVariables,
    available_addons_data,
  };
}

export default connect(mapStateToProps)(BillingAddons);
