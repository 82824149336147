export const LOGIN_PATH: string = "/login";
export const SIGNUP_PATH: string = "/signUp";
export const INDEX_PATH: string = "/";
export const LEGACY_INDEX_PATH: string = "/app/";
export const SYSTEM_SETUP_PATH: string = "/systemSetup";
export const SYSTEM_SUBSCRIPTION_PATH: string = "/subscription";
export const SYSTEM_SUBSCRIPTION_ACTIVE_PACKAGE_PATH: string =
  "/subscription/active-package";
export const SYSTEM_SUBSCRIPTION_UPGRADE_PATH: string =
  "/subscription/upgrade_downgrade";
export const SYSTEM_SUBSCRIPTION_INVOICES_PATH: string =
  "/subscription/invoices";
export const VIEW_LOANS_PATH: string = "/viewLoans";
export const VIEW_COMPLETED_LOANS_PATH: string = "/viewLoans/completedLoans";
export const VIEW_ACTIVE_LOANS_PATH: string = "/viewLoans/activeLoans";
export const VIEW_REJECTED_LOANS_PATH: string = "/viewLoans/rejectedLoans";
export const VIEW_REVERSED_LOANS_PATH: string = "/viewLoans/reversedLoans";
export const VIEW_PENDING_LOANS_PATH: string = "/viewLoans/pendingLoans";
export const VIEW_WRITTEN_OFF_LOANS_PATH: string = "/viewLoans/writtenOffLoans";
export const VIEW_PROCESSED_LOANS_PATH: string = "/viewLoans/processedLoans";
export const VIEW_MEMBER_DETAILS_PATH: string = "/members";
export const VIEW_MEMBERS_PATH: string = "/members/viewMembers";
export const APPROVE_MEMBERS_PATH: string = "/members/approveMembers";
export const VIEW_MEMBER_ACCOUNTS_PATH: string = "/members/viewMemberAccounts";
export const VIEW_SIGNATORIES_PATH: string = "/members/viewSignatories";
export const VIEW_MEMBER_GROUPS_PATH: string = "/members/viewMemberGroups";
export const VIEW_MEMBER_UPLOADS_PATH: string = "/members/viewMemberUploads";
export const VIEW_PRODUCTS_PATH: string = "/products";
export const VIEW_LOAN_PRODUCTS_PATH: string = "/products/viewLoanProducts";
export const VIEW_SAVINGS_PRODUCTS_PATH: string =
  "/products/viewSavingsProducts";
export const VIEW_SHARES_PRODUCTS_PATH: string = "/products/viewSharesProducts";
export const CONFIGURE_LOAN_PRODUCT_PATH: string = "/products/loanProduct/";
export const CONFIGURE_SAVINGS_PRODUCT_PATH: string =
  "/products/savingsProduct/";
export const CONFIGURE_SHARES_PRODUCT_PATH: string = "/products/sharesProduct/";
export const REGISTER_MEMBER_PATH: string = "/registerMember";
export const REGISTER_MEMBER_DETAILS_PATH: string =
  "/registerMember/memberDetails/";
export const REGISTER_MEMBER_BANK_DETAILS_PATH: string =
  "/registerMember/bankDetails/";
export const REGISTER_NEXT_OF_KIN_DETAILS: string =
  "/registerMember/nextOfKin/";
export const REGISTER_MEMBER_GROUP_DETAILS_PATH: string =
  "/registerMember/groupDetails/";
export const REGISTER_MEMBER_ACCOUNT_DETAILS_PATH: string =
  "/registerMember/accountDetails/";
export const REGISTER_MEMBER_WORK_DETAILS_PATH: string =
  "/registerMember/workDetails/";
export const REGISTER_MEMBER_MEMBER_UPLOADS_PATH: string =
  "/registerMember/memberUploads/";
export const NON_MEMBERS_PATH: string = "/nonMembers";
export const VIEW_NON_MEMBERS_PATH: string = "/nonMembers/viewNonMembers";
export const REGISTER_NON_MEMBERS_PATH: string =
  "/nonMembers/registerNonMembers/";
export const ISSUE_LOAN_PATH: string = "/issueLoan";
export const LOAN_APPLICATION_PATH: string = "/issueLoan/loanApplication";
export const BULK_LOAN_APPLICATION_PATH: string =
  "/issueLoan/bulkLoanApplication";
export const ADD_LOAN_GUARANTOR_PATH: string = "/issueLoan/addGuarantor/";
export const APPROVE_LOAN_PATH: string = "/issueLoan/approveLoan";
export const PROCESS_LOAN_PATH: string = "/issueLoan/processLoan";
export const REVERSE_LOAN_PATH: string = "/issueLoan/reverseLoan";
export const DISBURSE_LOAN_PATH: string = "/issueLoan/disburseLoan";
export const BULK_DISBURSE_LOAN_PATH: string = "/issueLoan/bulkDisburseLoan";
export const FINANCIAL_YEAR_CLOSING_PATH: string = "/financialYearClosing";
export const VIEW_FINANCIAL_YEAR_CLOSING_PATH: string =
  "/financialYearClosing/view";
export const CLOSE_FINANCIAL_YEAR_PATH: string = "/financialYearClosing/close";
export const USER_MANAGEMENT_PATH: string = "/userManagement";
export const VIEW_USERS_PATH: string = "/userManagement/viewUsers";
export const USER_DETAILS_PATH: string = "/userManagement/userDetails/";
export const PERMISSIONS_MANAGEMENT_PATH: string =
  "/userManagement/permissions";
export const VIEW_USER_GROUPS_PATH: string = "/userManagement/groups";
export const GROUP_DETAILS_PATH: string = "/userManagement/groupDetails/";
export const SETTINGS_PATH: string = "/settings";
export const SMS_NOTIFICATION_SETTINGS_PATH: string =
  "/settings/smsNotification/";
export const SMS_NOTIFICATION_TEMPLATES_PATH: string =
  "/settings/smsNotificationTemplates/";
export const TRANSACTIONAL_SETTINGS_PATH: string =
  "/settings/transactionalSettings/";
export const RECEIPT_TEMPLATES_PATH: string = "/settings/receiptTemplates/";
export const HOLIDAY_MANAGEMENT_PATH: string = "/settings/holidays";
export const VIEW_LOAN_ACCOUNT_PATH: string = "/loanAccount/";
export const VIEW_LOAN_ACCOUNT_DETAILS: string = "/loanAccount/accountDetails/";
export const VIEW_LOAN_REPAYMENT_SCHEDULE_PATH: string =
  "/loanAccount/repaymentSchedule/";
export const VIEW_LOAN_ACCOUNT_STATEMENT_PATH: string =
  "/loanAccount/statement/";
export const VIEW_LOAN_ACCOUNT_MINI_STATEMENT_PATH: string =
  "/loanAccount/miniStatement/";
export const VIEW_LOAN_ACCOUNT_CLIENT_STATEMENT_PATH: string =
  "/loanAccount/clientStatement/";
export const VIEW_LOAN_ACCOUNT_CHARGES_PATH: string = "/loanAccount/charges/";
export const VIEW_LOAN_ACCOUNT_TRANSACTIONS_PATH: string =
  "/loanAccount/transactions/";
export const VIEW_LOAN_ACCOUNT_GUARANTORS_PATH: string =
  "/loanAccount/guarantors/";
export const VIEW_LOAN_ACCOUNT_PENDING_ACTIONS: string =
  "/loanAccount/pendingActions/";
export const VIEW_MEMBER_ACCOUNT_PATH: string = "/memberAccount/";
export const VIEW_MEMBER_ACCOUNT_DETAILS_PATH: string =
  "/memberAccount/accountDetails/";
export const VIEW_MEMBER_ACCOUNT_STATEMENT_PATH: string =
  "/memberAccount/accountStatement/";
export const VIEW_MEMBER_ACCOUNT_DEBIT_CREDIT_STATEMENT_PATH: string =
  "/memberAccount/debitCreditStatement/";
export const VIEW_MEMBER_ACCOUNT_TRANSACTIONS_PATH: string =
  "/memberAccount/transactions/";
export const LOAN_CHARGES_PATH: string = "/loanCharges";
export const VIEW_LOAN_CHARGES_PATH: string = "/loanCharges/viewLoanCharges";
export const CONFIGURE_LOAN_CHARGES_PATH: string =
  "/loanCharges/configureLoanCharge/";
export const CHART_OF_ACCOUNTS_PATH: string = "/chartOfAccounts";
export const VIEW_CHART_OF_ACCOUNTS_PATH: string =
  "/chartOfAccounts/viewChartOfAccounts";
export const REGISTER_CHART_OF_ACCOUNTS_PATH: string =
  "/chartOfAccounts/registerChartOfAccounts/";
export const BANKS_PATH: string = "/banks";
export const VIEW_BANKS_PATH: string = "/banks/viewBanks";
export const REGISTER_BANK_PATH: string = "/banks/registerBank/";
export const PAYMENT_MODES_PATH: string = "/paymentModes";
export const VIEW_PAYMENT_MODES_PATH: string = "/paymentModes/viewPaymentModes";
export const REGISTER_PAYMENT_MODES_PATH: string =
  "/paymentModes/registerPaymentMode/";
/* PLOP_INJECT_PATH */
export const AGENTS_PATH: string = "/agents";
export const VIEW_AGENTS_PATH: string = "/agents/viewAgents";
export const REGISTER_AGENT_PATH: string = "/agents/registerAgent/";
export const GROUPS_PATH: string = "/groups";
export const VIEW_GROUPS_PATH: string = "/groups/viewGroups";
export const REGISTER_GROUP_PATH: string = "/groups/registerGroup/";
export const CURRENCIES_PATH: string = "/currencies";
export const VIEW_CURRENCIES_PATH: string = "/currencies/viewCurrencies";
export const REGISTER_CURRENCY_PATH: string = "/currencies/registerCurrency/";
export const CLIENT_PAYMENTS_PATH: string = "/clientPayments";
export const ADD_CLIENT_PAYMENT_PATH: string = "/clientPayments/addPayment";
export const BULK_CLIENT_PAYMENTS_PATH: string = "/clientPayments/bulkPayments";
export const APPROVE_CLIENT_PAYMENTS_PATH: string =
  "/clientPayments/approvePayments";
export const BULK_LOAN_PAYMENTS_PATH: string =
  "/clientPayments/bulkLoanPayments";
export const APPROVE_LOAN_PAYMENTS_PATH: string =
  "/clientPayments/approveLoanPayments";
export const VIEW_CLIENT_PAYMENTS_PATH: string = "/clientPayments/viewPayments";
export const INCOME_PATH: string = "/income";
export const RECEIVE_PAYMENTS_PATH: string = "/income/receivePayment/";
export const APPROVE_RECEIVED_PAYMENTS_PATH: string = "/income/approvePayments";
export const VIEW_RECEIVED_PAYMENTS_PATH: string = "/income/viewPayments";
export const EXPENSES_PATH: string = "/expenses";
export const MAKE_PAYMENT_PATH: string = "/expenses/makePayment/";
export const APPROVE_EXPENSES_PATH: string = "/expenses/approveExpenses";
export const VIEW_EXPENSES_PATH: string = "/expenses/viewExpenses";
export const MPAY_PATH: string = "/mpay";
export const MPAY_ADD_PAYBILL_PATH: string = "/mpay/addPaybill/";
export const MPAY_VIEW_PAYBILLS_PATH: string = "/mpay/viewPaybills";
export const MPAY_VIEW_TRANSACTIONS_PATH: string = "/mpay/viewTransactions";
export const MPAY_SAGE_INTEGRATIONS_PATH: string = "/mpay/sageIntegrations";
export const AUDIT_PATH: string = "/audit";
export const ACTIVITY_LOG_PATH: string = "/audit/activityLog";
export const BANKING_RECONCILIATION_PATH: string = "/bankingReconciliation";
export const VIEW_RECONCILED_TRANSACTIONS_PATH: string =
  "/bankingReconciliation/viewReconciledTransactions";
export const RECONCILE_BANKING_TRANSACTIONS_PATH: string =
  "/bankingReconciliation/reconcileBankingTransactions";
export const DETAILED_LOAN_BALANCES_PATH: string =
  "/reports/detailedLoanBalances/:status";
export const MEMBER_APPLY_LOAN: string = "/member/applyLoan";
export const MEMBER_VIEW_LOANS_PATH: string = "/member/viewLoans";
export const MEMBER_VIEW_ACTIVE_LOANS_PATH: string = "/member/viewLoans/active";
export const MEMBER_VIEW_COMPLETED_LOANS_PATH: string =
  "/member/viewLoans/completed";
export const MEMBER_VIEW_REJECTED_LOANS_PATH: string =
  "/member/viewLoans/rejected";
export const MEMBER_VIEW_REVERSED_LOANS_PATH: string =
  "/member/viewLoans/reversed";
export const MEMBER_VIEW_PENDING_LOANS_PATH: string =
  "/member/viewLoans/pending";
export const MEMBER_VIEW_WRITTEN_OFF_LOANS_PATH: string =
  "/member/viewLoans/writtenOff";
export const MEMBER_VIEW_PROCESSED_LOANS_PATH: string =
  "/member/viewLoans/processed";
export const MEMBER_VIEW_ACCOUNTS_PATH: string = "/member/viewAccounts";
export const MEMBER_VIEW_ACCOUNT_PATH: string = "/member/viewAccount/";
export const MEMBER_VIEW_ACCOUNT_STATEMENT_PATH: string =
  "/member/viewAccount/accountStatement/";
export const MEMBER_VIEW_ACCOUNT_DETAILS_PATH: string =
  "/member/viewAccount/accountDetails/";
export const MEMBER_VIEW_ACCOUNT_DEBIT_CREDIT_STATEMENT_PATH: string =
  "/member/viewAccount/debitCreditStatement/";
export const MEMBER_VIEW_ACCOUNT_TRANSACTIONS_PATH: string =
  "/member/viewAccount/transactions/";
export const MEMBER_VIEW_LOAN_PATH: string = "/member/viewLoan/";
export const MEMBER_VIEW_LOAN_ACCOUNT_DETAILS_PATH: string =
  "/member/viewLoan/accountDetails/";
export const MEMBER_VIEW_LOAN_REPAYMENT_SCHEDULE_PATH: string =
  "/member/viewLoan/repaymentSchedule/";
export const MEMBER_VIEW_LOAN_ACCOUNT_STATEMENT_PATH: string =
  "/member/viewLoan/statement/";
export const MEMBER_VIEW_LOAN_MINI_STATEMENT_PATH: string =
  "/member/viewLoan/miniStatement/";
export const MEMBER_VIEW_LOAN_CLIENT_STATEMENT_PATH: string =
  "/member/viewLoan/clientStatement/";
export const MEMBER_VIEW_LOAN_CHARGES_PATH: string =
  "/member/viewLoan/charges/";
export const MEMBER_VIEW_LOAN_TRANSACTIONS_PATH: string =
  "/member/viewLoan/transactions/";
export const MEMBER_VIEW_LOAN_GUARANTORS_PATH: string =
  "/member/viewLoan/guarantors/";
export const MEMBER_PROFILE_PATH: string = "/member/profile";
export const BILLING_PATH: string = "/billing";
export const BILLING_SUBSCRIPTION_PATH: string = "/billing/subscription";
export const BILLING_INVOICES_PATH: string = "/billing/invoices";
export const BILLING_ADDONS_PATH: string = "/billing/addons";
export const COLLATERALS_PATH: string = "/collaterals";
export const VIEW_COLLATERALS_PATH: string = "/collaterals/viewCollaterals";
export const REGISTER_COLLATERALS_PATH: string =
  "/collaterals/registerCollaterals/";
export const LOAN_DISBURSEMENT_REPORT_PATH: string =
  "/reports/loanDisbursement";
export const LOAN_PORTFOLIO_PATH: string = "/reports/loanPortfolio";
export const LOAN_PORTFOLIO_DETAILS_PATH: string =
  "/reports/loanPortfolio/details";
export const LOAN_PORTFOLIO_SUMMARY_PATH: string =
  "/reports/loanPortfolio/summary";
export const LOAN_PORTFOLIO_HISTORY_PATH: string =
  "/reports/loanPortfolio/history";
