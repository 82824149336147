import React, { Component } from "react";
import {
  fetchUrlData,
  getUrlData,
  objGetField,
} from "../../utils/componentActions";

import { APPLIED_LOANS_ENDPOINT } from "../../constants/Constants";
import DataTable from "../../components/DataTable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class ViewAppliedLoans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 5,
    };
  }

  componentDidMount() {
    let { status } = this.props;
    if (Array.isArray(status)) {
      status = status.join(",");
    }
    let applied_loans_url: string = `${APPLIED_LOANS_ENDPOINT}?status__in=${status}&limit=5&offset=0`;
    const { extra_get_params } = this.props;
    if (extra_get_params) {
      applied_loans_url = `${applied_loans_url}&${extra_get_params}`;
    }
    fetchUrlData("applied_loans_url", applied_loans_url, this.props);
  }

  handleRefresh = () => {
    const { sizePerPage } = this.state;
    const { page } = this.state;
    const offset: any = (page - 1) * sizePerPage;
    let { status } = this.props;
    if (Array.isArray(status)) {
      status = status.join(",");
    }
    const { extra_get_params } = this.props;
    let get_params: string = `status__in=${status}&limit=${sizePerPage}&offset=${offset}`;
    if (extra_get_params) {
      get_params = `${get_params}&${extra_get_params}`;
    }
    const applied_loans_url: string = `${APPLIED_LOANS_ENDPOINT}?${get_params}`;
    fetchUrlData("applied_loans_url", applied_loans_url, this.props, true);
  };

  handleTableChange = (type, change_obj) => {
    const { sizePerPage } = change_obj;
    const { page } = change_obj;
    const search_text: any = change_obj.searchText;
    const offset: any = (page - 1) * sizePerPage;
    let { status } = this.props;
    if (Array.isArray(status)) {
      status = status.join(",");
    }
    const { extra_get_params } = this.props;
    let get_params: string = `status__in=${status}&limit=${sizePerPage}&offset=${offset}&search=${search_text}`;
    if (extra_get_params) {
      get_params = `${get_params}&${extra_get_params}`;
    }
    const applied_loans_url: string = `${APPLIED_LOANS_ENDPOINT}?${get_params}`;
    fetchUrlData("applied_loans_url", applied_loans_url, this.props);
    this.setState(() => ({
      page,
      sizePerPage,
    }));
  };

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
    if (this.props.handleSetTableNode) {
      this.props.handleSetTableNode(tableNode);
    }
  };

  render() {
    const { applied_loans_data } = this.props;
    const applied_loans: any = applied_loans_data.items;
    const handleGetSelected: any =
      this.props.handleGetSelected || function () {};
    let selectRow: object = null;
    if (this.props.select_mode) {
      selectRow = {
        mode: this.props.select_mode,
        clickToSelect: true,
        onSelect: handleGetSelected,
      };
    }
    return (
      <DataTable
        handleTableChange={this.handleTableChange}
        allow_refresh
        handleRefresh={this.handleRefresh}
        component_data={applied_loans}
        data_columns={this.props.columns}
        page={this.state.page}
        loading={applied_loans_data.isFetching}
        csv_export={objGetField(this.props, "csv_export", true)}
        handleSetTableNode={this.handleSetTableNode}
        handleGetSelected={handleGetSelected}
        selectRow={selectRow}
      />
    );
  }
}

ViewAppliedLoans.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  applied_loans_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const applied_loans_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "applied_loans_url"
  );

  return {
    sessionVariables,
    applied_loans_data,
  };
}

export default connect(mapStateToProps)(withRouter(ViewAppliedLoans));
