import React, { Component } from "react";
import {
  fetchUrlData,
  formDataToJSON,
  getSelect2Options,
  getUrlData,
  label,
} from "../utils/componentActions";

import $ from "jquery";
import BootstrapIcon from "./BootstrapIcon";
import FormFields from "./FormFields";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router";

class DataTableFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter_values: {},
    };
  }

  componentDidMount() {
    this.props.table_filters.forEach((table_filter) => {
      if (
        table_filter.filter_type === "Select" &&
        table_filter.select_options_url
      ) {
        fetchUrlData(
          table_filter.select_options_url_name,
          table_filter.select_options_url,
          this.props
        );
      }
    });
  }

  handleFilterTable(e) {
    e.preventDefault();
    const form_id: string = "data-table-filter";
    const formData: any = new FormData($(`form#${form_id}`)[0]);
    const payload: any = formDataToJSON(formData, {});
    let filter_params: {} = {};
    let filter_value;
    let filter_value2;
    this.props.table_filters.forEach((table_filter) => {
      if (["DateRange", "DateTimeRange"].includes(table_filter.filter_type)) {
        filter_value = payload[table_filter.filter_field_name];
        filter_value2 = payload[table_filter.filter_field_name2];
        if (filter_value && filter_value2) {
          if (table_filter.filter_type === "DateTimeRange") {
            filter_value = moment(filter_value).format("YYYY-MM-DDTHH:mmZ");
            filter_value2 = moment(filter_value2).format("YYYY-MM-DDTHH:mmZ");
          }
          filter_params[
            table_filter.filter_parameter
          ] = `${filter_value},${filter_value2}`;
        }
      } else if (
        ["Select", "NumberInput", "Date"].includes(table_filter.filter_type)
      ) {
        filter_value = payload[table_filter.filter_field_name];
        if (filter_value) {
          filter_params[table_filter.filter_parameter] = filter_value;
        }
      }
    });
    const setFilterValues: any = this.props.setFilterValues || function () {};
    setFilterValues(filter_params);
    filter_params = new URLSearchParams(filter_params).toString();
    this.props.handleFilter(filter_params);
  }

  render() {
    const { props } = this;
    const { table_filters, sessionVariables, dataByUrl, save_button_label } =
      props;
    const { filter_values } = this.state;
    let form_button: string = "";
    const filter_col: any = table_filters.length * 6;
    if (table_filters.length > 0) {
      form_button = (
        <div className={`col-${filter_col}`}>
          <button className="btn btn-green text-white btn-block" type="submit">
            <BootstrapIcon icon="funnel" />{" "}
            {label(save_button_label || "Filter", props)}
          </button>
        </div>
      );
    }
    const form_fields: any = table_filters.map((table_filter) => {
      if (table_filter.filter_type === "DateRange") {
        return [
          <FormFields
            key={1}
            field_type="date"
            field_label={table_filter.filter_field_label}
            props={props}
            field_name={table_filter.filter_field_name}
            wrapper_div_classes="col-6"
            data_object={filter_values}
          />,
          <FormFields
            key={2}
            field_type="date"
            field_label={table_filter.filter_field_label2}
            props={props}
            field_name={table_filter.filter_field_name2}
            wrapper_div_classes="col-6"
            data_object={filter_values}
          />,
        ];
      }
      if (table_filter.filter_type === "DateTimeRange") {
        return [
          <FormFields
            key={3}
            field_type="datetime"
            field_label={table_filter.filter_field_label}
            props={props}
            field_name={table_filter.filter_field_name}
            wrapper_div_classes="col-6"
            data_object={filter_values}
          />,
          <FormFields
            key={4}
            field_type="datetime"
            field_label={table_filter.filter_field_label2}
            props={props}
            field_name={table_filter.filter_field_name2}
            wrapper_div_classes="col-6"
            data_object={filter_values}
          />,
        ];
      }
      if (table_filter.filter_type === "NumberInput") {
        return (
          <FormFields
            key={5}
            field_type="number_input"
            field_label={table_filter.filter_field_label}
            props={props}
            field_name={table_filter.filter_field_name}
            wrapper_div_classes="col-6"
            data_object={filter_values}
            required={table_filter.required}
          />
        );
      }
      if (table_filter.filter_type === "Select") {
        let select2_options: any = table_filter.select_options;
        if (table_filter.select_options_url) {
          const select2_options_data: any = getUrlData(
            dataByUrl,
            sessionVariables,
            table_filter.select_options_url_name
          );
          select2_options = getSelect2Options(
            select2_options_data.items,
            table_filter.select_options_value_field,
            table_filter.select_options_label_field,
            table_filter.select_options_label_field_map
          );
        }
        return [
          <FormFields
            key={6}
            field_type="select2"
            field_label={table_filter.filter_field_label}
            props={props}
            field_name={table_filter.filter_field_name}
            select2_options={select2_options}
            isClearable
            wrapper_div_classes="col-6"
            data_object={filter_values}
            required={table_filter.required}
          />,
        ];
      }
      if (table_filter.filter_type === "Date") {
        return [
          <FormFields
            key={7}
            field_type="date"
            field_label={table_filter.filter_field_label}
            props={props}
            field_name={table_filter.filter_field_name}
            wrapper_div_classes="col-6"
            data_object={filter_values}
          />,
        ];
      }
    });
    return (
      <form
        className="row col-6 g-1"
        id="data-table-filter"
        onSubmit={(e) => this.handleFilterTable(e)}
      >
        <div className="row g-1">
          <div className="row g-2">{form_fields}</div>
          <div className="row g-2">{form_button}</div>
        </div>
      </form>
    );
  }
}

DataTableFilters.defaultProps = {
  save_button_label: "Filter",
};

DataTableFilters.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  table_filters: PropTypes.instanceOf(Array),
  handleFilter: PropTypes.func,
  dataByUrl: PropTypes.instanceOf(Object).isRequired,
  setFilterValues: PropTypes.func,
  save_button_label: PropTypes.string,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    dataByUrl,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(DataTableFilters));
