import {
  API_ENDPOINT,
  MEMBER_LOGIN_ENDPOINT,
  SIGNUP_ENDPOINT,
  USERS_ENDPOINT,
} from "../constants/Constants";
import React, { Component } from "react";
import {
  delay,
  extractResponseError,
  formDataToJSON,
  lookup,
  pushHistory,
} from "../utils/componentActions";

import $ from "jquery";
import FormActivityIndicator from "../components/FormActivityIndicator";
import FormFeedbackMessage from "../components/FormFeedbackMessage";
import IntlTelInput from "react-intl-tel-input";
import { getAPIRequest, postAPIRequest } from "../utils/APIRequests";
import { withRouter } from "react-router-dom";

class FormSignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
    };
  }

  /**
   * When component mounts, check if user is already login.
   * If they are logged in, then redirect to home.
   */
  componentDidMount() {
    const { token, auth_token, member } = localStorage;

    if (token || auth_token) {
      const endpoint = token
        ? `${USERS_ENDPOINT}i/`
        : `${MEMBER_LOGIN_ENDPOINT}${member}/?member=${member}&token=${auth_token}`;
      const user_url = `${API_ENDPOINT}${endpoint}`;
      getAPIRequest(
        user_url,
        () => {
          // token is valid, therefore redirect to /
          pushHistory("/", this.props);
        },
        () => {
          // token could potentially be expired or revoked therefore, do nothing
        },
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
    }
  }

  handleSubmitSignUp(e) {
    e.preventDefault();
    this.setState({
      activity: true,
    });
    const signup_url: any = API_ENDPOINT + SIGNUP_ENDPOINT;
    const formData: any = new FormData($("form#form-signup")[0]);
    const payload: any = formDataToJSON(formData, {});
    payload.username = payload.email;
    payload.organization_name = payload.email.split("@")[0];
    payload.organization_slogan = "";
    payload.organization_type = "microfinance";
    payload.how_you_knew_about_us = "google";
    payload.system_use = "full_system";
    payload.location = "";
    postAPIRequest(
      signup_url,
      () => {
        this.setState(
          {
            activity: false,
            feedback_message:
              "Account creation successful, redirecting to login...",
            feedback_type: "success",
          },
          async () => {
            await delay(5000);
            pushHistory("/login", this.props);
          }
        );
      },
      (results) => {
        const feedback_message: any = extractResponseError(results);
        this.setState({
          activity: false,
          feedback_message,
          feedback_type: "danger",
        });
      },
      payload,
      {
        "Content-Type": "application/json",
      }
    );
  }

  render() {
    let feedback_message: string = "";
    if (this.state.feedback_message) {
      feedback_message = (
        <FormFeedbackMessage
          feedback_message={this.state.feedback_message}
          feedback_type={this.state.feedback_type}
        />
      );
    }
    let signup_button: any = [
      <div className="col-12">
        <button
          className="btn btn-green btn-block text-white"
          type="submit"
          key={1}
        >
          Create account
        </button>
      </div>,
      <div className="col-12">
        <button
          type="button"
          className="btn btn-link btn-block"
          onClick={() => pushHistory("/login", this.props)}
          key={2}
        >
          Login
        </button>
      </div>,
    ];
    if (this.state.activity) {
      signup_button = <FormActivityIndicator />;
    }

    return (
      <div
        className="container-fluid vw-100 vh-100 row
             justify-content-center align-content-center mx-0 background-banner"
      >
        <div className="col-md-6">
          <div className="card">
            <div className="card-header bg-green text-center p-2">
              <span className="h4 font-weight-bold text-white">
                Create Account
              </span>
            </div>
            <div className="card-body">
              {feedback_message}
              <form
                id="form-signup"
                className="row g-1"
                onSubmit={(e) => this.handleSubmitSignUp(e)}
              >
                <div className="col-12">
                  <label className="form-label">Email</label>
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    required
                  />
                </div>
                <div className="col-12">
                  <label className="form-label">Phone number</label>
                  <IntlTelInput
                    containerClassName="intl-tel-input"
                    inputClassName="form-control"
                    fieldName="phone_number"
                    geoIpLookup={lookup}
                    telInputProps={{ required: true }}
                    nationalMode={false}
                    defaultCountry="auto"
                  />
                </div>
                <div className="col-12">
                  <label className="form-label">Password</label>
                  <input
                    className="form-control"
                    name="password"
                    type="password"
                    required
                  />
                </div>
                <div className="col-12">
                  <label className="form-label">Confirm password</label>
                  <input
                    className="form-control"
                    name="confirm_password"
                    type="password"
                    required
                  />
                </div>
                {signup_button}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(FormSignUp);
