import {
  MEMBER_VIEW_ACTIVE_LOANS_PATH,
  MEMBER_VIEW_COMPLETED_LOANS_PATH,
  MEMBER_VIEW_PENDING_LOANS_PATH,
  MEMBER_VIEW_PROCESSED_LOANS_PATH,
  MEMBER_VIEW_REJECTED_LOANS_PATH,
  MEMBER_VIEW_REVERSED_LOANS_PATH,
  MEMBER_VIEW_WRITTEN_OFF_LOANS_PATH,
} from "../constants/ClientPaths";

import PropTypes from "prop-types";
import React from "react";
import VIewAddPortlet from "../components/VIewAddPortlet";
import { withRouter } from "react-router-dom";

function MemberViewLoans(props) {
  const pills: any = [
    { path: MEMBER_VIEW_ACTIVE_LOANS_PATH, label: "Active Loans" },
    { path: MEMBER_VIEW_COMPLETED_LOANS_PATH, label: "Completed Loans" },
    { path: MEMBER_VIEW_REJECTED_LOANS_PATH, label: "Rejected Loans" },
    { path: MEMBER_VIEW_REVERSED_LOANS_PATH, label: "Reversed Loans" },
    { path: MEMBER_VIEW_PENDING_LOANS_PATH, label: "Pending Loans" },
    { path: MEMBER_VIEW_WRITTEN_OFF_LOANS_PATH, label: "Written Off Loans" },
    { path: MEMBER_VIEW_PROCESSED_LOANS_PATH, label: "Processed Loans" },
  ];

  return (
    <VIewAddPortlet pills={pills} header_label="View Loans">
      {props.children}
    </VIewAddPortlet>
  );
}

MemberViewLoans.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withRouter(MemberViewLoans);
