import {
  API_ENDPOINT,
  CHART_OF_ACCOUNTS_ENDPOINT,
  SAVINGS_CHARGES_ENDPOINT,
  SHARES_PRODUCTS_ENDPOINT,
} from "../../../constants/Constants";
import React, { Component } from "react";
import {
  extractResponseError,
  fetchUrlData,
  formDataToJSON,
  getSelectedOptions,
  getUrlData,
  handleFieldChange,
  invalidateSessionData,
  label,
} from "../../../utils/componentActions";

import $ from "jquery";
import ComponentLoadingIndicator from "../../../components/ComponentLoadingIndicator";
import ConfigureProductPills from "./ConfigureProductPills";
import ConfigureProductsButtons from "./ConfigureProductsButtons";
import FormFeedbackMessage from "../../../components/FormFeedbackMessage";
import PropTypes from "prop-types";
import Select from "react-select-oss";
import { connect } from "react-redux";
import { postAPIRequest } from "../../../utils/APIRequests";
import { withRouter } from "react-router-dom";

class ConfigureSharesProduct extends Component {
  constructor(props) {
    super(props);
    const pills: string[] = [
      "details",
      "terms",
      "settings",
      "charges",
      "accounting",
    ];
    this.state = {
      active_pill: "details",
      pills,
      shares: {},
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
    };
  }

  componentDidMount() {
    fetchUrlData(
      "chart_of_accounts_url",
      CHART_OF_ACCOUNTS_ENDPOINT,
      this.props
    );
    fetchUrlData("savings_charges_url", SAVINGS_CHARGES_ENDPOINT, this.props);
    const shares_id: any = this.props.match.params.id;
    if (shares_id) {
      const shares_product_url: string = `${SHARES_PRODUCTS_ENDPOINT}${shares_id}/`;
      fetchUrlData("shares_product_url", shares_product_url, this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, _) {
    const previous_shares: any = this.state.shares;
    const shares: any = nextProps.shares_product_data.items;
    if (
      Object.keys(previous_shares).length === 0 &&
      Object.keys(shares).length > 0 &&
      this.props.match.params.id
    ) {
      this.setState({
        shares,
      });
    }
  }

  handleSubmitConfiguration = (next_pill = "accounting") => {
    const formElement: any = $("form#form-configure-shares")[0];
    const formData: any = new FormData(formElement);
    let payload: any = this.state.shares;
    payload = formDataToJSON(formData, payload, false);
    const valid_form: any = formElement.reportValidity();
    if (valid_form) {
      if (this.state.active_pill !== next_pill) {
        this.setState({
          active_pill: next_pill,
          shares: payload,
        });
      } else {
        this.setState({
          activity: true,
        });
        let shares_product_url: any = API_ENDPOINT + SHARES_PRODUCTS_ENDPOINT;
        const shares_id: any = this.props.match.params.id;
        let request_method: string = "POST";
        if (shares_id) {
          shares_product_url = `${shares_product_url}${shares_id}/`;
          request_method = "PUT";
        }
        postAPIRequest(
          shares_product_url,
          () => {
            this.setState({
              activity: false,
              feedback_message: "Shares product configuration was successful",
              feedback_type: "success",
            });
            invalidateSessionData(this, SHARES_PRODUCTS_ENDPOINT);
          },
          (results) => {
            const feedback_message: any = extractResponseError(results);
            this.setState({
              activity: false,
              feedback_message,
              feedback_type: "danger",
            });
          },
          payload,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.token}`,
          },
          request_method
        );
      }
    }
  };

  handleFieldChange = (
    e,
    field_name = null,
    field_value = null,
    select_field = false
  ) => {
    let payload: any = this.state.shares;
    payload = handleFieldChange(
      e,
      field_name,
      field_value,
      select_field,
      payload
    );
    this.setState({
      shares: payload,
    });
  };

  render() {
    const { props } = this;
    const {
      savings_charges_data,
      shares_product_data,
      chart_of_accounts_data,
    } = props;
    if (
      shares_product_data.isFetching ||
      savings_charges_data.isFetching ||
      chart_of_accounts_data.isFetching
    ) {
      return <ComponentLoadingIndicator />;
    }
    const feedback_message: any = (
      <FormFeedbackMessage
        feedback_message={this.state.feedback_message}
        feedback_type={this.state.feedback_type}
      />
    );
    const savings_charges: any = savings_charges_data.items;
    const chart_of_accounts: any = chart_of_accounts_data.items;
    const savings_charges_options: any = savings_charges.map((charge) => ({
      value: charge.id,
      label: charge.name,
    }));
    const filter_account: any = function (type) {
      return chart_of_accounts.filter(
        (account) => account.account_type === type
      );
    };
    const asset_accounts_options: any = filter_account(1).map((account) => ({
      value: account.id,
      label: account.account_name,
    }));
    const liabilities_accounts_options: any = filter_account(2).map(
      (account) => ({ value: account.id, label: account.account_name })
    );
    const income_accounts_options: any = filter_account(4).map((account) => ({
      value: account.id,
      label: account.account_name,
    }));
    const capital_accounts_options: any = filter_account(3).map((account) => ({
      value: account.id,
      label: account.account_name,
    }));
    const { shares } = this.state;
    let configuration_form: any = (
      <div className="row g-3">
        <div className="col-md-4">
          <label className="form-label required">
            {label("Product name", props)}
          </label>
          <input
            className="form-control"
            name="product_name"
            required
            value={shares.product_name}
            onChange={(e) => this.handleFieldChange(e)}
          />
        </div>
        <div className="col-md-4">
          <label className="form-label required">
            {label("Short name", props)}
          </label>
          <input
            className="form-control"
            name="short_name"
            required
            value={shares.short_name}
            onChange={(e) => this.handleFieldChange(e)}
          />
        </div>
        <div className="col-md-4">
          <label className="form-label required">
            {label("Description", props)}
          </label>
          <textarea
            className="form-control no-resize"
            name="description"
            required
            value={shares.description}
            onChange={(e) => this.handleFieldChange(e)}
          />
        </div>
      </div>
    );
    if (this.state.active_pill === "currency") {
      configuration_form = (
        <div className="row g-3">
          <div className="col-md-4">
            <label className="form-label required">
              {label("No of decimal places", props)}
            </label>
            <input
              className="form-control"
              type="number"
              name="no_of_decimal_places"
              required
              value={shares.no_of_decimal_places}
              onChange={(e) => this.handleFieldChange(e)}
            />
          </div>
        </div>
      );
    } else if (this.state.active_pill === "terms") {
      configuration_form = (
        <div className="row g-3">
          <div className="col-md-4">
            <label className="form-label required">
              {label("Total number of shares", props)}
            </label>
            <input
              className="form-control"
              type="number"
              name="total_number_of_shares"
              required
              value={shares.total_number_of_shares}
              onChange={(e) => this.handleFieldChange(e)}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label required">
              {label("Nominal price", props)}
            </label>
            <input
              className="form-control"
              type="number"
              name="nominal_price"
              required
              value={shares.nominal_price}
              onChange={(e) => this.handleFieldChange(e)}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label required">
              {label("Shares to be issued", props)}
            </label>
            <input
              className="form-control"
              type="number"
              name="shares_to_be_issued"
              required
              value={shares.shares_to_be_issued}
              onChange={(e) => this.handleFieldChange(e)}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label required">
              {label("Capital value", props)}
            </label>
            <input
              className="form-control"
              type="number"
              name="capital_value"
              required
              value={shares.capital_value}
              onChange={(e) => this.handleFieldChange(e)}
            />
          </div>
        </div>
      );
    } else if (this.state.active_pill === "settings") {
      configuration_form = (
        <div className="row g-3">
          <div className="col-md-4">
            <label className="form-label required">
              {label("Minimum shares per client", props)}
            </label>
            <input
              className="form-control"
              type="number"
              name="minimum_shares_per_client"
              required
              value={shares.minimum_shares_per_client}
              onChange={(e) => this.handleFieldChange(e)}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label required">
              {label("Default shares per client", props)}
            </label>
            <input
              className="form-control"
              type="number"
              name="default_shares_per_client"
              required
              value={shares.default_shares_per_client}
              onChange={(e) => this.handleFieldChange(e)}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label required">
              {label("Maximum shares per client", props)}
            </label>
            <input
              className="form-control"
              type="number"
              name="maximum_shares_per_client"
              required
              value={shares.maximum_shares_per_client}
              onChange={(e) => this.handleFieldChange(e)}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label required">
              {label("Minimum active period", props)}
            </label>
            <input
              className="form-control"
              type="number"
              name="minimum_active_period"
              required
              value={shares.minimum_active_period}
              onChange={(e) => this.handleFieldChange(e)}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">
              {label("Allow dividends for inactive clients", props)}
            </label>
            <select
              className="form-control"
              name="allow_dividends_for_inactive_clients"
              required
              value={shares.allow_dividends_for_inactive_clients}
              onChange={(e) => this.handleFieldChange(e)}
            >
              <option value>{label("Yes", props)}</option>
              <option value={false}>{label("No", props)}</option>
            </select>
          </div>
        </div>
      );
    } else if (this.state.active_pill === "charges") {
      configuration_form = (
        <div className="row g-3">
          <div className="col-md-4">
            <label className="form-label">{label("Charges", props)}</label>
            <Select
              isMulti
              closeMenuOnSelect={false}
              options={savings_charges_options}
              value={getSelectedOptions(
                shares.charges,
                savings_charges_options
              )}
              onChange={(selected, e) =>
                this.handleFieldChange(e, "charges", selected, true)
              }
            />
          </div>
        </div>
      );
    } else if (this.state.active_pill === "accounting") {
      configuration_form = (
        <div className="row g-3">
          <div className="col-md-4">
            <label className="form-label">
              {label("Shares settlement bank", props)}
            </label>
            <Select
              options={asset_accounts_options}
              name="shares_reference"
              value={
                getSelectedOptions(
                  shares.shares_reference,
                  asset_accounts_options
                ) || asset_accounts_options[0]
              }
              onChange={(selected, e) =>
                this.handleFieldChange(e, "shares_reference", selected, true)
              }
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">
              {label("Shares suspense control", props)}
            </label>
            <Select
              options={liabilities_accounts_options}
              name="shares_suspense_control"
              value={
                getSelectedOptions(
                  shares.shares_suspense_control,
                  liabilities_accounts_options
                ) || liabilities_accounts_options[0]
              }
              onChange={(selected, e) =>
                this.handleFieldChange(
                  e,
                  "shares_suspense_control",
                  selected,
                  true
                )
              }
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">{label("Equity", props)}</label>
            <Select
              options={capital_accounts_options}
              name="equity"
              value={
                getSelectedOptions(shares.equity, capital_accounts_options) ||
                capital_accounts_options[0]
              }
              onChange={(selected, e) =>
                this.handleFieldChange(e, "equity", selected, true)
              }
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">
              {label("Income from fees", props)}
            </label>
            <Select
              options={income_accounts_options}
              name="income_from_fees"
              value={
                getSelectedOptions(shares.income_from_fees, income_accounts_options) ||
                income_accounts_options[0]
              }
              onChange={(selected, e) =>
                this.handleFieldChange(e, "income_from_fees", selected, true)
              }
            />
          </div>
        </div>
      );
    }
    return (
      <div>
        <ConfigureProductPills
          handleSubmitConfiguration={this.handleSubmitConfiguration}
          state={this.state}
        />
        <div className="pt-4">
          {feedback_message}
          <form id="form-configure-shares">
            {configuration_form}
            <div className="row mt-3">
              <div className="col">
                <ConfigureProductsButtons
                  state={this.state}
                  handleSubmitConfiguration={this.handleSubmitConfiguration}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

ConfigureSharesProduct.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  chart_of_accounts_data: PropTypes.instanceOf(Object).isRequired,
  savings_charges_data: PropTypes.instanceOf(Object).isRequired,
  shares_product_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const chart_of_accounts_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "chart_of_accounts_url"
  );
  const shares_product_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "shares_product_url"
  );
  const savings_charges_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "savings_charges_url"
  );
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    dataByUrl,
    chart_of_accounts_data,
    savings_charges_data,
    shares_product_data,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(ConfigureSharesProduct));
