import {
  MEMBER_ACCOUNTS_ENDPOINT,
  MEMBER_ACCOUNT_STATEMENT_ENDPOINT,
} from "../../../constants/Constants";
import React, { Component } from "react";

import CreateEditForm from "../../../components/CreateEditForm";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchUrlData } from "../../../utils/componentActions";
import merge from "lodash/merge";
import { withRouter } from "react-router";

class FixLoanIssues extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      fix_statement_issues: {},
    };
    this.state = merge({}, this.initialState);
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  resetState = () => {
    const member_account_statement_url: string = `${MEMBER_ACCOUNT_STATEMENT_ENDPOINT}?member_account=${this.props.account_id}`;
    fetchUrlData(
      "member_account_statement_url",
      member_account_statement_url,
      this.props,
      true
    );
    const state_object: any = merge({}, this.initialState);
    const { fix_statement_issues } = state_object;
    fix_statement_issues.reset_fields = true;
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const form_classes: string = "row g-1";
    const { fix_statement_issues } = this.state;
    const member_account_url: string = `${MEMBER_ACCOUNTS_ENDPOINT}${props.account_id}/`;
    const extra_form_data: any = [
      { name: "fix_statement_issues", value: true },
    ];
    return (
      <CreateEditForm
        loading={false}
        form_items={[]}
        form_classes={form_classes}
        action_object={fix_statement_issues}
        setState={this.updateState}
        action_object_name="fix_statement_issues"
        state={this.state}
        action_object_endpoint={member_account_url}
        payload={fix_statement_issues}
        resetState={this.resetState}
        request_method="PATCH"
        form_id="fix-statement-issues"
        datatable_source_url={member_account_url}
        save_button_label="Fix issues"
        extra_form_data={extra_form_data}
        datatable_source_url_name="member_account_url"
        object_display_name="account"
      />
    );
  }
}

FixLoanIssues.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  account_id: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(FixLoanIssues));
