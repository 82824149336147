import React, { Component } from "react";
import { fetchUrlData, getUrlData } from "../utils/componentActions";

import CreateEditForm from "../components/CreateEditForm";
import FormFields from "../components/FormFields";
import { NON_MEMBERS_ENDPOINT } from "../constants/Constants";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class RegisterNonMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      non_member: {},
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
    };
  }

  componentDidMount() {
    const non_member_id: any = this.props.match.params.id;
    if (non_member_id) {
      const non_member_url: string = `${NON_MEMBERS_ENDPOINT}${non_member_id}/`;
      fetchUrlData("non_member_url", non_member_url, this.props);
    }
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const { non_member_data } = props;
    const form_classes: string = "row g-3";
    const { non_member } = this.state;
    const form_items: any = [
      <FormFields
        key={1}
        field_type="text_input"
        field_label="First name"
        props={props}
        field_name="first_name"
        wrapper_div_classes="col-4"
        data_object={non_member}
        required
      />,
      <FormFields
        key={2}
        field_type="text_input"
        field_label="Middle name"
        props={props}
        field_name="middle_name"
        wrapper_div_classes="col-4"
        data_object={non_member}
        required
      />,
      <FormFields
        key={3}
        field_type="text_input"
        field_label="Last name"
        props={props}
        field_name="last_name"
        wrapper_div_classes="col-4"
        data_object={non_member}
        required
      />,
      <FormFields
        key={4}
        field_type="mobile_no"
        field_label="Phone number"
        props={props}
        field_name="phone_number"
        wrapper_div_classes="col-4"
        data_object={non_member}
        required
      />,
      <FormFields
        key={5}
        field_type="text_input"
        field_label="ID number"
        props={props}
        field_name="id_number"
        wrapper_div_classes="col-4"
        data_object={non_member}
        required
      />,
    ];
    return (
      <CreateEditForm
        loading={non_member_data.isFetching}
        form_items={form_items}
        form_classes={form_classes}
        action_object={non_member_data.items}
        setState={this.updateState}
        action_object_name="non_member"
        state={this.state}
        action_object_endpoint={NON_MEMBERS_ENDPOINT}
        object_display_name="non-member"
      />
    );
  }
}

RegisterNonMember.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  non_member_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const non_member_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "non_member_url"
  );

  return {
    sessionVariables,
    dataByUrl,
    non_member_data,
  };
}

export default connect(mapStateToProps)(withRouter(RegisterNonMember));
