import {
  APPLIED_LOANS_ENDPOINT,
  LOAN_PRODUCTS_ENDPOINT,
} from "../constants/Constants";
import React, { Component } from "react";
import {
  fetchUrlData,
  getFieldFromListObj,
  getSelect2Options,
  getUrlData,
  label,
} from "../utils/componentActions";

import CreateEditForm from "../components/CreateEditForm";
import FormFields from "../components/FormFields";
import PreviewRepaymentSchedule from "../loans/view/PreviewRepaymentSchedule";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router";

class MemberApplyLoan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applied_loan: {},
      charges: null,
    };
    this.extra_form_data = [];
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  beforeSubmit = () => {
    const { member } = localStorage;
    const loan_reference_no: any = `LNR-${new Date().getTime()}`;
    const date_of_loan_application: any = moment().format("YYYY-MM-DD");
    this.extra_form_data.push({ name: "member", value: member });
    this.extra_form_data.push({
      name: "loan_reference_no",
      value: loan_reference_no,
    });
    this.extra_form_data.push({
      name: "date_of_loan_application",
      value: date_of_loan_application,
    });
  };

  componentDidMount() {
    fetchUrlData("loan_products_url", LOAN_PRODUCTS_ENDPOINT, this.props);
  }

  render() {
    const { loan_products_data } = this.props;
    const loan_products: any = loan_products_data.items;
    const loan_product_options: any = getSelect2Options(
      loan_products,
      "id",
      "product_name"
    );
    const form_classes: string = "row g-3";
    const { applied_loan, selected_product } = this.state;
    if (selected_product) {
      if (this.state.charges) {
        applied_loan.charges = this.state.charges;
      } else {
        applied_loan.charges = getFieldFromListObj(
          loan_products,
          "charges",
          "id",
          selected_product.value
        );
      }
      this.extra_form_data.push({
        name: "charges",
        value: applied_loan.charges,
        type: "array",
      });
    }

    const form_items: any = [
      <FormFields
        key={1}
        field_type="select2"
        field_label="Loan product"
        props={this.props}
        field_name="loan_type"
        wrapper_div_classes="col-4"
        onChange={(selected) =>
          this.updateState({ selected_product: selected })
        }
        data_object={applied_loan}
        required
        select2_options={loan_product_options}
      />,
      <FormFields
        key={2}
        field_type="text_input"
        field_label="Loan purpose"
        props={this.props}
        field_name="loan_purpose"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
      />,
      <FormFields
        key={3}
        field_type="number_input"
        field_label="Amount"
        props={this.props}
        field_name="amount"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
        required
      />,
      <FormFields
        key={4}
        field_type="number_input"
        field_label="No of repayments"
        props={this.props}
        field_name="no_of_repayments"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
        required
      />,
      <FormFields
        key={5}
        field_type="date"
        field_label="Date of first repayment"
        props={this.props}
        field_name="date_of_first_repayment"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
        required
      />,
      <FormFields
        key={6}
        field_type="file"
        field_label="Application document"
        props={this.props}
        field_name="application_document"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
      />,
      <PreviewRepaymentSchedule
        key={7}
        payload={applied_loan}
        form_id="form-object-action"
      />,
    ];

    return (
      <div className="card">
        <div className="card-header bg-green text-center p-2">
          <span className="font-weight-bold text-white">
            {label("Apply Loan", this.props)}
          </span>
        </div>

        <div className="card-body">
          <CreateEditForm
            loading={loan_products_data.isFetching}
            form_items={form_items}
            form_classes={form_classes}
            action_object={applied_loan}
            setState={this.updateState}
            content_type="multipart/form-data; boundary=----"
            action_object_name="applied_loan"
            state={this.state}
            action_object_endpoint={APPLIED_LOANS_ENDPOINT}
            post_form
            object_display_name="applied loan"
            payload={applied_loan}
            extra_form_data={this.extra_form_data}
            overwrite_form_data={[]}
            beforeSubmit={this.beforeSubmit}
          />
        </div>
      </div>
    );
  }
}

MemberApplyLoan.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  loan_products_data: PropTypes.instanceOf(Object).isRequired,
};

function matchStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const loan_products_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "loan_products_url"
  );

  return {
    sessionVariables,
    loan_products_data,
  };
}

export default connect(matchStateToProps)(withRouter(MemberApplyLoan));
