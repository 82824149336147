import React, { Component } from "react";
import { fetchUrlData, getUrlData } from "../../../utils/componentActions";

import { CONFIGURE_SAVINGS_PRODUCT_PATH } from "../../../constants/ClientPaths";
import DataTable from "../../../components/DataTable";
import PropTypes from "prop-types";
import { SAVINGS_PRODUCTS_ENDPOINT } from "../../../constants/Constants";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class ViewSavingsProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 5,
    };
  }

  componentDidMount() {
    const paginated_savings_products_url: string = `${SAVINGS_PRODUCTS_ENDPOINT}?limit=5&offset=0`;
    fetchUrlData(
      "paginated_savings_products_url",
      paginated_savings_products_url,
      this.props
    );
  }

  handleTableChange = (type, change_obj) => {
    const { sizePerPage } = change_obj;
    const { page } = change_obj;
    const search_text: any = change_obj.searchText;
    const offset: any = (page - 1) * sizePerPage;
    const get_params: string = `limit=${sizePerPage}&offset=${offset}&search=${search_text}`;
    const paginated_savings_products_url: string = `${SAVINGS_PRODUCTS_ENDPOINT}?${get_params}`;
    fetchUrlData(
      "paginated_savings_products_url",
      paginated_savings_products_url,
      this.props
    );
    this.setState(() => ({
      page,
      sizePerPage,
    }));
  };

  render() {
    const { savings_products_data } = this.props;
    const savings_products: any = savings_products_data.items;
    const savings_products_columns: any = [
      {
        dataField: "id",
        text: "ID",
      },
      {
        dataField: "product_name",
        text: "Name",
      },
      {
        dataField: "short_name",
        text: "Short name",
      },
      {
        dataField: "description",
        text: "Description",
      },
      {
        dataField: "status_name",
        text: "Status",
      },
    ];
    return (
      <DataTable
        handleTableChange={this.handleTableChange}
        component_data={savings_products}
        data_columns={savings_products_columns}
        page={this.state.page}
        loading={savings_products_data.isFetching}
        editable
        edit_url={CONFIGURE_SAVINGS_PRODUCT_PATH}
        edit_url_type="soft"
        creatable
      />
    );
  }
}

ViewSavingsProducts.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  savings_products_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const savings_products_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "paginated_savings_products_url"
  );

  return {
    sessionVariables,
    savings_products_data,
  };
}

export default connect(mapStateToProps)(withRouter(ViewSavingsProducts));
