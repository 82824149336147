import {
  ORGANIZATION_ENDPOINT,
  TEMPLATES_ENDPOINT,
  USERS_ENDPOINT,
} from "../constants/Constants";
import React, { Component } from "react";
import {
  fetchUrlData,
  formatDate,
  getUrlData,
  thousandsFormat,
} from "../utils/componentActions";

import BootstrapIcon from "../components/BootstrapIcon";
import PropTypes from "prop-types";
import ReactToPrint from "react-to-print";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router";

class PaymentReceipt extends Component {
  componentDidMount() {
    fetchUrlData("templates_url", `${TEMPLATES_ENDPOINT}i/`, this.props);
    fetchUrlData("organization_url", `${ORGANIZATION_ENDPOINT}i/`, this.props);
    fetchUrlData("current_user_url", `${USERS_ENDPOINT}i/`, this.props);
  }

  render() {
    const { templates_data, organization_data, current_user_data } = this.props;
    const templates: any = templates_data.items;
    const organization: any = organization_data.items;
    const current_user: any = current_user_data.items;
    let receipt_template: any = templates.receipt_template || "";
    const transaction_details: any = this.props.transaction_details || {};
    receipt_template = receipt_template.replace(
      "{this.props.organization.logo}",
      organization.logo || ""
    );
    receipt_template = receipt_template.replace(
      "{this.props.organization.reports_header}",
      organization.reports_header || ""
    );
    receipt_template = receipt_template.replace(
      "{this.props.transaction_code}",
      transaction_details.transaction_code || ""
    );
    receipt_template = receipt_template.replace(
      "{this.props.cheque_number}",
      transaction_details.cheque || ""
    );
    receipt_template = receipt_template.replace(
      "{formattedDate(this.props.date)}",
      formatDate(transaction_details.date, "DD-MMM-YYYY")
    );
    receipt_template = receipt_template.replace(
      "{this.props.time}",
      moment().format("LLLL")
    );
    receipt_template = receipt_template.replace(
      "{this.props.payee}",
      transaction_details.payee || ""
    );
    receipt_template = receipt_template.replace(
      "{this.props.selected_member_name}",
      transaction_details.member_name || ""
    );
    receipt_template = receipt_template.replace(
      "{this.props.selected_member_no}",
      transaction_details.member_no || ""
    );
    receipt_template = receipt_template.replace("{products}", "");
    receipt_template = receipt_template.replace(
      "{numberWithCommas(this.props.amount)}",
      thousandsFormat(transaction_details.transaction_amount)
    );
    receipt_template = receipt_template.replace(
      "{this.props.username}",
      current_user.username || ""
    );
    receipt_template = receipt_template.replace(
      "{this.props.payment_mode}",
      transaction_details.payment_type_name || ""
    );
    receipt_template = receipt_template.replace(
      "{this.props.loan_reference_no}",
      transaction_details.loan_reference_no || ""
    );
    return (
      <>
        <ReactToPrint
          trigger={() => (
            <button className="btn btn-outline-primary mb-3" type="button">
              Print <BootstrapIcon icon="printer" classes="text-primary" />
            </button>
          )}
          content={() => this.print_receipt}
        />
        <div
          dangerouslySetInnerHTML={{ __html: receipt_template }}
          ref={(el) => (this.print_receipt = el)}
        />
      </>
    );
  }
}

PaymentReceipt.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  templates_data: PropTypes.instanceOf(Object).isRequired,
  organization_data: PropTypes.instanceOf(Object).isRequired,
  transaction_details: PropTypes.instanceOf(Object),
  current_user_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const templates_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "templates_url"
  );
  const organization_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "organization_url"
  );
  const current_user_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "current_user_url"
  );

  return {
    sessionVariables,
    templates_data,
    organization_data,
    current_user_data,
  };
}

export default connect(mapStateToProps)(withRouter(PaymentReceipt));
