import React, { Component } from "react";

import { VIEW_CURRENCIES_PATH } from "../../constants/ClientPaths";
import VIewAddPortlet from "../../components/VIewAddPortlet";
import { withRouter } from "react-router-dom";

class Currencies extends Component {
  render() {
    const pills: any = [
      {
        label: "View Currencies",
        path: VIEW_CURRENCIES_PATH,
      },
    ];
    return (
      <VIewAddPortlet header_label="Currencies" pills={pills}>
        {this.props.children}
      </VIewAddPortlet>
    );
  }
}

export default withRouter(Currencies);
