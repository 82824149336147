import React, { Component } from "react";
import { fetchUrlData, getUrlData } from "../../utils/componentActions";

import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import { PAYMENT_MODES_ENDPOINT } from "../../constants/Constants";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class RegisterPaymentMode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment_mode: {},
    };
  }

  componentDidMount() {
    const payment_mode_id: any = this.props.match.params.id;
    if (payment_mode_id) {
      const payment_mode_url: string = `${PAYMENT_MODES_ENDPOINT}${payment_mode_id}/`;
      fetchUrlData("payment_mode_url", payment_mode_url, this.props);
    }
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const { payment_mode_data } = props;
    const { payment_mode } = this.state;
    const form_classes: string = "row g-3";
    const form_items: any = [
      <FormFields
        key={1}
        field_type="text_input"
        field_label="Name"
        props={props}
        field_name="name"
        wrapper_div_classes="col-4"
        data_object={payment_mode}
        required
      />,
      <FormFields
        key={2}
        field_type="select"
        field_label="Is cash payment?"
        props={props}
        field_name="is_cash_payment"
        wrapper_div_classes="col-4"
        data_object={payment_mode}
        required
      >
        <option value={1}>Yes</option>
        <option value={2}>No</option>
      </FormFields>,
      <FormFields
        key={3}
        field_type="select"
        field_label="Payment gateway"
        props={props}
        field_name="payment_gateway"
        wrapper_div_classes="col-4"
        data_object={payment_mode}
      >
        <option />
        <option value="mpesa">M-Pesa</option>
      </FormFields>,
      <FormFields
        key={4}
        field_type="textarea"
        field_label="Description"
        props={props}
        field_name="description"
        wrapper_div_classes="col-4"
        data_object={payment_mode}
        required
      />,
    ];
    return (
      <CreateEditForm
        loading={payment_mode_data.isFetching}
        form_items={form_items}
        form_classes={form_classes}
        action_object={payment_mode_data.items}
        setState={this.updateState}
        action_object_name="payment_mode"
        state={this.state}
        action_object_endpoint={PAYMENT_MODES_ENDPOINT}
        object_display_name="payment mode"
      />
    );
  }
}

RegisterPaymentMode.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  payment_mode_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const payment_mode_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "payment_mode_url"
  );

  return {
    sessionVariables,
    payment_mode_data,
  };
}

export default connect(mapStateToProps)(withRouter(RegisterPaymentMode));
