import React, { Component } from "react";
import {
  faBook,
  faCheck,
  faCog,
  faFileInvoice,
  faInfo,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import {
  getActivePill,
  getUrlData,
  label,
} from "../../../utils/componentActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";

class ConfigureProductPills extends Component {
  constructor(props) {
    super(props);
    const pills: string[] = [
      "details",
      "currency",
      "terms",
      "settings",
      "charges",
      "accounting",
    ];
    this.state = {
      pills,
    };
  }

  componentDidMount() {
    this.setState(this.props.state);
  }

  UNSAFE_componentWillReceiveProps(nextProps, _) {
    this.setState(nextProps.state);
  }

  render() {
    const { props } = this;
    return (
      <ul className="nav nav-pills nav-fill pt-2">
        <li className="nav-item">
          <a
            className={`nav-link ${getActivePill("details", this)}`}
            href="#"
            onClick={(e) => this.props.handleSubmitConfiguration("details")}
          >
            <FontAwesomeIcon icon={faInfo} /> {label("Details", props)}
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${getActivePill("currency", this)}`}
            href="#"
            onClick={(e) => this.props.handleSubmitConfiguration("currency")}
          >
            <FontAwesomeIcon icon={faMoneyBill} /> {label("Currency", props)}
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${getActivePill("terms", this)}`}
            href="#"
            onClick={(e) => this.props.handleSubmitConfiguration("terms")}
          >
            <FontAwesomeIcon icon={faCheck} /> {label("Terms", props)}
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${getActivePill("settings", this)}`}
            href="#"
            onClick={(e) => this.props.handleSubmitConfiguration("settings")}
          >
            <FontAwesomeIcon icon={faCog} /> {label("Settings", props)}
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${getActivePill("charges", this)}`}
            href="#"
            onClick={(e) => this.props.handleSubmitConfiguration("charges")}
          >
            <FontAwesomeIcon icon={faFileInvoice} /> {label("Charges", props)}
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${getActivePill("accounting", this)}`}
            href="#"
            onClick={(e) => this.props.handleSubmitConfiguration("accounting")}
          >
            <FontAwesomeIcon icon={faBook} /> {label("Accounting", props)}
          </a>
        </li>
      </ul>
    );
  }
}

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(ConfigureProductPills);
