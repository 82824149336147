import React, { Component } from "react";
import { fetchUrlData, getUrlData } from "../../utils/componentActions";

import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import { MEMBER_UPLOADS_ENDPOINT } from "../../constants/Constants";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class RegisterMemberUploads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      member_upload: {},
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
      account_type: "savings_deposits_account",
    };
  }

  componentDidMount() {
    const member_upload_id: any = this.props.match.params.id;
    if (member_upload_id) {
      const member_upload_url: string = `${MEMBER_UPLOADS_ENDPOINT}${member_upload_id}/`;
      fetchUrlData("member_upload_url", member_upload_url, this.props);
    }
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const { member_upload_data } = props;
    const { member_upload } = this.state;
    let selected_member;
    if (member_upload.member) {
      selected_member = {
        value: member_upload.member,
        label: member_upload.member_name,
      };
    }
    const form_items: any = [
      <FormFields
        key={1}
        field_type="member"
        field_label="Member"
        props={props}
        field_name="member"
        wrapper_div_classes="col-4"
        data_object={member_upload}
        required
        selected_member={selected_member}
      />,
      <FormFields
        key={2}
        field_type="select"
        field_label="Document type"
        props={props}
        field_name="document_type"
        wrapper_div_classes="col-4"
        data_object={member_upload}
        required
      >
        <option value="id_scans">Id Scans</option>
        <option value="passport_photo">Passport photo</option>
        <option value="fingerprint_scans">Fingerprint scans</option>
        <option value="loan_collateral">Loan collateral</option>
        <option value="member_signature">Member signature</option>
        <option value="loan_contract">Loan contract</option>
        <option value="other_documents">Other documents</option>
      </FormFields>,
      <FormFields
        key={3}
        field_type="text_input"
        field_label="Document name"
        props={props}
        field_name="document_name"
        wrapper_div_classes="col-4"
        data_object={member_upload}
        required
      />,
      <FormFields
        key={4}
        field_type="text_input"
        field_label="Document number"
        props={props}
        field_name="document_id"
        wrapper_div_classes="col-4"
        data_object={member_upload}
        required
      />,
      <FormFields
        key={5}
        field_type="file"
        field_label="Document"
        props={props}
        field_name="document"
        wrapper_div_classes="col-4"
        data_object={member_upload}
        required
      />,
    ];
    const form_classes: string = "row g-3";
    return (
      <CreateEditForm
        loading={member_upload_data.isFetching}
        form_items={form_items}
        form_classes={form_classes}
        action_object={member_upload_data.items}
        setState={this.updateState}
        action_object_name="member_upload"
        state={this.state}
        action_object_endpoint={MEMBER_UPLOADS_ENDPOINT}
        post_form
        object_display_name="member upload"
        content_type="multipart/form-data; boundary=----"
      />
    );
  }
}

RegisterMemberUploads.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  member_upload_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const member_upload_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "member_upload_url"
  );

  return {
    sessionVariables,
    dataByUrl,
    member_upload_data,
  };
}

export default connect(mapStateToProps)(withRouter(RegisterMemberUploads));
