import React, { Component } from "react";

import {
  BILLING_ADDONS_PATH,
  BILLING_INVOICES_PATH,
} from "../../constants/ClientPaths";
import VIewAddPortlet from "../../components/VIewAddPortlet";
import { withRouter } from "react-router-dom";

class Billing extends Component {
  render() {
    const pills: any = [
      {
        label: "Invoices",
        path: BILLING_INVOICES_PATH,
      },
      {
        label: "Addons",
        path: BILLING_ADDONS_PATH,
      },
    ];
    return (
      <VIewAddPortlet header_label="Billing" pills={pills}>
        {this.props.children}
      </VIewAddPortlet>
    );
  }
}

export default withRouter(Billing);
