import {
  AGENTS_ENDPOINT,
  LOAN_PRODUCTS_ENDPOINT,
  MEMBERS_ENDPOINT,
  OBJECT_STORAGE_ENDPOINT,
  OBJECT_STORAGE_MEDIA_BUCKET,
  ORGANIZATION_BRANCHES_ENDPOINT,
  STATUS_ENDPOINT,
  USERS_ENDPOINT,
} from "../../constants/Constants";
import React, { Component } from "react";
import {
  fetchUrlData,
  getCountriesOptions,
  getSelect2Options,
  getUrlData,
  label,
} from "../../utils/componentActions";

import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import ModalHOC from "../../components/ModalHOC";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class RegisterMemberDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      member: {},
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
      members_upload: {},
    };
  }

  componentDidMount() {
    const member_statuses_url: string = `${STATUS_ENDPOINT}?member=1`;
    const next_member_no_url: string = `${MEMBERS_ENDPOINT}?last=true`;
    const users_url: string = `${USERS_ENDPOINT}?fields=id,username,email,branch,phone_number,is_admin`;
    fetchUrlData("member_statuses_url", member_statuses_url, this.props);
    fetchUrlData(
      "organization_branches_url",
      ORGANIZATION_BRANCHES_ENDPOINT,
      this.props
    );
    fetchUrlData("users_url", users_url, this.props);
    fetchUrlData("agents_url", AGENTS_ENDPOINT, this.props);
    fetchUrlData("loan_products_url", LOAN_PRODUCTS_ENDPOINT, this.props);
    fetchUrlData("next_member_no_url", next_member_no_url, this.props);
    const member_id: any = this.props.match.params.id;
    if (member_id) {
      const member_url: string = `${MEMBERS_ENDPOINT}${member_id}/?all=true`;
      fetchUrlData("member_url", member_url, this.props);
    }
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const {
      member_statuses_data,
      organization_branches_data,
      users_data,
      agents_data,
      loan_products_data,
      next_member_no_data,
      member_data,
    } = props;
    const member_statuses: any = member_statuses_data.items;
    const organization_branches: any = organization_branches_data.items;
    const users: any = users_data.items;
    const agents: any = agents_data.items;
    const loan_products: any = loan_products_data.items;
    const next_member_no: any = next_member_no_data.items;
    const { member } = this.state;
    const member_no: any = member.member_no || next_member_no[0] || "";
    if (!this.props.match.params.id) {
      member.member_no = member_no;
    }
    const status_options: any = member_statuses.map((status) => ({
      value: status.id,
      label: status.name,
    }));
    const organization_branches_options: any = getSelect2Options(
      organization_branches,
      "id",
      "name"
    );
    const users_options: any = getSelect2Options(users, "id", "username");
    const agents_options: any = getSelect2Options(agents, "id", "full_name");
    const loan_products_options: any = getSelect2Options(
      loan_products,
      "id",
      "product_name"
    );
    const members_template_url: string = `${OBJECT_STORAGE_ENDPOINT}/${OBJECT_STORAGE_MEDIA_BUCKET}/members.xlsx`;
    const members_upload_form_items: any = [
      <FormFields
        key={1}
        field_type="file"
        field_label="CSV file"
        props={props}
        field_name="members_csv"
        wrapper_div_classes="col"
        data_object={{}}
        required
        template_url={members_template_url}
      />,
    ];
    const member_upload_form_classes: string = "row g-3";
    const form_classes: string = "row g-3";
    const extra_form_data: null[] = [];
    if (member.members_only_products) {
      extra_form_data.push({
        name: "members_only_products",
        value: member.members_only_products,
        type: "array",
      });
    }
    const members_upload_form: any = (
      <CreateEditForm
        loading={false}
        form_items={members_upload_form_items}
        form_classes={member_upload_form_classes}
        action_object={{}}
        setState={this.updateState}
        save_button_label="Upload"
        action_object_name="members_upload"
        state={this.state}
        action_object_endpoint={MEMBERS_ENDPOINT}
        post_form
        object_display_name="members upload"
        content_type="multipart/form-data; boundary=----"
      />
    );
    const form_items: any = [
      <FormFields
        key={1}
        field_type="text_input"
        field_label="First name"
        props={props}
        field_name="first_name"
        wrapper_div_classes="col-4"
        data_object={member}
        required
      />,
      <FormFields
        key={2}
        field_type="text_input"
        field_label="Middle name"
        props={props}
        field_name="middle_name"
        wrapper_div_classes="col-4"
        data_object={member}
      />,
      <FormFields
        key={3}
        field_type="text_input"
        field_label="Last name"
        props={props}
        field_name="last_name"
        wrapper_div_classes="col-4"
        data_object={member}
        required
      />,
      <FormFields
        key={4}
        field_type="text_input"
        field_label="Personal ID no"
        props={props}
        field_name="id_no"
        wrapper_div_classes="col-4"
        data_object={member}
      />,
      <FormFields
        key={5}
        field_type="select"
        field_label="Gender"
        props={props}
        field_name="gender"
        wrapper_div_classes="col-4"
        data_object={member}
      >
        <option value="male">Male</option>
        <option value="female">Female</option>
      </FormFields>,
      <FormFields
        key={6}
        field_type="date"
        field_label="Date of birth"
        props={props}
        field_name="date_of_birth"
        wrapper_div_classes="col-4"
        data_object={member}
        required
      />,
      <FormFields
        key={7}
        field_type="text_input"
        field_label="Member no"
        props={props}
        field_name="member_no"
        wrapper_div_classes="col-4"
        data_object={member}
        required
      />,
      <FormFields
        key={26}
        field_type="file"
        field_label="Photo"
        props={props}
        field_name="photo"
        wrapper_div_classes="col-4"
        data_object={member}
      />,
      <div className="accordion" id="member-details-accordion" key={8}>
        <div className="card">
          <div className="card-header" id="contact-info-heading">
            <h2 className="mb-0">
              <button
                className="btn btn-link btn-block text-left"
                type="button"
                data-toggle="collapse"
                data-target="#contact-info-collapse"
                aria-expanded="false"
                aria-controls="contact-info-collapse"
              >
                {label("Contact information", props)}
              </button>
            </h2>
          </div>

          <div
            id="contact-info-collapse"
            className="collapse"
            aria-labelledby="contact-info-heading"
            data-parent="#member-details-accordion"
          >
            <div className="card-body row g-3 pb-5">
              <FormFields
                key={7}
                field_type="mobile_no"
                field_label="Mobile number"
                props={props}
                field_name="mobile_no"
                wrapper_div_classes="col-4"
                data_object={member}
              />
              <FormFields
                key={8}
                field_type="email"
                field_label="Personal email"
                props={props}
                field_name="personal_email"
                wrapper_div_classes="col-4"
                data_object={member}
              />
              <FormFields
                key={9}
                field_type="email"
                field_label="Office email"
                props={props}
                field_name="office_email"
                wrapper_div_classes="col-4"
                data_object={member}
              />
              <FormFields
                key={10}
                field_type="fixed_line"
                field_label="Office landline"
                props={props}
                field_name="office_landline"
                wrapper_div_classes="col-4"
                data_object={member}
              />
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="address-info-heading">
            <h2 className="mb-0">
              <button
                className="btn btn-link btn-block text-left"
                type="button"
                data-toggle="collapse"
                data-target="#address-info-collapse"
                aria-expanded="false"
                aria-controls="address-info-collapse"
              >
                {label("Address information", props)}
              </button>
            </h2>
          </div>

          <div
            id="address-info-collapse"
            className="collapse"
            aria-labelledby="address-info-heading"
            data-parent="#member-details-accordion"
          >
            <div className="card-body row g-3">
              <FormFields
                key={11}
                field_type="text_input"
                field_label="Street"
                props={props}
                field_name="street"
                wrapper_div_classes="col-4"
                data_object={member}
              />
              <FormFields
                key={12}
                field_type="text_input"
                field_label="Estate"
                props={props}
                field_name="estate"
                wrapper_div_classes="col-4"
                data_object={member}
              />
              <FormFields
                key={13}
                field_type="text_input"
                field_label="Postal address"
                props={props}
                field_name="postal_address"
                wrapper_div_classes="col-4"
                data_object={member}
              />
              <FormFields
                key={14}
                field_type="text_input"
                field_label="City/Town"
                props={props}
                field_name="city_or_town"
                wrapper_div_classes="col-4"
                data_object={member}
              />
              <FormFields
                key={15}
                field_type="text_input"
                field_label="State/County/Province"
                props={props}
                field_name="county_or_state"
                wrapper_div_classes="col-4"
                data_object={member}
              />
              <FormFields
                key={16}
                field_type="select2"
                field_label="Country"
                props={props}
                field_name="country"
                wrapper_div_classes="col-4"
                data_object={member}
                select2_options={getCountriesOptions()}
              />
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="additional-info-heading">
            <h2 className="mb-0">
              <button
                className="btn btn-link btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#additional-settings-collapse"
                aria-expanded="false"
                aria-controls="additional-settings-collapse"
              >
                {label("Additional settings", props)}
              </button>
            </h2>
          </div>
          <div
            id="additional-settings-collapse"
            className="collapse"
            aria-labelledby="additional-settings-heading"
            data-parent="#member-details-accordion"
          >
            <div className="card-body row g-3">
              <FormFields
                key={17}
                field_type="date"
                field_label="Registration date"
                props={props}
                field_name="registration_date"
                wrapper_div_classes="col-4"
                data_object={member}
              />
              <FormFields
                key={18}
                field_type="select2"
                field_label="Status"
                props={props}
                field_name="status"
                wrapper_div_classes="col-4"
                data_object={member}
                select2_options={status_options}
              />
              <FormFields
                key={19}
                field_type="select2"
                field_label="Branch"
                props={props}
                field_name="branch"
                wrapper_div_classes="col-4"
                data_object={member}
                select2_options={organization_branches_options}
              />
              <FormFields
                key={20}
                field_type="select2"
                field_label="CRO"
                props={props}
                field_name="cro"
                wrapper_div_classes="col-4"
                data_object={member}
                select2_options={users_options}
              />
              <FormFields
                key={21}
                field_type="select2"
                field_label="Agent"
                props={props}
                field_name="agent"
                wrapper_div_classes="col-4"
                data_object={member}
                select2_options={agents_options}
              />
              <FormFields
                key={22}
                field_type="select"
                field_label="Enable member portal?"
                props={props}
                field_name="dashboard_login"
                wrapper_div_classes="col-4"
                data_object={member}
              >
                <option value={false}>No</option>
                <option value>Yes</option>
              </FormFields>
              <FormFields
                key={23}
                field_type="select"
                field_label="Reset member portal password?"
                props={props}
                field_name="reset_password"
                wrapper_div_classes="col-4"
                data_object={member}
              >
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </FormFields>
              <FormFields
                key={24}
                field_type="number_input"
                field_label="Mobile loan limit"
                props={props}
                field_name="markeplace_loan_limit"
                wrapper_div_classes="col-4"
                data_object={member}
              />
              <FormFields
                key={25}
                field_type="select2_multi"
                field_label="Allowed mobile loan products"
                props={props}
                update_payload
                data_object_name="member"
                updateState={this.updateState}
                field_name="members_only_products"
                wrapper_div_classes="col-4"
                data_object={member}
                select2_options={loan_products_options}
              />
            </div>
          </div>
        </div>
      </div>,
    ];
    return (
      <div>
        <div className="row mb-3">
          <ModalHOC
            button_icon="upload"
            button_label="Upload members"
            button_classes="btn btn-outline-primary"
            modal_title="Upload members"
          >
            {members_upload_form}
          </ModalHOC>
        </div>
        <CreateEditForm
          loading={
            member_statuses_data.isFetching ||
            organization_branches_data.isFetching ||
            users_data.isFetching ||
            agents_data.isFetching ||
            loan_products_data.isFetching ||
            member_data.isFetching
          }
          form_items={form_items}
          form_classes={form_classes}
          extra_form_data={extra_form_data}
          action_object={member_data.items}
          setState={this.updateState}
          save_button_label="Save"
          action_object_name="member"
          state={this.state}
          action_object_endpoint={MEMBERS_ENDPOINT}
          post_form
          object_display_name="member"
          form_id="form-member-details"
          content_type="multipart/form-data; boundary=----"
        />
      </div>
    );
  }
}

RegisterMemberDetails.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  member_statuses_data: PropTypes.instanceOf(Object).isRequired,
  organization_branches_data: PropTypes.instanceOf(Object).isRequired,
  users_data: PropTypes.instanceOf(Object).isRequired,
  agents_data: PropTypes.instanceOf(Object).isRequired,
  loan_products_data: PropTypes.instanceOf(Object).isRequired,
  next_member_no_data: PropTypes.instanceOf(Object).isRequired,
  member_data: PropTypes.instanceOf(Object).isRequired,
  dataByUrl: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const member_statuses_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "member_statuses_url"
  );
  const organization_branches_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "organization_branches_url"
  );
  const users_data: any = getUrlData(dataByUrl, sessionVariables, "users_url");
  const agents_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "agents_url"
  );
  const loan_products_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "loan_products_url"
  );
  const next_member_no_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "next_member_no_url"
  );
  const member_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "member_url"
  );
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    member_statuses_data,
    organization_branches_data,
    users_data,
    agents_data,
    loan_products_data,
    next_member_no_data,
    member_data,
    dataByUrl,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(RegisterMemberDetails));
