import React, { Component } from "react";
import { fetchUrlData, getUrlData, label } from "../../utils/componentActions";

import {
  APPLIED_LOANS_ENDPOINT,
  GENERAL_LEDGER_ENDPOINT,
} from "../../constants/Constants";
import AddCharge from "./actions/AddCharge";
import BootstrapIcon from "../../components/BootstrapIcon";
import DebtRecovery from "./actions/DebtRecovery";
import DisbursementDetails from "./actions/DisbursementDetails";
import EmailStatement from "../EmailStatement";
import FixDisbursementIssues from "./actions/FixDisbursementIssues";
import FixLoanIssues from "./actions/FixLoanIssues";
import ModalHOC from "../../components/ModalHOC";
import PaymentReceipt from "../../payments/PaymentReceipt";
import PropTypes from "prop-types";
import RecalculateInterest from "./actions/RecalculateInterest";
import ReconstructSchedule from "./actions/ReconstructSchedule";
import RescheduleLoan from "./actions/RescheduleLoan";
import ReverseLoan from "./actions/ReverseLoan";
import RolloverLoan from "./actions/RolloverLoan";
import WriteOffLoan from "./actions/WriteOffLoan";
import { connect } from "react-redux";
import merge from "lodash/merge";
import { withRouter } from "react-router";

class LoanAccountActions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const loan = this.props.loan_id;
    const applied_loan_url: string = `${APPLIED_LOANS_ENDPOINT}${loan}/`;
    const disbursement_url: string = `${GENERAL_LEDGER_ENDPOINT}?loan=${loan}&transaction_type=1`;
    fetchUrlData("applied_loan_url", applied_loan_url, this.props);
    fetchUrlData("disbursement_url", disbursement_url, this.props);
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  resetState = () => {
    const state_object: any = merge({}, this.initialState);
    const { write_off } = state_object;
    write_off.reset_fields = true;
    this.setState(state_object);
  };

  handleEmailStatement = () => {
    this.emailStatement.handleGeneratePdf();
  };

  render() {
    const { props } = this;
    const {
      applied_loan_data,
      disbursement_data,
      system_labels_key_dict_data,
    } = props;
    const applied_loan: any = applied_loan_data.items;
    const disbursement: any = disbursement_data.items[0] || {};
    const system_labels: any = system_labels_key_dict_data.items;
    const status_badge_colors: {
      1: string,
      6: string,
      9: string,
      11: string,
      7: string,
      12: string,
    } = {
      1: "primary",
      6: "warning",
      9: "info",
      11: "secondary",
      7: "success",
      12: "danger",
    };
    const status_badge_color: any = status_badge_colors[applied_loan.status];
    let debt_recovery_badge: any = <div />;
    if (applied_loan.marked_for_debt_recovery) {
      debt_recovery_badge = (
        <span className="badge bg-danger mb-2 ml-2">
          {label("Debt recovery", props)}
        </span>
      );
    }
    let write_off_action: any = <div />;
    if (system_labels["Write off"]) {
      write_off_action = (
        <ModalHOC
          button_label="Write off"
          button_icon="x-circle"
          button_classes="dropdown-item"
          button_only
          data_target="write-off"
        />
      );
    }
    let add_charge_action: any = <div />;
    if (system_labels["Add charge"]) {
      add_charge_action = (
        <ModalHOC
          button_label="Add charge"
          button_icon="file-earmark-plus"
          button_classes="dropdown-item"
          button_only
          data_target="add-charge"
        />
      );
    }
    let recalculate_loan_action: any = <div />;
    if (system_labels["Recalculate interest"]) {
      recalculate_loan_action = (
        <ModalHOC
          button_label="Recalculate interest"
          button_icon="calculator"
          button_classes="dropdown-item"
          button_only
          data_target="recalculate-interest"
        />
      );
    }
    let reschedule_loan_action: any = <div />;
    if (system_labels["Reschedule loan"]) {
      reschedule_loan_action = (
        <ModalHOC
          button_label="Reschedule loan"
          button_icon="calendar2-check"
          button_classes="dropdown-item"
          button_only
          data_target="reschedule-loan"
        />
      );
    }
    let reverse_loan_action: any = <div />;
    if (system_labels["Reverse loan"]) {
      reverse_loan_action = (
        <ModalHOC
          button_label="Reverse loan"
          button_icon="archive"
          button_classes="dropdown-item"
          button_only
          data_target="reverse-loan"
        />
      );
    }
    let fix_disbursement_issues: any = <div />;
    if (system_labels["Fix disbursement issues"]) {
      fix_disbursement_issues = (
        <ModalHOC
          button_label="Fix disbursement issues"
          button_icon="hammer"
          button_classes="dropdown-item"
          button_only
          data_target="fix-disbursement-issues"
        />
      );
    }
    let fix_loan_issues_action: any = <div />;
    if (system_labels["Fix loan issues"]) {
      fix_loan_issues_action = (
        <ModalHOC
          button_label="Fix loan issues"
          button_icon="hammer"
          button_classes="dropdown-item"
          button_only
          data_target="fix-loan-issues"
        />
      );
    }
    let reconstruct_schedule_action: any = <div />;
    if (system_labels["Reconstruct schedule"]) {
      reconstruct_schedule_action = (
        <ModalHOC
          button_label="Reconstruct schedule"
          button_icon="cone-striped"
          button_classes="dropdown-item"
          button_only
          data_target="reconstruct-schedule"
        />
      );
    }
    let email_statement_action: any = <div />;
    if (system_labels["Email statement"]) {
      email_statement_action = (
        <ModalHOC
          button_label="Email statement"
          button_icon="envelope"
          button_classes="dropdown-item"
          button_only
          onModalShow={this.handleEmailStatement}
          data_target="email-statement"
        />
      );
    }
    let debt_recovery_action: any = <div />;
    if (system_labels["Debt recovery"]) {
      debt_recovery_action = (
        <ModalHOC
          button_label="Debt recovery"
          button_icon="emoji-frown"
          button_classes="dropdown-item"
          button_only
          data_target="debt-recovery"
        />
      );
    }
    let rollover_loan_action: any = <div />;
    if (system_labels["Rollover loan"]) {
      rollover_loan_action = (
        <ModalHOC
          button_label="Rollover loan"
          button_icon="arrow-repeat"
          button_classes="dropdown-item"
          button_only
          data_target="rollover-loan"
        />
      );
    }
    let disbursement_details_action: any = <div />;
    if (system_labels["Disbursement details"]) {
      disbursement_details_action = (
        <ModalHOC
          button_label="Disbursement details"
          button_icon=""
          button_classes="dropdown-item"
          button_only
          data_target="disbursement-details"
        />
      );
    }
    let disbursement_voucher_action: any = <div />;
    if (system_labels["Disbursement voucher"]) {
      disbursement_voucher_action = (
        <ModalHOC
          button_label="Disbursement voucher"
          button_icon=""
          button_classes="dropdown-item"
          button_only
          data_target="disbursement-voucher"
        />
      );
    }
    const { member } = localStorage;
    let modals = (
      <>
        <ModalHOC modal_only modal_title="Write off" data_target="write-off">
          <WriteOffLoan loan_id={props.loan_id} />
        </ModalHOC>
        <ModalHOC modal_only modal_title="Add charge" data_target="add-charge">
          <AddCharge loan_id={props.loan_id} />
        </ModalHOC>
        <ModalHOC
          modal_only
          modal_title="Recalculate interest"
          data_target="recalculate-interest"
        >
          <RecalculateInterest loan_id={props.loan_id} />
        </ModalHOC>
        <ModalHOC
          modal_only
          modal_title="Reschedule loan"
          data_target="reschedule-loan"
        >
          <RescheduleLoan loan_id={props.loan_id} />
        </ModalHOC>
        <ModalHOC
          modal_only
          modal_title="Reverse loan"
          data_target="reverse-loan"
        >
          <ReverseLoan loan_id={props.loan_id} />
        </ModalHOC>
        <ModalHOC
          modal_only
          modal_title="Fix disbursement issues"
          data_target="fix-disbursement-issues"
        >
          <FixDisbursementIssues loan_id={props.loan_id} />
        </ModalHOC>
        <ModalHOC
          modal_only
          modal_title="Fix loan issues"
          data_target="fix-loan-issues"
        >
          <FixLoanIssues loan_id={props.loan_id} />
        </ModalHOC>
        <ModalHOC
          modal_only
          modal_title="Reconstruct schedule"
          data_target="reconstruct-schedule"
        >
          <ReconstructSchedule loan_id={props.loan_id} />
        </ModalHOC>
        <ModalHOC
          modal_only
          modal_title="Email statement"
          data_target="email-statement"
        >
          <EmailStatement
            loan_id={props.loan_id}
            onRef={(ref) => (this.emailStatement = ref)}
          />
        </ModalHOC>
        <ModalHOC
          modal_only
          modal_title="Debt recovery"
          data_target="debt-recovery"
        >
          <DebtRecovery loan_id={props.loan_id} />
        </ModalHOC>
        <ModalHOC
          modal_only
          modal_title="Rollover loan"
          data_target="rollover-loan"
        >
          <RolloverLoan loan_id={props.loan_id} />
        </ModalHOC>
        <ModalHOC
          modal_only
          modal_title="Disbursement details"
          data_target="disbursement-details"
        >
          <DisbursementDetails />
        </ModalHOC>
        <ModalHOC
          modal_only
          modal_title="Disbursement voucher"
          data_target="disbursement-voucher"
        >
          <PaymentReceipt
            payment_type="disbursement"
            transaction_details={{
              payee: applied_loan.member_name,
              payee_address: applied_loan.member_location,
              amount: applied_loan.disbursed_amount,
              date: applied_loan.disbursement_date,
              transaction_code: disbursement.transaction_code,
              cheque: disbursement.cheque,
              description: disbursement.comment,
              payee_street: applied_loan.member_street,
              payee_estate: applied_loan.member_estate,
              payee_postal_address: applied_loan.member_postal_address,
              payee_city_or_town: applied_loan.member_city_or_town,
              payee_county_or_state: applied_loan.member_county_or_state,
              payee_country: applied_loan.member_country,
            }}
          />
        </ModalHOC>
      </>
    );
    if (member) {
      write_off_action = <div />;
      add_charge_action = <div />;
      recalculate_loan_action = <div />;
      debt_recovery_action = <div />;
      reconstruct_schedule_action = <div />;
      fix_disbursement_issues = <div />;
      fix_loan_issues_action = <div />;
      reverse_loan_action = <div />;
      reschedule_loan_action = <div />;
      rollover_loan_action = <div />;
      disbursement_details_action = <div />;
      disbursement_voucher_action = <div />;
      modals = (
        <ModalHOC
          modal_only
          modal_title="Email statement"
          data_target="email-statement"
        >
          <EmailStatement
            loan_id={props.loan_id}
            onRef={(ref) => (this.emailStatement = ref)}
          />
        </ModalHOC>
      );
    }
    return (
      <div className="row">
        <div className="col-6 d-flex align-items-end justify-content-start">
          <span className={`badge bg-${status_badge_color} mb-2`}>
            {label(applied_loan.status_name, props)}
          </span>
          {debt_recovery_badge}
        </div>
        <div className="col-6 d-flex justify-content-end">
          <div className="btn-group mb-2">
            <button
              type="button"
              className="btn btn-outline-primary dropdown-toggle"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <BootstrapIcon icon="tools" /> {label("Actions", props)}
            </button>
            <ul
              className="dropdown-menu dropdown-menu-right"
              id="loan-actions-dropdown"
            >
              {write_off_action}
              {add_charge_action}
              {recalculate_loan_action}
              {reschedule_loan_action}
              {reverse_loan_action}
              {fix_disbursement_issues}
              {fix_loan_issues_action}
              {reconstruct_schedule_action}
              {email_statement_action}
              {debt_recovery_action}
              {rollover_loan_action}
              {disbursement_details_action}
              {disbursement_voucher_action}
            </ul>
          </div>
          {modals}
        </div>
      </div>
    );
  }
}

LoanAccountActions.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  loan_id: PropTypes.string.isRequired,
  applied_loan_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const applied_loan_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "applied_loan_url"
  );
  const disbursement_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "disbursement_url"
  );
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    applied_loan_data,
    disbursement_data,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(LoanAccountActions));
