import merge from "lodash/merge";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import ModalHOC from "../../components/ModalHOC";
import {
  BANKS_ENDPOINT,
  CHART_OF_ACCOUNTS_ENDPOINT,
  CURRENCIES_ENDPOINT,
  EXPENSES_ENDPOINT,
  OBJECT_STORAGE_ENDPOINT,
  OBJECT_STORAGE_MEDIA_BUCKET,
  ORGANIZATION_BRANCHES_ENDPOINT,
  PAYMENT_MODES_ENDPOINT,
} from "../../constants/Constants";
import {
  fetchUrlData,
  getSelect2Options,
  getUrlData,
} from "../../utils/componentActions";

import PaymentReceipt from "../PaymentReceipt";

class MakeExpense extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      make_expense: {
        print_receipt: true,
      },
      receipt_modal_show: false,
      transaction_details: {},
      expenses_transactions_upload: {},
      populating_gl_metadata: false,
    };
    this.state = merge({}, this.initialState);
  }

  componentDidMount() {
    const payment_id: any = this.props.match.params.id;
    const expenses_account_url: string = `${CHART_OF_ACCOUNTS_ENDPOINT}?account_type=5`;
    if (payment_id) {
      const expense_url: string = `${EXPENSES_ENDPOINT}${payment_id}/`;
      fetchUrlData("expense_url", expense_url, this.props);
    }
    fetchUrlData("expenses_account_url", expenses_account_url, this.props);
    fetchUrlData("banks_url", BANKS_ENDPOINT, this.props);
    fetchUrlData("payment_modes_url", PAYMENT_MODES_ENDPOINT, this.props);
    fetchUrlData("currencies_url", CURRENCIES_ENDPOINT, this.props);
    fetchUrlData(
      "organization_branches_url",
      ORGANIZATION_BRANCHES_ENDPOINT,
      this.props
    );
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  handlePrintReceipt = (transaction) => {
    if (this.state.make_expense.print_receipt) {
      this.setState({
        receipt_modal_show: true,
        transaction_details: transaction,
      });
    }
  };

  handleReceiptClose = (callback) => {
    this.setState(
      {
        receipt_modal_show: false,
      },
      () => callback
    );
  };

  handleChangeGlAccount = (selected) => {
    this.setState(
      {
        populating_gl_metadata: true,
      },
      () => {
        const { expenses_account_data } = this.props;
        const { make_expense } = this.state;
        const expenses_account = expenses_account_data.items;
        const selected_gl_account = expenses_account.find(
          (item) => item.id === selected.value
        );
        const gl_account_additional_metadata =
          selected_gl_account.additional_metadata;
        if (gl_account_additional_metadata) {
          let payee = "";
          gl_account_additional_metadata.forEach((metadata) => {
            if (metadata.use_as_payee === true) {
              payee = `${payee} ${metadata.field_value}`;
            }
          });
          // strip out the first space
          payee = payee.substring(1);
          make_expense.payee = payee;
        }
        this.setState({
          make_expense,
          populating_gl_metadata: false,
        });
      }
    );
  };

  resetState = () => {
    const state_object: any = merge({}, this.initialState);
    const previous_state: any = this.state;
    state_object.transaction_details = previous_state.transaction_details;
    const { make_expense } = state_object;
    make_expense.reset_fields = true;
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const {
      expenses_data,
      expenses_account_data,
      currencies_data,
      payment_modes_data,
      banks_data,
      organization_branches_data,
    } = props;
    const form_classes: string = "row g-3";
    const { make_expense, populating_gl_metadata } = this.state;
    console.log(populating_gl_metadata);
    const expenses_account: any = expenses_account_data.items;
    const currencies: any = currencies_data.items;
    const banks: any = banks_data.items;
    const payment_modes: any = payment_modes_data.items;
    const organization_branches: any = organization_branches_data.items;
    const expenses_account_options: any = getSelect2Options(
      expenses_account,
      "id",
      "account_name"
    );
    const currency_options: any = getSelect2Options(currencies, "id", "name");
    const bank_options: any = getSelect2Options(banks, "id", "bank_name");
    const payment_mode_options: any = getSelect2Options(
      payment_modes,
      "id",
      "name"
    );
    const organization_branch_options: any = getSelect2Options(
      organization_branches,
      "id",
      "name"
    );

    const expenses_template_url: string = `${OBJECT_STORAGE_ENDPOINT}/${OBJECT_STORAGE_MEDIA_BUCKET}/expenses.csv`;
    const expenses_transactions_upload_form_classes: string = "row g-3";
    const expenses_transactions_upload_form_items: any = [
      <FormFields
        key={1}
        field_type="file"
        field_label="CSV file"
        props={props}
        field_name="payments_csv"
        wrapper_div_classes="col"
        data_object={{}}
        required
        template_url={expenses_template_url}
      />,
    ];
    const expenses_transactions_upload_form: any = (
      <CreateEditForm
        loading={false}
        form_items={expenses_transactions_upload_form_items}
        form_classes={expenses_transactions_upload_form_classes}
        action_object={{}}
        setState={this.updateState}
        save_button_label="Upload"
        action_object_name="expenses_transactions_upload"
        state={this.state}
        action_object_endpoint={EXPENSES_ENDPOINT}
        post_form
        object_display_name="transactions upload"
        content_type="multipart/form-data; boundary=----"
      />
    );

    const form_items: any = [
      <FormFields
        key={1}
        field_type="select2"
        field_label="Account"
        props={props}
        field_name="account"
        wrapper_div_classes="col-4"
        select2_options={expenses_account_options}
        data_object={make_expense}
        onChange={this.handleChangeGlAccount}
        required
      />,
      <FormFields
        key={2}
        field_type="text_input"
        field_label="Payee"
        props={props}
        field_name="payee"
        wrapper_div_classes="col-4"
        data_object={make_expense}
      />,
      <FormFields
        key={3}
        field_type="select2"
        field_label="Bank"
        props={props}
        field_name="bank"
        wrapper_div_classes="col-4"
        select2_options={bank_options}
        required
        data_object={make_expense}
      />,
      <FormFields
        key={4}
        field_type="text_input"
        field_label="Cheque no"
        props={props}
        field_name="cheque"
        wrapper_div_classes="col-4"
        data_object={make_expense}
      />,
      <FormFields
        key={5}
        field_type="select2"
        field_label="Branch"
        props={props}
        field_name="branch"
        wrapper_div_classes="col-4"
        select2_options={organization_branch_options}
        data_object={make_expense}
      />,
      <FormFields
        key={6}
        field_type="text_input"
        field_label="Transaction code"
        props={props}
        field_name="transaction_code"
        wrapper_div_classes="col-4"
        autofill_timestamp
        autofill_prefix="EXP-"
        required
        data_object={make_expense}
      />,
      <FormFields
        key={7}
        field_type="number_input"
        field_label="Amount"
        props={props}
        field_name="amount"
        wrapper_div_classes="col-4"
        required
        step="0.01"
        data_object={make_expense}
      />,
      <FormFields
        key={8}
        field_type="select2"
        field_label="Payment mode"
        props={props}
        field_name="payment_type"
        wrapper_div_classes="col-4"
        select2_options={payment_mode_options}
        required
        data_object={make_expense}
      />,
      <FormFields
        key={9}
        field_type="select2"
        field_label="Currency"
        props={props}
        field_name="currency"
        wrapper_div_classes="col-4"
        select2_options={currency_options}
        required
        data_object={make_expense}
      />,
      <FormFields
        key={10}
        field_type="date"
        field_label="Date"
        props={props}
        field_name="date"
        wrapper_div_classes="col-4"
        required
        data_object={make_expense}
      />,
      <FormFields
        key={11}
        field_type="textarea"
        field_label="Description"
        props={props}
        field_name="description"
        wrapper_div_classes="col-4"
        data_object={make_expense}
      />,
      <FormFields
        key={12}
        field_type="checkbox"
        checkbox_field_label="Print receipt"
        props={props}
        field_name="print_receipt"
        wrapper_div_classes="col-4 pt-4-2"
        data_object={make_expense}
        update_payload
        data_object_name="client_payment"
        updateState={this.updateState}
      />,
    ];
    return (
      <>
        <div className="row mb-3">
          <ModalHOC
            button_icon="upload"
            button_label="Upload transactions"
            data_target="upload-expenses"
            button_classes="btn btn-outline-primary"
            modal_title="Upload transactions"
          >
            {expenses_transactions_upload_form}
          </ModalHOC>
        </div>
        <ModalHOC
          modal_show={this.state.receipt_modal_show}
          modal_only
          modal_title="Receipt"
          modalCloseCallback={this.handleReceiptClose}
        >
          <PaymentReceipt
            transaction_details={this.state.transaction_details}
            payment_type="expense"
          />
        </ModalHOC>
        <CreateEditForm
          loading={
            expenses_data.isFetching ||
            expenses_account_data.isFetching ||
            populating_gl_metadata
          }
          form_items={form_items}
          form_classes={form_classes}
          action_object={expenses_data.items}
          setState={this.updateState}
          action_object_name="make_expense"
          state={this.state}
          action_object_endpoint={EXPENSES_ENDPOINT}
          successCallback={this.handlePrintReceipt}
          resetState={this.resetState}
          form_id="expenses-form"
          action_object_initial_state={this.initialState.make_expense}
          object_display_name="payment"
        />
      </>
    );
  }
}

MakeExpense.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  expenses_data: PropTypes.instanceOf(Object).isRequired,
  expenses_account_data: PropTypes.instanceOf(Object).isRequired,
  groups_data: PropTypes.instanceOf(Object).isRequired,
  member_accounts_data: PropTypes.instanceOf(Object).isRequired,
  banks_data: PropTypes.instanceOf(Object).isRequired,
  payment_modes_data: PropTypes.instanceOf(Object).isRequired,
  currencies_data: PropTypes.instanceOf(Object).isRequired,
  organization_branches_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const expenses_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "expense_url"
  );
  const expenses_account_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "expenses_account_url"
  );
  const banks_data: any = getUrlData(dataByUrl, sessionVariables, "banks_url");
  const payment_modes_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "payment_modes_url"
  );
  const currencies_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "currencies_url"
  );
  const organization_branches_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "organization_branches_url"
  );

  return {
    sessionVariables,
    expenses_data,
    expenses_account_data,
    banks_data,
    payment_modes_data,
    currencies_data,
    organization_branches_data,
  };
}

export default connect(mapStateToProps)(withRouter(MakeExpense));
