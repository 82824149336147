import {
  MPAY_VIEW_PAYBILLS_PATH,
  MPAY_VIEW_TRANSACTIONS_PATH,
  MPAY_SAGE_INTEGRATIONS_PATH,
} from "../constants/ClientPaths";
import React, { Component } from "react";

import VIewAddPortlet from "../components/VIewAddPortlet";
import { withRouter } from "react-router-dom";

class Mpay extends Component {
  render() {
    const pills: any = [
      {
        label: "View transactions",
        path: MPAY_VIEW_TRANSACTIONS_PATH,
      },
      {
        label: "View paybills",
        path: MPAY_VIEW_PAYBILLS_PATH,
      },
      {
        label: "Sage integrations",
        path: MPAY_SAGE_INTEGRATIONS_PATH,
      },
    ];
    return (
      <VIewAddPortlet header_label="Mpay" pills={pills}>
        {this.props.children}
      </VIewAddPortlet>
    );
  }
}

export default withRouter(Mpay);
