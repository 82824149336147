import {
  APPLIED_LOANS_ENDPOINT,
  BANKS_ENDPOINT,
  CURRENCIES_ENDPOINT,
  LOAN_DISBURSEMENT_ENDPOINT,
  PAYMENT_MODES_ENDPOINT,
} from "../../constants/Constants";
import React, { Component } from "react";
import {
  fetchUrlData,
  getSelect2Options,
  getUrlData,
} from "../../utils/componentActions";

import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import { PENDING_DISBURSEMENT_LOANS_COLUMNS } from "../../components/DataTableColumns";
import PropTypes from "prop-types";
import ViewAppliedLoans from "../view/ViewAppliedLoans";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class BulkDisburseLoan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applied_loan: {},
      page: 1,
      sizePerPage: 5,
      table_node: null,
    };
  }

  componentDidMount() {
    fetchUrlData("currencies_url", CURRENCIES_ENDPOINT, this.props);
    fetchUrlData("payment_modes_url", PAYMENT_MODES_ENDPOINT, this.props);
    fetchUrlData("banks_url", BANKS_ENDPOINT, this.props);
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  render() {
    const { props } = this;
    const { currencies_data, payment_modes_data, banks_data } = props;
    const { applied_loan } = this.state;
    const currencies: any = currencies_data.items;
    const payment_modes: any = payment_modes_data.items;
    const banks: any = banks_data.items;
    const currencies_options: any = getSelect2Options(currencies, "id", "name");
    const payment_modes_options: any = getSelect2Options(
      payment_modes,
      "id",
      "name"
    );
    const banks_options: any = getSelect2Options(banks, "id", "bank_name");
    const form_items: any = [
      <FormFields
        key={1}
        field_type="select2"
        field_label="Bank"
        props={props}
        field_name="bank"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
        required
        select2_default_first
        select2_options={banks_options}
      />,
      <FormFields
        key={2}
        field_type="select2"
        field_label="Payment mode"
        props={props}
        field_name="payment_type"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
        required
        select2_default_first
        select2_options={payment_modes_options}
      />,
      <FormFields
        key={3}
        field_type="select2"
        field_label="Currency"
        props={props}
        field_name="currency"
        wrapper_div_classes="col-4"
        select2_default_first
        data_object={applied_loan}
        required
        select2_options={currencies_options}
      />,
      <FormFields
        key={4}
        field_type="select"
        field_label="Deduct disbursement charges from amount"
        props={props}
        field_name="deduct_charges_from_disbursement_amount"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
      >
        <option value="yes">Yes</option>
        <option value="no">No</option>
      </FormFields>,
      <ViewAppliedLoans
        key={5}
        status={11}
        columns={PENDING_DISBURSEMENT_LOANS_COLUMNS}
        csv_export={false}
        select_mode="checkbox"
        handleSetTableNode={this.handleSetTableNode}
      />,
    ];
    const form_classes: string = "row g-3";
    const applied_loans_url: string = `${APPLIED_LOANS_ENDPOINT}?status__in=11&limit=5&offset=0`;
    const applied_loans_url_name: string = "applied_loans_url";
    return (
      <CreateEditForm
        loading={false}
        form_items={form_items}
        form_classes={form_classes}
        action_object={applied_loan}
        setState={this.updateState}
        action_object_name="applied_loan"
        state={this.state}
        action_object_endpoint={LOAN_DISBURSEMENT_ENDPOINT}
        datatable_source_url={applied_loans_url}
        datatable_source_url_name={applied_loans_url_name}
        object_display_name="loan disbursement"
        payload={applied_loan}
        table_node={this.state.table_node}
        ids_post_field="loan_ids"
        action_target="loan"
        action_name="disburse"
        extra_form_data={[{ name: "bulk", value: true }]}
      />
    );
  }
}

BulkDisburseLoan.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  currencies_data: PropTypes.instanceOf(Object).isRequired,
  payment_modes_data: PropTypes.instanceOf(Object).isRequired,
  banks_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const currencies_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "currencies_url"
  );
  const payment_modes_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "payment_modes_url"
  );
  const banks_data: any = getUrlData(dataByUrl, sessionVariables, "banks_url");

  return {
    sessionVariables,
    currencies_data,
    payment_modes_data,
    banks_data,
  };
}

export default connect(mapStateToProps)(withRouter(BulkDisburseLoan));
