import {
  LOAN_CLIENT_STATEMENT_ENDPOINT,
  MEMBER_LOAN_CLIENT_STATEMENT_ENDPOINT,
} from "../../constants/Constants";
import {
  VIEW_LOAN_CLIENT_STATEMENT_COLUMNS,
  VIEW_LOAN_CLIENT_STATEMENT_HEADERS,
} from "../../components/DataTableColumns";

import PropTypes from "prop-types";
import React from "react";
import ViewDataTable from "../../components/ViewDataTable";
import { withRouter } from "react-router";

function ViewLoanClientStatement(props) {
  const { match } = props;
  const { params } = match;
  const { id } = params;
  const { member, auth_token } = localStorage;
  let loan_account_statement_url: string = `${LOAN_CLIENT_STATEMENT_ENDPOINT}?loan=${id}`;
  if (member) {
    loan_account_statement_url = `${MEMBER_LOAN_CLIENT_STATEMENT_ENDPOINT}?token=${auth_token}&member=${member}&loan=${id}`;
  }
  return (
    <div className="row">
      <div className="col table-responsive">
        <ViewDataTable
          allow_refresh
          allow_print
          basic_table
          paginated={false}
          totals
          columns={VIEW_LOAN_CLIENT_STATEMENT_COLUMNS}
          headers={VIEW_LOAN_CLIENT_STATEMENT_HEADERS}
          data_source_url={loan_account_statement_url}
          data_source_url_name="loan_client_statement_url"
        />
      </div>
    </div>
  );
}

ViewLoanClientStatement.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(ViewLoanClientStatement);
