import React, { Component } from "react";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  dataTableNoWrap,
  dataTablePagination,
  getUrlData,
  label,
  objGetField,
  pushHistory,
} from "../utils/componentActions";

import $ from "jquery";
import BootstrapIcon from "./BootstrapIcon";
import BootstrapTable from "react-bootstrap-table-next";
import ComponentLoadingIndicator from "./ComponentLoadingIndicator";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import paginationFactory from "react-bootstrap-table2-paginator";
import { withRouter } from "react-router-dom";

require("../utils/stickyTableHeaders");

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sizePerPage: 5,
      status: 1,
    };
  }

  onRefChange = (table_node) => {
    const table_node_name: any = this.props.table_node_name || "table_node";
    this[table_node_name] = table_node;
    if (this.props.handleSetTableNode) {
      this.props.handleSetTableNode(table_node);
    }
  };

  UNSAFE_componentWillUpdate(__, ___) {
    const paginated: any = objGetField(this.props, "paginated", true);
    const { component_data } = this.props;
    if (!paginated) {
      if (this.state.sizePerPage !== component_data.length) {
        this.setState({
          sizePerPage: component_data.length,
        });
      }
    }
    const table_wrapper_node: any = document.querySelector(
      ".react-bootstrap-table"
    );
    if (table_wrapper_node) {
      const table_node: any = table_wrapper_node.querySelectorAll("table")[0];
      if (table_node.querySelector("thead")) {
        const table_element: any = $(".react-bootstrap-table table");
        table_element.stickyTableHeaders({ container: ".table-responsive" });
      }
    }
  }

  handleTableChange = (type, change_obj) => {
    const { sizePerPage } = change_obj;
    const { page } = change_obj;
    this.setState(
      {
        page,
        sizePerPage,
      },
      this.props.handleTableChange(type, change_obj)
    );
  };

  computeRunningTotal = (data, columns) => {
    const { filter_values } = this.props;
    data.forEach((el, index) => {
      columns.forEach((column) => {
        let running_total: any =
          filter_values[`initial_${column.dataField}`] || 0;
        if (index > 0) {
          running_total = data[index - 1][column.dataField];
        }
        const running_balance_add: any = el[column.running_balance_add] || 0;
        const running_balance_subtract: any =
          el[column.running_balance_subtract] || 0;
        running_total += running_balance_add;
        running_total -= running_balance_subtract;
        el[column.dataField] = running_total;
      });
    });
  };

  render() {
    const { component_data, page } = this.props;
    const paginated: any = objGetField(this.props, "paginated", true);
    let data: any = component_data.results || [];
    let data_count: any = component_data.count || 0;
    const action_buttons: any = this.props.action_buttons || <div />;
    if (!paginated) {
      data = component_data;
      data_count = component_data.length;
    }
    const create_button_label: any = this.props.create_button_label || "Add";
    const { SearchBar } = Search;
    const rangeList: any = function* range() {
      for (let i: number = 5; i <= data_count; i *= 2) {
        yield i;
      }
    };
    const page_size_list: any = [
      ...rangeList(),
      { text: label("All", this.props), value: data_count },
    ];
    let pagination_options: any = dataTablePagination(
      5,
      1,
      data,
      page_size_list,
      data_count,
      this.props
    );
    if (!paginated) {
      pagination_options = dataTablePagination(
        5,
        1,
        data,
        page_size_list,
        data_count,
        this.props
      );
    } else {
      pagination_options.totalSize = data_count;
      pagination_options.page = page;
      pagination_options.sizePerPage = this.state.sizePerPage;
    }
    const data_columns: any = dataTableNoWrap(this.props.data_columns);
    data_columns.forEach((column) => {
      column.text = label(column.text, this.props);
    });
    let NoDataIndication: any = (
      <div className="text-center">
        {label("There is no data to display", this.props)}
      </div>
    );
    if (this.props.loading) {
      NoDataIndication = <ComponentLoadingIndicator />;
    }
    if (this.props.loading && !paginated) {
      return <ComponentLoadingIndicator />;
    }
    const debouncedHandleChange: any = _.debounce(this.handleTableChange, 500);
    const ExportCSVButton: any = (props) => {
      const handleClick: any = () => {
        props.onExport();
      };
      if (objGetField(this.props, "csv_export", true)) {
        return (
          <button
            className="btn btn-outline-success"
            onClick={handleClick}
            type="button"
          >
            <BootstrapIcon icon="file-earmark-spreadsheet-fill" />{" "}
            {label("Export to csv", this.props)}
          </button>
        );
      }
      return "";
    };
    if (this.props.editable) {
      const editFieldButton: any = (__, row) => {
        const item_id: any = row.id;
        let edit_url: string = `${this.props.edit_url}?id=${item_id}`;
        if (this.props.edit_url_type) {
          edit_url = `${this.props.edit_url}${item_id}`;
        }
        return (
          <button
            className="btn btn-outline-blue"
            onClick={() =>
              pushHistory(edit_url, this.props, this.props.edit_url_type)
            }
          >
            <BootstrapIcon icon="pencil-square" />
          </button>
        );
      };

      const edit_column: any = {
        dataField: "edit",
        text: "Edit",
        isDummyField: true,
        csvExport: false,
        formatter: (cell, row) => editFieldButton(cell, row),
      };
      data_columns.forEach((column) => {
        if (column.formatter && !column.csvFormatter) {
          column.csvFormatter = column.formatter;
        }
      });
      const edit_column_present: any = data_columns.find(
        (column) => column.dataField === "edit"
      );
      const totals_row_present: any = data_columns.find(
        (column) => typeof column.footer !== "undefined"
      );
      if (totals_row_present) {
        edit_column.footer = "";
      }
      if (!edit_column_present) {
        data_columns.push(edit_column);
      }
    }
    if (this.props.viewable) {
      const viewFieldButton: any = (__, row) => {
        const item_id: any = row.id;
        let view_url: string = `${this.props.view_url}?id=${item_id}`;
        if (this.props.view_url_type) {
          view_url = `${this.props.view_url}${item_id}`;
        }
        return (
          <button
            type="button"
            className="btn btn-outline-blue"
            onClick={() =>
              pushHistory(view_url, this.props, this.props.view_url_type)
            }
          >
            <BootstrapIcon icon="eye" />
          </button>
        );
      };

      const view_column: any = {
        dataField: "view",
        text: "View",
        isDummyField: true,
        csvExport: false,
        formatter: (cell, row) => viewFieldButton(cell, row),
      };
      data_columns.forEach((column) => {
        if (column.formatter && !column.csvFormatter) {
          column.csvFormatter = column.formatter;
        }
      });
      const view_column_present: any = data_columns.find(
        (column) => column.dataField === "view"
      );
      const totals_row_present: any = data_columns.find(
        (column) => typeof column.footer !== "undefined"
      );
      if (totals_row_present) {
        view_column.footer = "";
      }
      if (!view_column_present) {
        data_columns.push(view_column);
      }
    }
    let add_item_button: string = "";
    let refresh_button: string = "";
    if (this.props.creatable) {
      add_item_button = (
        <button
          className="btn btn-outline-primary ml-2"
          onClick={() =>
            pushHistory(
              this.props.edit_url,
              this.props,
              this.props.edit_url_type
            )
          }
        >
          <BootstrapIcon icon="plus" /> {label(create_button_label, this.props)}
        </button>
      );
    }
    if (this.props.allow_refresh) {
      const handleRefresh: any = this.props.handleRefresh || function () {};
      refresh_button = (
        <button
          className="btn btn-outline-primary ml-2"
          onClick={handleRefresh}
          type="button"
        >
          <BootstrapIcon icon="arrow-clockwise" />{" "}
          {label("Refresh", this.props)}
        </button>
      );
    }

    const csv_filename_arr: any = (
      this.props.location.pathname || "spreadsheet"
    ).split("/");
    const csv_filename: string = `${
      csv_filename_arr[csv_filename_arr.length - 1]
    }.csv`;
    const running_total_columns: any = data_columns.filter(
      (column) => column.running_total === true
    );
    if (running_total_columns.length > 0) {
      this.computeRunningTotal(data, running_total_columns);
    }

    const table: any = (props) => {
      if (!paginated) {
        return (
          <BootstrapTable
            ref={this.onRefChange}
            {...props.baseProps}
            wrapperClasses="table-responsive"
            pagination={paginationFactory(pagination_options)}
            totalSize={data_count}
            selectRow={this.props.selectRow}
            noDataIndication={() => NoDataIndication}
          />
        );
      }
      if (this.props.selectRow) {
        return (
          <BootstrapTable
            ref={this.onRefChange}
            remote
            {...props.baseProps}
            wrapperClasses="table-responsive"
            pagination={paginationFactory(pagination_options)}
            onTableChange={debouncedHandleChange}
            totalSize={data_count}
            selectRow={this.props.selectRow}
            noDataIndication={() => NoDataIndication}
          />
        );
      }
      return (
        <BootstrapTable
          ref={this.onRefChange}
          remote
          {...props.baseProps}
          wrapperClasses="table-responsive"
          pagination={paginationFactory(pagination_options)}
          onTableChange={debouncedHandleChange}
          totalSize={data_count}
          noDataIndication={() => NoDataIndication}
        />
      );
    };
    return (
      <div>
        <ToolkitProvider
          keyField="id"
          bootstrap4
          data={data}
          columns={data_columns}
          search
          exportCSV={{
            fileName: csv_filename,
          }}
        >
          {(props) => (
            <div className="mb-5 mt-3">
              <div className="row mb-2">
                <div className="col-md-6">
                  <ExportCSVButton {...props.csvProps} />
                  {add_item_button}
                  {refresh_button}
                  {action_buttons}
                </div>
                <div className="col-md-6">
                  <div className="float-right">
                    <SearchBar
                      {...props.searchProps}
                      placeholder={label("Search", this.props)}
                    />
                  </div>
                </div>
              </div>
              {table(props)}
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

DataTable.defaultProps = {
  viewable: false,
  view_url_type: "soft",
  view_url: "",
  data_columns: [],
  create_button_label: "Add",
  filter_values: [],
  table_node_name: "table_node",
  selectRow: false,
  action_buttons: <div />,
};

DataTable.propTypes = {
  view_url: PropTypes.string,
  view_url_type: PropTypes.string,
  viewable: PropTypes.bool,
  data_columns: PropTypes.arrayOf(Object),
  create_button_label: PropTypes.string,
  filter_values: PropTypes.arrayOf(Object),
  table_node_name: PropTypes.string,
  selectRow: PropTypes.bool,
  action_buttons: PropTypes.node,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    dataByUrl,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(DataTable));
