import {
  LOAN_PORTFOLIO_DETAILS_PATH,
  LOAN_PORTFOLIO_HISTORY_PATH,
  LOAN_PORTFOLIO_SUMMARY_PATH,
} from "../../constants/ClientPaths";
import React, { Component } from "react";

import VIewAddPortlet from "../../components/VIewAddPortlet";

/**
 * Loan portfolio component
 * @extends Component
 */

class LoanPortfolio extends Component {
  /**
   * Render Loan portfolio component
   * @return {ReactElement} markup
   */
  render() {
    const pills: any = [
      {
        label: "Portfolio",
        path: LOAN_PORTFOLIO_DETAILS_PATH,
      },
      {
        label: "Portfolio Summary",
        path: LOAN_PORTFOLIO_SUMMARY_PATH,
      },
      {
        label: "Portfolio history",
        path: LOAN_PORTFOLIO_HISTORY_PATH,
      },
    ];
    return (
      <VIewAddPortlet header_label="Loan portfolio" pills={pills}>
        {this.props.children}
      </VIewAddPortlet>
    );
  }
}

export default LoanPortfolio;
