import React, { Component } from "react";

export default class PackageDowngrade extends Component {
  render() {
    let silver_button: any = (
      <a
        href="https://www.jisort.com/billing/cart.php?a=add&pid=24"
        className="btn btn-lg btn-block btn-primary"
        target="_blank"
        rel="noreferrer"
      >
        Upgrade to this
      </a>
    );
    let gold_button: any = (
      <a
        href="https://www.jisort.com/billing/cart.php?a=add&pid=23"
        className="btn btn-lg btn-block btn-primary"
        target="_blank"
        rel="noreferrer"
      >
        Upgrade to this
      </a>
    );
    if (this.props.current_package) {
      if (this.props.current_package === 1) {
        silver_button = (
          <a
            className="btn btn-lg btn-block btn-primary"
            href="https://www.jisort.com/billing/"
            target="_blank"
            rel="noreferrer"
          >
            Upgrade to this
          </a>
        );
      } else if (this.props.current_package === 2) {
        silver_button = (
          <button
            className="pricing-button"
            onClick={this.handleBack.bind(this)}
          >
            You are here
          </button>
        );
        gold_button = (
          <a
            className="btn btn-lg btn-block btn-primary"
            href="https://www.jisort.com/billing/"
            target="_blank"
            rel="noreferrer"
          >
            Upgrade to this
          </a>
        );
      } else if (this.props.current_package === 3) {
        gold_button = (
          <button
            className="btn btn-lg btn-block btn-primary"
            onClick={this.handleBack.bind(this)}
          >
            You are here
          </button>
        );
      }
    }
    return (
      <div className="row mb-3 text-center g-1">
        <div className="card mb-4 shadow-sm col-12">
          <div className="card-header">
            <h4 className="my-0 font-weight-normal">Silver</h4>
          </div>
          <div className="card-body">
            <h1 className="card-title pricing-card-title">
              Ksh. 2,500 ($25) <small className="text-muted">/ mo</small>
            </h1>
            <ul className="list-unstyled mt-3 mb-4">
              <li>Per user</li>
            </ul>
            {silver_button}
          </div>
        </div>
        <div className="card mb-4 shadow-sm col-12">
          <div className="card-header">
            <h4 className="my-0 font-weight-normal">Gold</h4>
          </div>
          <div className="card-body">
            <h1 className="card-title pricing-card-title">
              Ksh. 2,000 ($20) <small className="text-muted">/ mo</small>
            </h1>
            <ul className="list-unstyled mt-3 mb-4">
              <li>Per user(10+ Users)</li>
            </ul>
            {gold_button}
          </div>
        </div>
      </div>
    );
  }
}
