import {
  API_ENDPOINT,
  BANKS_ENDPOINT,
  CHART_OF_ACCOUNTS_ENDPOINT,
  FORMULAS_ENDPOINT,
  LOAN_CHARGES_ENDPOINT,
  LOAN_PRODUCTS_ENDPOINT,
  ORGANIZATION_BRANCHES_ENDPOINT,
  SAVINGS_PRODUCTS_ENDPOINT,
  USERS_ENDPOINT,
} from "../../../constants/Constants";
import {
  JSONToFormData,
  extractResponseError,
  fetchUrlData,
  formDataToJSON,
  getSelect2Options,
  getUrlData,
  handleFieldChange,
  invalidateSessionData,
} from "../../../utils/componentActions";
import React, { Component } from "react";

import $ from "jquery";
import ComponentLoadingIndicator from "../../../components/ComponentLoadingIndicator";
import ConfigureProductPills from "./ConfigureProductPills";
import ConfigureProductsButtons from "./ConfigureProductsButtons";
import FormFeedbackMessage from "../../../components/FormFeedbackMessage";
import FormFields from "../../../components/FormFields";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { postFormAPIRequest } from "../../../utils/APIRequests";
import { withRouter } from "react-router-dom";

class ConfigureLoanProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_pill: "details",
      loan: {},
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, _) {
    const previous_loan: any = this.state.loan;
    const loan: any = nextProps.loan_product_data.items;
    const { loan_products_data } = nextProps;
    const loan_products: any = loan_products_data.items;
    if (
      Object.keys(previous_loan).length === 0 &&
      Object.keys(loan).length > 0 &&
      this.props.match.params.id
    ) {
      this.setState({
        loan,
      });
    }
    if (
      loan_products.length === 0 &&
      !loan_products_data.isFetching &&
      !loan_products_data.didInvalidate
    ) {
      this.setState({
        feedback_message:
          "You need to setup a loan product to use the loan management feature",
      });
    }
  }

  componentDidMount() {
    const users_url: string = `${USERS_ENDPOINT}?fields=id,username,email,branch,phone_number,is_admin`;
    fetchUrlData("banks_url", BANKS_ENDPOINT, this.props);
    fetchUrlData("loan_charges_url", LOAN_CHARGES_ENDPOINT, this.props);
    fetchUrlData(
      "chart_of_accounts_url",
      CHART_OF_ACCOUNTS_ENDPOINT,
      this.props
    );
    fetchUrlData(
      "all_savings_products_url",
      SAVINGS_PRODUCTS_ENDPOINT,
      this.props
    );
    fetchUrlData(
      "organization_branches_url",
      ORGANIZATION_BRANCHES_ENDPOINT,
      this.props
    );
    fetchUrlData("loan_products_url", LOAN_PRODUCTS_ENDPOINT, this.props);
    fetchUrlData("users_url", users_url, this.props);
    const loan_id: any = this.props.match.params.id;
    if (loan_id) {
      const loan_product_url: string = `${LOAN_PRODUCTS_ENDPOINT}${loan_id}/`;
      fetchUrlData("loan_product_url", loan_product_url, this.props);
    }
    fetchUrlData("formulas_url", FORMULAS_ENDPOINT, this.props);
  }

  handleSubmitConfiguration = (next_pill = "accounting") => {
    const formElement: any = $("form#form-configure-loan")[0];
    let formData: any = new FormData(formElement);
    let payload: any = this.state.loan;
    payload = formDataToJSON(formData, payload, false);
    formData = JSONToFormData(formData, payload);
    const valid_form: any = formElement.reportValidity();
    if (valid_form) {
      if (this.state.active_pill !== next_pill) {
        this.setState({
          active_pill: next_pill,
          loan: payload,
        });
      } else {
        this.setState({
          activity: true,
        });
        let loan_product_url: any = API_ENDPOINT + LOAN_PRODUCTS_ENDPOINT;
        const loan_id: any = this.props.match.params.id;
        let request_method: string = "POST";
        if (loan_id) {
          loan_product_url = `${loan_product_url}${loan_id}/`;
          request_method = "PUT";
        }
        if (typeof payload.start_date === "object") {
          payload.start_date = payload.start_date.format("YYYY-MM-DD");
        }
        if (typeof payload.close_date === "object") {
          payload.close_date = payload.close_date.format("YYYY-MM-DD");
        }
        formData.set(
          "repayment_schedule_columns_to_display",
          payload.repayment_schedule_columns
        );
        formData.delete("repayment_schedule_columns");
        postFormAPIRequest(
          loan_product_url,
          () => {
            this.setState({
              activity: false,
              feedback_message: "Loan product configuration was successful",
              feedback_type: "success",
            });
            invalidateSessionData(this, LOAN_PRODUCTS_ENDPOINT);
          },
          (results) => {
            const feedback_message: any = extractResponseError(results);
            this.setState({
              activity: false,
              feedback_message,
              feedback_type: "danger",
            });
          },
          formData,
          {
            "Content-Type": "multipart/form-data; boundary=----",
            Authorization: `Bearer ${localStorage.token}`,
          },
          request_method
        );
      }
    }
  };

  handleFieldChange = (
    e,
    field_name = null,
    field_value = null,
    select_field = false
  ) => {
    let payload: any = this.state.loan;
    payload = handleFieldChange(
      e,
      field_name,
      field_value,
      select_field,
      payload
    );
    this.setState({
      loan: payload,
    });
  };

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const {
      banks_data,
      loan_charges_data,
      chart_of_accounts_data,
      savings_products_data,
      organization_branches_data,
      loan_product_data,
      loan_products_data,
      users_data,
      formulas_data,
    } = props;
    if (
      banks_data.isFetching ||
      loan_charges_data.isFetching ||
      chart_of_accounts_data.isFetching ||
      savings_products_data.isFetching ||
      organization_branches_data.isFetching ||
      loan_product_data.isFetching ||
      loan_products_data.isFetching ||
      users_data.isFetching ||
      formulas_data.isFetching
    ) {
      return <ComponentLoadingIndicator />;
    }
    const { loan } = this.state;
    if (!props.match.params.id && !loan.repayment_schedule_columns) {
      loan.repayment_schedule_columns = [
        "principal_due",
        "balance_of_loan",
        "interest",
        "fees",
        "penalties",
      ];
    }
    const banks: any = banks_data.items;
    const formulas: any = formulas_data.items;
    const loan_charges: any = loan_charges_data.items;
    const chart_of_accounts: any = chart_of_accounts_data.items;
    const savings_products: any = savings_products_data.items;
    const organization_branches: any = organization_branches_data.items;
    const users: any = users_data.items;
    const banks_options: any = banks.map((bank) => ({
      value: bank.gl_account,
      label: bank.bank_name,
    }));
    const formulas_options: any = getSelect2Options(formulas, "id", "name");
    const users_options: any = getSelect2Options(users, "id", "username");
    const repayment_schedule_columns_options = [
      { value: "principal_due", label: "Principal due" },
      { value: "balance_of_loan", label: "Balance of loan" },
      { value: "interest", label: "Interest" },
      { value: "fees", label: "Fees" },
      { value: "penalties", label: "Penalties" },
      { value: "principal_plus_fees", label: "Principal + Fees" },
    ];
    const loan_charges_options: any = loan_charges.map((charge) => ({
      value: charge.id,
      label: charge.name,
    }));
    const filter_account: any = function (type) {
      return chart_of_accounts.filter(
        (account) => account.account_type === type
      );
    };
    const asset_accounts_options: any = filter_account(1).map((account) => ({
      value: account.id,
      label: account.account_name,
    }));
    const liabilities_accounts_options: any = filter_account(2).map(
      (account) => ({ value: account.id, label: account.account_name })
    );
    const income_accounts_options: any = filter_account(4).map((account) => ({
      value: account.id,
      label: account.account_name,
    }));
    const expenses_accounts_options: any = filter_account(5).map((account) => ({
      value: account.id,
      label: account.account_name,
    }));
    const savings_products_options: any = savings_products.map((product) => ({
      value: product.id,
      label: product.product_name,
    }));
    const organization_branches_options: any = organization_branches.map(
      (branch) => ({ value: branch.id, label: branch.name })
    );
    let feedback_message: string = "";
    if (this.state.feedback_message) {
      feedback_message = (
        <FormFeedbackMessage
          feedback_message={this.state.feedback_message}
          feedback_type={this.state.feedback_type}
        />
      );
    }
    const accrual_types_fields: any = (
      <>
        <FormFields
          field_type="select"
          field_label="Accrual type"
          props={props}
          field_name="accrual_type"
          wrapper_div_classes="col-4"
          updateState={this.updateState}
          update_payload
          data_object_name="loan"
          data_object={loan}
        >
          <option value="upfront">Upfront during disbursement</option>
        </FormFields>
        <FormFields
          field_type="select2"
          field_label="Receivables interest"
          props={props}
          field_name="receivables_interest"
          wrapper_div_classes="col-4"
          updateState={this.updateState}
          select2_options={asset_accounts_options}
          select2_default_first
          update_payload
          data_object_name="loan"
          data_object={loan}
        />
        <FormFields
          field_type="select2"
          field_label="Receivables fees"
          props={props}
          field_name="receivables_fees"
          wrapper_div_classes="col-4"
          updateState={this.updateState}
          select2_options={asset_accounts_options}
          select2_default_first
          update_payload
          data_object_name="loan"
          data_object={loan}
        />
        <FormFields
          field_type="select2"
          field_label="Receivables penalties"
          props={props}
          field_name="receivables_penalties"
          wrapper_div_classes="col-4"
          updateState={this.updateState}
          select2_options={asset_accounts_options}
          select2_default_first
          update_payload
          data_object_name="loan"
          data_object={loan}
        />
      </>
    );
    let configuration_form: any = (
      <div className="row g-3">
        <FormFields
          field_type="text_input"
          field_label="Product name"
          props={props}
          field_name="product_name"
          wrapper_div_classes="col-4"
          updateState={this.updateState}
          required
          update_payload
          data_object_name="loan"
          data_object={loan}
        />
        <FormFields
          field_type="text_input"
          field_label="Short name"
          props={props}
          field_name="short_name"
          wrapper_div_classes="col-4"
          updateState={this.updateState}
          required
          update_payload
          data_object_name="loan"
          data_object={loan}
        />
        <FormFields
          field_type="textarea"
          field_label="Description"
          props={props}
          field_name="description"
          wrapper_div_classes="col-4"
          updateState={this.updateState}
          required
          update_payload
          data_object_name="loan"
          data_object={loan}
        />
        <FormFields
          field_type="select"
          field_label="Product type"
          props={props}
          field_name="product_type"
          wrapper_div_classes="col-4"
          updateState={this.updateState}
          required
          update_payload
          data_object_name="loan"
          data_object={loan}
        >
          <option value="H">Personal loans</option>
          <option value="A">Overdraft</option>
          <option value="B">Credit cards</option>
          <option value="C">Business working capital loans</option>
          <option value="D">Business expansion loans</option>
          <option value="E">Mortgage loans</option>
          <option value="F">Asset finance loan</option>
          <option value="G">Trade finance facility</option>
          <option value="N">Other</option>
        </FormFields>
        <FormFields
          field_type="date"
          field_label="Start date"
          props={props}
          field_name="start_date"
          wrapper_div_classes="col-4"
          updateState={this.updateState}
          required
          update_payload
          data_object_name="loan"
          data_object={loan}
        />
        <FormFields
          field_type="date"
          field_label="Close date"
          props={props}
          field_name="close_date"
          wrapper_div_classes="col-4"
          updateState={this.updateState}
          required
          update_payload
          data_object_name="loan"
          data_object={loan}
        />
        <FormFields
          field_type="select2"
          field_label="Loan fund"
          props={props}
          select2_options={banks_options}
          field_name="loan_fund"
          wrapper_div_classes="col-4"
          updateState={this.updateState}
          isClearable
          update_payload
          data_object_name="loan"
          data_object={loan}
        />
      </div>
    );
    if (this.state.active_pill === "currency") {
      configuration_form = (
        <div className="row g-3">
          <FormFields
            field_type="number_input"
            field_label="No of decimal places"
            props={props}
            field_name="no_of_decimal_places"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            required
            update_payload
            data_object_name="loan"
            data_object={loan}
          />
        </div>
      );
    } else if (this.state.active_pill === "terms") {
      configuration_form = (
        <div className="row g-3">
          <FormFields
            field_type="number_input"
            field_label="Minimum principal"
            props={props}
            field_name="minimum_principal"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            required
            update_payload
            data_object_name="loan"
            data_object={loan}
          />
          <FormFields
            field_type="number_input"
            field_label="Maximum principal"
            props={props}
            field_name="maximum_principal"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            required
            update_payload
            data_object_name="loan"
            data_object={loan}
          />
          <FormFields
            field_type="number_input"
            field_label="Minimum no of repayments"
            props={props}
            field_name="minimum_repayments"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            required
            update_payload
            data_object_name="loan"
            data_object={loan}
          />
          <FormFields
            field_type="number_input"
            field_label="Maximum no of repayments"
            props={props}
            field_name="maximum_repayments"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            required
            update_payload
            data_object_name="loan"
            data_object={loan}
          />
          <FormFields
            field_type="number_input"
            field_label="Minimum interest rate(%)"
            props={props}
            field_name="minimum_interest_rate"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            required
            update_payload
            step="0.0001"
            data_object_name="loan"
            data_object={loan}
          />
          <FormFields
            field_type="number_input"
            field_label="Default interest rate(%)"
            props={props}
            field_name="default_interest_rate"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            required
            update_payload
            step="0.0001"
            data_object_name="loan"
            data_object={loan}
          />
          <FormFields
            field_type="number_input"
            field_label="Maximum interest rate(%)"
            props={props}
            field_name="maximum_interest_rate"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            required
            update_payload
            step="0.0001"
            data_object_name="loan"
            data_object={loan}
          />
          <FormFields
            field_type="select"
            field_label="Interest charged per"
            props={props}
            field_name="interest_charged_per"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            required
            update_payload
            data_object_name="loan"
            data_object={loan}
          >
            <option value="month">Month</option>
            <option value="year">Year</option>
          </FormFields>
          <FormFields
            field_type="number_input"
            field_label="Repayment interval"
            props={props}
            field_name="repaid_every"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            required
            update_payload
            data_object_name="loan"
            data_object={loan}
            input_group
            input_group_append={
              <FormFields
                field_type="select"
                props={props}
                is_input_group_append
                field_name="repayment_interval"
                wrapper_div_classes="col-4"
                updateState={this.updateState}
                update_payload
                data_object_name="loan"
                data_object={loan}
              >
                <option value="days">Days</option>
                <option value="months">Months</option>
              </FormFields>
            }
          />
          <FormFields
            field_type="number_input"
            field_label="Minimum days between disbursal and first repayment date"
            props={props}
            field_name="minimum_days_disbursement_repayment"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            required
            update_payload
            data_object_name="loan"
            data_object={loan}
          />
          <FormFields
            field_type="select"
            field_label="Interest method"
            props={props}
            field_name="interest_method"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            required
            update_payload
            data_object_name="loan"
            data_object={loan}
          >
            <option value="flat_rate">Flat rate</option>
            <option value="reducing_balance">Reducing balance</option>
          </FormFields>
          <FormFields
            field_type="number_input"
            field_label="Days overdue before moving to arrears"
            props={props}
            field_name="days_overdue_before_arreas"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            required
            update_payload
            data_object_name="loan"
            data_object={loan}
          />
          <FormFields
            field_type="number_input"
            field_label="Days overdue before moving to NPA"
            props={props}
            field_name="days_overdue_before_npa"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            required
            update_payload
            data_object_name="loan"
            data_object={loan}
          />
          <FormFields
            field_type="number_input"
            field_label="Minimum guarantors"
            props={props}
            field_name="minimum_guarantors"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            required
            update_payload
            data_object_name="loan"
            data_object={loan}
          />
          <FormFields
            field_type="number_input"
            field_label="Maximum guarantors"
            props={props}
            field_name="maximum_guarantors"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            required
            update_payload
            data_object_name="loan"
            data_object={loan}
          />
        </div>
      );
    } else if (this.state.active_pill === "settings") {
      let offer_letter_field: string = "";
      if ([true, "true"].includes(loan.enable_offer_letter)) {
        offer_letter_field = (
          <FormFields
            field_type="file"
            field_label="Offer letter"
            props={props}
            field_name="offer_letter"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            required
            update_payload
            data_object_name="loan"
            data_object={loan}
          />
        );
      }
      configuration_form = (
        <div className="row g-3">
          <FormFields
            field_type="select"
            field_label="Amortization"
            props={props}
            field_name="amortization"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            update_payload
            data_object_name="loan"
            data_object={loan}
          >
            <option value="equal_installments">Equal installments</option>
            <option value="equal_principal">Equal principal payments</option>
          </FormFields>
          <FormFields
            field_type="select"
            field_label="Is a group loan"
            props={props}
            field_name="is_group_loan"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            update_payload
            data_object_name="loan"
            data_object={loan}
          >
            <option value={false}>No</option>
            <option value>Yes</option>
          </FormFields>
          <FormFields
            field_type="select"
            field_label="Interest accrual"
            props={props}
            field_name="realtime_interest"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            update_payload
            data_object_name="loan"
            data_object={loan}
          >
            <option value={false}>No</option>
            <option value>Yes</option>
          </FormFields>
          <FormFields
            field_type="select"
            field_label="Penalties accrual"
            props={props}
            field_name="realtime_penalties"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            update_payload
            data_object_name="loan"
            data_object={loan}
          >
            <option value={false}>No</option>
            <option value>Yes</option>
          </FormFields>
          <FormFields
            field_type="select"
            field_label="Publish for mobile lending"
            props={props}
            field_name="publish_to_marketplace"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            update_payload
            data_object_name="loan"
            data_object={loan}
          >
            <option value={false}>No</option>
            <option value>Yes</option>
          </FormFields>
          <FormFields
            field_type="select"
            field_label="Total interest calculation method"
            props={props}
            field_name="total_interest_calculation_method"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            update_payload
            data_object_name="loan"
            data_object={loan}
          >
            <option value="as_per_loan_type">As per loan type</option>
            <option value="annual_reducing_balance">
              Annual reducing balance
            </option>
          </FormFields>
          <FormFields
            field_type="select"
            field_label="Calculate repayment ability from salary"
            props={props}
            field_name="calculate_repayment_ability_from_salary"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            update_payload
            data_object_name="loan"
            data_object={loan}
          >
            <option value={false}>No</option>
            <option value>Yes</option>
          </FormFields>
          <FormFields
            field_type="select"
            field_label="Instant disbursement?"
            props={props}
            field_name="instant_disbursement"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            update_payload
            data_object_name="loan"
            data_object={loan}
          >
            <option value={false}>No</option>
            <option value>Yes</option>
          </FormFields>
          <FormFields
            field_type="select"
            field_label="Available to members only?"
            props={props}
            field_name="members_only"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            update_payload
            data_object_name="loan"
            data_object={loan}
          >
            <option value>Yes</option>
            <option value={false}>No</option>
          </FormFields>
          <FormFields
            field_type="number_input"
            field_label="Maximum allowed concurrent loans"
            props={props}
            field_name="maximum_number_of_concurrent_loans"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            required
            update_payload
            data_object_name="loan"
            data_object={loan}
          />
          <FormFields
            field_type="select"
            field_label="Interest posting time"
            props={props}
            field_name="interest_posting_method"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            update_payload
            data_object_name="loan"
            data_object={loan}
          >
            <option value="on_application">On application</option>
            <option value="on_repayment_date">On repayment</option>
          </FormFields>
          <FormFields
            field_type="select"
            field_label="Disbursement mode"
            props={props}
            field_name="disbursement_mode"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            update_payload
            data_object_name="loan"
            data_object={loan}
          >
            <option value="mpesa">M-Pesa</option>
            <option value="bank">Bank</option>
            <option value="cheque">Cheque</option>
            <option value="ewallet">E-wallet</option>
            <option value="cash">Cash</option>
          </FormFields>
          <FormFields
            field_type="select2"
            field_label="Standing orders product"
            props={props}
            select2_options={savings_products_options}
            field_name="default_repayment_product"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            isClearable
            update_payload
            data_object_name="loan"
            data_object={loan}
          />
          <FormFields
            field_type="select"
            field_label="Enable penalty invoicing?"
            props={props}
            field_name="enable_penalties_invoicing"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            update_payload
            data_object_name="loan"
            data_object={loan}
          >
            <option value={false}>No</option>
            <option value>Yes</option>
          </FormFields>
          <FormFields
            field_type="select"
            field_label="Enable interest invoicing?"
            props={props}
            field_name="enable_interest_invoicing"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            update_payload
            data_object_name="loan"
            data_object={loan}
          >
            <option value={false}>No</option>
            <option value>Yes</option>
          </FormFields>
          <FormFields
            field_type="select"
            field_label="Enable charge invoicing?"
            props={props}
            field_name="enable_charge_invoicing"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            update_payload
            data_object_name="loan"
            data_object={loan}
          >
            <option value={false}>No</option>
            <option value>Yes</option>
          </FormFields>
          <FormFields
            field_type="select"
            field_label="Enable demand letter?"
            props={props}
            field_name="enable_demand_letter"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            update_payload
            data_object_name="loan"
            data_object={loan}
          >
            <option value={false}>No</option>
            <option value>Yes</option>
          </FormFields>
          <FormFields
            field_type="select"
            field_label="Enable offer letter?"
            props={props}
            field_name="enable_offer_letter"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            update_payload
            data_object_name="loan"
            data_object={loan}
          >
            <option value={false}>No</option>
            <option value>Yes</option>
          </FormFields>
          {offer_letter_field}
          <FormFields
            field_type="select2"
            field_label="Branch"
            props={props}
            field_name="branch"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            select2_options={organization_branches_options}
            isClearable
            update_payload
            data_object_name="loan"
            data_object={loan}
          />
          <FormFields
            field_type="select"
            field_label="Place guarantors funds on hold?"
            props={props}
            field_name="place_guarantor_funds_hold"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            update_payload
            data_object_name="loan"
            data_object={loan}
          >
            <option value={false}>No</option>
            <option value>Yes</option>
          </FormFields>
          <FormFields
            field_type="select"
            field_label="Allow multiple disbursement cheques/payments"
            props={props}
            field_name="enable_multiple_disbursals"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            update_payload
            data_object_name="loan"
            data_object={loan}
          >
            <option value={false}>No</option>
            <option value>Yes</option>
          </FormFields>
          <FormFields
            field_type="select2_multi"
            field_label="Users allowed to approve"
            props={props}
            field_name="allowed_loan_approvals"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            select2_options={users_options}
            required
            update_payload
            data_object_name="loan"
            data_object={loan}
          />
          <FormFields
            field_type="select2_multi"
            field_label="Approval level users"
            props={props}
            field_name="loan_approvals"
            wrapper_div_classes="col-4"
            data_object={loan}
            update_payload
            updateState={this.updateState}
            data_object_name="loan"
            select2_options={users_options}
          />
          <FormFields
            field_type="select2"
            field_label="Credit score formula"
            props={props}
            select2_options={formulas_options}
            field_name="credit_limit_formula"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            isClearable
            update_payload
            data_object_name="loan"
            data_object={loan}
          />
          <FormFields
            field_type="select2_multi"
            field_label="Repayment schedule columns"
            props={props}
            field_name="repayment_schedule_columns"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            select2_options={repayment_schedule_columns_options}
            required
            update_payload
            data_object_name="loan"
            data_object={loan}
          />
        </div>
      );
    } else if (this.state.active_pill === "charges") {
      configuration_form = (
        <div className="row g-3">
          <FormFields
            field_type="select2_multi"
            field_label="Charges"
            props={props}
            select2_options={loan_charges_options}
            field_name="charges"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            update_payload
            data_object_name="loan"
            data_object={loan}
          />
        </div>
      );
    } else if (this.state.active_pill === "accounting") {
      configuration_form = (
        <div className="row g-3">
          <FormFields
            field_type="select"
            field_label="Accounting method"
            props={props}
            field_name="accounting_method"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            update_payload
            data_object_name="loan"
            data_object={loan}
          >
            <option value="cash">Cash basis</option>
            <option value="accrual">Accrual basis</option>
          </FormFields>
          {loan.accounting_method === "accrual" && accrual_types_fields}
          <FormFields
            field_type="select2"
            field_label="Loan portfolio"
            props={props}
            field_name="gl_account"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            select2_options={asset_accounts_options}
            select2_default_first
            update_payload
            data_object_name="loan"
            data_object={loan}
          />
          <FormFields
            field_type="select2"
            field_label="Income from interest"
            props={props}
            field_name="interest_account"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            select2_options={income_accounts_options}
            select2_default_first
            update_payload
            data_object_name="loan"
            data_object={loan}
          />
          <FormFields
            field_type="select2"
            field_label="Overpayment liability"
            props={props}
            field_name="overpayment_account"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            select2_options={liabilities_accounts_options}
            select2_default_first
            update_payload
            data_object_name="loan"
            data_object={loan}
          />
          <FormFields
            field_type="select2"
            field_label="Losses from write off"
            props={props}
            field_name="losses_from_write_off"
            wrapper_div_classes="col-4"
            updateState={this.updateState}
            select2_options={expenses_accounts_options}
            select2_default_first
            update_payload
            data_object_name="loan"
            data_object={loan}
          />
        </div>
      );
    }
    return (
      <div>
        <ConfigureProductPills
          handleSubmitConfiguration={this.handleSubmitConfiguration}
          state={this.state}
        />
        <div className="pt-4">
          {feedback_message}
          <form id="form-configure-loan">
            {configuration_form}
            <div className="row mt-3">
              <div className="col">
                <ConfigureProductsButtons
                  state={this.state}
                  handleSubmitConfiguration={this.handleSubmitConfiguration}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

ConfigureLoanProduct.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  banks_data: PropTypes.instanceOf(Object).isRequired,
  loan_charges_data: PropTypes.instanceOf(Object).isRequired,
  chart_of_accounts_data: PropTypes.instanceOf(Object).isRequired,
  savings_products_data: PropTypes.instanceOf(Object).isRequired,
  organization_branches_data: PropTypes.instanceOf(Object).isRequired,
  loan_product_data: PropTypes.instanceOf(Object).isRequired,
  dataByUrl: PropTypes.instanceOf(Object).isRequired,
  loan_products_data: PropTypes.instanceOf(Object).isRequired,
  users_data: PropTypes.instanceOf(Object).isRequired,
  formulas_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const banks_data: any = getUrlData(dataByUrl, sessionVariables, "banks_url");
  const loan_charges_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "loan_charges_url"
  );
  const chart_of_accounts_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "chart_of_accounts_url"
  );
  const savings_products_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "all_savings_products_url"
  );
  const organization_branches_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "organization_branches_url"
  );
  const loan_product_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "loan_product_url"
  );
  const loan_products_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "loan_products_url"
  );
  const users_data: any = getUrlData(dataByUrl, sessionVariables, "users_url");
  const formulas_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "formulas_url"
  );

  return {
    sessionVariables,
    dataByUrl,
    banks_data,
    loan_charges_data,
    chart_of_accounts_data,
    savings_products_data,
    organization_branches_data,
    loan_product_data,
    loan_products_data,
    users_data,
    formulas_data,
  };
}

export default connect(mapStateToProps)(withRouter(ConfigureLoanProduct));
