import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DataTableActionForm from "../../components/DataTableActionForm";
import { ACTIVE_LOANS_COLUMNS } from "../../components/DataTableColumns";
import { APPLIED_LOANS_ENDPOINT } from "../../constants/Constants";
import AddChargeFields from "../../reports/loanaccount/actions/AddChargeFields";
import ViewAppliedLoans from "./ViewAppliedLoans";

class ViewActiveLoans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 5,
      add_charge: {},
    };
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  render() {
    const applied_loans_url: string = `${APPLIED_LOANS_ENDPOINT}?status__in=1&limit=5&offset=0`;
    const { add_charge } = this.state;
    const add_charge_fields: any = (
      <AddChargeFields
        add_charge={add_charge}
        loan_repayment_schedule_options={[{ value: "all", label: "All" }]}
        div_cols="col-4"
      />
    );
    const actions: any = [
      {
        name: "add charge",
        btn_classes: "btn btn-green text-white",
        noun: "charge addition",
        icon: "plus",
        target: "loan",
        plural: "loans",
        form_items: add_charge_fields,
        multiple_form_items: add_charge_fields,
        extra_form_data: [{ name: "bulk_charges", value: true }],
      },
      {
        name: "reconstruct",
        btn_classes: "btn btn-info ml-2",
        noun: "reconstruction",
        icon: "cone-striped",
        target: "loan",
        plural: "loans",
        form_items: [],
        multiple_form_items: [],
        extra_form_data: [{ name: "reconstruct_statement", value: true }],
      },
      {
        name: "fix",
        btn_classes: "btn btn-info ml-2",
        noun: "fixing",
        icon: "hammer",
        target: "loan",
        plural: "loans",
        form_items: [],
        multiple_form_items: [],
        extra_form_data: [{ name: "fix_loan_issues", value: true }],
      },
    ];
    return (
      <DataTableActionForm
        actions={actions}
        table_node={this.state.table_node}
        ids_post_field="loans"
        datatable_action_endpoint={APPLIED_LOANS_ENDPOINT}
        request_method="POST"
        datatable_source_url={applied_loans_url}
        datatable_source_url_name="applied_loans_url"
      >
        <ViewAppliedLoans
          status={1}
          select_mode="checkbox"
          handleSetTableNode={this.handleSetTableNode}
          columns={ACTIVE_LOANS_COLUMNS}
        />
      </DataTableActionForm>
    );
  }
}

ViewActiveLoans.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ViewActiveLoans));
