import {
  ACTIVITY_LOG_PATH,
  ADD_CLIENT_PAYMENT_PATH,
  ADD_LOAN_GUARANTOR_PATH,
  AGENTS_PATH,
  APPROVE_CLIENT_PAYMENTS_PATH,
  APPROVE_EXPENSES_PATH,
  APPROVE_LOAN_PATH,
  APPROVE_LOAN_PAYMENTS_PATH,
  APPROVE_MEMBERS_PATH,
  APPROVE_RECEIVED_PAYMENTS_PATH,
  AUDIT_PATH,
  BANKING_RECONCILIATION_PATH,
  BANKS_PATH,
  BILLING_PATH,
  BILLING_ADDONS_PATH,
  BILLING_INVOICES_PATH,
  BULK_CLIENT_PAYMENTS_PATH,
  BULK_DISBURSE_LOAN_PATH,
  BULK_LOAN_APPLICATION_PATH,
  BULK_LOAN_PAYMENTS_PATH,
  CHART_OF_ACCOUNTS_PATH,
  CLIENT_PAYMENTS_PATH,
  CLOSE_FINANCIAL_YEAR_PATH,
  COLLATERALS_PATH,
  CONFIGURE_LOAN_CHARGES_PATH,
  CONFIGURE_LOAN_PRODUCT_PATH,
  CONFIGURE_SAVINGS_PRODUCT_PATH,
  CONFIGURE_SHARES_PRODUCT_PATH,
  CURRENCIES_PATH,
  DETAILED_LOAN_BALANCES_PATH,
  DISBURSE_LOAN_PATH,
  EXPENSES_PATH,
  FINANCIAL_YEAR_CLOSING_PATH,
  GROUPS_PATH,
  GROUP_DETAILS_PATH,
  INCOME_PATH,
  INDEX_PATH,
  ISSUE_LOAN_PATH,
  LOAN_APPLICATION_PATH,
  LOAN_CHARGES_PATH,
  LOAN_DISBURSEMENT_REPORT_PATH,
  LOAN_PORTFOLIO_PATH,
  LOAN_PORTFOLIO_DETAILS_PATH,
  LOAN_PORTFOLIO_HISTORY_PATH,
  LOAN_PORTFOLIO_SUMMARY_PATH,
  LOGIN_PATH,
  MAKE_PAYMENT_PATH,
  MEMBER_APPLY_LOAN,
  MEMBER_PROFILE_PATH,
  MEMBER_VIEW_ACCOUNTS_PATH,
  MEMBER_VIEW_ACCOUNT_DEBIT_CREDIT_STATEMENT_PATH,
  MEMBER_VIEW_ACCOUNT_DETAILS_PATH,
  MEMBER_VIEW_ACCOUNT_PATH,
  MEMBER_VIEW_ACCOUNT_STATEMENT_PATH,
  MEMBER_VIEW_ACCOUNT_TRANSACTIONS_PATH,
  MEMBER_VIEW_ACTIVE_LOANS_PATH,
  MEMBER_VIEW_COMPLETED_LOANS_PATH,
  MEMBER_VIEW_LOANS_PATH,
  MEMBER_VIEW_LOAN_ACCOUNT_DETAILS_PATH,
  MEMBER_VIEW_LOAN_ACCOUNT_STATEMENT_PATH,
  MEMBER_VIEW_LOAN_CHARGES_PATH,
  MEMBER_VIEW_LOAN_CLIENT_STATEMENT_PATH,
  MEMBER_VIEW_LOAN_GUARANTORS_PATH,
  MEMBER_VIEW_LOAN_MINI_STATEMENT_PATH,
  MEMBER_VIEW_LOAN_PATH,
  MEMBER_VIEW_LOAN_REPAYMENT_SCHEDULE_PATH,
  MEMBER_VIEW_LOAN_TRANSACTIONS_PATH,
  MEMBER_VIEW_PENDING_LOANS_PATH,
  MEMBER_VIEW_PROCESSED_LOANS_PATH,
  MEMBER_VIEW_REJECTED_LOANS_PATH,
  MEMBER_VIEW_REVERSED_LOANS_PATH,
  MEMBER_VIEW_WRITTEN_OFF_LOANS_PATH,
  MPAY_ADD_PAYBILL_PATH,
  MPAY_PATH,
  MPAY_VIEW_PAYBILLS_PATH,
  MPAY_VIEW_TRANSACTIONS_PATH,
  MPAY_SAGE_INTEGRATIONS_PATH,
  NON_MEMBERS_PATH,
  PAYMENT_MODES_PATH,
  PERMISSIONS_MANAGEMENT_PATH,
  PROCESS_LOAN_PATH,
  RECEIPT_TEMPLATES_PATH,
  RECEIVE_PAYMENTS_PATH,
  RECONCILE_BANKING_TRANSACTIONS_PATH,
  REGISTER_AGENT_PATH,
  REGISTER_BANK_PATH,
  REGISTER_CHART_OF_ACCOUNTS_PATH,
  REGISTER_COLLATERALS_PATH,
  REGISTER_CURRENCY_PATH,
  REGISTER_GROUP_PATH,
  REGISTER_MEMBER_ACCOUNT_DETAILS_PATH,
  REGISTER_MEMBER_BANK_DETAILS_PATH,
  REGISTER_MEMBER_DETAILS_PATH,
  REGISTER_MEMBER_GROUP_DETAILS_PATH,
  REGISTER_MEMBER_MEMBER_UPLOADS_PATH,
  REGISTER_MEMBER_PATH,
  REGISTER_MEMBER_WORK_DETAILS_PATH,
  REGISTER_NEXT_OF_KIN_DETAILS,
  REGISTER_NON_MEMBERS_PATH,
  REGISTER_PAYMENT_MODES_PATH,
  REVERSE_LOAN_PATH,
  SETTINGS_PATH,
  SIGNUP_PATH,
  SMS_NOTIFICATION_SETTINGS_PATH,
  SMS_NOTIFICATION_TEMPLATES_PATH,
  SYSTEM_SETUP_PATH,
  TRANSACTIONAL_SETTINGS_PATH,
  USER_DETAILS_PATH,
  USER_MANAGEMENT_PATH,
  VIEW_ACTIVE_LOANS_PATH,
  VIEW_AGENTS_PATH,
  VIEW_BANKS_PATH,
  VIEW_CHART_OF_ACCOUNTS_PATH,
  VIEW_CLIENT_PAYMENTS_PATH,
  VIEW_COLLATERALS_PATH,
  VIEW_COMPLETED_LOANS_PATH,
  VIEW_CURRENCIES_PATH,
  VIEW_EXPENSES_PATH,
  VIEW_FINANCIAL_YEAR_CLOSING_PATH,
  VIEW_GROUPS_PATH,
  VIEW_LOANS_PATH,
  VIEW_LOAN_ACCOUNT_CHARGES_PATH,
  VIEW_LOAN_ACCOUNT_CLIENT_STATEMENT_PATH,
  VIEW_LOAN_ACCOUNT_DETAILS,
  VIEW_LOAN_ACCOUNT_GUARANTORS_PATH,
  VIEW_LOAN_ACCOUNT_MINI_STATEMENT_PATH,
  VIEW_LOAN_ACCOUNT_PATH,
  VIEW_LOAN_ACCOUNT_PENDING_ACTIONS,
  VIEW_LOAN_ACCOUNT_STATEMENT_PATH,
  VIEW_LOAN_ACCOUNT_TRANSACTIONS_PATH,
  VIEW_LOAN_CHARGES_PATH,
  VIEW_LOAN_PRODUCTS_PATH,
  VIEW_LOAN_REPAYMENT_SCHEDULE_PATH,
  VIEW_MEMBERS_PATH,
  VIEW_MEMBER_ACCOUNTS_PATH,
  VIEW_MEMBER_ACCOUNT_DEBIT_CREDIT_STATEMENT_PATH,
  VIEW_MEMBER_ACCOUNT_DETAILS_PATH,
  VIEW_MEMBER_ACCOUNT_PATH,
  VIEW_MEMBER_ACCOUNT_STATEMENT_PATH,
  VIEW_MEMBER_ACCOUNT_TRANSACTIONS_PATH,
  VIEW_MEMBER_DETAILS_PATH,
  VIEW_MEMBER_GROUPS_PATH,
  VIEW_MEMBER_UPLOADS_PATH,
  VIEW_NON_MEMBERS_PATH,
  VIEW_PAYMENT_MODES_PATH,
  VIEW_PENDING_LOANS_PATH,
  VIEW_PROCESSED_LOANS_PATH,
  VIEW_PRODUCTS_PATH,
  VIEW_RECEIVED_PAYMENTS_PATH,
  VIEW_RECONCILED_TRANSACTIONS_PATH,
  VIEW_REJECTED_LOANS_PATH,
  VIEW_REVERSED_LOANS_PATH,
  VIEW_SAVINGS_PRODUCTS_PATH,
  VIEW_SHARES_PRODUCTS_PATH,
  VIEW_SIGNATORIES_PATH,
  VIEW_USERS_PATH,
  VIEW_USER_GROUPS_PATH,
  VIEW_WRITTEN_OFF_LOANS_PATH,
} from "./constants/ClientPaths";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import ActivityLog from "./admin/audit/ActivityLog";
import AddClientPayment from "./clientpayments/AddClientPayment";
import AddLoanGuarantor from "./loans/issue/AddLoanGuarantor";
import Agents from "./agents/Agents";
import ApproveClientPayments from "./clientpayments/ApproveClientPayments";
import ApproveExpenses from "./payments/expenses/ApproveExpenses";
import ApproveLoan from "./loans/issue/ApproveLoan";
import ApproveLoanPayments from "./clientpayments/ApproveLoanPayments";
import ApproveMembers from "./members/register/ApproveMembers";
import ApproveReceivedPayments from "./payments/income/ApproveReceivedPayments";
import Audit from "./admin/audit/Audit";
import BankingReconciliation from "./finance/bankingreconciliation/BankingReconcilliation";
import Banks from "./admin/banks/Banks";
import Billing from "./admin/billing/Billing";
import BillingAddons from "./admin/billing/BillingAddons";
import BillingInvoices from "./admin/billing/BillingInvoices";
import BulkClientPayments from "./clientpayments/BulkClientPayments";
import BulkDisburseLoan from "./loans/issue/BulkDisburseLoan";
import BulkLoanApplication from "./loans/issue/BulkLoanApplication";
import BulkLoanPayments from "./clientpayments/BulkLoanPayments";
import { CLIENT_BASE_URL } from "./constants/Constants";
import ChartOfAccounts from "./admin/chartofaccounts/ChartOfAccounts";
import ClientPayments from "./clientpayments/ClientPayments";
import CloseFinancialYear from "./finance/financialyearclosing/CloseFinancialYear";
import Collaterals from "./loans/collaterals/Collaterals";
import ConfigureLoanCharge from "./admin/products/configure/ConfigureLoanCharge";
import ConfigureLoanProduct from "./admin/products/configure/ConfigureLoanProduct";
import ConfigureSavingsProduct from "./admin/products/configure/ConfigureSavingsProduct";
import ConfigureSharesProduct from "./admin/products/configure/ConfigureSharesProduct";
import Currencies from "./admin/currencies/Currencies";
import DetailedLoanBalances from "./reports/loans/DetailedLoanBalances";
import DisburseLoan from "./loans/issue/DisburseLoan";
import Expenses from "./payments/expenses/Expenses";
import FinancialYearClosing from "./finance/financialyearclosing/FinancialYearClosing";
import FormSignUp from "./account/FormSignUp";
import GroupDetails from "./admin/users/GroupDetails";
import Groups from "./groups/Groups";
import Income from "./payments/income/Income";
import IssueLoan from "./loans/issue/IssueLoan";
import LendingDashboard from "./dashboard/LendingDashboard";
import LoanApplication from "./loans/issue/LoanApplication";
import LoanCharges from "./admin/products/view/LoanCharges";
import LoanDisbursementReport from "./reports/loans/LoanDisbursementReport";
import LoanPortfolio from "./reports/loans/LoanPortfolio";
import LoanPortfolioDetails from "./reports/loans/LoanPortfolioDetails";
import LoanPortfolioHistory from "./reports/loans/LoanPortfolioHistory";
import LoanPortfolioSummary from "./reports/loans/LoanPortfolioSummary";
import Login from "./account/Login";
import MakeExpense from "./payments/expenses/MakeExpense";
import ManagePermissions from "./admin/users/ManagePermissions";
import MemberApplyLoan from "./memberportal/ApplyLoan";
import MemberProfile from "./memberportal/Profile";
import MemberViewAccount from "./memberportal/ViewAccount";
import MemberViewAccounts from "./memberportal/ViewAccounts";
import MemberViewActiveLoans from "./memberportal/ViewActiveLoans";
import MemberViewCompletedLoans from "./memberportal/ViewCompletedLoans";
import MemberViewLoan from "./memberportal/ViewLoan";
import MemberViewLoans from "./memberportal/ViewLoans";
import MemberViewPendingLoans from "./memberportal/ViewPendingLoans";
import MemberViewProcessedLoans from "./memberportal/ViewProcessedLoans";
import MemberViewRejectedLoans from "./memberportal/ViewRejectedLoans";
import MemberViewReversedLoans from "./memberportal/ViewReversedLoans";
import MemberViewWrittenOffLoans from "./memberportal/ViewWrittenOffLoans";
import Menu from "./components/Menu";
import Mpay from "./mpay/Mpay";
import NonMembers from "./nonmembers/NonMembers";
import PaymentModes from "./admin/paymentmodes/PaymentModes";
import ProcessLoan from "./loans/issue/ProcessLoan";
import React from "react";
import ReceiptTemplates from "./admin/settings/ReceiptTemplates";
import ReceivePayment from "./payments/income/ReceivePayment";
import ReconcileBankingTransactions from "./finance/bankingreconciliation/ReconcileBankingTransactions";
import RegisterAccountDetails from "./members/register/RegisterAccountDetails";
import RegisterAgent from "./agents/RegisterAgent";
import RegisterBank from "./admin/banks/RegisterBank";
import RegisterBankDetails from "./members/register/RegisterBankDetails";
import RegisterBusinessNumber from "./mpay/RegisterBusinessNumber";
import RegisterChartOfAccount from "./admin/chartofaccounts/RegisterChartOfAccount";
import RegisterCollaterals from "./loans/collaterals/RegisterCollaterals";
import RegisterCurrency from "./admin/currencies/RegisterCurrency";
import RegisterGroup from "./groups/RegisterGroup";
import RegisterGroupDetails from "./members/register/RegisterGroupDetails";
import RegisterMember from "./members/register/RegisterMember";
import RegisterMemberDetails from "./members/register/RegisterMemberDetails";
import RegisterMemberUploads from "./members/register/RegisterMemberUploads";
import RegisterNextOfKin from "./members/register/RegisterNextOfKin";
import RegisterNonMember from "./nonmembers/RegisterNonMember";
import RegisterPaymentMode from "./admin/paymentmodes/RegisterPaymentMode";
import RegisterWorkDetails from "./members/register/RegisterWorkDetails";
import ReverseLoan from "./loans/issue/ReverseLoan";
/* PLOP_INJECT_IMPORT */
import { ApmRoute as Route } from "@elastic/apm-rum-react";
import SageIntegrations from "./mpay/SageIntegrations";
import SMSNotification from "./admin/settings/SMSNotification";
import SMSNotificationTemplates from "./admin/settings/SMSNotificationTemplates";
import Settings from "./admin/settings/Settings";
import SystemSetup from "./admin/settings/SystemSetup";
import TransactionalSettings from "./admin/settings/TransactionalSettings";
import UserDetails from "./admin/users/UserDetails";
import UserManagement from "./admin/users/UserManagement";
import ViewActiveLoans from "./loans/view/ViewActiveLoans";
import ViewAgents from "./agents/ViewAgents";
import ViewBanks from "./admin/banks/ViewBanks";
import ViewBusinessNumbers from "./mpay/ViewBusinessNumbers";
import ViewChartOfAccounts from "./admin/chartofaccounts/ViewChartOfAccounts";
import ViewCollaterals from "./loans/collaterals/ViewCollaterals";
import ViewClientPayments from "./clientpayments/ViewClientPayments";
import ViewCompletedLoans from "./loans/view/ViewCompletedLoans";
import ViewCurrencies from "./admin/currencies/ViewCurrencies";
import ViewExpenses from "./payments/expenses/ViewExpenses";
import ViewFinancialYearClosing from "./finance/financialyearclosing/ViewFinancialYearClosing";
import ViewGroups from "./groups/ViewGroups";
import ViewLoanAccount from "./reports/loanaccount/ViewLoanAccount";
import ViewLoanAccountCharges from "./reports/loanaccount/ViewLoanAccountCharges";
import ViewLoanAccountDetails from "./reports/loanaccount/ViewLoanAccountDetails";
import ViewLoanAccountGuarantors from "./reports/loanaccount/ViewLoanAccountGuarantors";
import ViewLoanAccountMiniStatement from "./reports/loanaccount/ViewLoanAccountMiniStatement";
import ViewLoanAccountPendingActions from "./reports/loanaccount/ViewLoanAccountPendingActions";
import ViewLoanAccountStatement from "./reports/loanaccount/ViewLoanAccountStatement";
import ViewLoanAccountTransactions from "./reports/loanaccount/ViewLoanAccountTransactions";
import ViewLoanCharges from "./admin/products/view/ViewLoanCharges";
import ViewLoanClientStatement from "./reports/loanaccount/ViewLoanClientStatement";
import ViewLoanProducts from "./admin/products/view/ViewLoanProducts";
import ViewLoanRepaymentSchedule from "./reports/loanaccount/ViewLoanRepaymentSchedule";
import ViewLoans from "./loans/view/ViewLoans";
import ViewMemberAccount from "./reports/memberaccount/ViewMemberAccount";
import ViewMemberAccountDebitCreditStatement from "./reports/memberaccount/ViewMemberAccountDebitCreditStatement";
import ViewMemberAccountDetails from "./reports/memberaccount/ViewMemberAccountDetails";
import ViewMemberAccountStatement from "./reports/memberaccount/ViewMemberAccountStatement";
import ViewMemberAccountTransactions from "./reports/memberaccount/ViewMemberAccountTransactions";
import ViewMemberAccounts from "./members/view/VIewMemberAccounts";
import ViewMemberDetails from "./members/view/ViewMemberDetails";
import ViewMemberGroups from "./members/view/ViewMemberGroups";
import ViewMemberUploads from "./members/view/ViewMemberUploads";
import ViewMembers from "./members/view/ViewMembers";
import ViewMpayTransactions from "./mpay/ViewMpayTransactions";
import ViewNonMembers from "./nonmembers/ViewNonMembers";
import ViewPaymentModes from "./admin/paymentmodes/ViewPaymentModes";
import ViewPendingLoans from "./loans/view/ViewPendingLoans";
import ViewProcessedLoans from "./loans/view/ViewProcessedLoans";
import ViewProducts from "./admin/products/view/ViewProducts";
import ViewReceivedPayments from "./payments/income/ViewReceivedPayments";
import ViewReconciledTransactions from "./finance/bankingreconciliation/ViewReconciledTransactions";
import ViewRejectedLoans from "./loans/view/ViewRejectedLoans";
import ViewReversedLoans from "./loans/view/ViewReversedLoans";
import ViewSavingsProducts from "./admin/products/view/ViewSavingsProducts";
import ViewSharesProducts from "./admin/products/view/ViewSharesProducts";
import ViewSignatories from "./members/view/ViewSignatories";
import ViewUserGroups from "./admin/users/ViewUserGroups";
import ViewUsers from "./admin/users/ViewUsers";
import ViewWrittenOffLoans from "./loans/view/ViewWrittenOffLoans";

/**
 * This is the application entry point. It is responsible for rendering the
 * application and handling the routing.
 */
function App() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path={`${CLIENT_BASE_URL}${LOGIN_PATH}`}
          component={Login}
          key={1}
        />
        <Route
          exact
          path={`${CLIENT_BASE_URL}${SIGNUP_PATH}`}
          component={FormSignUp}
          key={3}
        />
        <Route
          exact
          path={`${CLIENT_BASE_URL}${SYSTEM_SETUP_PATH}`}
          component={SystemSetup}
          key={4}
        />
        <Menu key={2}>
          <Switch>
            <Route
              exact
              path={`${CLIENT_BASE_URL}${INDEX_PATH}`}
              component={LendingDashboard}
              key={2.1}
            />
            <ViewLoans key={2.2} path={`${CLIENT_BASE_URL}${VIEW_LOANS_PATH}`}>
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_ACTIVE_LOANS_PATH}`}
                component={ViewActiveLoans}
                key={2.21}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_COMPLETED_LOANS_PATH}`}
                component={ViewCompletedLoans}
                key={2.22}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_REJECTED_LOANS_PATH}`}
                component={ViewRejectedLoans}
                key={2.23}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_REVERSED_LOANS_PATH}`}
                component={ViewReversedLoans}
                key={2.24}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_PENDING_LOANS_PATH}`}
                component={ViewPendingLoans}
                key={2.25}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_WRITTEN_OFF_LOANS_PATH}`}
                component={ViewWrittenOffLoans}
                key={2.26}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_PROCESSED_LOANS_PATH}`}
                component={ViewProcessedLoans}
                key={2.27}
              />
            </ViewLoans>
            <ViewMemberDetails
              path={`${CLIENT_BASE_URL}${VIEW_MEMBER_DETAILS_PATH}`}
              key={2.3}
            >
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_MEMBERS_PATH}`}
                component={ViewMembers}
                key={2.31}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${APPROVE_MEMBERS_PATH}`}
                component={ApproveMembers}
                key={2.32}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_MEMBER_ACCOUNTS_PATH}`}
                component={ViewMemberAccounts}
                key={2.33}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_SIGNATORIES_PATH}`}
                component={ViewSignatories}
                key={2.34}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_MEMBER_GROUPS_PATH}`}
                component={ViewMemberGroups}
                key={2.35}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_MEMBER_UPLOADS_PATH}`}
                component={ViewMemberUploads}
                key={2.36}
              />
            </ViewMemberDetails>
            <ViewProducts
              path={`${CLIENT_BASE_URL}${VIEW_PRODUCTS_PATH}`}
              key={2.4}
            >
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_LOAN_PRODUCTS_PATH}`}
                component={ViewLoanProducts}
                key={2.41}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_SAVINGS_PRODUCTS_PATH}`}
                component={ViewSavingsProducts}
                key={2.42}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_SHARES_PRODUCTS_PATH}`}
                component={ViewSharesProducts}
                key={2.43}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${CONFIGURE_LOAN_PRODUCT_PATH}:id?`}
                component={ConfigureLoanProduct}
                key={2.44}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${CONFIGURE_SAVINGS_PRODUCT_PATH}:id?`}
                component={ConfigureSavingsProduct}
                key={2.45}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${CONFIGURE_SHARES_PRODUCT_PATH}:id?`}
                component={ConfigureSharesProduct}
                key={2.46}
              />
            </ViewProducts>
            <RegisterMember
              path={`${CLIENT_BASE_URL}${REGISTER_MEMBER_PATH}`}
              key={2.5}
            >
              <Route
                exact
                path={`${CLIENT_BASE_URL}${REGISTER_MEMBER_DETAILS_PATH}:id?`}
                component={RegisterMemberDetails}
                key={2.51}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${REGISTER_MEMBER_BANK_DETAILS_PATH}:id?`}
                component={RegisterBankDetails}
                key={2.52}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${REGISTER_NEXT_OF_KIN_DETAILS}:id?`}
                component={RegisterNextOfKin}
                key={2.53}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${REGISTER_MEMBER_GROUP_DETAILS_PATH}:id?`}
                component={RegisterGroupDetails}
                key={2.54}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${REGISTER_MEMBER_ACCOUNT_DETAILS_PATH}:id?`}
                component={RegisterAccountDetails}
                key={2.55}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${REGISTER_MEMBER_WORK_DETAILS_PATH}:id?`}
                component={RegisterWorkDetails}
                key={2.56}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${REGISTER_MEMBER_MEMBER_UPLOADS_PATH}:id?`}
                component={RegisterMemberUploads}
                key={2.57}
              />
            </RegisterMember>
            <NonMembers
              path={`${CLIENT_BASE_URL}${NON_MEMBERS_PATH}`}
              key={2.6}
            >
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_NON_MEMBERS_PATH}`}
                component={ViewNonMembers}
                key={2.61}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${REGISTER_NON_MEMBERS_PATH}:id?`}
                component={RegisterNonMember}
                key={2.62}
              />
            </NonMembers>
            <IssueLoan path={`${CLIENT_BASE_URL}${ISSUE_LOAN_PATH}`} key={2.7}>
              <Route
                exact
                path={`${CLIENT_BASE_URL}${LOAN_APPLICATION_PATH}`}
                component={LoanApplication}
                key={2.71}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${BULK_LOAN_APPLICATION_PATH}`}
                component={BulkLoanApplication}
                key={2.72}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${ADD_LOAN_GUARANTOR_PATH}:id?`}
                component={AddLoanGuarantor}
                key={2.73}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${APPROVE_LOAN_PATH}`}
                component={ApproveLoan}
                key={2.74}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${PROCESS_LOAN_PATH}`}
                component={ProcessLoan}
                key={2.75}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${REVERSE_LOAN_PATH}`}
                component={ReverseLoan}
                key={2.76}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${DISBURSE_LOAN_PATH}`}
                component={DisburseLoan}
                key={2.77}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${BULK_DISBURSE_LOAN_PATH}`}
                component={BulkDisburseLoan}
                key={2.78}
              />
            </IssueLoan>
            <FinancialYearClosing
              path={`${CLIENT_BASE_URL}${FINANCIAL_YEAR_CLOSING_PATH}`}
              key={2.8}
            >
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_FINANCIAL_YEAR_CLOSING_PATH}`}
                component={ViewFinancialYearClosing}
                key={2.81}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${CLOSE_FINANCIAL_YEAR_PATH}`}
                component={CloseFinancialYear}
                key={2.82}
              />
            </FinancialYearClosing>
            <UserManagement
              path={`${CLIENT_BASE_URL}${USER_MANAGEMENT_PATH}`}
              key={2.9}
            >
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_USERS_PATH}`}
                component={ViewUsers}
                key={2.91}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${USER_DETAILS_PATH}:id?`}
                component={UserDetails}
                key={2.92}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${PERMISSIONS_MANAGEMENT_PATH}`}
                component={ManagePermissions}
                key={2.93}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_USER_GROUPS_PATH}`}
                component={ViewUserGroups}
                key={2.94}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${GROUP_DETAILS_PATH}:id?`}
                component={GroupDetails}
                key={2.95}
              />
            </UserManagement>
            <Settings path={`${CLIENT_BASE_URL}${SETTINGS_PATH}`} key={2.1}>
              <Route
                exact
                path={`${CLIENT_BASE_URL}${SMS_NOTIFICATION_SETTINGS_PATH}:id?`}
                component={SMSNotification}
                key={2.101}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${SMS_NOTIFICATION_TEMPLATES_PATH}:id?`}
                component={SMSNotificationTemplates}
                key={2.102}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${TRANSACTIONAL_SETTINGS_PATH}:id?`}
                component={TransactionalSettings}
                key={2.103}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${RECEIPT_TEMPLATES_PATH}:id?`}
                component={ReceiptTemplates}
                key={2.104}
              />
            </Settings>
            <ViewLoanAccount
              path={`${CLIENT_BASE_URL}${VIEW_LOAN_ACCOUNT_PATH}:subPath?/:id?`}
              key={2.11}
            >
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_LOAN_ACCOUNT_DETAILS}:id?`}
                component={ViewLoanAccountDetails}
                key={2.111}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_LOAN_REPAYMENT_SCHEDULE_PATH}:id?`}
                component={ViewLoanRepaymentSchedule}
                key={2.112}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_LOAN_ACCOUNT_STATEMENT_PATH}:id?`}
                component={ViewLoanAccountStatement}
                key={2.113}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_LOAN_ACCOUNT_MINI_STATEMENT_PATH}:id?`}
                component={ViewLoanAccountMiniStatement}
                key={2.114}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_LOAN_ACCOUNT_CLIENT_STATEMENT_PATH}:id?`}
                component={ViewLoanClientStatement}
                key={2.115}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_LOAN_ACCOUNT_CHARGES_PATH}:id?`}
                component={ViewLoanAccountCharges}
                key={2.116}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_LOAN_ACCOUNT_TRANSACTIONS_PATH}:id?`}
                component={ViewLoanAccountTransactions}
                key={2.117}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_LOAN_ACCOUNT_GUARANTORS_PATH}:id?`}
                component={ViewLoanAccountGuarantors}
                key={2.118}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_LOAN_ACCOUNT_PENDING_ACTIONS}:id?`}
                component={ViewLoanAccountPendingActions}
                key={2.119}
              />
            </ViewLoanAccount>
            <ViewMemberAccount
              path={`${CLIENT_BASE_URL}${VIEW_MEMBER_ACCOUNT_PATH}:subPath?/:id?`}
              key={2.12}
            >
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_MEMBER_ACCOUNT_DETAILS_PATH}:id?`}
                component={ViewMemberAccountDetails}
                key={2.121}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_MEMBER_ACCOUNT_STATEMENT_PATH}:id?`}
                component={ViewMemberAccountStatement}
                key={2.122}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_MEMBER_ACCOUNT_DEBIT_CREDIT_STATEMENT_PATH}:id?`}
                component={ViewMemberAccountDebitCreditStatement}
                key={2.123}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_MEMBER_ACCOUNT_TRANSACTIONS_PATH}:id?`}
                component={ViewMemberAccountTransactions}
                key={2.124}
              />
            </ViewMemberAccount>
            <LoanCharges
              path={`${CLIENT_BASE_URL}${LOAN_CHARGES_PATH}`}
              key={2.13}
            >
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_LOAN_CHARGES_PATH}`}
                component={ViewLoanCharges}
                key={2.131}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${CONFIGURE_LOAN_CHARGES_PATH}:id?`}
                component={ConfigureLoanCharge}
                key={2.132}
              />
            </LoanCharges>
            <ChartOfAccounts
              path={`${CLIENT_BASE_URL}${CHART_OF_ACCOUNTS_PATH}`}
              key={2.14}
            >
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_CHART_OF_ACCOUNTS_PATH}`}
                component={ViewChartOfAccounts}
                key={2.141}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${REGISTER_CHART_OF_ACCOUNTS_PATH}:id?`}
                component={RegisterChartOfAccount}
                key={2.142}
              />
            </ChartOfAccounts>
            <Banks path={`${CLIENT_BASE_URL}${BANKS_PATH}`} key={2.15}>
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_BANKS_PATH}`}
                component={ViewBanks}
                key={2.151}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${REGISTER_BANK_PATH}:id?`}
                component={RegisterBank}
                key={2.152}
              />
            </Banks>
            <PaymentModes
              path={`${CLIENT_BASE_URL}${PAYMENT_MODES_PATH}`}
              key={2.16}
            >
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_PAYMENT_MODES_PATH}`}
                component={ViewPaymentModes}
                key={2.161}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${REGISTER_PAYMENT_MODES_PATH}:id?`}
                component={RegisterPaymentMode}
                key={2.162}
              />
            </PaymentModes>
            <Currencies
              path={`${CLIENT_BASE_URL}${CURRENCIES_PATH}`}
              key={2.17}
            >
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_CURRENCIES_PATH}`}
                component={ViewCurrencies}
                key={2.171}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${REGISTER_CURRENCY_PATH}:id?`}
                component={RegisterCurrency}
                key={2.127}
              />
            </Currencies>
            <Groups path={`${CLIENT_BASE_URL}${GROUPS_PATH}`} key={2.18}>
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_GROUPS_PATH}`}
                component={ViewGroups}
                key={2.181}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${REGISTER_GROUP_PATH}:id?`}
                component={RegisterGroup}
                key={2.182}
              />
            </Groups>
            <Agents path={`${CLIENT_BASE_URL}${AGENTS_PATH}`} key={2.19}>
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_AGENTS_PATH}`}
                component={ViewAgents}
                key={2.191}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${REGISTER_AGENT_PATH}:id?`}
                component={RegisterAgent}
                key={2.192}
              />
            </Agents>
            <ClientPayments
              path={`${CLIENT_BASE_URL}${CLIENT_PAYMENTS_PATH}`}
              key={2.2}
            >
              <Route
                exact
                path={`${CLIENT_BASE_URL}${ADD_CLIENT_PAYMENT_PATH}`}
                component={AddClientPayment}
                key={2.201}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${BULK_CLIENT_PAYMENTS_PATH}`}
                component={BulkClientPayments}
                key={2.202}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${APPROVE_CLIENT_PAYMENTS_PATH}`}
                component={ApproveClientPayments}
                key={2.203}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${BULK_LOAN_PAYMENTS_PATH}`}
                component={BulkLoanPayments}
                key={2.204}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${APPROVE_LOAN_PAYMENTS_PATH}`}
                component={ApproveLoanPayments}
                key={2.205}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_CLIENT_PAYMENTS_PATH}`}
                component={ViewClientPayments}
                key={2.206}
              />
            </ClientPayments>
            <Income path={`${CLIENT_BASE_URL}${INCOME_PATH}`} key={2.21}>
              <Route
                exact
                path={`${CLIENT_BASE_URL}${RECEIVE_PAYMENTS_PATH}:id?`}
                component={ReceivePayment}
                key={2.211}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${APPROVE_RECEIVED_PAYMENTS_PATH}`}
                component={ApproveReceivedPayments}
                key={2.212}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_RECEIVED_PAYMENTS_PATH}`}
                component={ViewReceivedPayments}
                key={2.213}
              />
            </Income>
            <Expenses path={`${CLIENT_BASE_URL}${EXPENSES_PATH}`} key={2.22}>
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MAKE_PAYMENT_PATH}:id?`}
                component={MakeExpense}
                key={2.221}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${APPROVE_EXPENSES_PATH}`}
                component={ApproveExpenses}
                key={2.222}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_EXPENSES_PATH}`}
                component={ViewExpenses}
                key={2.223}
              />
            </Expenses>
            <Mpay path={`${CLIENT_BASE_URL}${MPAY_PATH}`} key={2.23}>
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MPAY_VIEW_TRANSACTIONS_PATH}`}
                component={ViewMpayTransactions}
                key={2.231}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MPAY_VIEW_PAYBILLS_PATH}`}
                component={ViewBusinessNumbers}
                key={2.232}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MPAY_ADD_PAYBILL_PATH}:id?`}
                component={RegisterBusinessNumber}
                key={2.233}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MPAY_SAGE_INTEGRATIONS_PATH}`}
                component={SageIntegrations}
                key={2.234}
              />
            </Mpay>
            <Audit path={`${CLIENT_BASE_URL}${AUDIT_PATH}`} key={2.24}>
              <Route
                exact
                path={`${CLIENT_BASE_URL}${ACTIVITY_LOG_PATH}`}
                component={ActivityLog}
                key={2.241}
              />
            </Audit>
            <BankingReconciliation
              path={`${CLIENT_BASE_URL}${BANKING_RECONCILIATION_PATH}`}
              key={2.25}
            >
              <Route
                exact
                path={`${CLIENT_BASE_URL}${RECONCILE_BANKING_TRANSACTIONS_PATH}`}
                component={ReconcileBankingTransactions}
                key={2.251}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_RECONCILED_TRANSACTIONS_PATH}`}
                component={ViewReconciledTransactions}
                key={2.252}
              />
            </BankingReconciliation>
            <Route
              exact
              path={`${DETAILED_LOAN_BALANCES_PATH}`}
              component={DetailedLoanBalances}
              key={2.27}
            />
            <Route
              exact
              path={`${CLIENT_BASE_URL}${MEMBER_APPLY_LOAN}`}
              component={MemberApplyLoan}
              key={2.29}
            />
            <MemberViewLoans
              key={6.0}
              path={`${CLIENT_BASE_URL}${MEMBER_VIEW_LOANS_PATH}`}
            >
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MEMBER_VIEW_ACTIVE_LOANS_PATH}`}
                component={MemberViewActiveLoans}
                key={6.01}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MEMBER_VIEW_COMPLETED_LOANS_PATH}`}
                component={MemberViewCompletedLoans}
                key={6.02}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MEMBER_VIEW_REJECTED_LOANS_PATH}`}
                component={MemberViewRejectedLoans}
                key={6.02}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MEMBER_VIEW_REVERSED_LOANS_PATH}`}
                component={MemberViewReversedLoans}
                key={6.02}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MEMBER_VIEW_PENDING_LOANS_PATH}`}
                component={MemberViewPendingLoans}
                key={6.02}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MEMBER_VIEW_WRITTEN_OFF_LOANS_PATH}`}
                component={MemberViewWrittenOffLoans}
                key={6.02}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MEMBER_VIEW_PROCESSED_LOANS_PATH}`}
                component={MemberViewProcessedLoans}
                key={6.02}
              />
            </MemberViewLoans>
            <Route
              exact
              path={`${CLIENT_BASE_URL}${MEMBER_VIEW_ACCOUNTS_PATH}`}
              component={MemberViewAccounts}
              key={7.0}
            />
            <MemberViewAccount
              path={`${CLIENT_BASE_URL}${MEMBER_VIEW_ACCOUNT_PATH}:subPath?/:id?`}
              key={8.0}
            >
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MEMBER_VIEW_ACCOUNT_DETAILS_PATH}:id?`}
                component={ViewMemberAccountDetails}
                key={8.01}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MEMBER_VIEW_ACCOUNT_STATEMENT_PATH}:id?`}
                component={ViewMemberAccountStatement}
                key={8.02}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MEMBER_VIEW_ACCOUNT_DEBIT_CREDIT_STATEMENT_PATH}:id?`}
                component={ViewMemberAccountDebitCreditStatement}
                key={8.03}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MEMBER_VIEW_ACCOUNT_TRANSACTIONS_PATH}:id?`}
                component={ViewMemberAccountTransactions}
                key={8.04}
              />
            </MemberViewAccount>
            <MemberViewLoan
              path={`${CLIENT_BASE_URL}${MEMBER_VIEW_LOAN_PATH}:subPath?/:id?`}
              key={9.0}
            >
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MEMBER_VIEW_LOAN_ACCOUNT_DETAILS_PATH}:id?`}
                component={ViewLoanAccountDetails}
                key={9.111}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MEMBER_VIEW_LOAN_REPAYMENT_SCHEDULE_PATH}:id?`}
                component={ViewLoanRepaymentSchedule}
                key={9.112}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MEMBER_VIEW_LOAN_ACCOUNT_STATEMENT_PATH}:id?`}
                component={ViewLoanAccountStatement}
                key={9.113}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MEMBER_VIEW_LOAN_MINI_STATEMENT_PATH}:id?`}
                component={ViewLoanAccountMiniStatement}
                key={9.114}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MEMBER_VIEW_LOAN_CLIENT_STATEMENT_PATH}:id?`}
                component={ViewLoanClientStatement}
                key={9.115}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MEMBER_VIEW_LOAN_CHARGES_PATH}:id?`}
                component={ViewLoanAccountCharges}
                key={9.116}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MEMBER_VIEW_LOAN_TRANSACTIONS_PATH}:id?`}
                component={ViewLoanAccountTransactions}
                key={9.117}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${MEMBER_VIEW_LOAN_GUARANTORS_PATH}:id?`}
                component={ViewLoanAccountGuarantors}
                key={9.118}
              />
            </MemberViewLoan>
            <Route
              exact
              path={`${CLIENT_BASE_URL}${MEMBER_PROFILE_PATH}`}
              component={MemberProfile}
              key={10.0}
            />
            <Billing path={`${CLIENT_BASE_URL}${BILLING_PATH}`} key={11}>
              <Route
                exact
                path={`${CLIENT_BASE_URL}${BILLING_INVOICES_PATH}`}
                component={BillingInvoices}
                key={11.1}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${BILLING_ADDONS_PATH}`}
                component={BillingAddons}
                key={11.2}
              />
            </Billing>
            <LoanPortfolio
              path={`${CLIENT_BASE_URL}${LOAN_PORTFOLIO_PATH}`}
              key={12.0}
            >
              <Route
                exact
                path={`${LOAN_PORTFOLIO_DETAILS_PATH}`}
                component={LoanPortfolioDetails}
                key={12.1}
              />
              <Route
                exact
                path={`${LOAN_PORTFOLIO_SUMMARY_PATH}`}
                component={LoanPortfolioSummary}
                key={12.2}
              />
              <Route
                exact
                path={`${LOAN_PORTFOLIO_HISTORY_PATH}`}
                component={LoanPortfolioHistory}
                key={12.3}
              />
            </LoanPortfolio>
            <Collaterals
              path={`${CLIENT_BASE_URL}${COLLATERALS_PATH}`}
              key={13.0}
            >
              <Route
                exact
                path={`${CLIENT_BASE_URL}${VIEW_COLLATERALS_PATH}`}
                component={ViewCollaterals}
                key={13.1}
              />
              <Route
                exact
                path={`${CLIENT_BASE_URL}${REGISTER_COLLATERALS_PATH}:id?`}
                component={RegisterCollaterals}
                key={13.2}
              />
            </Collaterals>
            <Route
              exact
              path={`${LOAN_DISBURSEMENT_REPORT_PATH}`}
              component={LoanDisbursementReport}
              key={14}
            />
          </Switch>
        </Menu>
      </Switch>
    </Router>
  );
}

export default App;
