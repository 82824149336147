import { countries } from "countries-list";
import $ from "jquery";
import _ from "lodash";
import moment from "moment";
import React from "react";
import {
  fetchDataIfNeeded,
  invalidateData,
  setSessionVariable,
} from "../actions/actions";
import {
  API_ENDPOINT,
  CLIENT_BASE_URL,
  LEGACY_CLIENT_BASE_URL,
  SYSTEM_LABELS_ENDPOINT,
} from "../constants/Constants";
import { postAPIRequest } from "./APIRequests";

require("react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css");

const labels = [];

function translateLabels() {
  postAPIRequest(
    `${API_ENDPOINT}${SYSTEM_LABELS_ENDPOINT}`,
    () => {},
    () => {},
    { label_keys: labels },
    {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.token}`,
    }
  );
}

const debounce = _.debounce(translateLabels, 5000);
export const label: any = function (label_key, props) {
  let label: any = label_key;
  const { system_labels_key_dict_data } = props;
  if (system_labels_key_dict_data) {
    let system_labels_key_dict: any = system_labels_key_dict_data.items;
    if (Array.isArray(system_labels_key_dict_data.items)) {
      system_labels_key_dict = {};
    }
    let letter_casing: any = function (label) {
      return label;
    };
    if (_.capitalize(label_key) === label_key) {
      letter_casing = _.capitalize;
    } else if (_.toUpper(label_key) === label_key) {
      letter_casing = _.toUpper;
    } else if (_.upperFirst(label_key) === label_key) {
      letter_casing = _.upperFirst;
    }
    label = (system_labels_key_dict[label_key] || { label: label_key }).label;
    label = letter_casing(label);
    const system_labels_length = Object.keys(system_labels_key_dict).length;
    if (
      system_labels_length > 0 &&
      !system_labels_key_dict[label_key] &&
      labels.indexOf(label_key) < 0 &&
      label_key !== "undefined"
    ) {
      labels.push(label_key);
      debounce();
    }
  }
  return label;
};

export const getUrlData: any = function (
  dataByUrl,
  sessionVariables,
  url_var_name
) {
  const url: any = sessionVariables[url_var_name] || "";
  let isFetching: boolean = true;
  if (url === "") {
    isFetching = false;
  }
  return (
    dataByUrl[url] || {
      didInvalidate: false,
      isFetching,
      items: [],
      lastUpdated: "",
    }
  );
};

export const fetchUrlData: any = function (
  var_name,
  url,
  props,
  invalidate = false
) {
  const { dispatch } = props;
  url = API_ENDPOINT + url;
  props.dispatch(setSessionVariable(var_name, url));
  if (invalidate) {
    dispatch(invalidateData(url));
  }
  dispatch(fetchDataIfNeeded(url));
};

export const formDataToJSON: any = function (
  formData,
  payload,
  overwrite = true
) {
  formData.forEach((value, key) => {
    const form_field: any = $(`input[name=${key}]`);
    let input_type: string = "";
    if (form_field.length > 0) {
      input_type = form_field.attr("type");
    }
    if (!payload.key || overwrite) {
      if (input_type === "number") {
        const step: any = form_field.attr("step");
        if (["1", 1, undefined].includes(step)) {
          payload[key] = parseInt(value);
        } else {
          payload[key] = parseFloat(value);
        }
      } else if (input_type === "checkbox") {
        payload[key] = form_field.is(":checked");
      } else {
        payload[key] = value;
      }
    }
  });
  return payload;
};

export const JSONToFormData: any = function (formData, payload) {
  Object.keys(payload).forEach((key) => {
    if (!formData.has(key)) {
      if (Array.isArray(payload[key])) {
        payload[key].forEach((el) => {
          formData.append(key, el);
        });
      } else if (![null, undefined].includes(payload[key])) {
        formData.append(key, payload[key]);
      }
    }
  });
  return formData;
};

export const generateID: any = function (length) {
  let result: string = "";
  const characters: string =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const charactersLength: any = characters.length;
  for (let i: number = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const dynamicSort: any = function (property) {
  let sortOrder: number = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    const result: any =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

export const extractResponseError: any = function (results) {
  let alert_message: string = "";
  if (results.constructor === Array && results.length > 0) {
    alert_message = results[0];
  } else if (typeof results === "object") {
    if (results.detail) {
      alert_message = results.detail;
    } else if (results.non_field_errors) {
      alert_message = results.non_field_errors;
    } else if (Object.keys(results).length > 0) {
      for (const key in results) {
        alert_message += `${key}: ${results[key]} `;
      }
    } else {
      alert_message = results.toString();
    }
  } else if (typeof results === "string") {
    alert_message = results;
  }
  return alert_message;
};

export const pushHistory: any = function (url, props, type = "soft") {
  if (type === "hard") {
    if (url.startsWith("/app/") || url.startsWith("/mpay/")) {
      window.open(`${API_ENDPOINT}${url}`, "_self");
    } else {
      window.open(`${API_ENDPOINT}${LEGACY_CLIENT_BASE_URL}${url}`, "_self");
    }
  } else if (type === "server") {
    window.open(`${API_ENDPOINT}${url}`, "_self");
  } else {
    props.history.push(`${CLIENT_BASE_URL}${url}`);
  }
};

export const delay: any = (ms) => new Promise((res) => setTimeout(res, ms));

export const renameObjectKey: any = function (old_key, new_key, oldObject) {
  let newObject: {} = {};
  Object.keys(oldObject).forEach((key) => {
    if (key === old_key) {
      const new_pair: any = { [new_key]: oldObject[old_key] };
      newObject = { ...newObject, ...new_pair };
    } else {
      newObject = { ...newObject, [key]: oldObject[key] };
    }
  });
  return newObject;
};

export const dataTablePagination: any = function (
  size,
  start,
  data,
  page_size_list,
  data_size,
  props
) {
  const customTotal: any = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      <span className="ml-1">
        {label("Showing", props)} {from} {label("to", props)} {to}{" "}
        {label("of", props)} {data_size} {label("results", props)}
      </span>
    </span>
  );
  return {
    paginationSize: size,
    pageStartIndex: start,
    alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: label("First", props),
    prePageText: label("Back", props),
    nextPageText: label("Next", props),
    lastPageText: label("Last", props),
    nextPageTitle: label("First page", props),
    prePageTitle: label("Pre page", props),
    firstPageTitle: label("Next page", props),
    lastPageTitle: label("Last page", props),
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: page_size_list,
  };
};

export const thousandsFormat: any = function (x, decimal_places) {
  const roundNumber: any = function (num, scale) {
    return Number(`${Math.round(`${num}e+${scale}`)}e-${scale}`);
  };

  if ([undefined, null].includes(decimal_places)) {
    decimal_places = 0;
  }
  if (!x && x !== 0) {
    x = null;
  } else if (typeof x === "string") {
    x = parseInt(x);
  }
  if (x !== null) {
    try {
      x = x.toFixed(decimal_places);
    } catch (err) {
      x = roundNumber(x, decimal_places);
    }
    if (["0", 0].includes(decimal_places)) {
      try {
        x = parseFloat(x).toFixed(2);
      } catch (err) {
        x = roundNumber(parseFloat(x), 2);
      }
    }
    const parts: any = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  return x;
};

export const toggleActiveNav: any = function (e, nav_path, _this) {
  e.preventDefault();
  _this.setState(
    {
      active_nav: nav_path,
    },
    () => pushHistory(nav_path, _this.props)
  );
};

export const toggleActivePill: any = function (e, pill_name, _this) {
  e.preventDefault();
  _this.setState({
    active_pill: pill_name,
  });
};

export const getActiveNav: any = function (nav_path, _this) {
  let active_class: string = "";
  const active_nav: any = _this.state.active_nav.replace(/[0-9]/g, "");
  if (active_nav === nav_path) {
    active_class = "active";
  }
  return active_class;
};

export const getActivePill: any = function (pill_name, _this) {
  let active_class: string = "";
  const { active_pill } = _this.state;
  if (active_pill === pill_name) {
    active_class = "active";
  }
  return active_class;
};

export const dataTableNoWrap: any = function (columns) {
  const nowrap: { whiteSpace: string } = { whiteSpace: "nowrap" };
  columns.forEach((column) => {
    column.headerStyle = nowrap;
    column.style = nowrap;
    column.headerClasses = "text-grayish table-white";
  });
  return columns;
};

export const removeUnderscore: any = function (string) {
  if (string) {
    return string.replace(/_/g, " ");
  }
  return string;
};

export const lookup: any = function (callback) {
  try {
    const loadJSONP: any = (url, callback2) => {
      const ref: any = window.document.getElementsByTagName("script")[0];
      const script: any = window.document.createElement("script");
      script.src = `${
        url + (url.indexOf("?") + 1 ? "&" : "?")
      }callback=${callback2}`;
      ref.parentNode.insertBefore(script, ref);
      script.onload = () => {
        script.remove();
      };
    };
    let protocol: string = "http";
    if (typeof window !== "undefined") {
      protocol = (window.location || {}).protocol;
    }
    loadJSONP(`${protocol}//ipinfo.io?token=e6909d77aca84d`, "sendBack");
    window.sendBack = (resp) => {
      const countryCode: any = resp && resp.country ? resp.country : "";
      callback(countryCode);
    };
  } catch (e) {
    callback("");
  }
};

export const invalidateSessionData: any = function (_this, url_prefix = "") {
  const { dispatch, dataByUrl } = _this.props;
  const store_urls: any = Object.keys(dataByUrl);
  store_urls.forEach((url) => {
    if (url.includes(url_prefix)) {
      dispatch(invalidateData(url));
    }
  });
};

export const handleFieldChange: any = function (
  e,
  field_name = null,
  field_value = null,
  select_field = false,
  payload,
  data_object = {}
) {
  if (!field_name) {
    field_name = e.target.name;
    field_value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
  } else if (!field_value && !select_field) {
    field_value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
  }
  if (select_field) {
    if (Array.isArray(field_value)) {
      field_value = field_value.map((value) => value.value);
    } else {
      if ((field_value || {}).optgroup_display) {
        payload[`${field_name}_optgroup_display`] = (
          field_value || {}
        ).optgroup_display;
      }
      field_value = (field_value || {}).value || null;
    }
  }
  payload[field_name] = field_value;
  data_object[field_name] = field_value;
  return payload;
};

export const getSelectedOptions: any = function (
  field_value,
  options,
  default_first = false
) {
  if (Array.isArray(field_value)) {
    return options.filter((option) => field_value.includes(option.value));
  }
  const selected: any = options.find(
    (option) =>
      field_value === option.value || parseInt(field_value) === option.value
  );
  if (!selected && default_first) {
    return options[0];
  }
  return selected;
};

export const getCountriesOptions: any = function () {
  const countries_overwrite: any = {
    TW: { name: "Taiwan, China" },
  };
  return Object.keys(countries).map((key) => {
    const country: any = countries[key];
    return {
      value: key,
      label: (countries_overwrite[key] || country).name,
    };
  });
};

export const getFieldFromListObj: any = function (
  list_obj,
  field_key,
  match_field,
  match_value
) {
  return (
    (list_obj.find((obj) => match_value === obj[match_field]) || {})[
      field_key
    ] || []
  );
};

export const getSelect2Options: any = function (
  data,
  value_field,
  label_field,
  field_mapping = null,
  extra_attributes
) {
  return data.map((obj) => {
    let label: any = obj[label_field];
    if (field_mapping) {
      label = label_field;
      field_mapping.forEach((field) => {
        label = label.replace(`[${field}]`, obj[field] || "");
      });
    }
    return {
      value: obj[value_field],
      label,
      ...extra_attributes,
    };
  });
};

export const objGetField: any = function (obj, field, default_val) {
  return typeof obj[field] !== "undefined" ? obj[field] : default_val;
};

export const findObject: any = function (list, match_field, match_value) {
  return list.find((el) => el[match_field] === match_value);
};

export const parseJSON: any = function (json_string, is_array = false) {
  try {
    return JSON.parse(json_string);
  } catch (e) {
    return is_array ? [] : {};
  }
};

export const formatDate: any = function (date_string, format) {
  return ![null, undefined, ""].includes(date_string)
    ? moment(date_string).format(format)
    : "";
};

export const tableGetSelectedRowObj: any = function (table_node) {
  const selected_rows: any = table_node.selectionContext.selected;
  const table_data: any = table_node.table.getData();
  return table_data.find((el) => el.id === selected_rows[0]);
};

export const deepCompare: any = function () {
  let i;
  let l;
  let leftChain;
  let rightChain;

  function compare2Objects(x, y) {
    let p;

    // remember that NaN === NaN returns false
    // and Number.isNaN(undefined) returns true
    if (
      Number.isNaN(x) &&
      Number.isNaN(y) &&
      typeof x === "number" &&
      typeof y === "number"
    ) {
      return true;
    }

    // Compare primitives and functions.
    // Check if both arguments link to the same object.
    // Especially useful on the step where we compare prototypes
    if (x === y) {
      return true;
    }

    // Works in case when functions are created in constructor.
    // Comparing dates is a common scenario. Another built-ins?
    // We can even handle functions passed across iframes
    if (
      (typeof x === "function" && typeof y === "function") ||
      (x instanceof Date && y instanceof Date) ||
      (x instanceof RegExp && y instanceof RegExp) ||
      (x instanceof String && y instanceof String) ||
      (x instanceof Number && y instanceof Number)
    ) {
      return x.toString() === y.toString();
    }

    // At last checking prototypes as good as we can
    if (!(x instanceof Object && y instanceof Object)) {
      return false;
    }

    if (
      Object.prototype.isPrototypeOf.call(x, y) ||
      Object.prototype.isPrototypeOf.call(y, x)
    ) {
      return false;
    }

    if (x.constructor !== y.constructor) {
      return false;
    }

    if (x.prototype !== y.prototype) {
      return false;
    }

    // Check for infinitive linking loops
    if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
      return false;
    }

    // Quick checking of one object being a subset of another.
    // todo: cache the structure of arguments[0] for performance
    for (p in y) {
      if (
        Object.prototype.hasOwnProperty.call(y, p) !==
        Object.prototype.hasOwnProperty.call(x, p)
      ) {
        return false;
      }
      if (typeof y[p] !== typeof x[p]) {
        return false;
      }
    }

    for (p in x) {
      if (
        Object.prototype.hasOwnProperty.call(y, p) !==
        Object.prototype.hasOwnProperty.call(x, p)
      ) {
        return false;
      }
      if (typeof y[p] !== typeof x[p]) {
        return false;
      }

      switch (typeof x[p]) {
        case "object":
        case "function":
          leftChain.push(x);
          rightChain.push(y);

          if (!compare2Objects(x[p], y[p])) {
            return false;
          }

          leftChain.pop();
          rightChain.pop();
          break;

        default:
          if (x[p] !== y[p]) {
            return false;
          }
          break;
      }
    }

    return true;
  }

  if (arguments.length < 1) {
    return true; // Die silently? Don't know how to handle such case, please help...
    // throw "Need two or more arguments to compare";
  }

  for (i = 1, l = arguments.length; i < l; i++) {
    leftChain = []; // Todo: this can be cached
    rightChain = [];

    if (!compare2Objects(arguments[0], arguments[i])) {
      return false;
    }
  }

  return true;
};

export const arrayRemove: any = function (arr, value) {
  return arr.filter((ele) => ele !== value);
};
