import {
  APPLIED_LOANS_ENDPOINT,
  LOAN_GUARANTORS_ENDPOINT,
} from "../../constants/Constants";
import {
  LOAN_ACCOUNT_DETAILS_COLUMNS,
  LOAN_GUARANTORS_COLUMNS,
  PENDING_LOANS_COLUMNS,
} from "../../components/DataTableColumns";
import React, { Component } from "react";

import DataTableActionForm from "../../components/DataTableActionForm";
import FormFields from "../../components/FormFields";
import ModalHOC from "../../components/ModalHOC";
import ViewAppliedLoans from "../view/ViewAppliedLoans";
import ViewDataTable from "../../components/ViewDataTable";
import { withRouter } from "react-router-dom";

class ApproveLoan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 5,
      applied_loan: {},
    };
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  handleGetSelected = (row, isSelect) => {
    if (isSelect) {
      this.setState({
        applied_loan: row,
      });
    }
  };

  render() {
    const { props } = this;
    const applied_loans_url: string = `${APPLIED_LOANS_ENDPOINT}?status__in=6&limit=5&offset=0`;
    const applied_loans_url_name: string = "applied_loans_url";
    const { applied_loan } = this.state;
    const loan_guarantors_get_params: string = `loan=${applied_loan.id}`;
    const applied_loan_url: string = `${APPLIED_LOANS_ENDPOINT}${applied_loan.id}/`;
    const approve_multiple_form_items: any = [
      <FormFields
        key={1}
        field_type="date"
        field_label="Date of disbursement"
        props={props}
        field_name="disbursement_date"
        wrapper_div_classes="col-4 pb-3"
        data_object={applied_loan}
        required
      />,
    ];
    const approve_form_items: any = [
      <FormFields
        key={1}
        field_type="date"
        field_label="Date of disbursement"
        props={props}
        field_name="disbursement_date"
        wrapper_div_classes="col-4 pb-3"
        data_object={applied_loan}
        required
      />,
      <FormFields
        key={2}
        field_type="number_input"
        field_label="Approved amount"
        props={props}
        field_name="approved_amount"
        wrapper_div_classes="col-4 pb-3"
        data_object={applied_loan}
        required
        data_object_field="amount"
      />,
    ];
    const reject_form_items: any = [
      <FormFields
        key={1}
        field_type="textarea"
        field_label="Rejection reason"
        props={props}
        field_name="rejection_reason"
        wrapper_div_classes="col-4 pb-3"
        data_object={{}}
        required
        autofocus
      />,
    ];
    const actions: any = [
      {
        name: "approve",
        btn_classes: "btn btn-green text-white",
        noun: "approval",
        icon: "file-earmark-check",
        target: "loan",
        plural: "loans",
        form_items: approve_form_items,
        multiple_form_items: approve_multiple_form_items,
        extra_form_data: [
          { name: "status", value: 9 },
          { name: "approval", value: true },
        ],
      },
      {
        name: "reject",
        btn_classes: "btn btn-danger ml-2",
        noun: "rejection",
        icon: "x",
        target: "loan",
        plural: "loans",
        form_items: reject_form_items,
        multiple_form_items: reject_form_items,
        extra_form_data: [
          { name: "status", value: 10 },
          { name: "approval", value: true },
        ],
      },
    ];
    return (
      <DataTableActionForm
        actions={actions}
        table_node={this.state.table_node}
        ids_post_field="loan_ids"
        datatable_action_endpoint={APPLIED_LOANS_ENDPOINT}
        request_method="POST"
        datatable_source_url={applied_loans_url}
        datatable_source_url_name={applied_loans_url_name}
      >
        <ViewAppliedLoans
          status={6}
          columns={PENDING_LOANS_COLUMNS}
          csv_export={false}
          selected={this.state.selected_rows}
          select_mode="checkbox"
          handleSetTableNode={this.handleSetTableNode}
          extra_get_params="approval=true"
          handleGetSelected={this.handleGetSelected}
        />
        <ModalHOC
          modal_only
          modal_title="Loan details"
          data_target="view-loan-details"
          modal_size="modal-lg"
        >
          <ViewDataTable
            paginated={false}
            basic_table
            vertical_headers
            columns={LOAN_ACCOUNT_DETAILS_COLUMNS}
            data_source_url={applied_loan_url}
            csv_export={false}
            wait_for_props
            data_source_url_name="applied_loan_url"
          />
          <ViewDataTable
            columns={LOAN_GUARANTORS_COLUMNS}
            wait_for_props
            csv_export={false}
            allow_refresh={false}
            basic_table
            paginated={false}
            display_tenant_header={false}
            extra_get_params={loan_guarantors_get_params}
            data_source_url={LOAN_GUARANTORS_ENDPOINT}
            data_source_url_name="loan_guarantors_url"
          />
        </ModalHOC>
      </DataTableActionForm>
    );
  }
}

export default withRouter(ApproveLoan);
