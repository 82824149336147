import React, { Component } from "react";

import { VIEW_CHART_OF_ACCOUNTS_PATH } from "../../constants/ClientPaths";
import VIewAddPortlet from "../../components/VIewAddPortlet";
import { withRouter } from "react-router-dom";

class ChartOfAccounts extends Component {
  render() {
    const pills: any = [
      {
        label: "View chart of accounts",
        path: VIEW_CHART_OF_ACCOUNTS_PATH,
      },
    ];
    return (
      <VIewAddPortlet header_label="Chart of accounts" pills={pills}>
        {this.props.children}
      </VIewAddPortlet>
    );
  }
}

export default withRouter(ChartOfAccounts);
