import {
  BANKS_ENDPOINT,
  CHART_OF_ACCOUNTS_ENDPOINT,
  CURRENCIES_ENDPOINT,
  GROUPS_ENDPOINT,
  INCOME_ENDPOINT,
  MEMBER_ACCOUNTS_ENDPOINT,
  OBJECT_STORAGE_ENDPOINT,
  OBJECT_STORAGE_MEDIA_BUCKET,
  ORGANIZATION_BRANCHES_ENDPOINT,
  PAYMENT_MODES_ENDPOINT,
} from "../../constants/Constants";
import React, { Component } from "react";
import {
  fetchUrlData,
  getSelect2Options,
  getUrlData,
} from "../../utils/componentActions";

import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import ModalHOC from "../../components/ModalHOC";
import PaymentReceipt from "../PaymentReceipt";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import merge from "lodash/merge";
import { withRouter } from "react-router";

class Class extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      receive_payment: {
        print_receipt: true,
      },
      receipt_modal_show: false,
      transaction_details: {},
      income_transactions_upload: {},
    };
    this.state = merge({}, this.initialState);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const previous_receive_payment: any = prevState.receive_payment;
    const { receive_payment } = this.state;
    if (previous_receive_payment.member !== receive_payment.member) {
      this.onMemberChange({}, receive_payment.member);
    }
  }

  componentDidMount() {
    const payment_id: any = this.props.match.params.id;
    const income_accounts_url: string = `${CHART_OF_ACCOUNTS_ENDPOINT}?account_type=4`;
    if (payment_id) {
      const income_url: string = `${INCOME_ENDPOINT}${payment_id}/`;
      fetchUrlData("income_url", income_url, this.props);
    }
    fetchUrlData("income_accounts_url", income_accounts_url, this.props);
    fetchUrlData("groups_url", GROUPS_ENDPOINT, this.props);
    fetchUrlData("banks_url", BANKS_ENDPOINT, this.props);
    fetchUrlData("payment_modes_url", PAYMENT_MODES_ENDPOINT, this.props);
    fetchUrlData("currencies_url", CURRENCIES_ENDPOINT, this.props);
    fetchUrlData(
      "organization_branches_url",
      ORGANIZATION_BRANCHES_ENDPOINT,
      this.props
    );
  }

  handlePrintReceipt = (transaction) => {
    if (this.state.receive_payment.print_receipt) {
      this.setState({
        receipt_modal_show: true,
        transaction_details: transaction,
      });
    }
  };

  handleReceiptClose = (callback) => {
    this.setState(
      {
        receipt_modal_show: false,
      },
      () => callback
    );
  };

  updateState = (state_object) => {
    this.setState(state_object);
  };

  resetState = () => {
    const state_object: any = merge({}, this.initialState);
    const previous_state: any = this.state;
    state_object.transaction_details = previous_state.transaction_details;
    const { receive_payment } = state_object;
    receive_payment.reset_fields = true;
    this.setState(state_object);
  };

  onMemberChange = (select_object, member_id = null) => {
    if (!member_id) {
      member_id = select_object.value;
    }
    const member_accounts_url: string = `${MEMBER_ACCOUNTS_ENDPOINT}?member=${member_id}`;
    fetchUrlData("member_accounts_url", member_accounts_url, this.props);
  };

  render() {
    const { props } = this;
    const {
      income_data,
      income_accounts_data,
      groups_data,
      member_accounts_data,
      currencies_data,
      payment_modes_data,
      banks_data,
      organization_branches_data,
    } = props;
    const form_classes: string = "row g-3";
    const { receive_payment } = this.state;
    const income_accounts: any = income_accounts_data.items;
    const groups: any = groups_data.items;
    const member_accounts: any = member_accounts_data.items;
    const currencies: any = currencies_data.items;
    const banks: any = banks_data.items;
    const payment_modes: any = payment_modes_data.items;
    const organization_branches: any = organization_branches_data.items;
    const group_options_label: string = "[group_name]([group_code])";
    const group_options_label_map: string[] = ["group_name", "group_code"];
    const income_account_options: any = getSelect2Options(
      income_accounts,
      "id",
      "account_name"
    );
    const group_options: any = getSelect2Options(
      groups,
      "id",
      group_options_label,
      group_options_label_map
    );
    const member_account_option: any = getSelect2Options(
      member_accounts,
      "id",
      "account_display_name"
    );
    const currency_options: any = getSelect2Options(currencies, "id", "name");
    const bank_options: any = getSelect2Options(banks, "id", "bank_name");
    const payment_mode_options: any = getSelect2Options(
      payment_modes,
      "id",
      "name"
    );
    const organization_branch_options: any = getSelect2Options(
      organization_branches,
      "id",
      "name"
    );
    const extra_form_data: any = [{ name: "income", value: true }];

    const income_template_url: string = `${OBJECT_STORAGE_ENDPOINT}/${OBJECT_STORAGE_MEDIA_BUCKET}/income.csv`;
    const income_transactions_upload_form_classes: string = "row g-3";
    const income_transactions_upload_form_items: any = [
      <FormFields
        key={1}
        field_type="file"
        field_label="CSV file"
        props={props}
        field_name="payments_csv"
        wrapper_div_classes="col"
        data_object={{}}
        required
        template_url={income_template_url}
      />,
    ];
    const income_transactions_upload_form: any = (
      <CreateEditForm
        loading={false}
        form_items={income_transactions_upload_form_items}
        form_classes={income_transactions_upload_form_classes}
        action_object={{}}
        setState={this.updateState}
        save_button_label="Upload"
        action_object_name="income_transactions_upload"
        state={this.state}
        action_object_endpoint={INCOME_ENDPOINT}
        post_form
        object_display_name="transactions upload"
        content_type="multipart/form-data; boundary=----"
      />
    );

    const form_items: any = [
      <FormFields
        key={1}
        field_type="select2"
        field_label="Account"
        props={props}
        field_name="account"
        wrapper_div_classes="col-4"
        select2_options={income_account_options}
        data_object={receive_payment}
        required
      />,
      <FormFields
        key={2}
        field_type="text_input"
        field_label="Payer"
        props={props}
        field_name="payer"
        wrapper_div_classes="col-4"
        data_object={receive_payment}
      />,
      <FormFields
        key={3}
        field_type="select2"
        field_label="Group"
        props={props}
        field_name="group"
        wrapper_div_classes="col-4"
        select2_options={group_options}
        data_object={receive_payment}
      />,
      <FormFields
        key={4}
        field_type="member"
        field_label="Member"
        props={props}
        field_name="member"
        wrapper_div_classes="col-4"
        onChange={(selected) => this.onMemberChange(selected)}
        data_object={receive_payment}
      />,
      <FormFields
        key={5}
        field_type="select2"
        field_label="Member account"
        props={props}
        field_name="member_account"
        wrapper_div_classes="col-4"
        select2_options={member_account_option}
        data_object={receive_payment}
      />,
      <FormFields
        key={6}
        field_type="select2"
        field_label="Branch"
        props={props}
        field_name="branch"
        wrapper_div_classes="col-4"
        select2_options={organization_branch_options}
        data_object={receive_payment}
      />,
      <FormFields
        key={7}
        field_type="select2"
        field_label="Bank"
        props={props}
        field_name="bank"
        wrapper_div_classes="col-4"
        select2_options={bank_options}
        required
        data_object={receive_payment}
      />,
      <FormFields
        key={8}
        field_type="text_input"
        field_label="Cheque no"
        props={props}
        field_name="cheque"
        wrapper_div_classes="col-4"
        data_object={receive_payment}
      />,
      <FormFields
        key={9}
        field_type="text_input"
        field_label="Transaction code"
        props={props}
        field_name="transaction_code"
        wrapper_div_classes="col-4"
        autofill_timestamp
        autofill_prefix="INC-"
        required
        data_object={receive_payment}
      />,
      <FormFields
        key={10}
        field_type="number_input"
        field_label="Amount"
        props={props}
        field_name="amount"
        wrapper_div_classes="col-4"
        required
        step="0.01"
        data_object={receive_payment}
      />,
      <FormFields
        key={11}
        field_type="select2"
        field_label="Payment mode"
        props={props}
        field_name="payment_type"
        wrapper_div_classes="col-4"
        select2_options={payment_mode_options}
        required
        data_object={receive_payment}
      />,
      <FormFields
        key={12}
        field_type="select2"
        field_label="Currency"
        props={props}
        field_name="currency"
        wrapper_div_classes="col-4"
        select2_options={currency_options}
        required
        data_object={receive_payment}
      />,
      <FormFields
        key={13}
        field_type="date"
        field_label="Date"
        props={props}
        field_name="date"
        wrapper_div_classes="col-4"
        required
        data_object={receive_payment}
      />,
      <FormFields
        key={14}
        field_type="textarea"
        field_label="Description"
        props={props}
        field_name="description"
        wrapper_div_classes="col-4"
        data_object={receive_payment}
      />,
      <FormFields
        key={15}
        field_type="select"
        field_label="Transaction type"
        props={props}
        field_name="transaction_type"
        wrapper_div_classes="col-4"
        data_object={receive_payment}
      >
        <option />
        <option value={22}>Administration fees</option>
        <option value={54}>Withdrawal charges</option>
        <option value={55}>Deposit charges</option>
        <option value={56}>Transaction charges</option>
        <option value={57}>Late payment charges</option>
        <option value={14}>Member registration fees</option>
      </FormFields>,
      <FormFields
        key={16}
        field_type="checkbox"
        checkbox_field_label="Print receipt"
        props={props}
        field_name="print_receipt"
        wrapper_div_classes="col-4 pt-4-2"
        data_object={receive_payment}
        update_payload
        data_object_name="client_payment"
        updateState={this.updateState}
      />,
    ];
    return (
      <>
        <div className="row mb-3">
          <ModalHOC
            button_icon="upload"
            button_label="Upload transactions"
            data_target="upload-income"
            button_classes="btn btn-outline-primary"
            modal_title="Upload transactions"
          >
            {income_transactions_upload_form}
          </ModalHOC>
        </div>
        <ModalHOC
          modal_show={this.state.receipt_modal_show}
          modal_only
          modal_title="Receipt"
          modalCloseCallback={this.handleReceiptClose}
        >
          <PaymentReceipt
            transaction_details={this.state.transaction_details}
            payment_type="income"
          />
        </ModalHOC>
        <CreateEditForm
          loading={income_data.isFetching || income_accounts_data.isFetching}
          form_items={form_items}
          form_classes={form_classes}
          action_object={income_data.items}
          setState={this.updateState}
          action_object_name="receive_payment"
          state={this.state}
          action_object_endpoint={INCOME_ENDPOINT}
          successCallback={this.handlePrintReceipt}
          resetState={this.resetState}
          form_id="income-form"
          action_object_initial_state={this.initialState.receive_payment}
          extra_form_data={extra_form_data}
          object_display_name="payment"
        />
      </>
    );
  }
}

Class.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  income_data: PropTypes.instanceOf(Object).isRequired,
  income_accounts_data: PropTypes.instanceOf(Object).isRequired,
  groups_data: PropTypes.instanceOf(Object).isRequired,
  member_accounts_data: PropTypes.instanceOf(Object).isRequired,
  banks_data: PropTypes.instanceOf(Object).isRequired,
  payment_modes_data: PropTypes.instanceOf(Object).isRequired,
  currencies_data: PropTypes.instanceOf(Object).isRequired,
  organization_branches_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const income_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "income_url"
  );
  const income_accounts_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "income_accounts_url"
  );
  const groups_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "groups_url"
  );
  const member_accounts_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "member_accounts_url"
  );
  const banks_data: any = getUrlData(dataByUrl, sessionVariables, "banks_url");
  const payment_modes_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "payment_modes_url"
  );
  const currencies_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "currencies_url"
  );
  const organization_branches_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "organization_branches_url"
  );

  return {
    sessionVariables,
    income_data,
    income_accounts_data,
    groups_data,
    member_accounts_data,
    banks_data,
    payment_modes_data,
    currencies_data,
    organization_branches_data,
  };
}

export default connect(mapStateToProps)(withRouter(Class));
