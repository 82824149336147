import {
  CHART_OF_ACCOUNTS_ENDPOINT,
  MEMBER_ACCOUNTS_ENDPOINT,
  OBJECT_STORAGE_ENDPOINT,
  OBJECT_STORAGE_MEDIA_BUCKET,
  SAVINGS_PRODUCTS_ENDPOINT,
  SHARES_PRODUCTS_ENDPOINT,
} from "../../constants/Constants";
import React, { Component } from "react";
import { fetchUrlData, getUrlData } from "../../utils/componentActions";

import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import ModalHOC from "../../components/ModalHOC";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class RegisterAccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      member_account: {},
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
      account_type: "savings_deposits_account",
      member_accounts_upload: {},
    };
  }

  componentDidMount() {
    fetchUrlData("savings_products_url", SAVINGS_PRODUCTS_ENDPOINT, this.props);
    fetchUrlData("shares_products_url", SHARES_PRODUCTS_ENDPOINT, this.props);
    const member_account_id: any = this.props.match.params.id;
    if (member_account_id) {
      const member_account_url: string = `${MEMBER_ACCOUNTS_ENDPOINT}${member_account_id}/`;
      fetchUrlData("member_account_url", member_account_url, this.props);
    }
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const { savings_products_data, shares_products_data, member_account_data } =
      props;
    const { member_account } = this.state;
    const savings_products: any = savings_products_data.items;
    const shares_products: any = shares_products_data.items;
    let selected_member;
    if (member_account.member) {
      selected_member = {
        value: member_account.member,
        label: member_account.member_name,
      };
    }
    let product_options: any = savings_products.map((product) => ({
      value: product.id,
      label: product.product_name,
    }));
    const account_type: any =
      member_account.account_type || this.state.account_type;
    if (account_type === "shares_account") {
      product_options = shares_products.map((product) => ({
        value: product.id,
        label: product.product_name,
      }));
    }
    const accounts_template_url: string = `${OBJECT_STORAGE_ENDPOINT}/${OBJECT_STORAGE_MEDIA_BUCKET}/member_accounts.csv`;
    const member_accounts_upload_form_items: any = [
      <FormFields
        key={1}
        field_type="file"
        field_label="CSV file"
        props={props}
        field_name="accounts_csv"
        wrapper_div_classes="col"
        data_object={{}}
        required
        template_url={accounts_template_url}
      />,
    ];
    const member_accounts_upload_form_classes: string = "row g-3";
    const member_accounts_upload_form: any = (
      <CreateEditForm
        loading={false}
        form_items={member_accounts_upload_form_items}
        form_classes={member_accounts_upload_form_classes}
        action_object={{}}
        setState={this.updateState}
        save_button_label="Upload"
        action_object_name="member_accounts_upload"
        state={this.state}
        action_object_endpoint={MEMBER_ACCOUNTS_ENDPOINT}
        post_form
        object_display_name="member accounts upload"
        content_type="multipart/form-data; boundary=----"
      />
    );
    const form_items: any = [
      <FormFields
        key={1}
        field_type="member"
        field_label="Member"
        props={props}
        field_name="member"
        wrapper_div_classes="col-4"
        data_object={member_account}
        required
        selected_member={selected_member}
      />,
      <FormFields
        key={2}
        field_type="select"
        field_label="Account type"
        props={props}
        field_name="account_type"
        wrapper_div_classes="col-4"
        data_object={member_account}
        required
        onChange={(e) => this.setState({ account_type: e.target.value })}
      >
        <option value="savings_deposits_account">Savings and deposits</option>
        <option value="shares_account">Shares</option>
      </FormFields>,
      <FormFields
        key={3}
        field_type="select2"
        field_label="Product"
        props={props}
        field_name="account"
        wrapper_div_classes="col-4"
        data_object={member_account}
        required
        select2_value_key={account_type}
        select2_options={product_options}
      />,
      <FormFields
        key={4}
        field_type="text_input"
        field_label="Account number"
        props={props}
        field_name="account_number"
        wrapper_div_classes="col-4"
        autofill_timestamp
        data_object={member_account}
        required
        autofill_prefix="AC-"
      />,
      <FormFields
        key={5}
        field_type="text_input"
        field_label="Account name"
        props={props}
        field_name="account_name"
        wrapper_div_classes="col-4"
        data_object={member_account}
      />,
      <FormFields
        key={6}
        field_type="date"
        field_label="Opening date"
        props={props}
        field_name="opening_date"
        wrapper_div_classes="col-4"
        data_object={member_account}
        required
      />,
      <FormFields
        key={7}
        field_type="select"
        field_label="Status"
        props={props}
        field_name="status"
        wrapper_div_classes="col-4"
        data_object={member_account}
        required
      >
        <option value={1}>Active</option>
        <option value={19}>Closed</option>
      </FormFields>,
    ];
    const form_classes: string = "row g-3";
    return (
      <div>
        <div className="row mb-3">
          <ModalHOC
            button_icon="upload"
            button_label="Upload member accounts"
            button_classes="btn btn-outline-primary"
            modal_title="Upload member accounts"
          >
            {member_accounts_upload_form}
          </ModalHOC>
        </div>
        <CreateEditForm
          loading={
            savings_products_data.isFetching ||
            shares_products_data.isFetching ||
            member_account_data.isFetching
          }
          form_items={form_items}
          form_classes={form_classes}
          action_object={member_account_data.items}
          setState={this.updateState}
          action_object_name="member_account"
          state={this.state}
          action_object_endpoint={MEMBER_ACCOUNTS_ENDPOINT}
          form_id="form-account-details"
          object_display_name="member account"
        />
      </div>
    );
  }
}

RegisterAccountDetails.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  savings_products_data: PropTypes.instanceOf(Object).isRequired,
  shares_products_data: PropTypes.instanceOf(Object).isRequired,
  member_account_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const savings_products_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "savings_products_url"
  );
  const shares_products_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "shares_products_url"
  );
  const member_account_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "member_account_url"
  );

  return {
    sessionVariables,
    dataByUrl,
    savings_products_data,
    shares_products_data,
    member_account_data,
  };
}

export default connect(mapStateToProps)(withRouter(RegisterAccountDetails));
