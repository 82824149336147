import React, { Component } from "react";
import {
  fetchUrlData,
  getUrlData,
  pushHistory,
} from "../../utils/componentActions";

import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import PropTypes from "prop-types";
import { REGISTER_MEMBER_WORK_DETAILS_PATH } from "../../constants/ClientPaths";
import { WORK_DETAILS_ENDPOINT } from "../../constants/Constants";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class RegisterWorkDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      work_detail: {},
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
    };
  }

  componentDidMount() {
    const work_detail_id: any = this.props.match.params.id;
    if (work_detail_id) {
      const work_detail_url: string = `${WORK_DETAILS_ENDPOINT}${work_detail_id}/`;
      fetchUrlData("work_detail_url", work_detail_url, this.props);
    }
  }

  componentDidUpdate(prevProps, prevState, _) {
    const work_detail_data: any = this.props.member_work_details_data;
    const work_detail: any = work_detail_data.items[0] || {};
    const previous_work_detail: any =
      prevProps.member_work_details_data.items[0] || {};
    if (
      Object.keys(work_detail).length > 0 &&
      previous_work_detail.member !== work_detail.member
    ) {
      const work_detail_path: string = `${REGISTER_MEMBER_WORK_DETAILS_PATH}${work_detail.id}`;
      const work_detail_url: string = `${WORK_DETAILS_ENDPOINT}${work_detail.id}/`;
      fetchUrlData("work_detail_url", work_detail_url, this.props);
      pushHistory(work_detail_path, this.props, "soft");
    } else if (
      work_detail_data.lastUpdated !== "" &&
      this.props.match.params.id &&
      Object.keys(work_detail).length === 0
    ) {
      pushHistory(REGISTER_MEMBER_WORK_DETAILS_PATH, this.props, "soft");
    }
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  handleMemberChange = (member_object) => {
    const member_work_details_url: string = `${WORK_DETAILS_ENDPOINT}?member=${member_object.value}`;
    fetchUrlData(
      "member_work_details_url",
      member_work_details_url,
      this.props
    );
  };

  render() {
    const { props } = this;
    const { work_detail_data } = props;
    const { work_detail } = this.state;
    let selected_member;
    if (work_detail.member) {
      selected_member = {
        value: work_detail.member,
        label: work_detail.member_name,
      };
    }
    const form_items: any = [
      <FormFields
        key={1}
        field_type="member"
        field_label="Member"
        props={props}
        field_name="member"
        wrapper_div_classes="col-4"
        data_object={work_detail}
        required
        onChange={this.handleMemberChange}
        selected_member={selected_member}
      />,
      <FormFields
        key={2}
        field_type="text_input"
        field_label="Employer"
        props={props}
        field_name="employer"
        wrapper_div_classes="col-4"
        data_object={work_detail}
        required
      />,
      <FormFields
        key={3}
        field_type="text_input"
        field_label="Official designation"
        props={props}
        field_name="official_designation"
        wrapper_div_classes="col-4"
        data_object={work_detail}
        required
      />,
      <FormFields
        key={4}
        field_type="text_input"
        field_label="PF number"
        props={props}
        field_name="pf_number"
        wrapper_div_classes="col-4"
        data_object={work_detail}
        required
      />,
      <FormFields
        key={5}
        field_type="text_input"
        field_label="Employment terms"
        props={props}
        field_name="terms"
        wrapper_div_classes="col-4"
        data_object={work_detail}
        required
      />,
      <FormFields
        key={6}
        field_type="number_input"
        field_label="Basic salary"
        props={props}
        field_name="basic_salary"
        wrapper_div_classes="col-4"
        data_object={work_detail}
        required
      />,
      <FormFields
        key={7}
        field_type="number_input"
        field_label="Gross salary"
        props={props}
        field_name="gross_salary"
        wrapper_div_classes="col-4"
        data_object={work_detail}
        required
      />,
      <FormFields
        key={8}
        field_type="number_input"
        field_label="Net salary"
        props={props}
        field_name="net_salary"
        wrapper_div_classes="col-4"
        data_object={work_detail}
        required
      />,
      <FormFields
        key={9}
        field_type="date"
        field_label="Date of employment"
        props={props}
        field_name="date_of_employment"
        wrapper_div_classes="col-4"
        data_object={work_detail}
        required
      />,
      <FormFields
        key={10}
        field_type="date"
        field_label="Date of contract expiry"
        props={props}
        field_name="contract_expiry_date"
        wrapper_div_classes="col-4"
        data_object={work_detail}
        required
      />,
    ];
    const form_classes: string = "row g-3";
    return (
      <CreateEditForm
        loading={work_detail_data.isFetching}
        form_items={form_items}
        form_classes={form_classes}
        action_object={work_detail_data.items}
        setState={this.updateState}
        action_object_name="work_detail"
        state={this.state}
        action_object_endpoint={WORK_DETAILS_ENDPOINT}
        object_display_name="work details"
      />
    );
  }
}

RegisterWorkDetails.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  work_detail_data: PropTypes.instanceOf(Object).isRequired,
  member_work_details_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const work_detail_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "work_detail_url"
  );
  const member_work_details_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "member_work_details_url"
  );

  return {
    sessionVariables,
    dataByUrl,
    work_detail_data,
    member_work_details_data,
  };
}

export default connect(mapStateToProps)(withRouter(RegisterWorkDetails));
