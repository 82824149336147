import PropTypes from "prop-types";
import React, { Component } from "react";
import FileViewer from "react-file-viewer";
import ImgsViewer from "react-images-viewer";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { PDFReader } from "reactjs-pdf-reader";
import BootstrapIcon from "./BootstrapIcon";
import ComponentLoadingIndicator from "./ComponentLoadingIndicator";
import ModalHOC from "./ModalHOC";
import { getUrlData, label } from "../utils/componentActions";

class FileViewerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_open: false,
    };
  }

  handleShowImage = () => {
    this.setState({
      modal_open: true,
    });
  };

  handleCloseImage = () => {
    this.setState({
      modal_open: false,
    });
  };

  spinnerComponent() {
    return <ComponentLoadingIndicator />;
  }

  render() {
    const { props } = this;
    const { data_target, file_url, view_button_label } = props;
    let file_modal;
    let file_type;
    if (file_url) {
      let file_url_array: any = file_url.split(".");
      file_type = file_url_array[file_url_array.length - 1]
        .substring(0, 3)
        .toLowerCase();
      let file_viewer;
      if (file_type === "pdf") {
        file_viewer = <PDFReader url={file_url} />;
      } else if (["png", "jpg", "gif", "bmp"].includes(file_type)) {
        file_viewer = (
          <ImgsViewer
            imgs={[
              {
                src: file_url,
              },
            ]}
            isOpen={this.state.modal_open}
            onClose={this.handleCloseImage}
            spinner={this.spinnerComponent}
          />
        );
      } else {
        file_viewer = (
          <FileViewer
            fileType={file_type}
            filePath={file_url}
            onError={(e) => console.log(e)}
          />
        );
      }
      file_modal = ["png", "jpg", "gif", "bmp"].includes(file_type) ? (
        file_viewer
      ) : (
        <ModalHOC
          modal_only={true}
          modal_title="View file"
          data_target={data_target}
          modal_size="modal-xl"
        >
          {file_viewer}
        </ModalHOC>
      );
    } else {
      file_modal = (
        <ModalHOC
          modal_only
          modal_title="View file"
          data_target={data_target}
          modal_size="modal-xl"
        />
      );
    }
    let modal_button: any = (
      <ModalHOC
        button_only
        button_icon="eye"
        button_classes="btn btn-outline-primary"
        button_label={view_button_label}
        data_target={data_target}
      />
    );
    if (["png", "jpg", "gif", "bmp"].includes(file_type)) {
      modal_button = (
        <button
          className="btn btn-outline-primary"
          onClick={this.handleShowImage}
        >
          <BootstrapIcon icon="eye" /> {label(view_button_label, this.props)}
        </button>
      );
    }
    return (
      <div className="d-flex">
        <div className="mr-3">{modal_button}</div>
        <div className="mr-3">
          <a
            href={file_url}
            target="_blank"
            className="btn btn-outline-primary"
          >
            <BootstrapIcon icon="download" /> {label("Download", this.props)}
          </a>
        </div>
        {file_modal}
      </div>
    );
  }
}

FileViewerModal.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  file_url: PropTypes.string,
  data_target: PropTypes.string,
  view_button_label: PropTypes.string,
};

FileViewerModal.defaultProps = {
  data_target: "view-file",
  view_button_label: "View file",
};

/**
 * Maps state to props.
 * @param {Object} state
 * @return {Object}
 */
function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(FileViewerModal));
