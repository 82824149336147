import {
  MEMBER_REPAYMENT_SCHEDULE_ENDPOINT,
  REPAYMENT_SCHEDULE_ENDPOINT,
} from "../../constants/Constants";
import {
  VIEW_LOAN_REPAYMENT_SCHEDULE_COLUMNS,
  VIEW_LOAN_REPAYMENT_SCHEDULE_HEADERS,
} from "../../components/DataTableColumns";

import PropTypes from "prop-types";
import React from "react";
import ViewDataTable from "../../components/ViewDataTable";
import { withRouter } from "react-router";

function ViewLoanRepaymentSchedule(props) {
  const { match } = props;
  const { params } = match;
  const { id } = params;
  const { member, auth_token } = localStorage;
  let loan_repayment_schedule_url: string = `${REPAYMENT_SCHEDULE_ENDPOINT}?loan=${id}&ordering=date`;
  if (member) {
    loan_repayment_schedule_url = `${MEMBER_REPAYMENT_SCHEDULE_ENDPOINT}?token=${auth_token}&member=${member}&loan=${id}&ordering=date`;
  }
  return (
    <div className="row">
      <div className="col table-responsive">
        <ViewDataTable
          allow_refresh
          allow_print
          basic_table
          paginated={false}
          totals
          columns={VIEW_LOAN_REPAYMENT_SCHEDULE_COLUMNS}
          headers={VIEW_LOAN_REPAYMENT_SCHEDULE_HEADERS}
          data_source_url={loan_repayment_schedule_url}
          data_source_url_name="loan_repayment_schedule_url"
        />
      </div>
    </div>
  );
}

ViewLoanRepaymentSchedule.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(ViewLoanRepaymentSchedule);
