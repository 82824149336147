import React, { Component } from "react";

import FormChangePassword from "./FormChangePassword";
import FormFeedbackMessage from "../components/FormFeedbackMessage";
import FormLogin from "./FormLogin";
import FormOTP from "./FormOTP";
import PackageDowngrade from "./PackageDowngrade";
import { withRouter } from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
      password_change: false,
      otp_prompt: false,
      package_downgrade: false,
      username: null,
      password: null,
    };
  }

  handleUpdateState = (state_object, callback = null) => {
    this.setState(state_object);
    if (callback) {
      callback();
    }
  };

  render() {
    let feedback_message: string = "";
    if (this.state.feedback_message) {
      feedback_message = (
        <FormFeedbackMessage
          feedback_message={this.state.feedback_message}
          feedback_type={this.state.feedback_type}
        />
      );
    }
    let form_title: string = "Login";
    let active_form: any = (
      <FormLogin
        handleSubmit={(e) => this.handleSubmitLogin(e)}
        handleUpdateState={this.handleUpdateState}
        activity={this.state.activity}
      />
    );
    if (this.state.otp_prompt) {
      form_title = "OTP";
      active_form = (
        <FormOTP
          handleSubmit={(e) => this.handleSubmitLogin(e)}
          activity={this.state.activity}
          handleUpdateState={this.handleUpdateState}
          username={this.state.username}
          password={this.state.password}
        />
      );
    } else if (this.state.password_change) {
      form_title = "Change Password";
      active_form = (
        <FormChangePassword
          handleSubmit={(e) => this.handleSubmitLogin(e)}
          activity={this.state.activity}
          username={this.state.username}
          handleUpdateState={this.handleUpdateState}
        />
      );
    } else if (this.state.package_downgrade) {
      form_title = "Package Subscription";
      active_form = <PackageDowngrade />;
    }
    return (
      <div
        className="container-fluid vw-100 vh-100 row
             justify-content-center align-content-center mx-0"
      >
        <div className="col-md-6">
          <div className="card">
            <div className="card-header bg-green text-center p-2">
              <span className="h4 font-weight-bold text-white">
                {form_title}
              </span>
            </div>
            <div className="card-body">
              {feedback_message}
              {active_form}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
