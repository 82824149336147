import React, { Component } from "react";

import { PROCESSED_LOANS_COLUMNS } from "../../components/DataTableColumns";
import ViewAppliedLoans from "./ViewAppliedLoans";
import { withRouter } from "react-router-dom";

class ViewProcessedLoans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 5,
    };
  }

  render() {
    return <ViewAppliedLoans status={11} columns={PROCESSED_LOANS_COLUMNS} />;
  }
}

export default withRouter(ViewProcessedLoans);
