import React, { Component } from "react";

import { LOAN_PORTFOLIO_SUMMARY_ENDPOINT } from "../../constants/Constants";
import { LOAN_PORTFOLIO_SUMMARY_FILTERS } from "../../components/DataTableFilterFields";
import {
  LOAN_PORTFOLIO_SUMMARY_COLUMNS,
  LOAN_PORTFOLIO_SUMMARY_HEADERS,
} from "../../components/DataTableColumns";
import ViewDataTable from "../../components/ViewDataTable";

/**
 * LoanPortfolioSummary component
 * @extends Component
 */

class LoanPortfolioSummary extends Component {
  /**
   * Renders LoanPortfolioSummary component
   * @return {ReactElement} LoanPortfolioSummary component markup
   */
  render() {
    return (
      <ViewDataTable
        table_filters={LOAN_PORTFOLIO_SUMMARY_FILTERS}
        data_source_url={LOAN_PORTFOLIO_SUMMARY_ENDPOINT}
        headers={LOAN_PORTFOLIO_SUMMARY_HEADERS}
        columns={LOAN_PORTFOLIO_SUMMARY_COLUMNS}
        totals
        basic_table
        paginated={false}
        allow_print
        allow_refresh
        wait_for_props
        wrapper_class="table-responsive"
      />
    );
  }
}

export default LoanPortfolioSummary;
