import {
  API_ENDPOINT,
  APPLIED_LOANS_ENDPOINT,
  MEMBER_APPLIED_LOANS_ENDPOINT,
} from "../../constants/Constants";
import React, { Component } from "react";
import {
  extractResponseError,
  formDataToJSON,
  getUrlData,
  label,
  thousandsFormat,
} from "../../utils/componentActions";

import $ from "jquery";
import BootstrapIcon from "../../components/BootstrapIcon";
import ComponentLoadingIndicator from "../../components/ComponentLoadingIndicator";
import FormFeedbackMessage from "../../components/FormFeedbackMessage";
import PropTypes from "prop-types";
import ReactToPrint from "react-to-print";
import { connect } from "react-redux";
import { getAPIRequest } from "../../utils/APIRequests";
import moment from "moment";
import queryString from "query-string";
import { withRouter } from "react-router";

class PreviewRepaymentSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      repayment_schedule_preview: [],
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
    };
  }

  handlePreviewSchedule(e) {
    e.preventDefault();
    this.setState({
      activity: true,
    });
    let payload: any = this.props.payload || {};
    payload = { ...payload };
    const form_id: any = this.props.form_id || "form-object-action";
    const formData: any = new FormData($(`form#${form_id}`)[0]);
    payload = formDataToJSON(formData, payload);
    let charges: any = payload.charges.join(",");
    if (charges === "") {
      charges = " ";
    }
    const query_payload: any = {
      check_schedule: true,
      loan_type: payload.loan_type,
      amount: payload.amount,
      no_of_repayments: payload.no_of_repayments,
      charges,
    };
    const query_params: any = queryString.stringify(query_payload);
    let applied_loans_endpoint: any = APPLIED_LOANS_ENDPOINT;
    if (localStorage.member) {
      applied_loans_endpoint = MEMBER_APPLIED_LOANS_ENDPOINT;
    }
    const repayment_schedule_preview_url: string = `${API_ENDPOINT}${applied_loans_endpoint}?${query_params}`;
    getAPIRequest(
      repayment_schedule_preview_url,
      (results) => {
        this.setState({
          activity: false,
          repayment_schedule_preview: results[payload.no_of_repayments],
          feedback_message: null,
        });
      },
      (results) => {
        const feedback_message: any = extractResponseError(results);
        this.setState({
          activity: false,
          feedback_message,
          feedback_type: "danger",
        });
      },
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
      }
    );
  }

  render() {
    const { repayment_schedule_preview } = this.state;
    const feedback_message: any = (
      <FormFeedbackMessage
        feedback_message={this.state.feedback_message}
        feedback_type={this.state.feedback_type}
      />
    );
    let total_principal_due: number = 0;
    let total_interest: number = 0;
    let total_fees: number = 0;
    let total_due: number = 0;
    const repayment_schedule_row: any = repayment_schedule_preview.map(
      (schedule, key) => {
        total_principal_due += schedule.principal_due || 0;
        total_interest += schedule.interest || 0;
        total_fees += schedule.fees || 0;
        total_due += schedule.due || 0;
        return (
          <tr key={key}>
            <td>{moment(schedule.date).format("DD-MMM-YYYY")}</td>
            <td>{thousandsFormat(schedule.principal_due)}</td>
            <td>{thousandsFormat(schedule.balance_of_loan)}</td>
            <td>{thousandsFormat(schedule.interest)}</td>
            <td>{thousandsFormat(schedule.fees)}</td>
            <td>{thousandsFormat(schedule.due)}</td>
          </tr>
        );
      }
    );
    let repayment_schedule_table: any = (
      <table
        className="table table-hover table-bordered"
        ref={(el) => (this.preview_schedule_table = el)}
      >
        <thead>
          <tr>
            <th className="text-grayish">{label("Date", this.props)}</th>
            <th className="text-grayish">
              {label("Principal due", this.props)}
            </th>
            <th className="text-grayish">
              {label("Loan balance", this.props)}
            </th>
            <th className="text-grayish">{label("Interest", this.props)}</th>
            <th className="text-grayish">{label("Fees", this.props)}</th>
            <th className="text-grayish">{label("Due", this.props)}</th>
          </tr>
        </thead>
        <tbody>{repayment_schedule_row}</tbody>
        <tfoot>
          <tr>
            <td />
            <td className="text-grayish">
              {thousandsFormat(total_principal_due)}
            </td>
            <td />
            <td className="text-grayish">{thousandsFormat(total_interest)}</td>
            <td className="text-grayish">{thousandsFormat(total_fees)}</td>
            <td className="text-grayish">{thousandsFormat(total_due)}</td>
          </tr>
        </tfoot>
      </table>
    );
    if (this.state.activity) {
      repayment_schedule_table = <ComponentLoadingIndicator />;
    }
    return (
      <>
        <div className="col-12">
          <div className="col-4">
            <button
              className="btn btn-outline-primary"
              onClick={(e) => this.handlePreviewSchedule(e)}
              data-toggle="modal"
              data-target="#preview-schedule-modal"
            >
              <BootstrapIcon icon="eye" />{" "}
              {label("Preview repayment schedule", this.props)}
            </button>
          </div>
        </div>
        <div
          className="modal fade"
          id="preview-schedule-modal"
          data-backdrop="static"
          data-keyboard="false"
          tabIndex="-1"
          aria-labelledby="preview-schedule-label"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="preview-schedule-label">
                  {label("Repayment schedule", this.props)}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <ReactToPrint
                  trigger={() => (
                    <button
                      className="btn btn-outline-primary mb-3"
                      type="button"
                    >
                      {label("Print", this.props)}{" "}
                      <BootstrapIcon icon="printer" classes="text-primary" />
                    </button>
                  )}
                  content={() => this.preview_schedule_table}
                />
                {feedback_message}
                {repayment_schedule_table}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  {label("Close", this.props)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

PreviewRepaymentSchedule.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(PreviewRepaymentSchedule));
