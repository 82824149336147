import {
  APPLIED_LOANS_ENDPOINT,
  LOAN_CHARGES_ENDPOINT,
} from "../../../constants/Constants";
import React, { Component } from "react";
import {
  deepCompare,
  fetchUrlData,
  getSelect2Options,
  getUrlData,
} from "../../../utils/componentActions";

import CreateEditForm from "../../../components/CreateEditForm";
import FormFields from "../../../components/FormFields";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import merge from "lodash/merge";
import moment from "moment";
import { withRouter } from "react-router";

class ReconstructSchedule extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      reconstruct_statement: {
        do_in_background: true,
      },
    };
    this.state = merge({}, this.initialState);
  }

  componentDidMount() {
    const applied_loan_url: string = `${APPLIED_LOANS_ENDPOINT}${this.props.loan_id}/`;
    fetchUrlData("applied_loan_url", applied_loan_url, this.props);
    fetchUrlData("loan_charges_url", LOAN_CHARGES_ENDPOINT, this.props);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    const { applied_loan_data } = nextProps;
    const previous_applied_loan_data: any = this.props.applied_loan_data;
    const applied_loan: any = applied_loan_data.items;
    const is_fetching: any = applied_loan_data.isFetching;
    const previous_applied_loan: any = previous_applied_loan_data.items;
    const different_loan_account: any = !deepCompare(
      previous_applied_loan,
      applied_loan
    );
    const { reconstruct_statement } = nextState;
    const previous_reconstruct_statement: any =
      this.state.reconstruct_statement;
    if (!is_fetching) {
      if (
        !Object.keys(reconstruct_statement).includes("charges") ||
        different_loan_account
      ) {
        reconstruct_statement.charges = applied_loan.charges;
      }
      if (
        !Object.keys(reconstruct_statement).includes(
          "date_of_next_repayment"
        ) ||
        different_loan_account
      ) {
        let date_of_next_repayment: any = moment(
          applied_loan.disbursement_date
        ).add(applied_loan.days_before_first_repayment, "days");
        if (applied_loan.grace_period > 0) {
          date_of_next_repayment = moment(applied_loan.disbursement_date).add(
            applied_loan.grace_period,
            "days"
          );
        }
        reconstruct_statement.date_of_next_repayment = date_of_next_repayment;
      }
      if (
        !Object.keys(reconstruct_statement).includes("no_of_repayments") ||
        different_loan_account
      ) {
        reconstruct_statement.no_of_repayments = applied_loan.no_of_repayments;
      }
      if (
        !deepCompare(reconstruct_statement, previous_reconstruct_statement) ||
        different_loan_account
      ) {
        this.setState({
          reconstruct_statement,
        });
      }
    }
  }

  updateState = (state_object) => {
    const { reconstruct_statement } = state_object;
    if (reconstruct_statement.charges === null) {
      reconstruct_statement.charges = [];
      state_object.reconstruct_statement = reconstruct_statement;
    }
    this.setState(state_object);
  };

  render() {
    const form_classes: string = "row g-1";
    const { props } = this;
    const { loan_charges_data } = props;
    const { reconstruct_statement } = this.state;
    const loan_charges: any = loan_charges_data.items;
    const loan_charge_options: any = getSelect2Options(
      loan_charges,
      "id",
      "name"
    );
    const applied_loan_url: string = `${APPLIED_LOANS_ENDPOINT}${this.props.loan_id}/`;
    const extra_form_data: any = [
      { name: "reconstruct_statement", value: true },
    ];
    const form_items: any = [
      <FormFields
        key={1}
        field_type="date"
        field_label="First repayment date"
        props={props}
        required
        field_name="date_of_next_repayment"
        wrapper_div_classes="col-12"
        update_payload
        data_object_name="reconstruct_statement"
        updateState={this.updateState}
        data_object={reconstruct_statement}
      />,
      <FormFields
        key={2}
        field_type="number_input"
        field_label="No of repayments"
        props={props}
        required
        field_name="no_of_repayments"
        wrapper_div_classes="col-12"
        update_payload
        data_object_name="reconstruct_statement"
        updateState={this.updateState}
        data_object={reconstruct_statement}
      />,
      <FormFields
        key={3}
        field_type="select2_multi"
        field_label="Charges"
        props={props}
        field_name="charges"
        wrapper_div_classes="col-12"
        select2_options={loan_charge_options}
        update_payload
        updateState={this.updateState}
        data_object_name="reconstruct_statement"
        data_object={reconstruct_statement}
      />,
      <FormFields
        key={4}
        field_type="checkbox"
        checkbox_field_label="Override rollovers?"
        props={props}
        field_name="override_rollovers"
        wrapper_div_classes="col-12"
        checkbox_checked
        update_payload
        data_object_name="reconstruct_statement"
        updateState={this.updateState}
        data_object={reconstruct_statement}
      />,
      <FormFields
        key={5}
        field_type="checkbox"
        checkbox_field_label="Do in background?"
        props={props}
        field_name="do_in_background"
        wrapper_div_classes="col-12"
        checkbox_checked
        update_payload
        data_object_name="reconstruct_statement"
        updateState={this.updateState}
        data_object={reconstruct_statement}
      />,
    ];
    return (
      <CreateEditForm
        loading={false}
        form_items={form_items}
        form_classes={form_classes}
        action_object={reconstruct_statement}
        setState={this.updateState}
        action_object_name="reconstruct_statement"
        state={this.state}
        action_object_endpoint={applied_loan_url}
        payload={reconstruct_statement}
        request_method="PATCH"
        form_id="reconstruct-statement"
        datatable_source_url={applied_loan_url}
        save_button_label="Reconstruct statement"
        extra_form_data={extra_form_data}
        datatable_source_url_name="applied_loan_url"
        object_display_name="loan"
      />
    );
  }
}

ReconstructSchedule.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  applied_loan_data: PropTypes.instanceOf(Object).isRequired,
  loan_charges_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const applied_loan_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "applied_loan_url"
  );
  const loan_charges_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "loan_charges_url"
  );

  return {
    sessionVariables,
    applied_loan_data,
    loan_charges_data,
  };
}

export default connect(mapStateToProps)(withRouter(ReconstructSchedule));
