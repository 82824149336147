import {
  APPLIED_LOANS_ENDPOINT,
  LOAN_CHARGES_ENDPOINT,
  REPAYMENT_SCHEDULE_ENDPOINT,
} from "../../../constants/Constants";
import React, { Component } from "react";
import {
  fetchUrlData,
  getSelect2Options,
  getUrlData,
} from "../../../utils/componentActions";

import AddChargeFields from "./AddChargeFields";
import CreateEditForm from "../../../components/CreateEditForm";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import merge from "lodash/merge";
import { withRouter } from "react-router";

class AddCharge extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      add_charge: {},
    };
    this.state = merge({}, this.initialState);
  }

  componentDidMount() {
    const loan_repayment_schedule_url: string = `${REPAYMENT_SCHEDULE_ENDPOINT}?loan=${this.props.loan_id}`;
    fetchUrlData("loan_charges_url", LOAN_CHARGES_ENDPOINT, this.props);
    fetchUrlData(
      "loan_repayment_schedule_url",
      loan_repayment_schedule_url,
      this.props
    );
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  resetState = () => {
    const loan_repayment_schedule_url: string = `${REPAYMENT_SCHEDULE_ENDPOINT}?loan=${this.props.loan_id}`;
    fetchUrlData(
      "loan_repayment_schedule_url",
      loan_repayment_schedule_url,
      this.props,
      true
    );
    const state_object: any = merge({}, this.initialState);
    const { add_charge } = state_object;
    add_charge.reset_fields = true;
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const { loan_charges_data, loan_repayment_schedule_data } = props;
    let loan_repayment_schedule: any = loan_repayment_schedule_data.items;
    loan_repayment_schedule = loan_repayment_schedule.filter(
      (schedule) => schedule.paid === 0 && schedule.repayment_no
    );
    const submit_button_disabled: any = !loan_repayment_schedule.length > 0;
    let feedback_message: object = null;
    if (loan_repayment_schedule.length === 0) {
      feedback_message = "There are no unpaid installments to add charge";
    }
    const loan_repayment_schedule_options: any = getSelect2Options(
      loan_repayment_schedule,
      "date",
      "date"
    );
    loan_repayment_schedule_options.unshift({ value: "all", label: "All" });
    const form_classes: string = "row g-1";
    const { add_charge } = this.state;
    const applied_loan_url: string = `${APPLIED_LOANS_ENDPOINT}${props.loan_id}/`;
    const form_items: any = [
      <AddChargeFields
        key={1}
        add_charge={add_charge}
        loan_repayment_schedule_options={loan_repayment_schedule_options}
      />,
    ];
    return (
      <CreateEditForm
        loading={
          loan_charges_data.isFetching ||
          loan_repayment_schedule_data.isFetching
        }
        form_items={form_items}
        form_classes={form_classes}
        action_object={add_charge}
        setState={this.updateState}
        action_object_name="add_charge"
        state={this.state}
        action_object_endpoint={applied_loan_url}
        payload={add_charge}
        resetState={this.resetState}
        feedback_message={feedback_message}
        request_method="PATCH"
        form_id="add-charge"
        submit_button_disabled={submit_button_disabled}
        datatable_source_url={applied_loan_url}
        datatable_source_url_name="applied_loan_url"
        object_display_name="charge"
      />
    );
  }
}

AddCharge.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  loan_charges_data: PropTypes.instanceOf(Object).isRequired,
  loan_repayment_schedule_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const loan_charges_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "loan_charges_url"
  );
  const loan_repayment_schedule_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "loan_repayment_schedule_url"
  );

  return {
    sessionVariables,
    loan_charges_data,
    loan_repayment_schedule_data,
  };
}

export default connect(mapStateToProps)(withRouter(AddCharge));
