import { MEMBER_ACTIVE_LOANS_COLUMNS } from "../components/DataTableColumns";
import MemberViewAppliedLoans from "./ViewAppliedLoans";
import React from "react";
import { withRouter } from "react-router-dom";

function MemberViewActiveLoans() {
  return (
    <MemberViewAppliedLoans
      status={1}
      columns={MEMBER_ACTIVE_LOANS_COLUMNS}
      viewable
    />
  );
}

export default withRouter(MemberViewActiveLoans);
