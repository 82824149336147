import React, { Component } from "react";
import BillingSubscriptionForm from "./BillingSubscriptionForm";
import {
  BILLING_INVOICES_ENDPOINT,
  BILLING_SUBSCRIPTION_ENDPOINT,
} from "../../constants/Constants";
import { BILLING_INVOICES_COLUMNS } from "../../components/DataTableColumns";
import ModalHOC from "../../components/ModalHOC";
import PageLoadingIndicator from "../../components/PageLoadingIndicator";
import ViewDataTable from "../../components/ViewDataTable";
import { fetchUrlData, getUrlData, label } from "../../utils/componentActions";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class BillingInvoices extends Component {
  componentDidMount() {
    fetchUrlData(
      "billing_subscription_url",
      BILLING_SUBSCRIPTION_ENDPOINT,
      this.props
    );
  }

  getInterval = (interval_count) => {
    if (interval_count > 1) {
      return label("months", this.props);
    } else {
      return label("month", this.props);
    }
  };

  render() {
    const { props } = this;
    const { subscription_data } = props;
    const { isFetching, lastUpdated } = subscription_data;

    if (isFetching || (!isFetching && lastUpdated === "")) {
      return <PageLoadingIndicator />;
    }

    const subscription = subscription_data.items;
    const plan = subscription.plan;

    return (
      <>
        <div className="row">
          <div className="col col-md-4">
            <div className="card">
              <div className="card-body text-center">
                <p>{label("Current subscription", props)}</p>

                <p>
                  {subscription.meta.users} {label("User(s)", props)}
                </p>

                <p>
                  {plan.currency} {plan.amount} /{" "}
                  {plan.interval_count > 1 && plan.interval_count}{" "}
                  {plan.interval}
                  {plan.interval_count > 1 && "s"}
                </p>

                <ModalHOC
                  button_label="Change subscription"
                  button_icon="bi-trash"
                  button_classes="btn btn-success"
                  data_target="change-subscription"
                  button_only
                />

                <ModalHOC
                  modal_only
                  modal_title="Change subscription"
                  data_target="change-subscription"
                >
                  <BillingSubscriptionForm
                    users={subscription.meta.users}
                    interval_count={plan.interval_count}
                    subscription={subscription.id}
                  />
                </ModalHOC>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <ViewDataTable
            paginated
            allow_refresh
            csv_export={false}
            columns={BILLING_INVOICES_COLUMNS}
            data_source_url={BILLING_INVOICES_ENDPOINT}
            data_source_url_name="billing_invoices_url"
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const subscription_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "billing_subscription_url"
  );

  return {
    sessionVariables,
    dataByUrl,
    subscription_data,
  };
};

export default connect(mapStateToProps)(withRouter(BillingInvoices));
