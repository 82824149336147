import {
  CLIENT_PAYMENTS_ENDPOINT,
  LOAN_ACCOUNT_STATEMENT_ENDPOINT,
  LOAN_ACCOUNT_TRANSACTIONS_ENDPOINT,
  LOAN_CLIENT_STATEMENT_ENDPOINT,
  MEMBER_LOAN_ACCOUNT_TRANSACTIONS_ENDPOINT,
  REPAYMENT_SCHEDULE_ENDPOINT,
} from "../../constants/Constants";
import React, { Component } from "react";
import {
  VIEW_LOAN_ACCOUNT_TRANSACTIONS_COLUMNS,
  VIEW_LOAN_TRANSACTION_JOURNALS_COLUMNS,
} from "../../components/DataTableColumns";

import DataTableActionForm from "../../components/DataTableActionForm";
import FormFields from "../../components/FormFields";
import ModalHOC from "../../components/ModalHOC";
import PropTypes from "prop-types";
import ViewDataTable from "../../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ViewLoanAccountTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_row_obj: {},
    };
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  handleTableRowSelect = (row) => {
    this.setState({
      selected_row_obj: row,
    });
  };

  render() {
    const loan_id: any = this.props.match.params.id;
    let loan_transactions_url: string = `${LOAN_ACCOUNT_TRANSACTIONS_ENDPOINT}?loan=${loan_id}`;
    const { member, auth_token } = localStorage;
    if (member) {
      loan_transactions_url = `${MEMBER_LOAN_ACCOUNT_TRANSACTIONS_ENDPOINT}?token=${auth_token}&member=${member}&loan=${loan_id}`;
    }
    const data_source_url_name: string = "loan_transactions_url";
    const { selected_row_obj } = this.state;
    const form_items: any = [
      <FormFields
        key={1}
        field_type="textarea"
        field_label="Reversal reason"
        props={this.props}
        field_name="reverse_reason"
        wrapper_div_classes="col-4 pb-3"
        data_object={{}}
        required
        autofocus
      />,
    ];
    let actions: any = [
      {
        name: "reverse",
        btn_classes: "btn btn-danger",
        noun: "reversal",
        icon: "x",
        target: "transaction",
        plural: "transactions",
        form_items,
        multiple_form_items: form_items,
        extra_form_data: [
          { name: "status", value: 12, type: "int" },
          { name: "loan_payment", value: true },
        ],
      },
    ];
    const fetch_data_urls: any = [
      {
        url_name: "loan_repayment_schedule_url",
        url: `${REPAYMENT_SCHEDULE_ENDPOINT}?loan=${loan_id}`,
      },
      {
        url_name: "loan_account_statement_url",
        url: `${LOAN_ACCOUNT_STATEMENT_ENDPOINT}?loan=${loan_id}`,
      },
      {
        url_name: "loan_client_statement_url",
        url: `${LOAN_CLIENT_STATEMENT_ENDPOINT}?loan=${loan_id}`,
      },
    ];
    if (member) {
      actions = [];
    }
    return (
      <DataTableActionForm
        actions={actions}
        table_node={this.state.table_node}
        datatable_action_endpoint={CLIENT_PAYMENTS_ENDPOINT}
        request_method="PATCH"
        datatable_source_url={loan_transactions_url}
        fetch_data_urls={fetch_data_urls}
        datatable_source_url_name={data_source_url_name}
      >
        <ViewDataTable
          handleSetTableNode={this.handleSetTableNode}
          allow_refresh
          handleTableRowSelect={this.handleTableRowSelect}
          paginated={false}
          select_mode="radio"
          clickToSelect
          columns={VIEW_LOAN_ACCOUNT_TRANSACTIONS_COLUMNS}
          data_source_url={loan_transactions_url}
          data_source_url_name={data_source_url_name}
        />
        <ModalHOC
          modal_only
          modal_title="Journal entries"
          data_target="view-journal-entries"
          modal_size="modal-xl"
        >
          <ViewDataTable
            paginated={false}
            basic_table
            totals
            table_node_name="loan_account_transactions"
            component_data_provided
            display_tenant_header={false}
            columns={VIEW_LOAN_TRANSACTION_JOURNALS_COLUMNS}
            csv_export={false}
            data_items={selected_row_obj.related_transactions || []}
          />
        </ModalHOC>
      </DataTableActionForm>
    );
  }
}

ViewLoanAccountTransactions.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(
  withRouter(ViewLoanAccountTransactions)
);
