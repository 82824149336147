import {
  APPLIED_LOANS_ENDPOINT,
  LOAN_PRODUCTS_ENDPOINT,
} from "../../constants/Constants";
import React, { Component } from "react";
import {
  fetchUrlData,
  getFieldFromListObj,
  getSelect2Options,
  getUrlData,
} from "../../utils/componentActions";

import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import PreviewRepaymentSchedule from "../view/PreviewRepaymentSchedule";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class LoanApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applied_loan: {},
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
      charges: null,
    };
  }

  componentDidMount() {
    fetchUrlData("loan_products_url", LOAN_PRODUCTS_ENDPOINT, this.props);
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const { loan_products_data } = props;
    const loan_products: any = loan_products_data.items;
    const loan_product_options: any = getSelect2Options(
      loan_products,
      "id",
      "product_name"
    );
    const form_classes: string = "row g-3";
    const { applied_loan } = this.state;
    const { selected_product } = this.state;
    let charges: null[] = [];
    const extra_form_data: null[] = [];
    if (selected_product) {
      charges = getFieldFromListObj(
        loan_products,
        "charges_list",
        "id",
        selected_product.value
      );
      if (this.state.charges) {
        applied_loan.charges = this.state.charges;
      } else {
        applied_loan.charges = getFieldFromListObj(
          loan_products,
          "charges",
          "id",
          selected_product.value
        );
      }
      extra_form_data.push({
        name: "charges",
        value: applied_loan.charges,
        type: "array",
      });
    }
    const charges_options_value_field: string =
      "[name] - [charge_time_type] - [charge_calculation] - Amount [amount]";
    const charges_options_field_map: string[] = [
      "name",
      "charge_time_type",
      "charge_calculation",
      "amount",
    ];
    const charges_options: any = getSelect2Options(
      charges,
      "id",
      charges_options_value_field,
      charges_options_field_map
    );
    const form_items: any = [
      <FormFields
        key={1}
        field_type="member"
        field_label="Member"
        props={props}
        field_name="member"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
        required
      />,
      <FormFields
        key={2}
        field_type="text_input"
        field_label="Loan reference no"
        props={props}
        field_name="loan_reference_no"
        wrapper_div_classes="col-4"
        autofill_prefix="LNR-"
        data_object={applied_loan}
        required
        autofill_timestamp
      />,
      <FormFields
        key={3}
        field_type="select2"
        field_label="Loan product"
        props={props}
        field_name="loan_type"
        wrapper_div_classes="col-4"
        onChange={(selected) =>
          this.updateState({ selected_product: selected })
        }
        data_object={applied_loan}
        required
        select2_options={loan_product_options}
      />,
      <FormFields
        key={15}
        field_type="text_input"
        field_label="Loan purpose"
        props={props}
        field_name="loan_purpose"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
      />,
      <FormFields
        key={4}
        field_type="select2_multi"
        field_label="Charges"
        props={props}
        field_name="charges"
        wrapper_div_classes="col-4"
        onChange={(selected, selected_ids) =>
          this.setState({
            charges: selected_ids,
          })
        }
        data_object={applied_loan}
        select2_options={charges_options}
      />,
      <FormFields
        key={5}
        field_type="number_input"
        field_label="Amount"
        props={props}
        field_name="amount"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
        required
      />,
      <FormFields
        key={6}
        field_type="number_input"
        field_label="No of repayments"
        props={props}
        field_name="no_of_repayments"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
        required
      />,
      <FormFields
        key={7}
        field_type="date"
        field_label="Date of loan application"
        props={props}
        field_name="date_of_loan_application"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
        required
      />,
      <FormFields
        key={8}
        field_type="date"
        field_label="Date of first repayment"
        props={props}
        field_name="date_of_first_repayment"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
        required
      />,
      <FormFields
        key={9}
        field_type="number_input"
        field_label="Interest rate"
        props={props}
        field_name="interest_rate"
        wrapper_div_classes="col-4"
        step="0.01"
        data_object={applied_loan}
      />,
      <FormFields
        key={10}
        field_type="file"
        field_label="Application document"
        props={props}
        field_name="application_document"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
      />,
      <FormFields
        key={11}
        field_type="number_input"
        field_label="Repaid on"
        props={props}
        field_name="repaid_on"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
      />,
      <FormFields
        key={12}
        field_type="select"
        field_label="Is a top-up?"
        props={props}
        field_name="topup"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
      >
        <option value="0">No</option>
        <option value="1">Yes</option>
      </FormFields>,
      <FormFields
        key={13}
        field_type="select"
        field_label="Create standing order?"
        props={props}
        field_name="create_repayment_standing_order"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <PreviewRepaymentSchedule
        key={14}
        payload={applied_loan}
        form_id="form-object-action"
      />,
    ];
    return (
      <CreateEditForm
        loading={loan_products_data.isFetching}
        form_items={form_items}
        form_classes={form_classes}
        action_object={applied_loan}
        setState={this.updateState}
        content_type="multipart/form-data; boundary=----"
        action_object_name="applied_loan"
        state={this.state}
        action_object_endpoint={APPLIED_LOANS_ENDPOINT}
        post_form
        object_display_name="applied loan"
        payload={applied_loan}
        extra_form_data={extra_form_data}
        overwrite_form_data={[]}
      />
    );
  }
}

LoanApplication.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  loan_products_data: PropTypes.instanceOf(Object).isRequired,
};

function matchStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const loan_products_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "loan_products_url"
  );

  return {
    sessionVariables,
    loan_products_data,
  };
}

export default connect(matchStateToProps)(withRouter(LoanApplication));
