import { applyMiddleware, createStore } from "redux";

import { createLogger } from "redux-logger";
import rootReducer from "./reducers/reducers";
import thunkMiddleware from "redux-thunk";

const loggerMiddleware: any = createLogger();

export default function configureStore(preloadedState) {
  return process.env.NODE_ENV === "development"
    ? createStore(
        rootReducer,
        preloadedState,
        applyMiddleware(thunkMiddleware, loggerMiddleware)
      )
    : createStore(
        rootReducer,
        preloadedState,
        applyMiddleware(thunkMiddleware)
      );
}
