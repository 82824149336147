import React, { Component } from "react";
import { MPAY_SAGE_INTEGRATIONS_COLUMNS } from "../components/DataTableColumns";
import { MPAY_SAGE_INTEGRATIONS_ENDPOINT } from "../constants/Constants";
import PropTypes from "prop-types";
import DataTableActionForm from "../components/DataTableActionForm";
import FormFields from "../components/FormFields";
import ViewDataTable from "../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class SageIntegrations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_row_obj: {},
    };
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  handleTableRowSelect = (row) => {
    this.setState({
      selected_row_obj: row,
    });
  };

  render() {
    const { props } = this;
    const schedule_form_items: any = [
      <FormFields
        key={1}
        field_type="number_input"
        field_label="Time interval in hours"
        props={props}
        field_name="fetch_interval"
        wrapper_div_classes="col-4 pb-3"
        data_object={{}}
        required
        autofocus
      />,
    ];
    const actions: any = [
      {
        name: "fetch",
        btn_classes: "btn btn-green text-white",
        noun: "fetch",
        icon: "file-earmark-check",
        target: "company",
        plural: "company",
        form_items: [],
      },
      {
        name: "schedule",
        btn_classes: "btn btn-warning text-white ml-2",
        noun: "scheduling",
        icon: "file-earmark-check",
        target: "schedule",
        plural: "schedule",
        form_items: schedule_form_items,
      },
    ];
    return (
      <DataTableActionForm
        actions={actions}
        table_node={this.state.table_node}
        ids_post_field="loan_ids"
        datatable_action_endpoint={MPAY_SAGE_INTEGRATIONS_ENDPOINT}
        request_method="PATCH"
        data_source_url={MPAY_SAGE_INTEGRATIONS_ENDPOINT}
        data_source_url_name="paginated_sage_integrations"
      >
        <ViewDataTable
          handleSetTableNode={this.handleSetTableNode}
          handleTableRowSelect={this.handleTableRowSelect}
          allow_refresh
          csv_export={false}
          select_mode="radio"
          clickToSelect
          columns={MPAY_SAGE_INTEGRATIONS_COLUMNS}
          data_source_url={MPAY_SAGE_INTEGRATIONS_ENDPOINT}
          data_source_url_name="paginated_sage_integrations"
        />
      </DataTableActionForm>
    );
  }
}

export default withRouter(SageIntegrations);
