import {
  API_ENDPOINT,
  CHART_OF_ACCOUNTS_ENDPOINT,
  SAVINGS_CHARGES_ENDPOINT,
  SAVINGS_PRODUCTS_ENDPOINT,
} from "../../../constants/Constants";
import React, { Component } from "react";
import {
  extractResponseError,
  fetchUrlData,
  formDataToJSON,
  getSelectedOptions,
  getUrlData,
  handleFieldChange,
  invalidateSessionData,
  label,
} from "../../../utils/componentActions";

import $ from "jquery";
import ComponentLoadingIndicator from "../../../components/ComponentLoadingIndicator";
import ConfigureProductPills from "./ConfigureProductPills";
import ConfigureProductsButtons from "./ConfigureProductsButtons";
import FormFeedbackMessage from "../../../components/FormFeedbackMessage";
import PropTypes from "prop-types";
import Select from "react-select-oss";
import { connect } from "react-redux";
import { postAPIRequest } from "../../../utils/APIRequests";
import { withRouter } from "react-router-dom";

class ConfigureSavingsProduct extends Component {
  constructor(props) {
    super(props);
    const pills: string[] = [
      "details",
      "terms",
      "settings",
      "charges",
      "accounting",
    ];
    this.state = {
      active_pill: "details",
      pills,
      savings: {},
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
    };
  }

  componentDidMount() {
    fetchUrlData(
      "chart_of_accounts_url",
      CHART_OF_ACCOUNTS_ENDPOINT,
      this.props
    );
    fetchUrlData("savings_charges_url", SAVINGS_CHARGES_ENDPOINT, this.props);
    fetchUrlData("savings_products_url", SAVINGS_PRODUCTS_ENDPOINT, this.props);
    const savings_id: any = this.props.match.params.id;
    if (savings_id) {
      const savings_product_url: string = `${SAVINGS_PRODUCTS_ENDPOINT}${savings_id}/`;
      fetchUrlData("savings_product_url", savings_product_url, this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, _) {
    const previous_savings: any = this.state.savings;
    const savings: any = nextProps.savings_product_data.items;
    const { savings_products_data } = nextProps;
    const savings_products: any = savings_products_data.items;
    if (
      Object.keys(previous_savings).length === 0 &&
      Object.keys(savings).length > 0 &&
      this.props.match.params.id
    ) {
      this.setState({
        savings,
      });
    }
    if (
      savings_products.length === 0 &&
      !savings_products_data.isFetching &&
      !savings_products_data.didInvalidate
    ) {
      this.setState({
        feedback_message:
          "You need to setup a savings product to use the savings management feature",
      });
    }
  }

  handleSubmitConfiguration = (next_pill = "accounting") => {
    const formElement: any = $("form#form-configure-savings")[0];
    const formData: any = new FormData(formElement);
    let payload: any = this.state.savings;
    payload = formDataToJSON(formData, payload, false);
    const valid_form: any = formElement.reportValidity();
    if (valid_form) {
      if (this.state.active_pill !== next_pill) {
        this.setState({
          active_pill: next_pill,
          savings: payload,
        });
      } else {
        this.setState({
          activity: true,
        });
        let savings_product_url: any = API_ENDPOINT + SAVINGS_PRODUCTS_ENDPOINT;
        const savings_id: any = this.props.match.params.id;
        let request_method: string = "POST";
        if (savings_id) {
          savings_product_url = `${savings_product_url}${savings_id}/`;
          request_method = "PUT";
        }
        postAPIRequest(
          savings_product_url,
          () => {
            this.setState({
              activity: false,
              feedback_message: "Savings product configuration was successful",
              feedback_type: "success",
            });
            invalidateSessionData(this, SAVINGS_PRODUCTS_ENDPOINT);
          },
          (results) => {
            const feedback_message: any = extractResponseError(results);
            this.setState({
              activity: false,
              feedback_message,
              feedback_type: "danger",
            });
          },
          payload,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.token}`,
          },
          request_method
        );
      }
    }
  };

  handleFieldChange = (
    e,
    field_name = null,
    field_value = null,
    select_field = false
  ) => {
    let payload: any = this.state.savings;
    payload = handleFieldChange(
      e,
      field_name,
      field_value,
      select_field,
      payload
    );
    this.setState({
      savings: payload,
    });
  };

  render() {
    const {
      savings_product_data,
      savings_charges_data,
      chart_of_accounts_data,
      savings_products_data,
    } = this.props;
    if (
      savings_product_data.isFetching ||
      savings_charges_data.isFetching ||
      chart_of_accounts_data.isFetching ||
      savings_products_data.isFetching
    ) {
      return <ComponentLoadingIndicator />;
    }
    const savings_charges: any = savings_charges_data.items;
    const chart_of_accounts: any = chart_of_accounts_data.items;
    const savings_charges_options: any = savings_charges.map((charge) => ({
      value: charge.id,
      label: charge.name,
    }));
    const filter_account: any = function (type) {
      return chart_of_accounts.filter(
        (account) => account.account_type === type
      );
    };
    const asset_accounts_options: any = filter_account(1).map((account) => ({
      value: account.id,
      label: account.account_name,
    }));
    const liabilities_accounts_options: any = filter_account(2).map(
      (account) => ({ value: account.id, label: account.account_name })
    );
    const income_accounts_options: any = filter_account(4).map((account) => ({
      value: account.id,
      label: account.account_name,
    }));
    const expenses_accounts_options: any = filter_account(5).map((account) => ({
      value: account.id,
      label: account.account_name,
    }));
    const { props } = this;
    let feedback_message: string = "";
    if (this.state.feedback_message) {
      feedback_message = (
        <FormFeedbackMessage
          feedback_message={this.state.feedback_message}
          feedback_type={this.state.feedback_type}
        />
      );
    }
    const { savings } = this.state;
    let configuration_form: any = (
      <div className="row g-3">
        <div className="col-md-4">
          <label className="form-label required">
            {label("Product name", props)}
          </label>
          <input
            className="form-control"
            name="product_name"
            required
            value={savings.product_name}
            onChange={(e) => this.handleFieldChange(e)}
          />
        </div>
        <div className="col-md-4">
          <label className="form-label required">
            {label("Short name", props)}
          </label>
          <input
            className="form-control"
            name="short_name"
            required
            value={savings.short_name}
            onChange={(e) => this.handleFieldChange(e)}
          />
        </div>
        <div className="col-md-4">
          <label className="form-label required">
            {label("Description", props)}
          </label>
          <textarea
            className="form-control no-resize"
            name="description"
            required
            value={savings.description}
            onChange={(e) => this.handleFieldChange(e)}
          />
        </div>
      </div>
    );
    if (this.state.active_pill === "currency") {
      configuration_form = (
        <div className="row g-3">
          <div className="col-md-4">
            <label className="form-label required">
              {label("No of decimal places", props)}
            </label>
            <input
              className="form-control"
              type="number"
              name="no_of_decimal_places"
              required
              value={savings.no_of_decimal_places}
              onChange={(e) => this.handleFieldChange(e)}
            />
          </div>
        </div>
      );
    } else if (this.state.active_pill === "terms") {
      configuration_form = (
        <div className="row g-3">
          <div className="col-md-4">
            <label className="form-label required">
              {label("Nominal annual interest", props)}
            </label>
            <input
              className="form-control"
              type="number"
              name="norminal_annual_interest"
              required
              value={savings.norminal_annual_interest}
              onChange={(e) => this.handleFieldChange(e)}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">
              {label("Interest compounding period", props)}
            </label>
            <select
              className="form-control"
              name="interest_compounding_period"
              required
              value={savings.interest_compounding_period}
              onChange={(e) => this.handleFieldChange(e)}
            >
              <option value="daily">{label("Daily", props)}</option>
              <option value="monthly">{label("Monthly", props)}</option>
              <option value="quarterly">{label("Quarterly", props)}</option>
              <option value="semi_annually">
                {label("Semi-annually", props)}
              </option>
              <option value="annually">{label("Annually", props)}</option>
            </select>
          </div>
          <div className="col-md-4">
            <label className="form-label">
              {label("Interest calculated using", props)}
            </label>
            <select
              className="form-control"
              name="interest_calculated_using"
              required
              value={savings.interest_calculated_using}
              onChange={(e) => this.handleFieldChange(e)}
            >
              <option value="daily_balance">
                {label("Daily balance", props)}
              </option>
              <option value="average_daily_balance">
                {label("Average daily balance", props)}
              </option>
            </select>
          </div>
          <div className="col-md-4">
            <label className="form-label">
              {label("Interest posting period", props)}
            </label>
            <select
              className="form-control"
              name="interest_posting_period"
              required
              value={savings.interest_posting_period}
              onChange={(e) => this.handleFieldChange(e)}
            >
              <option value="monthly">{label("Monthly", props)}</option>
              <option value="quarterly">{label("Quarterly", props)}</option>
              <option value="Biannually">{label("Biannually", props)}</option>
              <option value="Annually">{label("Annually", props)}</option>
            </select>
          </div>
        </div>
      );
    } else if (this.state.active_pill === "settings") {
      configuration_form = (
        <div className="row g-3">
          <div className="col-md-4">
            <label className="form-label required">
              {label("Minimum opening balance", props)}
            </label>
            <input
              className="form-control"
              type="number"
              name="minimum_opening_balance"
              required
              value={savings.minimum_opening_balance}
              onChange={(e) => this.handleFieldChange(e)}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">
              {label("Enforce minimum balance", props)}
            </label>
            <select
              className="form-control"
              name="enforce_minimum_balance"
              required
              value={savings.enforce_minimum_balance}
              onChange={(e) => this.handleFieldChange(e)}
            >
              <option value={false}>{label("No", props)}</option>
              <option value>{label("Yes", props)}</option>
            </select>
          </div>
          <div className="col-md-4">
            <label className="form-label">
              {label("Is overdraft allowed?", props)}
            </label>
            <select
              className="form-control"
              name="is_overdraft_allowed"
              required
              value={savings.is_overdraft_allowed}
              onChange={(e) => this.handleFieldChange(e)}
            >
              <option value={false}>{label("No", props)}</option>
              <option value>{label("Yes", props)}</option>
            </select>
          </div>
          <div className="col-md-4">
            <label className="form-label">
              {label("Enable dormancy tracking?", props)}
            </label>
            <select
              className="form-control"
              name="enable_dormancy_tracking"
              required
              value={savings.enable_dormancy_tracking}
              onChange={(e) => this.handleFieldChange(e)}
            >
              <option value={false}>{label("No", props)}</option>
              <option value>{label("Yes", props)}</option>
            </select>
          </div>
          <div className="col-md-4">
            <label className="form-label required">
              {label("Balance required for interest calculation", props)}
            </label>
            <input
              className="form-control"
              type="number"
              name="balance_required_for_interest_calculation"
              required
              value={savings.balance_required_for_interest_calculation}
              onChange={(e) => this.handleFieldChange(e)}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label required">
              {label("Minimum balance", props)}
            </label>
            <input
              className="form-control"
              type="number"
              name="minimum_balance"
              required
              value={savings.minimum_balance}
              onChange={(e) => this.handleFieldChange(e)}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">
              {label("Allow withdrawal?", props)}
            </label>
            <select
              className="form-control"
              name="allow_withdrawal"
              required
              value={savings.allow_withdrawal}
              onChange={(e) => this.handleFieldChange(e)}
            >
              <option value={false}>{label("No", props)}</option>
              <option value>{label("Yes", props)}</option>
            </select>
          </div>
          <div className="col-md-4">
            <label className="form-label required">
              {label("Minimum shares required", props)}
            </label>
            <input
              className="form-control"
              type="number"
              name="minimum_shares_required"
              required
              value={savings.minimum_shares_required}
              onChange={(e) => this.handleFieldChange(e)}
            />
          </div>
        </div>
      );
    } else if (this.state.active_pill === "charges") {
      configuration_form = (
        <div className="row g-3">
          <div className="col-md-4">
            <label className="form-label">{label("Charges", props)}</label>
            <Select
              isMulti
              closeMenuOnSelect={false}
              options={savings_charges_options}
              value={getSelectedOptions(
                savings.charges,
                savings_charges_options
              )}
              onChange={(selected, e) =>
                this.handleFieldChange(e, "charges", selected, true)
              }
            />
          </div>
        </div>
      );
    } else if (this.state.active_pill === "accounting") {
      configuration_form = (
        <div className="row g-3">
          <div className="col-md-4">
            <label className="form-label">
              {label("Savings settlement account", props)}
            </label>
            <Select
              options={asset_accounts_options}
              name="savings_reference"
              value={
                getSelectedOptions(
                  savings.savings_reference,
                  asset_accounts_options
                ) || asset_accounts_options[0]
              }
              onChange={(selected, e) =>
                this.handleFieldChange(e, "savings_reference", selected, true)
              }
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">
              {label("Overdraft portfolio", props)}
            </label>
            <Select
              options={asset_accounts_options}
              name="overdraft_portfolio"
              value={
                getSelectedOptions(
                  savings.overdraft_portfolio,
                  asset_accounts_options
                ) || asset_accounts_options[0]
              }
              onChange={(selected, e) =>
                this.handleFieldChange(e, "overdraft_portfolio", selected, true)
              }
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">
              {label("Savings portfolio", props)}
            </label>
            <Select
              options={liabilities_accounts_options}
              name="savings_control"
              value={
                getSelectedOptions(
                  savings.savings_control,
                  liabilities_accounts_options
                ) || liabilities_accounts_options[0]
              }
              onChange={(selected, e) =>
                this.handleFieldChange(e, "savings_control", selected, true)
              }
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">
              {label("Savings transfer in suspense", props)}
            </label>
            <Select
              options={liabilities_accounts_options}
              name="savings_transfer_in_suspense"
              value={
                getSelectedOptions(
                  savings.savings_transfer_in_suspense,
                  liabilities_accounts_options
                ) || liabilities_accounts_options[0]
              }
              onChange={(selected, e) =>
                this.handleFieldChange(
                  e,
                  "savings_transfer_in_suspense",
                  selected,
                  true
                )
              }
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">
              {label("Interest on savings", props)}
            </label>
            <Select
              options={income_accounts_options}
              name="interest_on_savings"
              value={
                getSelectedOptions(
                  savings.interest_on_savings,
                  income_accounts_options
                ) || income_accounts_options[0]
              }
              onChange={(selected, e) =>
                this.handleFieldChange(e, "interest_on_savings", selected, true)
              }
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">{label("Write off", props)}</label>
            <Select
              options={expenses_accounts_options}
              name="write_off"
              value={
                getSelectedOptions(
                  savings.write_off,
                  expenses_accounts_options
                ) || expenses_accounts_options[0]
              }
              onChange={(selected, e) =>
                this.handleFieldChange(e, "write_off", selected, true)
              }
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">
              {label("Income from fees", props)}
            </label>
            <Select
              options={income_accounts_options}
              name="income_from_fees"
              value={
                getSelectedOptions(
                  savings.income_from_fees,
                  income_accounts_options
                ) || income_accounts_options[0]
              }
              onChange={(selected, e) =>
                this.handleFieldChange(e, "income_from_fees", selected, true)
              }
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">
              {label("Income from penalties", props)}
            </label>
            <Select
              options={income_accounts_options}
              name="income_from_penalties"
              value={
                getSelectedOptions(
                  savings.income_from_penalties,
                  income_accounts_options
                ) || income_accounts_options[0]
              }
              onChange={(selected, e) =>
                this.handleFieldChange(
                  e,
                  "income_from_penalties",
                  selected,
                  true
                )
              }
            />
          </div>
          <div className="col-md-4">
            <label className="form-label">
              {label("Overdraft interest income", props)}
            </label>
            <Select
              options={income_accounts_options}
              name="overdraft_interest_income"
              value={
                getSelectedOptions(
                  savings.overdraft_interest_income,
                  income_accounts_options
                ) || income_accounts_options[0]
              }
              onChange={(selected, e) =>
                this.handleFieldChange(
                  e,
                  "overdraft_interest_income",
                  selected,
                  true
                )
              }
            />
          </div>
        </div>
      );
    }
    return (
      <div>
        <ConfigureProductPills
          handleSubmitConfiguration={this.handleSubmitConfiguration}
          state={this.state}
        />
        <div className="pt-4">
          {feedback_message}
          <form id="form-configure-savings">
            {configuration_form}
            <div className="row mt-3">
              <div className="col">
                <ConfigureProductsButtons
                  state={this.state}
                  handleSubmitConfiguration={this.handleSubmitConfiguration}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

ConfigureSavingsProduct.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  chart_of_accounts_data: PropTypes.instanceOf(Object).isRequired,
  savings_product_data: PropTypes.instanceOf(Object).isRequired,
  savings_charges_data: PropTypes.instanceOf(Object).isRequired,
  savings_products_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const chart_of_accounts_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "chart_of_accounts_url"
  );
  const savings_product_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "savings_product_url"
  );
  const savings_charges_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "savings_charges_url"
  );
  const savings_products_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "savings_products_url"
  );
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    dataByUrl,
    chart_of_accounts_data,
    savings_product_data,
    savings_charges_data,
    savings_products_data,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(ConfigureSavingsProduct));
