import React, { Component } from "react";
import { fetchUrlData, getUrlData } from "../utils/componentActions";

import { AGENTS_ENDPOINT } from "../constants/Constants";
import CreateEditForm from "../components/CreateEditForm";
import FormFields from "../components/FormFields";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class RegisterAgent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agent: {},
    };
  }

  componentDidMount() {
    const agent_id: any = this.props.match.params.id;
    if (agent_id) {
      const agent_url: string = `${AGENTS_ENDPOINT}${agent_id}/`;
      fetchUrlData("agent_url", agent_url, this.props);
    }
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const { agent_data } = props;
    const { agent } = this.state;
    const form_classes: string = "row g-3";
    const form_items: any = [
      <FormFields
        key={1}
        field_type="text_input"
        field_label="First name"
        props={props}
        field_name="first_name"
        wrapper_div_classes="col-4"
        data_object={agent}
        required
      />,
      <FormFields
        key={2}
        field_type="text_input"
        field_label="Middle name"
        props={props}
        field_name="middle_name"
        wrapper_div_classes="col-4"
        data_object={agent}
        required
      />,
      <FormFields
        key={3}
        field_type="text_input"
        field_label="Last name"
        props={props}
        field_name="last_name"
        wrapper_div_classes="col-4"
        data_object={agent}
        required
      />,
      <FormFields
        key={4}
        field_type="text_input"
        field_label="Personal ID no"
        props={props}
        field_name="id_number"
        wrapper_div_classes="col-4"
        data_object={agent}
        required
      />,
      <FormFields
        key={5}
        field_type="email"
        field_label="Personal email"
        props={props}
        field_name="personal_email"
        wrapper_div_classes="col-4"
        data_object={agent}
        required
      />,
      <FormFields
        key={6}
        field_type="mobile_no"
        field_label="Phone number"
        props={props}
        field_name="phone_number"
        wrapper_div_classes="col-4"
        data_object={agent}
        required
      />,
      <FormFields
        key={7}
        field_type="select"
        field_label="Status"
        props={props}
        field_name="status"
        wrapper_div_classes="col-4"
        data_object={agent}
      >
        <option value={1}>Active</option>
        <option value={2}>Inactive</option>
        <option value={13}>Deleted</option>
      </FormFields>,
      <FormFields
        key={8}
        field_type="select"
        field_label="Enable agency banking app?"
        props={props}
        field_name="enable_login"
        wrapper_div_classes="col-4"
        data_object={agent}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
    ];
    return (
      <CreateEditForm
        loading={agent_data.isFetching}
        form_items={form_items}
        form_classes={form_classes}
        action_object={agent_data.items}
        setState={this.updateState}
        action_object_name="agent"
        state={this.state}
        action_object_endpoint={AGENTS_ENDPOINT}
        object_display_name="agent"
      />
    );
  }
}

RegisterAgent.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  agent_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const agent_data: any = getUrlData(dataByUrl, sessionVariables, "agent_url");

  return {
    sessionVariables,
    agent_data,
  };
}

export default connect(mapStateToProps)(withRouter(RegisterAgent));
