import {
  API_ENDPOINT,
  GOOGLE_CLIENT_ID,
  GOOGLE_LOGIN_ENDPOINT,
  LOGIN_ENDPOINT,
  MEMBER_LOGIN_ENDPOINT,
  OAUTH_CLIENT_ID,
  USERS_ENDPOINT,
} from "../constants/Constants";
import React, { Component } from "react";
import {
  delay,
  extractResponseError,
  formDataToJSON,
  pushHistory,
} from "../utils/componentActions";

import $ from "jquery";
import FormActivityIndicator from "../components/FormActivityIndicator";
import { INDEX_PATH } from "../constants/ClientPaths";
import PropTypes from "prop-types";
import { getAPIRequest, postAPIRequest } from "../utils/APIRequests";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { GoogleLoginButton } from "react-social-login-buttons";
import { LoginSocialGoogle } from "reactjs-social-login";

class FormLogin extends Component {
  /**
   * When login action has started.
   * We update the activity state to true.
   */
  onLoginStart() {
    this.props.handleUpdateState({
      activity: true,
    });
  }

  /**
   * login action
   * @param data
   * @param url
   */
  login(data, url) {
    const payload = {
      grant_type: "password",
      client_id: OAUTH_CLIENT_ID,
      ...data,
    };

    postAPIRequest(
      url,
      (results) => {
        let feedback_message: string = "";
        let password_change: boolean = false;
        let otp_prompt: boolean = false;
        let package_downgrade: boolean = false;
        let redirect_to_home: boolean = false;

        if (results.exception) {
          feedback_message = results.exception;
          password_change = true;
        } else if (results.otp) {
          feedback_message = results.otp;
          otp_prompt = true;
        } else if (results.package_downgrade) {
          package_downgrade = true;
        } else {
          localStorage.token = results.access_token;
          if (data.user_type === "member") {
            localStorage.member = results.id;
            localStorage.auth_token = results.member_dashboard_token;
          }
          feedback_message = "Log in successful, redirecting...";
          redirect_to_home = true;
        }

        this.props.handleUpdateState(
          {
            activity: false,
            feedback_message,
            feedback_type: "success",
            password_change,
            otp_prompt,
            package_downgrade,
            username: payload.username,
            password: payload.password,
          },
          async () => {
            if (redirect_to_home) {
              const { location } = this.props;
              const { search } = location;
              const query_string: any = queryString.parse(search);
              await delay(1000);
              const next_path: any = query_string.next || INDEX_PATH;
              pushHistory(next_path, this.props);
            }
          }
        );
      },
      (results) => {
        const feedback_message: any = extractResponseError(results);
        this.props.handleUpdateState({
          activity: false,
          feedback_message,
          feedback_type: "danger",
        });
      },
      payload,
      {
        "Content-Type": "application/json",
      }
    );
  }

  /**
   * Handle login form submission
   * @param e
   */
  handleSubmitLogin(e) {
    e.preventDefault();
    this.onLoginStart();
    const formData: any = new FormData($("form#form-login")[0]);
    const data = formDataToJSON(formData, {});
    let login_url: string = API_ENDPOINT + LOGIN_ENDPOINT;
    if (data.user_type === "member") {
      login_url = API_ENDPOINT + MEMBER_LOGIN_ENDPOINT;
    }
    this.login(data, login_url);
  }

  /**
   * Handle google login
   * @param userInfo
   */
  handleGoogleLogin(userInfo) {
    const formData: any = new FormData($("form#form-login")[0]);
    const data = formDataToJSON(formData, { id_token: userInfo.credential });
    delete data.username;
    delete data.password;
    this.login(data, API_ENDPOINT + GOOGLE_LOGIN_ENDPOINT);
  }

  /**
   * When component mounts, check if user is already login.
   * If they are logged in, then redirect to home.
   */
  componentDidMount() {
    const { token, auth_token, member } = localStorage;

    if (token || auth_token) {
      const endpoint = token
        ? `${USERS_ENDPOINT}i/`
        : `${MEMBER_LOGIN_ENDPOINT}${member}/?member=${member}&token=${auth_token}`;
      const user_url = `${API_ENDPOINT}${endpoint}`;
      getAPIRequest(
        user_url,
        () => {
          // token is valid, therefore redirect to /
          pushHistory("/", this.props);
        },
        () => {
          // token could potentially be expired or revoked therefore, do nothing
        },
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );
    }
  }

  render() {
    let login_signup_buttons: any = (
      <div className="col-12">
        <button className="btn btn-green btn-block text-white" type="submit">
          Login
        </button>
        <LoginSocialGoogle
          client_id={GOOGLE_CLIENT_ID}
          onLoginStart={() => this.onLoginStart()}
          scope="profile email"
          discoveryDocs="claims_supported"
          access_type="offline"
          typeResponse="idToken"
          className="my-3"
          onResolve={({ data }) => {
            this.handleGoogleLogin(data);
          }}
          onReject={(err) => {
            this.props.handleUpdateState({
              activity: false,
            });
            console.log(err);
          }}
        >
          <GoogleLoginButton className="m-0 w-100" align="center" />
        </LoginSocialGoogle>
        <button
          className="btn btn-outline-blue btn-block"
          type="button"
          onClick={() => pushHistory("/signUp", this.props)}
        >
          Register
        </button>
        <button
          type="button"
          className="btn btn-link btn-block"
          onClick={() => pushHistory("/reset_password/", this.props, "server")}
        >
          Click here to reset password
        </button>
      </div>
    );
    if (this.props.activity) {
      login_signup_buttons = <FormActivityIndicator />;
    }
    return (
      <form
        id="form-login"
        className="row g-1"
        onSubmit={(e) => this.handleSubmitLogin(e)}
      >
        <div className="col-12">
          <label className="form-label">Username</label>
          <input
            className="form-control"
            type="text"
            name="username"
            required
          />
        </div>
        <div className="col-12">
          <label className="form-label">Password</label>
          <input
            className="form-control"
            type="password"
            name="password"
            required
          />
        </div>
        <div className="col-12">
          <label className="form-label">User type</label>
          <select className="form-select" name="user_type">
            <option value="staff">Staff</option>
            <option value="member">Member</option>
            {/* <option value="employee">Employee</option> */}
          </select>
        </div>
        {login_signup_buttons}
      </form>
    );
  }
}

FormLogin.defaultProps = {
  location: {},
};

FormLogin.propTypes = {
  location: PropTypes.instanceOf(Object),
};

export default withRouter(FormLogin);
