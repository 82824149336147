import {
  APPLIED_LOANS_ENDPOINT,
  BANKS_ENDPOINT,
  CLIENT_PAYMENTS_ENDPOINT,
  CURRENCIES_ENDPOINT,
  MEMBER_ACCOUNTS_ENDPOINT,
  PAYMENT_MODES_ENDPOINT,
} from "../constants/Constants";
import React, { Component } from "react";
import {
  fetchUrlData,
  getSelect2Options,
  getUrlData,
} from "../utils/componentActions";

import BootstrapIcon from "../components/BootstrapIcon";
import CreateEditForm from "../components/CreateEditForm";
import FormFields from "../components/FormFields";
import ModalHOC from "../components/ModalHOC";
import PaymentReceipt from "./PaymentReceipt";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import merge from "lodash/merge";
import { withRouter } from "react-router";

class AddClientPayment extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      client_payment: {
        print_receipt: true,
      },
      accounts_amounts: [{ id: 1 }],
      multiple_payments: false,
      receipt_modal_show: false,
      transaction_details: {},
    };
    this.state = merge({}, this.initialState);
  }

  componentDidMount() {
    fetchUrlData("banks_url", BANKS_ENDPOINT, this.props);
    fetchUrlData("payment_modes_url", PAYMENT_MODES_ENDPOINT, this.props);
    fetchUrlData("currencies_url", CURRENCIES_ENDPOINT, this.props);
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  resetState = () => {
    const state_object: any = merge({}, this.initialState);
    const previous_state: any = this.state;
    state_object.transaction_details = previous_state.transaction_details;
    const { client_payment } = state_object;
    client_payment.reset_fields = true;
    this.setState(state_object);
  };

  getMemberAccounts = (select_obj) => {
    const selected_member_accounts_url: string = `${MEMBER_ACCOUNTS_ENDPOINT}?member=${select_obj.value}`;
    const selected_member_active_loans_url: string = `${APPLIED_LOANS_ENDPOINT}?member=${select_obj.value}&status=1`;
    fetchUrlData(
      "selected_member_accounts_url",
      selected_member_accounts_url,
      this.props
    );
    fetchUrlData(
      "selected_member_active_loans_url",
      selected_member_active_loans_url,
      this.props
    );
  };

  handleChangeAccount = (select_obj, index) => {
    const accounts_amounts: any = [...this.state.accounts_amounts];
    if (select_obj.optgroup_label === "loan") {
      accounts_amounts[index].loan = select_obj.value;
      delete accounts_amounts[index].member_account;
    } else {
      accounts_amounts[index].member_account = select_obj.value;
      delete accounts_amounts[index].loan;
    }
    this.setState({
      accounts_amounts,
    });
  };

  handlePrintReceipt = (transaction) => {
    const transaction_details: any = transaction;
    transaction_details.transaction_amount =
      this.state.accounts_amounts[0].amount;
    if (
      this.state.client_payment.print_receipt &&
      !this.state.multiple_payments
    ) {
      this.setState({
        receipt_modal_show: true,
        transaction_details,
      });
    }
  };

  handleReceiptClose = (callback) => {
    this.setState(
      {
        receipt_modal_show: false,
      },
      () => callback
    );
  };

  getAddRemovePaymentButton = (index) => {
    const getButton: any = (icon, color, onClick) => (
      <button type="button" className={`btn btn-${color}`} onClick={onClick}>
        <BootstrapIcon icon={icon} />
      </button>
    );
    const add_button: any =
      index === 0 && getButton("plus", "primary", () => this.addPayment());
    const remove_button: any =
      index !== 0 &&
      getButton("dash", "danger", () => this.removePayment(index));
    return add_button || remove_button;
  };

  addPayment = () => {
    const id: any = this.state.accounts_amounts.length + 1;
    this.setState((prev) => ({
      accounts_amounts: [...prev.accounts_amounts, { id }],
      multiple_payments: true,
    }));
  };

  removePayment = (index) => {
    const accounts_amounts: any = [...this.state.accounts_amounts];
    accounts_amounts[index] = null;
    this.setState({
      accounts_amounts: [...accounts_amounts],
      multiple_payments:
        accounts_amounts.filter((account_amount) => account_amount !== null)
          .length > 1,
    });
  };

  render() {
    const { props } = this;
    const {
      banks_data,
      payment_modes_data,
      currencies_data,
      selected_member_accounts_data,
      selected_member_active_loans_data,
    } = props;
    const banks: any = banks_data.items;
    const payment_modes: any = payment_modes_data.items;
    const currencies: any = currencies_data.items;
    const selected_member_accounts: any = selected_member_accounts_data.items;
    const selected_member_active_loans: any =
      selected_member_active_loans_data.items;
    const active_loan_option_field_map: string[] = [
      "loan_name",
      "loan_reference_no",
      "approved_amount",
    ];
    const active_loan_option_value_field: string =
      "[loan_name]-[loan_reference_no]([approved_amount])";
    const member_account_option_field_map: string[] = [
      "account_display_name",
      "account_balance",
    ];
    const member_account_option_value_field: string =
      "[account_display_name](Bal [account_balance])";
    const banks_options: any = getSelect2Options(banks, "id", "bank_name");
    const payment_mode_options: any = getSelect2Options(
      payment_modes,
      "id",
      "name"
    );
    const currency_options: any = getSelect2Options(currencies, "id", "name");
    const selected_member_account_options: any = getSelect2Options(
      selected_member_accounts,
      "id",
      member_account_option_value_field,
      member_account_option_field_map,
      {
        optgroup_label: "savings_shares",
        optgroup_display: "Savings & Shares",
      }
    );
    const selected_member_active_loan_options: any = getSelect2Options(
      selected_member_active_loans,
      "id",
      active_loan_option_value_field,
      active_loan_option_field_map,
      {
        optgroup_label: "loan",
        optgroup_display: "Loans",
      }
    );
    const account_or_loan_options: any = [
      {
        label: "Loans",
        options: selected_member_active_loan_options,
      },
      {
        label: "Savings & Shares",
        options: selected_member_account_options,
      },
    ];
    const form_classes: string = "row g-3";
    const { client_payment } = this.state;
    const payment_fields: any = this.state.accounts_amounts.map(
      (payment, index) =>
        payment && (
          <div key={payment.id} className="row align-items-end g-3">
            <FormFields
              field_type="select2"
              field_label="Account/Loan"
              props={props}
              field_name="account_or_loan"
              wrapper_div_classes="col-4"
              select2_options={account_or_loan_options}
              onChange={(selected) => this.handleChangeAccount(selected, index)}
              data_object={payment}
              required
            />
            <FormFields
              field_type="number_input"
              field_label="Amount"
              props={props}
              field_name="amount"
              wrapper_div_classes="col-4"
              updateState={this.updateState}
              update_payload
              step={0.01}
              data_object={payment}
              required
            />
            <div className="col-4">
              {this.getAddRemovePaymentButton(index)}
              <div className="mt-2" />
            </div>
          </div>
        )
    );
    const print_field: any = !this.state.multiple_payments && (
      <FormFields
        key={11}
        field_type="checkbox"
        checkbox_field_label="Print receipt"
        props={props}
        field_name="print_receipt"
        wrapper_div_classes="col-4 pt-4-2"
        data_object={client_payment}
        update_payload
        data_object_name="client_payment"
        updateState={this.updateState}
      />
    );
    const form_items: any = (
      <>
        <FormFields
          key={1}
          field_type="member"
          field_label="Member"
          props={props}
          field_name="member"
          wrapper_div_classes="col-4"
          onChange={(selected) => this.getMemberAccounts(selected)}
          data_object={client_payment}
          required
        />
        <FormFields
          key={4}
          field_type="select2"
          field_label="Bank"
          props={props}
          field_name="bank"
          wrapper_div_classes="col-4"
          select2_options={banks_options}
          select2_default_first
          data_object={client_payment}
          required
        />
        <div className="col-12">{payment_fields}</div>
        <FormFields
          key={5}
          field_type="text_input"
          field_label="Transaction code"
          props={props}
          field_name="transaction_code"
          wrapper_div_classes="col-4"
          data_object={client_payment}
          required
          autofill_timestamp
          autofill_prefix="CON-"
        />
        <FormFields
          key={6}
          field_type="select2"
          field_label="Payment mode"
          props={props}
          field_name="payment_type"
          wrapper_div_classes="col-4"
          select2_options={payment_mode_options}
          select2_default_first
          data_object={client_payment}
          required
        />
        <FormFields
          key={7}
          field_type="select2"
          field_label="Currency"
          props={props}
          field_name="currency"
          wrapper_div_classes="col-4"
          select2_options={currency_options}
          select2_default_first
          data_object={client_payment}
          required
        />
        <FormFields
          key={8}
          field_type="date"
          field_label="Date"
          props={props}
          field_name="date"
          wrapper_div_classes="col-4"
          data_object={client_payment}
          required
        />
        <FormFields
          key={9}
          field_type="text_input"
          field_label="Cheque no"
          props={props}
          field_name="cheque_no"
          wrapper_div_classes="col-4"
          data_object={client_payment}
        />
        <FormFields
          key={10}
          field_type="textarea"
          field_label="Comment"
          props={props}
          field_name="comment"
          wrapper_div_classes="col-4"
          data_object={client_payment}
        />
        {print_field}
      </>
    );
    return (
      <>
        <ModalHOC
          modal_show={this.state.receipt_modal_show}
          modal_only
          modal_title="Receipt"
          modalCloseCallback={this.handleReceiptClose}
        >
          <PaymentReceipt
            transaction_details={this.state.transaction_details}
          />
        </ModalHOC>
        <CreateEditForm
          loading={
            banks_data.isFetching ||
            currencies_data.isFetching ||
            payment_modes_data.isFetching
          }
          form_classes={form_classes}
          action_object={{}}
          setState={this.updateState}
          action_object_name="client_payment"
          state={this.state}
          action_object_endpoint={CLIENT_PAYMENTS_ENDPOINT}
          payload={client_payment}
          successCallback={this.handlePrintReceipt}
          resetState={this.resetState}
          object_display_name="client payment"
          field_array={this.state.accounts_amounts}
          field_array_keys={[
            "account_or_loan",
            "amount",
            "loan",
            "member_account",
          ]}
        >
          {form_items}
        </CreateEditForm>
      </>
    );
  }
}

AddClientPayment.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  banks_data: PropTypes.instanceOf(Object).isRequired,
  payment_modes_data: PropTypes.instanceOf(Object).isRequired,
  currencies_data: PropTypes.instanceOf(Object).isRequired,
  selected_member_accounts_data: PropTypes.instanceOf(Object).isRequired,
  selected_member_active_loans_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const banks_data: any = getUrlData(dataByUrl, sessionVariables, "banks_url");
  const payment_modes_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "payment_modes_url"
  );
  const currencies_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "currencies_url"
  );
  const selected_member_accounts_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "selected_member_accounts_url"
  );
  const selected_member_active_loans_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "selected_member_active_loans_url"
  );

  return {
    sessionVariables,
    banks_data,
    payment_modes_data,
    currencies_data,
    selected_member_accounts_data,
    selected_member_active_loans_data,
  };
}

export default connect(mapStateToProps)(withRouter(AddClientPayment));
