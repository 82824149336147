import React, { Component } from "react";

import { COLLATERAL_ENDPOINT } from "../../constants/Constants";
import PropTypes from "prop-types";
import { REGISTER_COLLATERALS_PATH } from "../../constants/ClientPaths";
import { COLLATERAL_COLUMNS } from "../../components/DataTableColumns";
import ViewDataTable from "../../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

/**
 * This component is used to display the list of collaterals
 */
class ViewCollaterals extends Component {
  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  /**
   * Render the component.
   */
  render() {
    return (
      <ViewDataTable
        handleSetTableNode={this.handleSetTableNode}
        allow_refresh
        columns={COLLATERAL_COLUMNS}
        data_source_url={COLLATERAL_ENDPOINT}
        editable
        creatable
        edit_url={REGISTER_COLLATERALS_PATH}
        data_source_url_name="paginated_collaterals_url"
      />
    );
  }
}

export default ViewCollaterals;
