import {
  MEMBER_VIEW_ACCOUNT_DEBIT_CREDIT_STATEMENT_PATH,
  MEMBER_VIEW_ACCOUNT_DETAILS_PATH,
  MEMBER_VIEW_ACCOUNT_STATEMENT_PATH,
  MEMBER_VIEW_ACCOUNT_TRANSACTIONS_PATH,
} from "../constants/ClientPaths";
import React, { Component } from "react";
import {
  VIEW_MEMBER_ACCOUNT_LEFT_COLUMNS,
  VIEW_MEMBER_ACCOUNT_RIGHT_COLUMNS,
} from "../components/DataTableColumns";
import {
  fetchUrlData,
  getSelect2Options,
  getUrlData,
  pushHistory,
} from "../utils/componentActions";

import FormFields from "../components/FormFields";
import { MEMBER_PORTAL_ACCOUNTS_ENDPOINT } from "../constants/Constants";
import Pills from "../components/Pills";
import Portlet from "../components/Portlet";
import PropTypes from "prop-types";
import ViewDataTable from "../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class MemberViewAccount extends Component {
  componentDidMount() {
    const { member, auth_token } = localStorage;
    const member_accounts_url: string = `${MEMBER_PORTAL_ACCOUNTS_ENDPOINT}?token=${auth_token}&member=${member}`;
    fetchUrlData("member_accounts_url", member_accounts_url, this.props, true);
  }

  handleAccountChange(selected) {
    pushHistory(
      `${MEMBER_VIEW_ACCOUNT_STATEMENT_PATH}${selected.value}`,
      this.props
    );
  }

  render() {
    const { member_accounts_data, computedMatch } = this.props;
    const { member, auth_token } = localStorage;
    const member_accounts: any = member_accounts_data.items;
    const account_option_value_field: string =
      "[account_display_name]([account_balance])";
    const account_option_field_map: string[] = [
      "account_display_name",
      "account_balance",
    ];
    const account_options: any = getSelect2Options(
      member_accounts,
      "id",
      account_option_value_field,
      account_option_field_map
    );
    const pills: any = [
      {
        label: "Account details",
        path: MEMBER_VIEW_ACCOUNT_DETAILS_PATH,
      },
      {
        label: "Account statement",
        path: MEMBER_VIEW_ACCOUNT_STATEMENT_PATH,
      },
      {
        label: "Debit credit statement",
        path: MEMBER_VIEW_ACCOUNT_DEBIT_CREDIT_STATEMENT_PATH,
      },
      {
        label: "Transactions",
        path: MEMBER_VIEW_ACCOUNT_TRANSACTIONS_PATH,
      },
    ];
    const account_id: any = computedMatch.params.id;
    const member_account_url: string = `${MEMBER_PORTAL_ACCOUNTS_ENDPOINT}${account_id}/?token=${auth_token}&member=${member}`;
    const filter_form_items: any = [
      <FormFields
        key={2}
        field_type="select2"
        field_label="Account"
        props={this.props}
        field_name="account"
        wrapper_div_classes="col-4 pb-3"
        select2_options={account_options}
        onChange={(selected) => this.handleAccountChange(selected)}
        data_object={{}}
        required
      />,
    ];
    let member_account_statements: any = <div />;
    if (account_id) {
      member_account_statements = (
        <>
          <div className="row">
            <div className="col table-responsive">
              <ViewDataTable
                paginated={false}
                basic_table
                vertical_headers
                columns={VIEW_MEMBER_ACCOUNT_LEFT_COLUMNS}
                data_source_url={member_account_url}
                csv_export={false}
                data_source_url_name="member_account_url"
              />
            </div>
            <div className="col table-responsive">
              <ViewDataTable
                paginated={false}
                basic_table
                vertical_headers
                columns={VIEW_MEMBER_ACCOUNT_RIGHT_COLUMNS}
                data_source_url={member_account_url}
                csv_export={false}
                data_source_url_name="member_account_url"
              />
            </div>
          </div>
          <Pills pills={pills} append_id param_id={account_id} />
          {this.props.children}
        </>
      );
    }
    return (
      <Portlet header_label="Member account">
        <div className="row g-3">{filter_form_items}</div>
        {member_account_statements}
      </Portlet>
    );
  }
}

MemberViewAccount.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  member_accounts_data: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node.isRequired,
  computedMatch: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const member_accounts_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "member_accounts_url"
  );

  return {
    sessionVariables,
    member_accounts_data,
  };
}

export default connect(mapStateToProps)(withRouter(MemberViewAccount));
