import {
  ADD_CLIENT_PAYMENT_PATH,
  APPROVE_CLIENT_PAYMENTS_PATH,
  APPROVE_LOAN_PAYMENTS_PATH,
  BULK_CLIENT_PAYMENTS_PATH,
  BULK_LOAN_PAYMENTS_PATH,
  VIEW_CLIENT_PAYMENTS_PATH,
} from "../constants/ClientPaths";
import React, { Component } from "react";

import VIewAddPortlet from "../components/VIewAddPortlet";
import { withRouter } from "react-router-dom";

class ClientPayments extends Component {
  render() {
    const pills: any = [
      {
        label: "Add Payment",
        path: ADD_CLIENT_PAYMENT_PATH,
      },
      {
        label: "Bulk Savings & Shares Payments",
        path: BULK_CLIENT_PAYMENTS_PATH,
      },
      {
        label: "Approve Savings & Shares Payments",
        path: APPROVE_CLIENT_PAYMENTS_PATH,
      },
      {
        label: "Bulk Loan Payments",
        path: BULK_LOAN_PAYMENTS_PATH,
      },
      {
        label: "Approve Loan Payments",
        path: APPROVE_LOAN_PAYMENTS_PATH,
      },
      {
        label: "View Payments",
        path: VIEW_CLIENT_PAYMENTS_PATH,
      },
    ];
    return (
      <VIewAddPortlet header_label="Client Payments" pills={pills}>
        {this.props.children}
      </VIewAddPortlet>
    );
  }
}

export default withRouter(ClientPayments);
