import { MEMBER_PENDING_LOANS_COLUMNS } from "../components/DataTableColumns";
import MemberViewAppliedLoans from "./ViewAppliedLoans";
import React from "react";
import { withRouter } from "react-router-dom";

function MemberViewPendingLoans() {
  return (
    <MemberViewAppliedLoans status={6} columns={MEMBER_PENDING_LOANS_COLUMNS} />
  );
}

export default withRouter(MemberViewPendingLoans);
