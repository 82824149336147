import {
  APPLIED_LOANS_ENDPOINT,
  LOAN_ACTIVITY_ENDPOINT,
} from "../../constants/Constants";
import React, { Component } from "react";

import DataTableActionForm from "../../components/DataTableActionForm";
import FormFields from "../../components/FormFields";
import PropTypes from "prop-types";
import { VIEW_LOAN_ACCOUNT_PENDING_ACTIONS_COLUMNS } from "../../components/DataTableColumns";
import ViewDataTable from "../../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ViewLoanAccountPendingActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_row_obj: {},
    };
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  handleTableRowSelect = (row) => {
    this.setState({
      selected_row_obj: row,
    });
  };

  dataFormat(data_items) {
    if (["[", "]"].includes(data_items)) {
      return [];
    }
    if (typeof data_items === "object") {
      return data_items;
    }
    return JSON.parse(data_items);
  }

  render() {
    const { props } = this;
    const loan_id: any = this.props.match.params.id;
    const applied_loan_url: string = `${APPLIED_LOANS_ENDPOINT}${loan_id}/`;
    const loan_activity_url: string = `${LOAN_ACTIVITY_ENDPOINT}?loan=${loan_id}&pending_actions=True`;
    const data_source_url_name: string = "applied_loan_url";
    const table_data_source_url_name: string = "loan_activity_url";
    const { selected_row_obj } = this.state;
    const payload: any = selected_row_obj.post_data;
    const reject_form_items: any = [
      <FormFields
        key={1}
        field_type="textarea"
        field_label="Rejection reason"
        props={props}
        field_name="rejection_reason"
        wrapper_div_classes="col-4 pb-3"
        data_object={{}}
        required
        autofocus
      />,
    ];
    const actions: any = [
      {
        name: "approve",
        btn_classes: "btn btn-green text-white",
        noun: "approval",
        icon: "file-earmark-check",
        target: "task",
        plural: "tasks",
        form_items: [],
        multiple_form_items: [],
        payload,
        extra_form_data: [
          { name: "action_status", value: 9 },
          { name: "approve_reject_action", value: true },
          {
            name: "instance_activity_id",
            value: selected_row_obj.instance_activity_id,
          },
        ],
      },
      {
        name: "reject",
        btn_classes: "btn btn-danger ml-2",
        noun: "rejection",
        icon: "x",
        target: "task",
        plural: "tasks",
        form_items: reject_form_items,
        multiple_form_items: reject_form_items,
        payload,
        extra_form_data: [
          { name: "action_status", value: 10 },
          { name: "approve_reject_action", value: true },
          {
            name: "instance_activity_id",
            value: selected_row_obj.instance_activity_id,
          },
        ],
      },
    ];
    return (
      <DataTableActionForm
        actions={actions}
        table_node={this.state.table_node}
        ids_post_field="activity_id"
        datatable_action_endpoint={selected_row_obj.path_info}
        request_method={selected_row_obj.method}
        datatable_source_url={applied_loan_url}
        url_as_is
        datatable_source_url_name={data_source_url_name}
      >
        <ViewDataTable
          handleSetTableNode={this.handleSetTableNode}
          allow_refresh
          handleTableRowSelect={this.handleTableRowSelect}
          paginated={false}
          select_mode="radio"
          dataFormat={this.dataFormat}
          table_node_name="loan_account_pending_actions"
          columns={VIEW_LOAN_ACCOUNT_PENDING_ACTIONS_COLUMNS}
          data_source_url={loan_activity_url}
          data_source_url_name={table_data_source_url_name}
        />
      </DataTableActionForm>
    );
  }
}

ViewLoanAccountPendingActions.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(
  withRouter(ViewLoanAccountPendingActions)
);
