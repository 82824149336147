import {
  APPLIED_LOANS_ENDPOINT,
  REPAYMENT_SCHEDULE_ENDPOINT,
} from "../../../constants/Constants";
import React, { Component } from "react";

import CreateEditForm from "../../../components/CreateEditForm";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchUrlData } from "../../../utils/componentActions";
import merge from "lodash/merge";
import { withRouter } from "react-router";

class DebtRecovery extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      debt_recovery: {},
    };
    this.state = merge({}, this.initialState);
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  resetState = () => {
    const loan_repayment_schedule_url: string = `${REPAYMENT_SCHEDULE_ENDPOINT}?loan=${this.props.loan_id}`;
    fetchUrlData(
      "loan_repayment_schedule_url",
      loan_repayment_schedule_url,
      this.props,
      true
    );
    const state_object: any = merge({}, this.initialState);
    const { debt_recovery } = state_object;
    debt_recovery.reset_fields = true;
    this.setState(state_object);
  };

  render() {
    const form_classes: string = "row g-1";
    const { debt_recovery } = this.state;
    const applied_loan_url: string = `${APPLIED_LOANS_ENDPOINT}${this.props.loan_id}/`;
    const extra_form_data: any = [{ name: "debt_recovery", value: true }];
    return (
      <CreateEditForm
        loading={false}
        form_items={[]}
        form_classes={form_classes}
        action_object={debt_recovery}
        setState={this.updateState}
        action_object_name="debt_recovery"
        state={this.state}
        action_object_endpoint={applied_loan_url}
        payload={debt_recovery}
        resetState={this.resetState}
        request_method="PATCH"
        form_id="debt-recovery"
        datatable_source_url={applied_loan_url}
        save_button_label="Mark for debt recovery"
        extra_form_data={extra_form_data}
        datatable_source_url_name="applied_loan_url"
        object_display_name="debt recovery"
      />
    );
  }
}

DebtRecovery.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(DebtRecovery));
