import React, { Component } from "react";

import { CLOSE_FINANCIAL_YEAR_PATH } from "../../constants/ClientPaths";
import DataTableActionForm from "../../components/DataTableActionForm";
import { FINANCIAL_YEAR_CLOSING_COLUMNS } from "../../components/DataTableColumns";
import { FINANCIAL_YEAR_CLOSING_ENDPOINT } from "../../constants/Constants";
import FormFields from "../../components/FormFields";
import PropTypes from "prop-types";
import ViewDataTable from "../../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ViewFinancialYearClosing extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  render() {
    const { props } = this;
    const reverse_form_items: any = [
      <FormFields
        key={1}
        field_type="textarea"
        field_label="Reversal reason"
        props={props}
        field_name="reversal_reason"
        wrapper_div_classes="col-4 pb-3"
        data_object={{}}
        required
        autofocus
      />,
    ];
    const actions: any = [
      {
        name: "reverse",
        btn_classes: "btn btn-danger",
        noun: "reversal",
        icon: "x",
        target: "financial year",
        plural: "financial years",
        form_items: reverse_form_items,
        extra_form_data: [{ name: "status", value: 12 }],
      },
    ];
    const data_source_url: any = FINANCIAL_YEAR_CLOSING_ENDPOINT;
    const data_source_url_name: string = "financial_year_closing";
    const extra_get_params: string = "transaction_type=27";
    return (
      <DataTableActionForm
        actions={actions}
        table_node={this.state.table_node}
        ids_post_field="loan_ids"
        datatable_action_endpoint={FINANCIAL_YEAR_CLOSING_ENDPOINT}
        request_method="PATCH"
        datatable_source_url={data_source_url}
        datatable_source_url_name={data_source_url_name}
      >
        <ViewDataTable
          columns={FINANCIAL_YEAR_CLOSING_COLUMNS}
          data_source_url={data_source_url}
          data_source_url_name={data_source_url_name}
          extra_get_params={extra_get_params}
          creatable
          create_button_label="Close financial year"
          edit_url={CLOSE_FINANCIAL_YEAR_PATH}
          handleSetTableNode={this.handleSetTableNode}
          select_mode="radio"
        />
      </DataTableActionForm>
    );
  }
}

ViewFinancialYearClosing.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ViewFinancialYearClosing));
