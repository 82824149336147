import {
  INVALIDATE_DATA,
  RECEIVE_DATA,
  REQUEST_DATA,
  SELECT_API_ENDPOINT,
} from "../actions/actions";

import { combineReducers } from "redux";

function sessionVariables(state = {}, action) {
  switch (action.type) {
    case SELECT_API_ENDPOINT:
      return { ...state, [action.var_name]: action.var_value };
    default:
      return state;
  }
}

function urlData(
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
  },
  action
) {
  switch (action.type) {
    case INVALIDATE_DATA:
      return {
        ...state,
        didInvalidate: true,
        isFetching: false,
        items: [],
      };
    case REQUEST_DATA:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case RECEIVE_DATA:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.items,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
}

function dataByUrl(state = {}, action) {
  switch (action.type) {
    case INVALIDATE_DATA:
    case RECEIVE_DATA:
    case REQUEST_DATA:
      return {
        ...state,
        [action.endpoint]: urlData(state[action.endpoint], action),
      };
    default:
      return state;
  }
}

const rootReducer: any = combineReducers({
  dataByUrl,
  sessionVariables,
});

export default rootReducer;
