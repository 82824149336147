import React, { Component } from "react";

export default class ToastNotification extends Component {
  render() {
    const toast_notification: any = this.props.toast_items.map(
      (toast_item, key) => (
        <div
          className="toast"
          style={{ position: "absolute", top: 0, right: 0 }}
          key={key}
        >
          <div className="toast-header">
            <strong className="mr-5">{toast_item.toast_title}</strong>
            <small>{toast_item.toast_time}</small>
            <button
              type="button"
              className="ml-2 mb-1 close"
              data-dismiss="toast"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="toast-body">{toast_item.toast_content}</div>
        </div>
      )
    );
    return (
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{ position: "relative", zIndex: 2 }}
      >
        {toast_notification}
      </div>
    );
  }
}
