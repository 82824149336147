import React, { Component } from "react";

import { GROUP_DETAILS_PATH } from "../../constants/ClientPaths";
import PropTypes from "prop-types";
import { USER_GROUPS_COLUMNS } from "../../components/DataTableColumns";
import { USER_GROUPS_ENDPOINT } from "../../constants/Constants";
import ViewDataTable from "../../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ViewUserGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  render() {
    const data_source_url: any = USER_GROUPS_ENDPOINT;
    const data_source_url_name: string = "paginated_user_groups_url";
    return (
      <ViewDataTable
        columns={USER_GROUPS_COLUMNS}
        data_source_url={data_source_url}
        data_source_url_name={data_source_url_name}
        creatable
        editable
        edit_url={GROUP_DETAILS_PATH}
        handleSetTableNode={this.handleSetTableNode}
      />
    );
  }
}

ViewUserGroups.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ViewUserGroups));
