import {
  REGISTER_MEMBER_ACCOUNT_DETAILS_PATH,
  REGISTER_MEMBER_BANK_DETAILS_PATH,
  REGISTER_MEMBER_DETAILS_PATH,
  REGISTER_MEMBER_GROUP_DETAILS_PATH,
  REGISTER_MEMBER_MEMBER_UPLOADS_PATH,
  REGISTER_MEMBER_WORK_DETAILS_PATH,
  REGISTER_NEXT_OF_KIN_DETAILS,
} from "../../constants/ClientPaths";
import React, { Component } from "react";

import VIewAddPortlet from "../../components/VIewAddPortlet";
import { withRouter } from "react-router-dom";

class RegisterMember extends Component {
  render() {
    const pills: any = [
      {
        label: "Personal Details",
        path: REGISTER_MEMBER_DETAILS_PATH,
      },
      {
        label: "Next of Kin",
        path: REGISTER_NEXT_OF_KIN_DETAILS,
      },
      {
        label: "Bank Details",
        path: REGISTER_MEMBER_BANK_DETAILS_PATH,
      },
      {
        label: "Enroll to Group",
        path: REGISTER_MEMBER_GROUP_DETAILS_PATH,
      },
      {
        label: "Savings & Shares Account",
        path: REGISTER_MEMBER_ACCOUNT_DETAILS_PATH,
      },
      {
        label: "Work Details",
        path: REGISTER_MEMBER_WORK_DETAILS_PATH,
      },
      {
        label: "Attachments",
        path: REGISTER_MEMBER_MEMBER_UPLOADS_PATH,
      },
    ];
    return (
      <VIewAddPortlet header_label="Register member" pills={pills}>
        {this.props.children}
      </VIewAddPortlet>
    );
  }
}

export default withRouter(RegisterMember);
