import { MEMBER_PROFILE_COLUMNS } from "../components/DataTableColumns";
import FormChangePassword from "../account/FormChangePassword";
import {
  MEMBER_PROFILE_ENDPOINT,
  MEMBER_LOGIN_ENDPOINT,
} from "../constants/Constants";
import React, { Component } from "react";
import FormFeedbackMessage from "../components/FormFeedbackMessage";
import ViewDataTable from "../components/ViewDataTable";
import { label } from "../utils/componentActions";
import { withRouter } from "react-router";

class MemberProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleUpdateState = (status, cb) => {
    this.setState(status);
  };

  render() {
    const { member, auth_token } = localStorage;
    const member_profile_url: string = `${MEMBER_PROFILE_ENDPOINT}${member}/?member=${member}&token=${auth_token}`;

    const feedback_message: any = (
      <FormFeedbackMessage
        feedback_message={
          this.state.feedback_message || this.props.feedback_message
        }
        feedback_type={this.state.feedback_type || this.props.feedback_type}
      />
    );

    return (
      <div className="container">
        <div className="row">
          <div className="col">
            <ViewDataTable
              paginated={false}
              basic_table
              vertical_headers
              columns={MEMBER_PROFILE_COLUMNS}
              data_source_url={member_profile_url}
              csv_export={false}
              data_source_url_name="member_profile_url"
            />
          </div>

          <div className="col mt-3">
            {feedback_message}

            <div className="card">
              <div className="card-header bg-green text-center p-2">
                <span className="font-weight-bold text-white">
                  {label("Change password", this.props)}
                </span>
              </div>
              <div className="card-body">
                <FormChangePassword
                  action_url={MEMBER_LOGIN_ENDPOINT + member + "/"}
                  activity={this.state.activity}
                  handleUpdateState={this.handleUpdateState}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(MemberProfile);
