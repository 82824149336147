import React, { Component } from "react";
import { fetchUrlData, getUrlData } from "../../utils/componentActions";

import { CURRENCIES_ENDPOINT } from "../../constants/Constants";
import CreateEditForm from "../../components/CreateEditForm";
import Data from "currency-codes/data";
import FormFields from "../../components/FormFields";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class RegisterCurrency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currency: {},
    };
  }

  componentDidMount() {
    const currency_id: any = this.props.match.params.id;
    if (currency_id) {
      const currency_url: string = `${CURRENCIES_ENDPOINT}${currency_id}/`;
      fetchUrlData("currency_url", currency_url, this.props);
    }
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  handleCurrencyChange = (select_obj) => {
    const { currency } = this.state;
    currency.code = select_obj.code;
    this.setState({
      currency,
    });
  };

  render() {
    const { props } = this;
    const { currency_data } = props;
    const { currency } = this.state;
    const form_classes: string = "row g-3";
    const currencies_list: any = Data.map((currency) => ({
      value: currency.currency,
      label: currency.currency,
      code: currency.code,
    }));
    let currency_code_list: any = Data.map((currency) => ({
      value: currency.code,
      label: currency.code,
    }));
    if (currency.code) {
      currency_code_list = currency_code_list.filter(
        (currency_code) => currency_code.value === currency.code
      );
    }
    const form_items: any = [
      <FormFields
        key={1}
        field_type="select2"
        field_label="Name"
        props={props}
        field_name="name"
        wrapper_div_classes="col-4"
        select2_options={currencies_list}
        onChange={this.handleCurrencyChange}
        data_object={currency}
        required
      />,
      <FormFields
        key={2}
        field_type="select2"
        field_label="Code"
        props={props}
        field_name="code"
        wrapper_div_classes="col-4"
        select2_options={currency_code_list}
        data_object={currency}
        required
      />,
    ];
    return (
      <CreateEditForm
        loading={currency_data.isFetching}
        form_items={form_items}
        form_classes={form_classes}
        action_object={currency_data.items}
        setState={this.updateState}
        action_object_name="currency"
        state={this.state}
        action_object_endpoint={CURRENCIES_ENDPOINT}
        object_display_name="currency"
      />
    );
  }
}

RegisterCurrency.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  currency_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const currency_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "currency_url"
  );

  return {
    sessionVariables,
    currency_data,
  };
}

export default connect(mapStateToProps)(withRouter(RegisterCurrency));
