import React, { Component } from "react";

import { MEMBER_UPLOADS_ENDPOINT } from "../../constants/Constants";
import PropTypes from "prop-types";
import { REGISTER_MEMBER_MEMBER_UPLOADS_PATH } from "../../constants/ClientPaths";
import { VIEW_MEMBER_UPLOADS_COLUMNS } from "../../components/DataTableColumns";
import ViewDataTable from "../../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class ViewMemberUploads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table_node: null,
    };
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  render() {
    return (
      <ViewDataTable
        columns={VIEW_MEMBER_UPLOADS_COLUMNS}
        csv_export
        allow_refresh
        creatable
        editable
        edit_url={REGISTER_MEMBER_MEMBER_UPLOADS_PATH}
        data_source_url={MEMBER_UPLOADS_ENDPOINT}
        handleSetTableNode={this.handleSetTableNode}
        data_source_url_name="paginated_member_uploads_url"
      />
    );
  }
}

ViewMemberUploads.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ViewMemberUploads));
