import {
  APPLIED_LOANS_ENDPOINT,
  COLLATERAL_ENDPOINT,
  LOAN_GUARANTORS_ENDPOINT,
  NON_MEMBERS_ENDPOINT,
} from "../../constants/Constants";
import {
  GUARANTEED_LOANS_COLUMNS,
  LOAN_GUARANTORS_COLUMNS,
} from "../../components/DataTableColumns";
import React, { Component } from "react";
import {
  fetchUrlData,
  getSelect2Options,
  getUrlData,
} from "../../utils/componentActions";

import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import ModalHOC from "../../components/ModalHOC";
import PropTypes from "prop-types";
import ViewDataTable from "../../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class AddLoanGuarantor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loan_guarantor: {},
      guarantor_type: "member",
      collateral_or_savings: "savings",
      guaranteed_loans_get_params: "",
      loan_guarantors_get_params: "",
    };
  }

  componentDidMount() {
    const guarantee_loans_url: string = `${APPLIED_LOANS_ENDPOINT}?status__in=1,6,9,11`;
    fetchUrlData("guarantee_loans_url", guarantee_loans_url, this.props);
    fetchUrlData("non_members_url", NON_MEMBERS_ENDPOINT, this.props);
    const loan_guarantor_id: any = this.props.match.params.id;
    if (loan_guarantor_id) {
      const loan_guarantor_url: string = `${LOAN_GUARANTORS_ENDPOINT}${loan_guarantor_id}/`;
      fetchUrlData("loan_guarantor_url", loan_guarantor_url, this.props);
    }
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  handleMemberNonMemberChange(member_non_member, type) {
    const active_collateral_url: string = `${COLLATERAL_ENDPOINT}?status=1&${type}=${member_non_member.value}`;
    fetchUrlData("active_collateral_url", active_collateral_url, this.props);
    const guaranteed_loans_get_params: string = `status=1&${type}=${member_non_member.value}`;
    this.setState({
      guaranteed_loans_get_params,
    });
  }

  handleLoanChange(select_obj) {
    this.setState({
      loan_guarantors_get_params: `loan=${select_obj.value}`,
    });
  }

  render() {
    const { props } = this;
    const {
      guarantee_loans_data,
      non_members_data,
      active_collateral_data,
      loan_guarantor_data,
    } = props;
    const { loan_guarantor } = this.state;
    const guarantee_loans: any = guarantee_loans_data.items;
    const non_members: any = non_members_data.items;
    const active_collateral: any = active_collateral_data.items;
    const view_guaranteed_loans: any = (
      <ModalHOC
        button_icon="eye"
        button_label="View guaranteed loans"
        button_classes="btn btn-outline-primary"
        modal_size="modal-xl"
        show_close_button
        data_target="guaranteed-loans-modal"
      >
        <ViewDataTable
          columns={GUARANTEED_LOANS_COLUMNS}
          wait_for_props
          extra_get_params={this.state.guaranteed_loans_get_params}
          data_source_url={LOAN_GUARANTORS_ENDPOINT}
          data_source_url_name="guaranteed_loans_url"
        />
      </ModalHOC>
    );
    const view_loan_guarantors: any = (
      <ModalHOC
        button_icon="eye"
        button_label="View current guarantors"
        button_classes="btn btn-outline-primary"
        modal_size="modal-xl"
        show_close_button
        data_target="loan-guarantors-modal"
      >
        <ViewDataTable
          columns={LOAN_GUARANTORS_COLUMNS}
          wait_for_props
          extra_get_params={this.state.loan_guarantors_get_params}
          data_source_url={LOAN_GUARANTORS_ENDPOINT}
          data_source_url_name="loan_guarantors_url"
        />
      </ModalHOC>
    );

    const loan_option_value_field: string =
      "[member_name]([member_no])-[loan_name]-[loan_reference_no]([approved_amount])";
    const loan_option_field_map: string[] = [
      "member_name",
      "member_no",
      "loan_name",
      "loan_reference_no",
      "approved_amount",
    ];
    const collateral_option_field_map: string[] = [
      "member_name",
      "non_member_name",
      "collateral_id_no",
    ];
    const collateral_option_value_field: string =
      "[member_name][non_member_name]-[collateral_id_no]";

    const active_loans_options: any = getSelect2Options(
      guarantee_loans,
      "id",
      loan_option_value_field,
      loan_option_field_map
    );
    const non_members_options: any = getSelect2Options(
      non_members,
      "id",
      "non_member_name"
    );
    const collateral_options: any = getSelect2Options(
      active_collateral,
      "id",
      collateral_option_value_field,
      collateral_option_field_map
    );

    let member_non_member_field: any = (
      <FormFields
        key={2}
        field_type="member"
        field_label="Member"
        props={props}
        field_name="member"
        wrapper_div_classes="col-4"
        onChange={(selected) =>
          this.handleMemberNonMemberChange(selected, "member")
        }
        bottom_append={view_guaranteed_loans}
        data_object={loan_guarantor}
        required
      />
    );
    if (this.state.guarantor_type === "non_member") {
      member_non_member_field = (
        <FormFields
          key={2}
          field_type="select2"
          field_label="Non-member"
          props={props}
          field_name="non_member"
          wrapper_div_classes="col-4"
          data_object={loan_guarantor}
          required
          onChange={(selected) =>
            this.handleMemberNonMemberChange(selected, "non_member")
          }
          select2_options={non_members_options}
        />
      );
    }
    let savings_collateral_field: any = (
      <FormFields
        key={4}
        field_type="number_input"
        field_label="Savings amount"
        props={props}
        field_name="savings_amount"
        wrapper_div_classes="col-4"
        data_object={loan_guarantor}
        required
      />
    );
    if (this.state.collateral_or_savings === "2") {
      savings_collateral_field = (
        <FormFields
          key={4}
          field_type="select2"
          field_label="Collateral"
          props={props}
          field_name="collateral"
          wrapper_div_classes="col-4"
          data_object={loan_guarantor}
          required
          select2_options={collateral_options}
        />
      );
    }
    const form_classes: string = "row g-3";
    const form_items: any = [
      <FormFields
        key={1}
        field_type="select"
        field_label="Guarantor type"
        props={props}
        field_name="guarantor_type"
        wrapper_div_classes="col-4"
        data_object={loan_guarantor}
        required
        onChange={(e) => this.setState({ guarantor_type: e.target.value })}
      >
        <option value="member">Member</option>
        <option value="non_member">Non-member</option>
      </FormFields>,
      member_non_member_field,
      <FormFields
        key={3}
        field_type="select"
        field_label="Collateral/Savings?"
        props={props}
        field_name="collateral_or_savings"
        wrapper_div_classes="col-4"
        data_object={loan_guarantor}
        required
        onChange={(e) =>
          this.setState({ collateral_or_savings: e.target.value })
        }
      >
        <option value="1">Savings</option>
        <option value="2">Collateral</option>
      </FormFields>,
      savings_collateral_field,
      <FormFields
        key={5}
        field_type="select2"
        field_label="Loan to guarantee"
        props={props}
        field_name="loan"
        wrapper_div_classes="col-4"
        data_object={loan_guarantor}
        required
        onChange={(selected) => this.handleLoanChange(selected)}
        bottom_append={view_loan_guarantors}
        select2_options={active_loans_options}
      />,
    ];
    return (
      <CreateEditForm
        loading={guarantee_loans_data.isFetching}
        form_items={form_items}
        form_classes={form_classes}
        action_object={loan_guarantor_data.items}
        setState={this.updateState}
        action_object_name="loan_guarantor"
        state={this.state}
        action_object_endpoint={LOAN_GUARANTORS_ENDPOINT}
        object_display_name="loan guarantor"
      />
    );
  }
}

AddLoanGuarantor.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  guarantee_loans_data: PropTypes.instanceOf(Object).isRequired,
  non_members_data: PropTypes.instanceOf(Object).isRequired,
  active_collateral_data: PropTypes.instanceOf(Object).isRequired,
  loan_guarantor_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const guarantee_loans_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "guarantee_loans_url"
  );
  const non_members_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "non_members_url"
  );
  const active_collateral_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "active_collateral_url"
  );
  const loan_guarantor_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "loan_guarantor_url"
  );

  return {
    sessionVariables,
    guarantee_loans_data,
    non_members_data,
    active_collateral_data,
    loan_guarantor_data,
  };
}

export default connect(mapStateToProps)(withRouter(AddLoanGuarantor));
