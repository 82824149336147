import { MEMBER_PORTAL_ACCOUNTS_COLUMNS } from "../components/DataTableColumns";
import { MEMBER_PORTAL_ACCOUNTS_ENDPOINT } from "../constants/Constants";
import { MEMBER_VIEW_ACCOUNT_STATEMENT_PATH } from "../constants/ClientPaths";
import React from "react";
import ViewDataTable from "../components/ViewDataTable";
import { label } from "../utils/componentActions";
import { withRouter } from "react-router-dom";

function MemberViewAccounts(props) {
  const { member, auth_token } = localStorage;

  return (
    <div className="card">
      <div className="card-header bg-green text-center p-2">
        <span className="font-weight-bold text-white">
          {label("View Accounts", props)}
        </span>
      </div>

      <div className="card-body">
        <ViewDataTable
          columns={MEMBER_PORTAL_ACCOUNTS_COLUMNS}
          csv_export={false}
          viewable
          view_url={MEMBER_VIEW_ACCOUNT_STATEMENT_PATH}
          extra_get_params={`token=${auth_token}&member=${member}`}
          data_source_url={MEMBER_PORTAL_ACCOUNTS_ENDPOINT}
          data_source_url_name="paginated_member_accounts_url"
        />
      </div>
    </div>
  );
}

export default withRouter(MemberViewAccounts);
