import React, { Component } from "react";
import { fetchUrlData, getUrlData } from "../utils/componentActions";

import DataTable from "../components/DataTable";
import { NON_MEMBERS_ENDPOINT } from "../constants/Constants";
import PropTypes from "prop-types";
import { REGISTER_NON_MEMBERS_PATH } from "../constants/ClientPaths";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class ViewNonMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 5,
      table_node: null,
    };
  }

  componentDidMount() {
    const paginated_non_members_url: string = `${NON_MEMBERS_ENDPOINT}?limit=5&offset=0`;
    fetchUrlData(
      "paginated_non_members_url",
      paginated_non_members_url,
      this.props
    );
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  handleTableChange = (type, change_obj) => {
    const { sizePerPage } = change_obj;
    const { page } = change_obj;
    const search_text: any = change_obj.searchText;
    const offset: any = (page - 1) * sizePerPage;
    const { group } = this.state;
    const get_params: string = `group=${group}&limit=${sizePerPage}&offset=${offset}&search=${search_text}`;
    const paginated_non_members_url: string = `${NON_MEMBERS_ENDPOINT}?${get_params}`;
    fetchUrlData(
      "paginated_non_members_url",
      paginated_non_members_url,
      this.props
    );
    this.setState(() => ({
      page,
      sizePerPage,
    }));
  };

  render() {
    const { non_members_data } = this.props;
    const non_members: any = non_members_data.items;
    const non_members_columns: any = [
      {
        dataField: "id",
        text: "ID",
      },
      {
        dataField: "first_name",
        text: "First name",
      },
      {
        dataField: "middle_name",
        text: "Middle name",
      },
      {
        dataField: "last_name",
        text: "Last name",
      },
      {
        dataField: "phone_number",
        text: "Phone number",
      },
      {
        dataField: "id_number",
        text: "ID number",
      },
    ];
    return (
      <DataTable
        handleTableChange={this.handleTableChange}
        component_data={non_members}
        data_columns={non_members_columns}
        page={this.state.page}
        handleSetTableNode={this.handleSetTableNode}
        loading={non_members_data.isFetching}
        creatable
        editable
        edit_url={REGISTER_NON_MEMBERS_PATH}
        edit_url_type="soft"
      />
    );
  }
}

ViewNonMembers.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  non_members_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const non_members_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "paginated_non_members_url"
  );

  return {
    sessionVariables,
    non_members_data,
  };
}

export default connect(mapStateToProps)(withRouter(ViewNonMembers));
