import { MEMBER_REVERSED_LOANS_COLUMNS } from "../components/DataTableColumns";
import MemberViewAppliedLoans from "./ViewAppliedLoans";
import React from "react";
import { withRouter } from "react-router-dom";

function MemberViewReversedLoans() {
  return (
    <MemberViewAppliedLoans
      status={12}
      columns={MEMBER_REVERSED_LOANS_COLUMNS}
    />
  );
}

export default withRouter(MemberViewReversedLoans);
