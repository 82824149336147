import React, { Component } from "react";
import { fetchUrlData, getUrlData } from "../../utils/componentActions";

import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import PropTypes from "prop-types";
import { SMS_NOTIFICATION_SETTINGS_ENDPOINT } from "../../constants/Constants";
import { SMS_NOTIFICATION_SETTINGS_PATH } from "../../constants/ClientPaths";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment-timezone";

class SMSNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {},
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
      sms_reminders_enabled: false,
    };
  }

  componentDidMount() {
    fetchUrlData(
      "sms_notification_settings_url",
      `${SMS_NOTIFICATION_SETTINGS_ENDPOINT}i/`,
      this.props
    );

    const { sms_notification_settings_data } = this.props;

    if (!sms_notification_settings_data.isFetching) {
      const settings = sms_notification_settings_data.items;
      this.setState((prevState) => ({
        settings,
        sms_reminders_enabled: settings.sms_reminders_enabled,
      }));
    }
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const { sms_notification_settings_data } = props;
    const { settings, sms_reminders_enabled } = this.state;
    const sms_reminder_fields: any = [
      <FormFields
        key={13}
        field_type="select"
        field_label="Loan due payment"
        props={props}
        field_name="sms_on_loans_due_payment"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={14}
        field_type="select"
        field_label="Loan overdue payment"
        props={props}
        field_name="sms_on_loans_overdue_payment"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={21}
        field_type="select"
        field_label="Defaulted loans"
        props={props}
        field_name="sms_on_defaulted_loans"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={25}
        field_type="select"
        field_label="Loan debt recovery"
        props={props}
        field_name="sms_on_loan_debt_recovery"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={22}
        field_type="select"
        field_label="CRB listing"
        props={props}
        field_name="sms_to_notify_crb_listing"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={23}
        field_type="select"
        field_label="Guarantor defaulted loan"
        props={props}
        field_name="sms_guarantor_on_defaulted_loans"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={15}
        field_type="text_input"
        field_label="Number of days before payment"
        props={props}
        field_name="no_of_days_before_payment_sms"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={16}
        field_type="text_input"
        field_label="Number of days after payment"
        props={props}
        field_name="no_of_days_after_payment_sms"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={30}
        field_type="time"
        field_label="Time to send reminder"
        props={props}
        field_name="sms_reminder_time"
        wrapper_div_classes="col-4"
        data_object={settings}
        required
      />,
    ];
    const form_items: any = [
      <FormFields
        key={1}
        field_type="select"
        field_label="Loan application"
        props={props}
        field_name="sms_on_loan_application"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={2}
        field_type="select"
        field_label="Loan guarantor"
        props={props}
        field_name="sms_on_loan_guarantor"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={3}
        field_type="select"
        field_label="Loan guarantee"
        props={props}
        field_name="sms_on_loan_guarantee"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={4}
        field_type="select"
        field_label="Loan approval"
        props={props}
        field_name="sms_on_loan_approval"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={5}
        field_type="select"
        field_label="Loan rejection"
        props={props}
        field_name="sms_on_loan_rejection"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={6}
        field_type="select"
        field_label="Loan processing"
        props={props}
        field_name="sms_on_loan_processing"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={7}
        field_type="select"
        field_label="Loan reversal"
        props={props}
        field_name="sms_on_loan_reversal"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={8}
        field_type="select"
        field_label="Loan disbursement"
        props={props}
        field_name="sms_on_loan_disbursement"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={9}
        field_type="select"
        field_label="Loan payment"
        props={props}
        field_name="sms_on_loan_payment"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={10}
        field_type="select"
        field_label="Loan completion"
        props={props}
        field_name="sms_on_loan_completion"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={11}
        field_type="select"
        field_label="User to approve loan"
        props={props}
        field_name="sms_on_user_to_approve"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={12}
        field_type="select"
        field_label="User has approved loan"
        props={props}
        field_name="sms_on_user_has_approved"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={17}
        field_type="select"
        field_label="Billing invoice"
        props={props}
        field_name="sms_on_billing_invoice"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={18}
        field_type="select"
        field_label="Member account contribution"
        props={props}
        field_name="sms_on_member_contribution"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={19}
        field_type="select"
        field_label="Realtime loan penalties"
        props={props}
        field_name="sms_on_realtime_loan_penalties"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={20}
        field_type="select"
        field_label="Mobile loans"
        props={props}
        field_name="marketplace_sms"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={24}
        field_type="select"
        field_label="Loan overpayment"
        props={props}
        field_name="sms_on_loan_overpayment"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={26}
        field_type="select"
        field_label="Member registration"
        props={props}
        field_name="sms_on_member_registration"
        wrapper_div_classes="col-4"
        data_object={settings}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={27}
        field_type="select"
        field_label="Send SMS reminders"
        props={props}
        field_name="sms_reminders_enabled"
        wrapper_div_classes="col-4"
        data_object={settings}
        onChange={(selected) =>
          this.setState(() => ({
            sms_reminders_enabled: selected.target.value === "true",
          }))
        }
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <>{sms_reminders_enabled && sms_reminder_fields}</>,
    ];
    const form_classes: string = "row g-3";
    return (
      <CreateEditForm
        loading={sms_notification_settings_data.isFetching}
        form_items={form_items}
        form_classes={form_classes}
        action_object={sms_notification_settings_data.items}
        action_object_id={sms_notification_settings_data.items.id}
        setState={this.updateState}
        action_object_name="settings"
        state={this.state}
        action_object_endpoint={SMS_NOTIFICATION_SETTINGS_ENDPOINT}
        fetch_data_urls={[
          {
            url_name: "sms_notification_settings_url",
            url: `${SMS_NOTIFICATION_SETTINGS_ENDPOINT}i/`,
          },
        ]}
        base_path={SMS_NOTIFICATION_SETTINGS_PATH}
        obj_id_field="id"
        object_display_name="notification settings"
        push_id_to_path
        extra_form_data={[{ name: "timezone", value: moment.tz.guess() }]}
      />
    );
  }
}

SMSNotification.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  sms_notification_settings_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const sms_notification_settings_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "sms_notification_settings_url"
  );

  return {
    sessionVariables,
    sms_notification_settings_data,
  };
}

export default connect(mapStateToProps)(withRouter(SMSNotification));
