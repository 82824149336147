import {
  GROUPS_ENDPOINT,
  OBJECT_STORAGE_ENDPOINT,
} from "../constants/Constants";
import React, { Component } from "react";
import {
  fetchUrlData,
  getSelect2Options,
  getSelectedOptions,
  getUrlData,
  handleFieldChange,
  label,
  lookup,
  objGetField,
} from "../utils/componentActions";

import BaseSelect from "react-select-oss";
import BootstrapIcon from "./BootstrapIcon";
import CreatableSelect from "react-select-oss/creatable";
import Datetime from "react-datetime";
import IntlTelInput from "react-intl-tel-input";
import MembersSelectDropdown from "./MembersSelectDropdown";
import PropTypes from "prop-types";
import ReactSelectRequired from "./ReactSelectRequired";
import SelectAsync from "./SelectAsync";
import { connect } from "react-redux";
import merge from "lodash/merge";
import { withRouter } from "react-router";
import moment from "moment-timezone";

class FormFields extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      file_field_filename: label("Choose file...", props),
    };
    this.state = merge({}, this.initialState);
  }

  componentDidMount() {
    if (this.props.field_type === "member") {
      fetchUrlData("groups_url", GROUPS_ENDPOINT, this.props);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const data_object: any = this.props.data_object || {};
    const previous_data_object: any = prevProps.data_object || {};
    if (data_object.reset_fields && !previous_data_object.reset_fields) {
      const state_object: any = this.state;
      if (this.props.field_type === "checkbox") {
        state_object[this.props.field_name] = this.props.checkbox_checked;
      } else {
        state_object[this.props.field_name] = "";
      }
      this.setState(state_object);
    }
  }

  generateTimeStamp(field_name, prefix) {
    const milliseconds: any = new Date().getTime();
    this.setState({
      [field_name]: prefix + milliseconds,
    });
  }

  handleFieldChange = (
    e,
    callback,
    field_name = null,
    field_value = null,
    select_field = false,
    create_field_name = null
  ) => {
    let payload: any = this.state;
    const data_object: any = this.props.data_object || {};
    if (select_field && create_field_name) {
      if (field_value.__isNew__) {
        delete data_object[field_name];
        field_name = create_field_name;
      } else {
        delete data_object[create_field_name];
      }
    }
    const { data_object_name } = this.props;
    payload =
      typeof data_object === "object"
        ? handleFieldChange(
            e,
            field_name,
            field_value,
            select_field,
            payload,
            data_object
          )
        : handleFieldChange(e, field_name, field_value, select_field, payload);
    if (this.props.update_payload && data_object_name) {
      const updateState: any = this.props.updateState || function () {};
      updateState({ [data_object_name]: data_object });
    }
    this.setState(payload, () => callback);
  };

  handleDateChange = (e, onChange, field_name, selected) => {
    this.handleFieldChange(e, onChange, field_name, selected);
  };

  toggleShowPassword(field_name) {
    const password_visible: any =
      this.state[`${field_name}_password_visible`] || false;
    this.setState({
      [`${field_name}_password_visible`]: !password_visible,
    });
  }

  render() {
    const { groups_data } = this.props;
    const { field_type } = this.props;
    let field: string = "";
    const { field_label } = this.props;
    const { checkbox_field_label } = this.props;
    const { field_name } = this.props;
    const data_object_field: any = this.props.data_object_field || field_name;
    const { wrapper_div_classes } = this.props;
    const { data_object } = this.props;
    const { props } = this;
    let required: any = this.props.required || false;
    const min: any = this.props.min || 0;
    const max: any = this.props.max;
    let { select2_options } = this.props;
    const select2_value_key: any =
      this.props.select2_value_key || data_object_field;
    const autofill_timestamp: any = this.props.autofill_timestamp || false;
    const autofill_prefix: any = this.props.autofill_prefix || "";
    const autofocus: any = this.props.autofocus || false;
    const select2_default_first: any =
      this.props.select2_default_first || false;
    const step: any = this.props.step || "1";
    const { state } = this;
    const select2_create_field_name: any =
      this.props.select2_create_field_name || data_object_field;
    let bottom_append: any = (
      <div className="mt-2">{this.props.bottom_append}</div>
    ) || <div />;
    let related_field_append: any = <React.Fragment key={1} />;
    const textarea_rows: any = this.props.textarea_rows || 2;
    const { checkbox_checked } = this.props;
    const { text_input_readonly } = this.props;
    const { static_field_value } = this.props;
    const { input_group } = this.props;
    const { input_group_append } = this.props;
    const { is_input_group_append } = this.props;
    const { datepicker_direction } = this.props;
    const onChange: any = this.props.onChange || function () {};
    const selected_member: any = this.props.selected_member || null;
    const selected_data: any = this.props.selected_data || null;
    const form_label_classes: any = required
      ? "form-label required"
      : "form-label";
    const Select: any = (selectProps) => (
      <ReactSelectRequired
        {...selectProps}
        placeholder={`${label("Select", props)}...`}
        SelectComponent={BaseSelect}
        options={selectProps.options}
      />
    );
    if (["date", "datetime", "time"].includes(field_type)) {
      let user_timezone = moment.tz.guess();
      let time_format: boolean = false;
      let date_format: any = "YYYY-MM-DD";
      let datetime_icon = "calendar4-event";
      if (field_type === "datetime") {
        time_format = true;
      }
      if (field_type === "time") {
        date_format = false;
        time_format = "HH:mm";
        datetime_icon = "clock";
      }
      let datepicker_classes: string = "";
      if (datepicker_direction === "up") {
        datepicker_classes = "rdtPickerOpenUpwards";
      }
      const datepickerInput: any = function (datePickerProps, openCalendar) {
        return (
          <div className="input-group" onClick={() => openCalendar()}>
            <input
              type="text"
              className="form-control"
              {...datePickerProps}
              data-readonly
            />
            <span className="input-group-text" role="button">
              <BootstrapIcon icon={datetime_icon} />
            </span>
          </div>
        );
      };
      field = (
        <Datetime
          timeFormat={time_format}
          dateFormat={date_format}
          closeOnSelect
          onChange={(selected, e) =>
            this.handleDateChange(e, onChange(selected), field_name, selected)
          }
          displayTimeZone={user_timezone}
          className={datepicker_classes}
          renderInput={datepickerInput}
          value={state[field_name] || data_object[field_name]}
          onBlur={() => this.setState({ datepicker_open: false })}
          inputProps={{ name: field_name, required }}
        />
      );
    } else if (field_type === "member") {
      let group_filter: object = null;
      const current_bottom_append: any = bottom_append;
      bottom_append = (
        <div className="d-flex flex-row mb-3">
          <div className="mt-2 mr-2">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="toggle-assign"
              onChange={(e) =>
                this.handleFieldChange(e, onChange(e), "filter_by_group")
              }
            />
            <label className="form-check-label ml-1" htmlFor="toggle-assign">
              {label("Filter by group", props)}
            </label>
          </div>
          {current_bottom_append}
        </div>
      );
      if (state.filter_by_group) {
        const groups: any = groups_data.items;
        const group_options_label: string = "[group_name]([group_code])";
        const group_options_label_map: string[] = ["group_name", "group_code"];
        const group_options: any = getSelect2Options(
          groups,
          "id",
          group_options_label,
          group_options_label_map
        );
        const select2_value: any = getSelectedOptions(
          state.group_filter,
          group_options
        );
        related_field_append = (
          <div className={wrapper_div_classes} key={1}>
            <label className={form_label_classes}>
              {label("Group", props)}
            </label>
            <Select
              options={group_options}
              name="group_filter"
              onChange={(selected, e) =>
                this.handleFieldChange(
                  e,
                  onChange(selected),
                  "group_filter",
                  selected,
                  true
                )
              }
              value={select2_value}
            />
          </div>
        );
        group_filter = state.group_filter;
      }
      field = (
        <MembersSelectDropdown
          field_name={field_name}
          required={required}
          group_filter={group_filter}
          onChange={(selected) =>
            this.setState(
              {
                [field_name]: selected,
              },
              () => onChange(selected)
            )
          }
          defaultValue={
            state[field_name] ||
            selected_member ||
            data_object[field_name] ||
            ""
          }
        />
      );
    } else if (field_type === "text_input") {
      let input_value: any = state[field_name] || data_object[field_name] || "";
      if (static_field_value) {
        input_value = static_field_value;
      }
      let autofill_button: string = "";
      if (autofill_timestamp) {
        autofill_button = (
          <button
            className="btn btn-outline-primary mt-2"
            type="button"
            onClick={() => this.generateTimeStamp(field_name, autofill_prefix)}
          >
            <BootstrapIcon icon="hammer" /> {label("Generate", props)}
          </button>
        );
      }
      field = (
        <>
          <div className="input-group">
            <input
              className="form-control"
              name={field_name}
              required={required}
              onChange={(e) =>
                this.handleFieldChange(e, onChange(e), field_name)
              }
              value={input_value}
              readOnly={text_input_readonly}
            />
            <span className="input-group-text">
              <BootstrapIcon icon="input-cursor-text" />
            </span>
          </div>
          {autofill_button}
        </>
      );
    } else if (field_type === "password") {
      const password_visible: any =
        state[`${field_name}_password_visible`] || false;
      let password_field_type: string = "password";
      let eye_icon: string = "eye";
      if (password_visible) {
        password_field_type = "text";
        eye_icon = "eye-slash";
      }
      field = (
        <div className="input-group">
          <input
            className="form-control"
            type={password_field_type}
            name={field_name}
            required={required}
            onChange={(e) => this.handleFieldChange(e, onChange(e), field_name)}
            value={state[field_name] || ""}
          />
          <span
            className="input-group-text"
            role="button"
            onClick={() => this.toggleShowPassword(field_name)}
          >
            <BootstrapIcon icon={eye_icon} />
          </span>
        </div>
      );
    } else if (field_type === "email") {
      field = (
        <div className="input-group">
          <input
            className="form-control"
            type="email"
            name={field_name}
            required={required}
            onChange={(e) => this.handleFieldChange(e, onChange(e), field_name)}
            value={state[field_name] || data_object[field_name] || ""}
          />
          <span className="input-group-text">@</span>
        </div>
      );
    } else if (field_type === "textarea") {
      field = (
        <div className="input-group">
          <textarea
            className="form-control"
            name={field_name}
            required={required}
            onChange={(e) => this.handleFieldChange(e, onChange(e), field_name)}
            rows={textarea_rows}
            value={state[field_name] || data_object[field_name] || ""}
            style={{ resize: "none" }}
            autoFocus={autofocus}
          />
          <span
            className="input-group-text"
            role="button"
            onClick={() => this.toggleShowPassword(field_name)}
          >
            <BootstrapIcon icon="fonts" />
          </span>
        </div>
      );
    } else if (field_type === "number_input") {
      let field_value: string = "";
      if (![null, undefined].includes(state[field_name])) {
        field_value = state[field_name];
      } else if (![null, undefined].includes(data_object[data_object_field])) {
        field_value = data_object[data_object_field];
      }
      field = (
        <input
          type="number"
          min={min}
          max={max}
          className="form-control"
          name={field_name}
          step={step}
          onChange={(e) => this.handleFieldChange(e, onChange(e), field_name)}
          value={field_value}
          required={required}
        />
      );
      if (!input_group) {
        field = (
          <div className="input-group">
            {field}
            <span className="input-group-text">
              <BootstrapIcon icon="input-cursor" />
            </span>
          </div>
        );
      }
    } else if (field_type === "select") {
      field = (
        <select
          className="form-select"
          name={field_name}
          onChange={(e) => this.handleFieldChange(e, onChange(e), field_name)}
          value={state[field_name] || data_object[data_object_field]}
          defaultValue={data_object[field_name]}
        >
          {React.Children.map(this.props.children, function (option) {
            const value = option.props.value;
            const display = option.props.children;

            return <option value={value}>{label(display, props)}</option>;
          })}
        </select>
      );
    } else if (field_type === "select2") {
      if (state[`${select2_value_key}_optgroup_display`]) {
        select2_options = select2_options.find(
          (select2_option) =>
            select2_option.label ===
            state[`${select2_value_key}_optgroup_display`]
        ).options;
      }
      const default_value: any = getSelectedOptions(
        data_object[select2_value_key],
        select2_options,
        select2_default_first
      );
      const select2_value: any =
        getSelectedOptions(state[select2_value_key], select2_options) ||
        default_value;
      field = (
        <Select
          options={select2_options}
          name={field_name}
          required={required}
          isClearable={this.props.isClearable || false}
          onChange={(selected, e) =>
            this.handleFieldChange(
              e,
              onChange(selected),
              select2_value_key,
              selected,
              true
            )
          }
          value={select2_value}
        />
      );
    } else if (field_type === "select2_async") {
      field = (
        <SelectAsync
          endpoint={this.props.endpoint}
          value_key={this.props.value_key}
          label_key={this.props.label_key}
          field_name={field_name}
          required={required}
          onChange={(selected) =>
            this.setState(
              {
                [field_name]: selected,
              },
              () => onChange(selected)
            )
          }
          defaultValue={
            state[field_name] || selected_data || data_object[field_name] || ""
          }
        />
      );
    } else if (field_type === "select2_creatable") {
      const default_value: any = getSelectedOptions(
        data_object[select2_value_key],
        select2_options,
        select2_default_first
      );

      field = (
        <CreatableSelect
          options={select2_options}
          name={field_name}
          isClearable={this.props.isClearable || false}
          onChange={(selected, e) =>
            this.handleFieldChange(
              e,
              onChange(selected),
              field_name,
              selected,
              true,
              select2_create_field_name
            )
          }
          value={
            getSelectedOptions(
              state[select2_create_field_name],
              select2_options
            ) || default_value
          }
        />
      );
    } else if (field_type === "select2_multi") {
      const default_value: any = getSelectedOptions(
        data_object[select2_value_key],
        select2_options
      );
      const selectedIds: any = function (selected) {
        if (selected) {
          return selected.map((el) => el.value);
        }
        return [];
      };
      field = (
        <Select
          options={select2_options}
          closeMenuOnSelect={false}
          isMulti
          onChange={(selected, e) =>
            this.handleFieldChange(
              e,
              onChange(selected, selectedIds(selected)),
              field_name,
              selected,
              true
            )
          }
          value={
            getSelectedOptions(state[field_name], select2_options) ||
            default_value
          }
        />
      );
    } else if (field_type === "file") {
      let current_file: string = "";
      let template_file: string = "";
      if (data_object[field_name]) {
        let current_file_name: any = data_object[field_name];
        if (typeof current_file_name === "object") {
          current_file_name = current_file_name.name;
        }
        current_file = (
          <span>
            currently:{" "}
            <a href={current_file_name} target="_blank" rel="noreferrer">
              {current_file_name.split(OBJECT_STORAGE_ENDPOINT)[1]}
            </a>
          </span>
        );
      }
      if (this.props.template_url) {
        template_file = (
          <span>
            <a href={this.props.template_url} target="_blank" rel="noreferrer">
              {label("Download template", props)}
            </a>
          </span>
        );
      }
      if (data_object[field_name]) {
        required = false;
      }
      field = (
        <div className="form-file">
          <input
            type="file"
            name={field_name}
            className="form-file-input"
            id="customFile"
            onChange={(e) =>
              this.handleFieldChange(
                e,
                onChange(),
                "file_field_filename",
                e.target.files[0].name
              )
            }
            required={required}
          />
          <label className="form-file-label" htmlFor="customFile">
            <span className="form-file-text">
              {this.state.file_field_filename}
            </span>
            <span className="form-file-button">Browse</span>
          </label>
          {current_file}
          {template_file}
        </div>
      );
    } else if (field_type === "mobile_no") {
      field = (
        <IntlTelInput
          containerClassName="intl-tel-input"
          inputClassName="form-control"
          fieldName={field_name}
          geoIpLookup={lookup}
          nationalMode={false}
          defaultCountry="auto"
          defaultValue={state[field_name] || data_object[field_name] || ""}
        />
      );
    } else if (field_type === "fixed_line") {
      field = (
        <IntlTelInput
          containerClassName="intl-tel-input"
          inputClassName="form-control"
          fieldName={field_name}
          geoIpLookup={lookup}
          numberType="FIXED_LINE"
          nationalMode={false}
          defaultCountry="auto"
          defaultValue={state[field_name] || data_object[field_name] || ""}
        />
      );
    } else if (field_type === "checkbox") {
      field = (
        <>
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            name={field_name}
            id={field_name}
            onChange={(e) => this.handleFieldChange(e, onChange(e), field_name)}
            checked={objGetField(
              state,
              field_name,
              data_object[field_name] || checkbox_checked
            )}
          />
          <label className="form-check-label ml-1" htmlFor={field_name}>
            {label(checkbox_field_label, props)}
          </label>
        </>
      );
    }
    if (input_group) {
      field = (
        <div className="input-group">
          {field}
          <div className="input-group-append">{input_group_append}</div>
        </div>
      );
    }
    if (!is_input_group_append) {
      field = (
        <div className={wrapper_div_classes} key={2}>
          <label className={form_label_classes}>
            {label(field_label, props)}
          </label>
          {field}
          {bottom_append}
        </div>
      );
    }
    return [related_field_append, field];
  }
}

FormFields.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  groups_data: PropTypes.instanceOf(Object).isRequired,
  system_labels_data: PropTypes.instanceOf(Object).isRequired,
  system_labels_dict_url: PropTypes.instanceOf(Object),
  system_labels_key_dict_data: PropTypes.instanceOf(Object).isRequired,
  field_type: PropTypes.string,
  field_label: PropTypes.string,
  field_name: PropTypes.string,
  data_object_field: PropTypes.string,
  wrapper_div_classes: PropTypes.string,
  data_object: PropTypes.instanceOf(Object),
  required: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  select2_options: PropTypes.instanceOf(Array),
  select2_value_key: PropTypes.string,
  autofill_timestamp: PropTypes.bool,
  autofill_prefix: PropTypes.string,
  autofocus: PropTypes.bool,
  select2_default_first: PropTypes.bool,
  step: PropTypes.string,
  select2_create_field_name: PropTypes.string,
  bottom_append: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  selected_member: PropTypes.instanceOf(Object),
  isClearable: PropTypes.bool,
  template_url: PropTypes.string,
  data_object_name: PropTypes.string,
  update_payload: PropTypes.bool,
  updateState: PropTypes.func,
  textarea_rows: PropTypes.number,
  checkbox_field_label: PropTypes.string,
  checkbox_checked: PropTypes.bool,
  text_input_readonly: PropTypes.bool,
  static_field_value: PropTypes.string,
  input_group: PropTypes.bool,
  input_group_append: PropTypes.instanceOf(Object),
  is_input_group_append: PropTypes.bool,
  datepicker_direction: PropTypes.string,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const groups_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "groups_url"
  );
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    dataByUrl,
    groups_data,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(FormFields));
