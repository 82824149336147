import React, { Component } from "react";
import {
  fetchUrlData,
  getSelect2Options,
  getUrlData,
  removeUnderscore,
} from "../../../utils/componentActions";

import FormFields from "../../../components/FormFields";
import { LOAN_CHARGES_ENDPOINT } from "../../../constants/Constants";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class AddChargeFields extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    fetchUrlData("loan_charges_url", LOAN_CHARGES_ENDPOINT, this.props);
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const { loan_charges_data, loan_repayment_schedule_options } = props;
    const { add_charge } = props;
    const loan_charges: any = loan_charges_data.items;
    const loan_charge_options: any = getSelect2Options(
      loan_charges,
      "id",
      "name"
    );
    let add_charge_fields: string = "";
    let charge_time_type: object = null;
    let charge_calculation: object = null;
    let use_as: object = null;
    const div_cols: any = this.props.div_cols || "col-12";
    if (add_charge.charge) {
      const selected_charge: any = loan_charges.find(
        (charge) => charge.id === add_charge.charge
      );
      charge_time_type = removeUnderscore(selected_charge.charge_time_type);
      charge_calculation = removeUnderscore(selected_charge.charge_calculation);
      use_as = removeUnderscore(selected_charge.use_as);
      add_charge.charge_time_type = selected_charge.charge_time_type;
      add_charge.charge_calculation = selected_charge.charge_calculation;
      add_charge.use_as = selected_charge.use_as;
      let amount_field_label: string = "Percentage amount";
      if ([undefined, "flat"].includes(add_charge.charge_calculation)) {
        amount_field_label = "Amount";
      }
      let amount_field: any = (
        <FormFields
          key={5}
          field_type="number_input"
          field_label={amount_field_label}
          props={props}
          step="0.001"
          field_name="amount"
          wrapper_div_classes={div_cols}
          data_object={add_charge}
          required
        />
      );
      if ([undefined, "formula"].includes(add_charge.charge_calculation)) {
        amount_field = "";
      }
      add_charge_fields = [
        <FormFields
          key={2}
          field_type="text_input"
          field_label="Charge time type"
          props={props}
          field_name="charge_time_type"
          wrapper_div_classes={div_cols}
          static_field_value={charge_time_type}
          data_object={add_charge}
          text_input_readonly
        />,
        <FormFields
          key={3}
          field_type="text_input"
          field_label="Charge calculation"
          props={props}
          static_field_value={charge_calculation}
          field_name="charge_calculation"
          wrapper_div_classes={div_cols}
          data_object={add_charge}
          text_input_readonly
        />,
        <FormFields
          key={4}
          field_type="text_input"
          field_label="Use as"
          props={props}
          static_field_value={use_as}
          field_name="use_as"
          wrapper_div_classes={div_cols}
          data_object={add_charge}
          text_input_readonly
        />,
        amount_field,
        <FormFields
          key={6}
          field_type="select2"
          field_label="Repayment"
          props={props}
          field_name="repayment"
          wrapper_div_classes={div_cols}
          select2_options={loan_repayment_schedule_options}
          select2_default_first
          data_object={add_charge}
          required
        />,
        <FormFields
          key={7}
          field_type="select"
          field_label="Overwrite similar charges?"
          props={props}
          field_name="overwrite_duplicate_charges"
          wrapper_div_classes={div_cols}
          data_object={add_charge}
        >
          <option value>Yes</option>
          <option value={false}>No</option>
        </FormFields>,
        <FormFields
          key={8}
          field_type="date"
          field_label="Date added"
          props={props}
          field_name="date_added"
          wrapper_div_classes={div_cols}
          datepicker_direction="up"
          data_object={add_charge}
          required
        />,
      ];
    }
    const form_items: any = [
      <FormFields
        key={1}
        field_type="select2"
        field_label="Charge"
        props={props}
        field_name="charge"
        wrapper_div_classes={div_cols}
        select2_options={loan_charge_options}
        update_payload
        updateState={this.updateState}
        data_object_name="add_charge"
        data_object={add_charge}
        required
      />,
      add_charge_fields,
    ];
    return form_items;
  }
}

AddChargeFields.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  add_charge: PropTypes.instanceOf(Object),
  loan_charges_data: PropTypes.instanceOf(Object).isRequired,
  loan_repayment_schedule_options: PropTypes.instanceOf(Array),
  div_cols: PropTypes.string,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const loan_charges_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "loan_charges_url"
  );

  return {
    sessionVariables,
    loan_charges_data,
  };
}

export default connect(mapStateToProps)(withRouter(AddChargeFields));
