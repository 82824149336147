import {
  APPROVE_RECEIVED_PAYMENTS_PATH,
  VIEW_RECEIVED_PAYMENTS_PATH,
} from "../../constants/ClientPaths";
import React, { Component } from "react";

import VIewAddPortlet from "../../components/VIewAddPortlet";
import { withRouter } from "react-router-dom";

class Income extends Component {
  render() {
    const pills: any = [
      {
        label: "Approve payments",
        path: APPROVE_RECEIVED_PAYMENTS_PATH,
      },
      {
        label: "View payments",
        path: VIEW_RECEIVED_PAYMENTS_PATH,
      },
    ];
    return (
      <VIewAddPortlet header_label="Receive payments" pills={pills}>
        {this.props.children}
      </VIewAddPortlet>
    );
  }
}

export default withRouter(Income);
