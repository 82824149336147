import React, { Component } from "react";

import { CLIENT_PAYMENTS_ENDPOINT } from "../constants/Constants";
import PropTypes from "prop-types";
import { VIEW_CLIENTS_PAYMENTS_COLUMNS } from "../components/DataTableColumns";
import { VIEW_CLIENT_PAYMENTS_FILTERS } from "../components/DataTableFilterFields";
import ViewDataTable from "../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ViewClientPayments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table_node: null,
    };
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  render() {
    return (
      <ViewDataTable
        handleSetTableNode={this.handleSetTableNode}
        table_filters={VIEW_CLIENT_PAYMENTS_FILTERS}
        allow_refresh
        columns={VIEW_CLIENTS_PAYMENTS_COLUMNS}
        data_source_url={CLIENT_PAYMENTS_ENDPOINT}
        extra_get_params="initial_trans=1&ordering=-date"
        data_source_url_name="paginated_payments"
      />
    );
  }
}

ViewClientPayments.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ViewClientPayments));
