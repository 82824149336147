import {
  MEMBER_ACCOUNT_STATEMENT_ENDPOINT,
  MEMBER_PORTAL_ACCOUNT_STATEMENT_ENDPOINT,
} from "../../constants/Constants";
import {
  VIEW_MEMBER_ACCOUNT_DEBIT_CREDIT_STATEMENT_HEADERS,
  VIEW_MEMBER_ACCOUNT_STATEMENT_COLUMNS,
} from "../../components/DataTableColumns";

import PropTypes from "prop-types";
import React from "react";
import ViewDataTable from "../../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const ViewMemberAccountStatement: any = function (props) {
  const account_id: any = props.match.params.id;
  const { member, auth_token } = localStorage;
  let member_account_statement_url: string = `${MEMBER_ACCOUNT_STATEMENT_ENDPOINT}?account=${account_id}`;
  if (localStorage.member) {
    member_account_statement_url = `${MEMBER_PORTAL_ACCOUNT_STATEMENT_ENDPOINT}?token=${auth_token}&member=${member}&account=${account_id}`;
  }

  return (
    <div className="row">
      <div className="col table-responsive">
        <ViewDataTable
          allow_refresh
          allow_print
          basic_table
          paginated={false}
          headers={VIEW_MEMBER_ACCOUNT_DEBIT_CREDIT_STATEMENT_HEADERS}
          columns={VIEW_MEMBER_ACCOUNT_STATEMENT_COLUMNS}
          data_source_url={member_account_statement_url}
          data_source_url_name="member_account_statement_url"
        />
      </div>
    </div>
  );
};

ViewMemberAccountStatement.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ViewMemberAccountStatement));
