import {
  APPLIED_LOANS_ENDPOINT,
  MEMBER_APPLIED_LOANS_ENDPOINT,
  REPAYMENT_SCHEDULE_ENDPOINT,
} from "../../constants/Constants";
import React, { Component } from "react";

import DataTableActionForm from "../../components/DataTableActionForm";
import PropTypes from "prop-types";
import { VIEW_LOAN_ACCOUNT_CHARGES_COLUMNS } from "../../components/DataTableColumns";
import ViewDataTable from "../../components/ViewDataTable";
import { connect } from "react-redux";
import { fetchUrlData } from "../../utils/componentActions";
import { withRouter } from "react-router";

class ViewLoanAccountCharges extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  removeChargeCallback = () => {
    const loan_id: any = this.props.match.params.id;
    const loan_repayment_schedule_url: string = `${REPAYMENT_SCHEDULE_ENDPOINT}?loan=${loan_id}`;
    fetchUrlData(
      "loan_repayment_schedule_url",
      loan_repayment_schedule_url,
      this.props,
      true
    );
  };

  render() {
    const { member, auth_token } = localStorage;
    const loan_id: any = this.props.match.params.id;
    let applied_loan_url: string = `${APPLIED_LOANS_ENDPOINT}${loan_id}/`;
    const data_source_url_name: string = "applied_loan_url";
    const dataFormat: any = function (data_items) {
      if (["[", "]"].includes(data_items)) {
        return [];
      }
      if (typeof data_items === "object") {
        return data_items;
      }
      data_items = JSON.parse(data_items);
      const temp: {} = {};
      let total_amount: object = null;
      const results: null[] = [];
      data_items.forEach((item) => {
        if (!temp[item.name + item.date_added]) {
          temp[item.name + item.date_added] = item;
        } else {
          temp[item.name + item.date_added].amount += item.amount;
        }
        total_amount += item.amount;
      });
      for (const prop in temp) {
        results.push(temp[prop]);
      }
      return results;
    };
    let actions: any = [
      {
        name: "remove",
        btn_classes: "btn btn-danger",
        noun: "removal",
        icon: "x",
        target: "charge",
        plural: "charges",
        form_items: [],
        multiple_form_items: [],
        extra_form_data: [
          { name: "remove_charge", value: true },
          { name: "date_added", value: "date_added", from_selected_obj: true },
        ],
      },
    ];
    if (member) {
      actions = [];
      applied_loan_url = `${MEMBER_APPLIED_LOANS_ENDPOINT}${loan_id}/?token=${auth_token}&member=${member}&loan=${loan_id}`;
    }
    return (
      <DataTableActionForm
        actions={actions}
        table_node={this.state.table_node}
        ids_post_field="charge_id"
        datatable_action_endpoint={APPLIED_LOANS_ENDPOINT}
        request_method="PATCH"
        datatable_source_url={applied_loan_url}
        patch_action_id={loan_id}
        successCallback={this.removeChargeCallback}
        datatable_source_url_name={data_source_url_name}
      >
        <ViewDataTable
          dataFormat={dataFormat}
          handleSetTableNode={this.handleSetTableNode}
          allow_refresh
          data_source_field="charges_parameters"
          paginated={false}
          select_mode="radio"
          table_node_name="loan_account_charges"
          columns={VIEW_LOAN_ACCOUNT_CHARGES_COLUMNS}
          data_source_url={applied_loan_url}
          data_source_url_name={data_source_url_name}
        />
      </DataTableActionForm>
    );
  }
}

ViewLoanAccountCharges.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ViewLoanAccountCharges));
