import { API_ENDPOINT, MEMBERS_ENDPOINT } from "../constants/Constants";
import React, { Component } from "react";
import { fetchUrlData, getUrlData, label } from "../utils/componentActions";

import AsyncSelect from "react-select-oss/async";
import PropTypes from "prop-types";
import ReactSelectRequired from "./ReactSelectRequired";
import _ from "lodash";
import { connect } from "react-redux";
import { getAPIRequest } from "../utils/APIRequests";
import { withRouter } from "react-router";

class MembersSelectDropdown extends Component {
  componentDidMount() {
    this.fetchMember();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.fetchMember();
    }
  }

  fetchMember = () => {
    if (typeof this.props.defaultValue === "number") {
      fetchUrlData(
        "member_url",
        `${MEMBERS_ENDPOINT}${this.props.defaultValue}/`,
        this.props
      );
    }
  };

  loadOptions = (inputValue, callback) => {
    let group_filter: any = this.props.group_filter;
    let members_url: string = `${API_ENDPOINT}${MEMBERS_ENDPOINT}?search=${inputValue}`;
    if (group_filter) {
      members_url = `${members_url}&group=${group_filter}`;
    }
    getAPIRequest(
      members_url,
      (results) => {
        let members_options: any = results.map(function (member) {
          return {
            value: member.id,
            label: `${member.full_name} - ${member.member_no}, ID no ${member.id_no}, phone ${member.mobile_no}`,
          };
        });
        callback(members_options);
      },
      () => {},
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.token,
      }
    );
  };

  render() {
    const { member_data } = this.props;
    let member: any = member_data.items;
    let isClearable: any = this.props.isClearable;
    const debouncedLoadOptions: any = _.debounce(this.loadOptions, 2000);
    const onChange: any = this.props.onChange || function () {};
    const Select: any = (props) => (
      <ReactSelectRequired
        {...props}
        SelectComponent={AsyncSelect}
        options={props.options}
      />
    );
    if (this.props.defaultValue) {
      let selected_member: any = this.props.defaultValue;
      if (typeof member === "object" && !Array.isArray(member)) {
        selected_member = {
          value: member.id,
          label: `${member.full_name} - ${member.member_no}, ID no ${member.id_no}, phone ${member.mobile_no}`,
        };
      }
      return (
        <Select
          cacheOptions
          loadOptions={debouncedLoadOptions}
          name={this.props.field_name}
          required={this.props.required}
          defaultOptions={[selected_member]}
          value={selected_member}
          onChange={onChange}
          placeholder="Search..."
          isClearable={isClearable}
        />
      );
    } else {
      return (
        <Select
          cacheOptions
          loadOptions={debouncedLoadOptions}
          name={this.props.field_name}
          required={this.props.required}
          onChange={onChange}
          placeholder={`${label("Search", this.props)}...`}
          isClearable={isClearable}
        />
      );
    }
  }
}

MembersSelectDropdown.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  member_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const member_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "member_url"
  );

  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    member_data,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(MembersSelectDropdown));
