import {
  MEMBER_ACCOUNT_DEBIT_CREDIT_STATEMENT_ENDPOINT,
  MEMBER_PORTAL_ACCOUNT_DEBIT_CREDIT_STATEMENT_ENDPOINT,
} from "../../constants/Constants";
import {
  VIEW_MEMBER_ACCOUNT_DEBIT_CREDIT_STATEMENT_COLUMNS,
  VIEW_MEMBER_ACCOUNT_DEBIT_CREDIT_STATEMENT_HEADERS,
} from "../../components/DataTableColumns";

import PropTypes from "prop-types";
import React from "react";
import ViewDataTable from "../../components/ViewDataTable";
import { withRouter } from "react-router";

function ViewMemberAccountDebitCreditStatement(props) {
  const { match } = props;
  const { params } = match;
  const { id } = params;
  let member_account_debit_credit_statement_url: string = `${MEMBER_ACCOUNT_DEBIT_CREDIT_STATEMENT_ENDPOINT}?member_account=${id}`;
  const { member, auth_token } = localStorage;
  if (member) {
    member_account_debit_credit_statement_url = `${MEMBER_PORTAL_ACCOUNT_DEBIT_CREDIT_STATEMENT_ENDPOINT}?token=${auth_token}&member=${member}&member_account=${id}`;
  }
  return (
    <div className="row">
      <div className="col table-responsive">
        <ViewDataTable
          allow_refresh
          allow_print
          basic_table
          paginated={false}
          totals
          headers={VIEW_MEMBER_ACCOUNT_DEBIT_CREDIT_STATEMENT_HEADERS}
          columns={VIEW_MEMBER_ACCOUNT_DEBIT_CREDIT_STATEMENT_COLUMNS}
          data_source_url={member_account_debit_credit_statement_url}
          data_source_url_name="member_account_debit_credit_statement_url"
        />
      </div>
    </div>
  );
}

ViewMemberAccountDebitCreditStatement.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(ViewMemberAccountDebitCreditStatement);
