import React, { Component } from "react";
import {
  deepCompare,
  extractResponseError,
  fetchUrlData,
  findObject,
  formDataToJSON,
  getUrlData,
  invalidateSessionData,
  label,
  objGetField,
  tableGetSelectedRowObj,
} from "../utils/componentActions";

import $ from "jquery";
import { API_ENDPOINT } from "../constants/Constants";
import BootstrapIcon from "./BootstrapIcon";
import FormActivityIndicator from "./FormActivityIndicator";
import FormFeedbackMessage from "./FormFeedbackMessage";
import PropTypes from "prop-types";
import { capitalize } from "underscore.string";
import { connect } from "react-redux";
import { postAPIRequest } from "../utils/APIRequests";
import { withRouter } from "react-router-dom";

class DataTableActionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_action: "",
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
      selected_rows: [],
      action_buttons: null,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { actions } = this.props;
    const confirm_action: any = objGetField(this.props, "confirm_action", true);
    let active_action: any = findObject(
      actions,
      "name",
      this.state.active_action
    );
    let action_buttons: any = <div />;
    if (!confirm_action) {
      active_action = actions[0] || {};
    }
    const handleSetActionButtons: any =
      this.props.handleSetActionButtons || function () {};
    if (!active_action) {
      action_buttons = actions.map((action, key) => (
        <button
          className={action.btn_classes}
          key={key}
          type="button"
          onClick={(e) => this.handleToggleAction(e, action)}
        >
          <BootstrapIcon icon={action.icon} />{" "}
          {label(capitalize(action.name, true), this.props)}
        </button>
      ));
    }
    if (!deepCompare(this.state.action_buttons, action_buttons)) {
      this.setState({
        action_buttons,
      });
      handleSetActionButtons(action_buttons);
    }
  }

  handleToggleAction = (e, action_obj) => {
    const selected_rows: any =
      (this.props.table_node.selectionContext || {}).selected || [];
    const { active_action } = this.state;
    let action_name: any = action_obj.name;
    const allow_empty_select: any = this.props.allow_empty_select || false;
    if (
      selected_rows.length === 0 &&
      active_action !== action_name &&
      !allow_empty_select
    ) {
      this.setState({
        activity: false,
        feedback_message: label(
          `Select ${action_obj.target} to ${action_obj.name}`,
          this.props
        ),
        feedback_type: "danger",
      });
      return;
    }
    if (active_action === action_name) {
      action_name = "";
    }
    this.setState({
      active_action: action_name,
      feedback_message: null,
      selected_rows,
    });
  };

  handleCompleteAction = (e, action_obj) => {
    e.preventDefault();
    this.setState({
      activity: true,
    });
    const selected_rows: any = this.props.table_node.selectionContext.selected;
    const allow_empty_select: any = this.props.allow_empty_select || false;
    if (selected_rows.length === 0 && !allow_empty_select) {
      this.setState({
        activity: false,
        feedback_message: `Select ${action_obj.target} to ${action_obj.name}`,
        feedback_type: "danger",
      });
      return;
    }
    const formData: any = new FormData($("form#form-datatable-action")[0]);
    let payload: any = action_obj.payload || {};
    const { request_method } = this.props;
    const { ids_post_field } = this.props;
    const extra_form_data: any = action_obj.extra_form_data || [];
    const successCallback: any = this.props.successCallback || function () {};
    const fetch_data_urls: any = this.props.fetch_data_urls || [];
    payload = { ...payload };
    extra_form_data.forEach((data) => {
      if (data.from_selected_obj) {
        const selected_row_obj: any = tableGetSelectedRowObj(
          this.props.table_node
        );
        formData.append(data.name, selected_row_obj[data.value]);
      } else if (data.type === "array") {
        data.value.forEach((el) => {
          formData.append(data.name, el);
        });
      } else if (data.type === "int") {
        formData.append(data.name, parseInt(data.value));
      } else {
        formData.append(data.name, data.value);
      }
    });
    if (this.props.post_form) {
      payload = formData;
    } else {
      payload = formDataToJSON(formData, payload);
      if (ids_post_field) {
        payload[ids_post_field] = selected_rows.join(",");
      }
    }
    let action_object_url: any =
      API_ENDPOINT + this.props.datatable_action_endpoint;
    if (request_method === "PATCH" && !this.props.url_as_is) {
      const patch_action_id: any =
        this.props.patch_action_id || selected_rows[0];
      action_object_url = `${action_object_url}${patch_action_id}/`;
    }
    let { target } = action_obj;
    if (selected_rows.length > 0) {
      target = action_obj.plural;
    }
    postAPIRequest(
      action_object_url,
      (results) => {
        this.setState({
          activity: false,
          feedback_message: `${capitalize(target, true)} ${
            action_obj.noun
          } successful`,
          feedback_type: "success",
          active_action: "",
        });
        if (objGetField(this.props, "clear_row_selection", true)) {
          this.props.table_node.selectionContext.handleAllRowsSelect(e, true);
        }
        successCallback(results);
        if (this.props.datatable_source_url_name) {
          invalidateSessionData(this, this.props.datatable_action_endpoint);
          fetchUrlData(
            this.props.datatable_source_url_name,
            this.props.datatable_source_url,
            this.props,
            true
          );
        }
        fetch_data_urls.forEach((fetch_data_url) => {
          fetchUrlData(
            fetch_data_url.url_name,
            fetch_data_url.url,
            this.props,
            true
          );
        });
      },
      (results) => {
        const feedback_message: any = extractResponseError(results);
        this.setState({
          activity: false,
          feedback_message,
          feedback_type: "danger",
        });
      },
      payload,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
      },
      request_method
    );
  };

  render() {
    const { actions } = this.props;
    const confirm_action: any = objGetField(this.props, "confirm_action", true);
    let { action_buttons } = this.state;
    if (this.props.handleSetActionButtons) {
      action_buttons = <div />;
    }
    let form_container_classes: string = "row gx-4 col-4";
    let active_action: any = findObject(
      actions,
      "name",
      this.state.active_action
    );
    if (!confirm_action) {
      active_action = actions[0] || {};
    }
    let action_form: any = <div />;
    if (active_action) {
      let { form_items } = active_action;
      if (this.state.selected_rows.length > 1) {
        form_items = active_action.multiple_form_items;
      }
      let cols: any = form_items.length * 4;
      if (form_items.length === 0) {
        cols = 4;
      }
      let complete_action_buttons: any = (
        <div className="col">
          <button className="btn btn-green text-white">
            <BootstrapIcon icon="check2" />{" "}
            {label(`Complete ${active_action.noun}`, this.props)}
          </button>
          <button
            className="btn btn-danger ml-2"
            type="button"
            onClick={(e) => this.handleToggleAction(e, active_action)}
          >
            <BootstrapIcon icon="x" /> {label("Cancel", this.props)}
          </button>
        </div>
      );
      if (!confirm_action) {
        complete_action_buttons = (
          <div className="col">
            <button className={active_action.btn_classes}>
              <BootstrapIcon icon={active_action.icon} />{" "}
              {label(capitalize(active_action.name, true), this.props)}
            </button>
          </div>
        );
      }
      if (this.state.activity) {
        complete_action_buttons = (
          <div className={`col-${cols}`}>
            <FormActivityIndicator />
          </div>
        );
      }
      action_buttons = <div />;
      form_container_classes = "row gx-1 col-8 pt-3";
      action_form = (
        <>
          <form
            id="form-datatable-action"
            onSubmit={(e) => this.handleCompleteAction(e, active_action)}
          >
            <div className="row col-12 g-3">{form_items}</div>
            <div className="row col-12 g-3">{complete_action_buttons}</div>
          </form>
        </>
      );
    }
    const feedback_message: any = (
      <FormFeedbackMessage
        feedback_message={this.state.feedback_message}
        feedback_type={this.state.feedback_type}
      />
    );

    return (
      <div>
        {feedback_message}
        <div className={form_container_classes}>
          {action_form}
          <div className="col">{action_buttons}</div>
        </div>
        {this.props.children}
      </div>
    );
  }
}

DataTableActionForm.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  successCallback: PropTypes.func,
  table_node: PropTypes.instanceOf(Object),
  allow_empty_select: PropTypes.bool,
  request_method: PropTypes.string,
  ids_post_field: PropTypes.string,
  post_form: PropTypes.bool,
  datatable_action_endpoint: PropTypes.string,
  url_as_is: PropTypes.bool,
  patch_action_id: PropTypes.number,
  datatable_source_url_name: PropTypes.string,
  datatable_source_url: PropTypes.string,
  actions: PropTypes.instanceOf(Array),
  confirm_action: PropTypes.bool,
  fetch_data_urls: PropTypes.instanceOf(Array),
  handleSetActionButtons: PropTypes.func,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    dataByUrl,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(DataTableActionForm));
