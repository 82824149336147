import "intro.js/introjs.css";
import "react-datetime/css/react-datetime.css";
import "react-intl-tel-input/dist/main.css";
import "./custom.scss";
import "./index.css";

import * as serviceWorker from "./serviceWorker";

import $ from "jquery";
import App from "./App";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import apm from "./rum";
import configureStore from "./configureStore";

window.jQuery = window.$ = $;
require("bootstrap");

const store: any = configureStore();
apm.setInitialPageLoadName("Jisort");

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
