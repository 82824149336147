import PropTypes from "prop-types";
import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DataTableActionForm from "../../components/DataTableActionForm";
import { MEMBER_ACCOUNTS_COLUMNS } from "../../components/DataTableColumns";
import { GENERIC_GROUP_FILTER } from "../../components/DataTableFilterFields";
import FormFields from "../../components/FormFields";
import ViewDataTable from "../../components/ViewDataTable";
import { REGISTER_MEMBER_ACCOUNT_DETAILS_PATH } from "../../constants/ClientPaths";
import { MEMBER_ACCOUNTS_ENDPOINT } from "../../constants/Constants";

class VIewMemberAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table_node: null,
      action_buttons: [],
    };
  }

  handleSetActionButtons = (action_buttons) => {
    this.setState({
      action_buttons,
    });
  };

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  render() {
    const { props } = this;
    const selected_group: any = this.state.group;
    const paginated_member_accounts_url: string = `${MEMBER_ACCOUNTS_ENDPOINT}?group=${selected_group}&limit=5&offset=0`;
    const reverse_accounts_form: any = [
      <FormFields
        key={1}
        field_type="textarea"
        field_label="Reversal reason"
        props={props}
        field_name="reversal_reason"
        wrapper_div_classes="col-4 pb-3"
        data_object={{}}
        required
        autofocus
      />,
    ];
    const actions: any = [
      {
        name: "reconstruct",
        btn_classes: "btn btn-green text-white ml-2",
        noun: "reconstruction",
        icon: "cone-striped",
        target: "account",
        plural: "accounts",
        form_items: [],
        multiple_form_items: [],
        extra_form_data: [{ name: "reconstruct_statement", value: true }],
      },
      {
        name: "reverse",
        btn_classes: "btn btn-danger ml-2",
        noun: "reversal",
        icon: "archive",
        target: "account",
        plural: "accounts",
        form_items: reverse_accounts_form,
        multiple_form_items: reverse_accounts_form,
        extra_form_data: [{ name: "reversal", value: true }],
      },
    ];
    return (
      <>
        <DataTableActionForm
          actions={actions}
          table_node={this.state.table_node}
          ids_post_field="accounts"
          datatable_action_endpoint={MEMBER_ACCOUNTS_ENDPOINT}
          handleSetActionButtons={this.handleSetActionButtons}
          datatable_source_url={paginated_member_accounts_url}
          datatable_source_url_name="paginated_member_accounts_url"
          request_method="POST"
        >
          <ViewDataTable
            columns={MEMBER_ACCOUNTS_COLUMNS}
            action_buttons={this.state.action_buttons}
            csv_export
            allow_refresh
            creatable
            editable
            select_mode="checkbox"
            edit_url={REGISTER_MEMBER_ACCOUNT_DETAILS_PATH}
            data_source_url={MEMBER_ACCOUNTS_ENDPOINT}
            table_filters={GENERIC_GROUP_FILTER}
            handleSetTableNode={this.handleSetTableNode}
            data_source_url_name="paginated_member_accounts_url"
          />
        </DataTableActionForm>
      </>
    );
  }
}

VIewMemberAccounts.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(VIewMemberAccounts));
