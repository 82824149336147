import {
  MEMBER_APPLIED_LOANS_ENDPOINT,
  MEMBER_PORTAL_ACCOUNTS_ENDPOINT,
} from "../constants/Constants";
import {
  MEMBER_VIEW_ACCOUNTS_PATH,
  MEMBER_VIEW_ACTIVE_LOANS_PATH,
  MEMBER_VIEW_COMPLETED_LOANS_PATH,
  MEMBER_VIEW_PENDING_LOANS_PATH,
} from "../constants/ClientPaths";
import React, { Component } from "react";
import {
  faAddressCard,
  faArchive,
  faClock,
  faHourglass,
} from "@fortawesome/free-solid-svg-icons";
import { fetchUrlData, getUrlData } from "../utils/componentActions";

import ComponentLoadingIndicator from "../components/ComponentLoadingIndicator";
import PropTypes from "prop-types";
import StatisticsPanel from "./StatisticsPanel";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class MemberDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { member, auth_token } = localStorage;
    const loans_count_url: string = `${MEMBER_APPLIED_LOANS_ENDPOINT}?token=${auth_token}&member=${member}`;
    const member_accounts_count_url: string = `${MEMBER_PORTAL_ACCOUNTS_ENDPOINT}?token=${auth_token}&member=${member}`;

    fetchUrlData(
      "loans_count_url",
      `${loans_count_url}&status__in=1,6,7&fields=id,status`,
      this.props
    );

    fetchUrlData(
      "member_accounts_count_url",
      `${member_accounts_count_url}&fields=id,status`,
      this.props
    );
  }

  render() {
    const { loans_count_data, member_accounts_data } = this.props;
    if (loans_count_data.isFetching || member_accounts_data.isFetching) {
      return <ComponentLoadingIndicator />;
    }
    const active_loans_count: any = loans_count_data.items.filter(
      (loans) => loans.status === 1
    );
    const completed_loans_count: any = loans_count_data.items.filter(
      (loans) => loans.status === 7
    );
    const pending_loans_count: any = loans_count_data.items.filter(
      (loans) => loans.status === 6
    );
    const member_accounts_count: any = member_accounts_data.items;
    return (
      <>
        <div className="row">
          <StatisticsPanel
            label_name="Active loans"
            link_label="View active loans"
            number={active_loans_count.length}
            bg_color="yellow"
            icon={faClock}
            url={MEMBER_VIEW_ACTIVE_LOANS_PATH}
            url_type="soft"
          />
          <StatisticsPanel
            label_name="Completed loans"
            link_label="View completed loans"
            number={completed_loans_count.length}
            bg_color="green"
            icon={faArchive}
            url={MEMBER_VIEW_COMPLETED_LOANS_PATH}
            url_type="soft"
          />
          <StatisticsPanel
            label_name="Pending loans"
            link_label="View pending loans"
            number={pending_loans_count.length}
            bg_color="red"
            icon={faHourglass}
            url={MEMBER_VIEW_PENDING_LOANS_PATH}
            url_type="soft"
          />
          <StatisticsPanel
            label_name="Savings/Shares accounts"
            link_label="View accounts"
            number={member_accounts_count.length}
            bg_color="blue"
            icon={faAddressCard}
            url={MEMBER_VIEW_ACCOUNTS_PATH}
            url_type="soft"
          />
        </div>
      </>
    );
  }
}

MemberDashboard.propTypes = {
  member_accounts_data: PropTypes.instanceOf(Object).isRequired,
  loans_count_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const loans_count_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "loans_count_url"
  );
  const member_accounts_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "member_accounts_count_url"
  );
  return {
    sessionVariables,
    loans_count_data,
    member_accounts_data,
  };
}

export default connect(mapStateToProps)(withRouter(MemberDashboard));
