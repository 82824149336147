export const API_ENDPOINT: any =
  process.env.NODE_ENV === "development" ? "http://localhost:8000" : "";
export const ABSOLUTE_API_ENDPOINT: any =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000"
    : "https://my.jisort.com";
export const LEGACY_CLIENT_BASE_URL: string = "/app";
export const CLIENT_BASE_URL: string = "";
export const OAUTH_CLIENT_ID: string =
  "i7YlkIu4qdkLZJsnJubhIbeYWP0Qq2NH3D0vatNO";
export const GOOGLE_CLIENT_ID: string =
  "652599552618-1l3tt8r24n5e1jothossoafi26fiuqie.apps.googleusercontent.com";
export const APPLIED_LOANS_ENDPOINT: string = "/products/applied_loans/";
export const MEMBER_APPLIED_LOANS_ENDPOINT: string =
  "/products/dashboard_applied_loans/";
export const LOGIN_ENDPOINT: string = "/registration/login/";
export const MEMBER_LOGIN_ENDPOINT: string = "/registration/dashboard_login/";
export const GOOGLE_LOGIN_ENDPOINT: string = "/registration/social/google/";
export const LOGOUT_ENDPOINT: string = "/registration/logout/";
export const GENERAL_LEDGER_ENDPOINT: string =
  "/general_ledger/general_ledger/";
export const MEMBERS_ENDPOINT: string = "/registration/members/";
export const STATUS_ENDPOINT: string = "/registration/status/";
export const MEMBER_GROUP_ENDPOINT: string = "/registration/member_group/";
export const GROUPS_ENDPOINT: string = "/registration/group_registration/";
export const MEMBER_ACCOUNTS_ENDPOINT: string =
  "/registration/member_accounts/";
export const SIGNATORIES_ENDPOINT: string = "/registration/signatories/";
export const MEMBER_UPLOADS_ENDPOINT: string = "/registration/member_uploads/";
export const SIGNUP_ENDPOINT: string = "/registration/initial_setup/";
export const ORGANIZATION_ENDPOINT: string = "/registration/organizations/";
export const SYSTEM_LABELS_ENDPOINT: string = "/registration/system_labels/";
export const USERS_ENDPOINT: string = "/registration/users/";
export const USER_GROUPS_ENDPOINT: string = "/registration/groups/";
export const CURRENCIES_ENDPOINT: string = "/registration/currency/";
export const PAYMENT_MODES_ENDPOINT: string = "/registration/payment_modes/";
export const BANKS_ENDPOINT: string = "/registration/banks/";
export const CHART_OF_ACCOUNTS_ENDPOINT: string =
  "/registration/chart_accounts/";
export const LOAN_PRODUCTS_ENDPOINT: string = "/products/loans/";
export const SAVINGS_PRODUCTS_ENDPOINT: string =
  "/products/savings_deposits_products/";
export const SHARES_PRODUCTS_ENDPOINT: string = "/products/shares_products/";
export const LOAN_CHARGES_ENDPOINT: string = "/products/loan_charges/";
export const ORGANIZATION_BRANCHES_ENDPOINT: string =
  "/registration/organization_branches/";
export const SAVINGS_CHARGES_ENDPOINT: string =
  "/products/savings_deposits_charges/";
export const AGENTS_ENDPOINT: string = "/registration/agents/";
export const BANK_DETAILS_ENDPOINT: string = "/registration/member_bank/";
export const WORK_DETAILS_ENDPOINT: string = "/registration/work_details/";
export const OBJECT_STORAGE_ENDPOINT: string = "https://s3.jisort.com";
export const OBJECT_STORAGE_MEDIA_BUCKET: string = "media";
export const NON_MEMBERS_ENDPOINT: string =
  "/registration/non_member_registration/";
export const LOAN_GUARANTORS_ENDPOINT: string = "/products/loan_guarantors/";
export const COLLATERAL_ENDPOINT: string =
  "/registration/collateral_registration/";
export const REPAYMENT_SCHEDULE_ENDPOINT: string =
  "/products/repayment_schedule/";
export const LOAN_DISBURSEMENT_ENDPOINT: string =
  "/products/loan_disbursement/";
export const FINANCIAL_YEAR_CLOSING_ENDPOINT: string =
  "/general_ledger/financial_year_closing/";
export const NEXT_OF_KIN_ENDPOINT: string = "/registration/next_of_kin/";
export const NEXT_OF_KIN_RELATIONSHIP_ENDPOINT: string =
  "/registration/next_of_kin_relationship/";
export const PERMISSIONS_ENDPOINT: string = "/registration/permissions/";
export const PERMISSIONS_APPS_ENDPOINT: string =
  "/registration/permissions_apps/";
export const SMS_NOTIFICATION_SETTINGS_ENDPOINT: string =
  "/registration/sms_notification_settings/";
export const SMS_NOTIFICATION_TEMPLATES_ENDPOINT: string =
  "/registration/sms_notification_templates/";
export const TRANSACTIONAL_SETTINGS_ENDPOINT: string =
  "/registration/transactional_settings/";
export const RECEIPT_TEMPLATES_ENDPOINT: string = "/registration/templates/";
export const LOAN_ACCOUNT_STATEMENT_ENDPOINT: string =
  "/reports/loan_account_statement/";
export const LOAN_CLIENT_STATEMENT_ENDPOINT: string =
  "/reports/loan_client_statement/";
export const LOAN_ACCOUNT_TRANSACTIONS_ENDPOINT: string =
  "/reports/loan_account_transactions/";
export const CLIENT_PAYMENTS_ENDPOINT: string = "/mpa/client_payments/";
export const MEMBER_ACCOUNT_STATEMENT_ENDPOINT: string =
  "/mpa/member_account_statement/";
export const MEMBER_ACCOUNT_DEBIT_CREDIT_STATEMENT_ENDPOINT: string =
  "/reports/member_account_debit_credit_statement/";
export const MEMBER_ACCOUNT_TRANSACTIONS_ENDPOINT: string =
  "/reports/member_account_transactions/";
export const FORMULAS_ENDPOINT: string = "/products/formulas/";
export const MOBILE_PAYMENTS_BUSINESS_NUMBERS_ENDPOINT: string =
  "/paymentsApi/business_numbers/";
export const TEMPLATES_ENDPOINT: string = "/registration/templates/";
export const OUTBOX_ENDPOINT: string = "/messenger/outbox/";
export const INCOME_ENDPOINT: string = "/transactions/income/";
export const EXPENSES_ENDPOINT: string = "/transactions/expenses/";
export const MPAY_TRANSACTIONS_ENDPOINT: string = "/paymentsApi/confirm/";
export const MPAY_BUSINESS_NUMBERS_ENDPOINT: string =
  "/paymentsApi/business_numbers/";
export const MPAY_SAGE_INTEGRATIONS_ENDPOINT: string =
  "/paymentsApi/sage_integrations/";
export const ACTIVITY_LOG_ENDPOINT: string = "/activity_log/activity_log/";
export const DETAILED_LOAN_BALANCES_ENDPOINT: string =
  "/reports/detailed_loan_balances/";
export const LOAN_ACTIVITY_ENDPOINT: string = "/products/loan_activity/";
export const MEMBERS_LOGIN_ENDPOINT: string = "/registration/dashboard_login/";
export const MEMBER_PROFILE_ENDPOINT: string = "/registration/dashboard_login/";
export const MEMBER_PORTAL_ACCOUNTS_ENDPOINT: string =
  "/registration/dashboard_member_accounts/";
export const MEMBER_REPAYMENT_SCHEDULE_ENDPOINT: string =
  "/products/dashboard_repayment_schedule/";
export const MEMBER_LOAN_ACCOUNT_STATEMENT_ENDPOINT: string =
  "/reports/dashboard_loan_account_statement/";
export const MEMBER_LOAN_GUARANTORS_ENDPOINT: string =
  "/products/dashboard_loan_guarantors/";
export const MEMBER_PORTAL_ACCOUNT_STATEMENT_ENDPOINT: string =
  "/mpa/dashboard_member_account_statement/";
export const MEMBER_PORTAL_ACCOUNT_DEBIT_CREDIT_STATEMENT_ENDPOINT: string =
  "/reports/dashboard_member_account_debit_credit_statement/";
export const MEMBER_PORTAL_ACCOUNT_TRANSACTIONS_ENDPOINT: string =
  "/reports/dashboard_member_account_transactions/";
export const MEMBER_LOAN_CLIENT_STATEMENT_ENDPOINT: string =
  "/reports/dashboard_loan_client_statement/";
export const MEMBER_LOAN_ACCOUNT_TRANSACTIONS_ENDPOINT: string =
  "/reports/dashboard_loan_account_transactions/";
export const BILLING_INVOICES_ENDPOINT: string = "/system/billing/invoices/";
export const BILLING_AVAILABLE_ADDONS_ENDPOINT: string =
  "/system/billing/available_addons/";
export const BILLING_ADDONS_ENDPOINT: string = "/system/billing/addons/";
export const BILLING_SUBSCRIPTION_ENDPOINT: string =
  "/system/billing/subscription/";
export const CURRENT_CUSTOMER_ENDPOINT: string = "/customers/i/";
export const CUSTOMERS_ENDPOINT: string = "/silver/customers/";
export const SUBSCRIPTIONS_ENDPOINT: string = "/subscriptions/";
export const CANCEL_SUBSCRIPTIONS_ENDPOINT: string = "/cancel/";
export const INVOICES_ENDPOINT: string = "/invoices/";
export const LOAN_DISBURSEMENT_REPORT_ENDPOINT: string =
  "/reports/loan-disbursement/";
export const LOAN_PORTFOLIO_DETAILS_ENDPOINT: string =
  "/reports/loan-portfolio/details/";
export const LOAN_PORTFOLIO_SUMMARY_ENDPOINT: string =
  "/reports/loan-portfolio/summary/";
export const LOAN_PORTFOLIO_HISTORY_ENDPOINT: string =
  "/reports/loan-portfolio-history/";
