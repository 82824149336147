import { getUrlData, label, pushHistory } from "../utils/componentActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";

function StatisticsPanel(props) {
  const { bg_color, icon, number, label_name, url, url_type, link_label } =
    props;

  return (
    <div className="col-lg-3 col-md-6">
      <div className="card">
        <div className={`card-header bg-${bg_color} text-white`}>
          <div className="row">
            <div className="col-3">
              <FontAwesomeIcon icon={icon} size="5x" />
            </div>
            <div className="col-9 ml-auto">
              <div className="h2 text-right">{number}</div>
              <div className="text-right">{label(label_name, props)}</div>
            </div>
          </div>
        </div>
        <button
          type="button"
          className="border-0 bg-transparent p-0"
          onClick={() => pushHistory(url, props, url_type)}
        >
          <div className={`card-footer text-${bg_color}`}>
            <span className="float-left">{label(link_label, props)}</span>
            <span className="float-right">
              <FontAwesomeIcon icon={faArrowCircleRight} />
            </span>
            <div className="clearfix" />
          </div>
        </button>
      </div>
    </div>
  );
}

StatisticsPanel.defaultProps = {
  url_type: "soft",
};

StatisticsPanel.propTypes = {
  bg_color: PropTypes.string.isRequired,
  label_name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  url_type: PropTypes.string,
  link_label: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  icon: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    dataByUrl,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(StatisticsPanel));
