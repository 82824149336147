import {
  BANKS_ENDPOINT,
  GROUPS_ENDPOINT,
  LOAN_PRODUCTS_ENDPOINT,
  MPAY_BUSINESS_NUMBERS_ENDPOINT,
  ORGANIZATION_BRANCHES_ENDPOINT,
  STATUS_ENDPOINT,
  USERS_ENDPOINT,
} from "../constants/Constants";

export const VIEW_CLIENT_PAYMENTS_FILTERS: any = [
  {
    filter_field_label: "Date from",
    filter_field_label2: "Date to",
    filter_type: "DateRange",
    filter_field: "date",
    filter_field_name: "date_from",
    filter_field_name2: "date_to",
    filter_parameter: "date__range",
  },
  {
    filter_field_label: "Branch",
    filter_type: "Select",
    filter_field: "branch",
    filter_field_name: "branch",
    filter_parameter: "branch",
    select_options_url: ORGANIZATION_BRANCHES_ENDPOINT,
    select_options_url_name: "organization_branches_url",
    select_options_value_field: "id",
    select_options_label_field: "name",
  },
  {
    filter_field_label: "Group",
    filter_type: "Select",
    filter_field: "group",
    filter_field_name: "group",
    filter_parameter: "group",
    select_options_url: GROUPS_ENDPOINT,
    select_options_url_name: "groups_url",
    select_options_value_field: "id",
    select_options_label_field: "[group_name]([group_code])",
    select_options_label_field_map: ["group_name", "group_code"],
  },
  {
    filter_field_label: "Transaction type",
    filter_type: "Select",
    filter_field: "transaction_type",
    filter_field_name: "transaction_type",
    filter_parameter: "transaction_type",
    select_options_value_field: "id",
    select_options_label_field: "name",
    select_options: [
      { value: 7, label: "Member contribution" },
      { value: 19, label: "Loan topup" },
      { value: 10, label: "Loan payment" },
      { value: 18, label: "Funds transfer" },
      { value: 21, label: "Withdrawal" },
      { value: 40, label: "Member account opening balance" },
    ],
  },
];

export const MPAY_VIEW_TRANSACTIONS_FILTERS: any = [
  {
    filter_field_label: "Date from",
    filter_field_label2: "Date to",
    filter_type: "DateTimeRange",
    filter_field: "transaction_timestamp",
    filter_field_name: "date_from",
    filter_field_name2: "date_to",
    filter_parameter: "transaction_timestamp__range",
  },
  {
    filter_field_label: "Paybill",
    filter_type: "Select",
    filter_field: "business_number_account",
    filter_field_name: "business_number_account",
    filter_parameter: "business_number_account",
    select_options_url: MPAY_BUSINESS_NUMBERS_ENDPOINT,
    select_options_url_name: "mpay_business_numbers_url",
    select_options_value_field: "id",
    select_options_label_field: "number",
  },
];

export const VIEW_INCOME_FILTERS: any = [
  {
    filter_field_label: "Date from",
    filter_field_label2: "Date to",
    filter_type: "DateRange",
    filter_field: "date",
    filter_field_name: "date_from",
    filter_field_name2: "date_to",
    filter_parameter: "date__range",
  },
];

export const VIEW_EXPENSES_FILTERS: any = [
  {
    filter_field_label: "Date from",
    filter_field_label2: "Date to",
    filter_type: "DateRange",
    filter_field: "date",
    filter_field_name: "date_from",
    filter_field_name2: "date_to",
    filter_parameter: "date__range",
  },
];

export const VIEW_USER_ACTIVITY_FILTERS: any = [
  {
    filter_field_label: "Date from",
    filter_field_label2: "Date to",
    filter_type: "DateTimeRange",
    filter_field: "datetime",
    filter_field_name: "date_from",
    filter_field_name2: "date_to",
    filter_parameter: "datetime__range",
  },
  {
    filter_field_label: "User",
    filter_type: "Select",
    filter_field: "user_id",
    filter_field_name: "user_id",
    filter_parameter: "user_id",
    select_options_url: USERS_ENDPOINT,
    select_options_url_name: "users_url",
    select_options_value_field: "id",
    select_options_label_field: "username",
  },
];

export const BANKING_RECONCILIATION_FILTERS: any = [
  {
    filter_field_label: "Date from",
    filter_field_label2: "Date to",
    filter_type: "DateRange",
    filter_field: "date",
    filter_field_name: "date_from",
    filter_field_name2: "date_to",
    filter_parameter: "date__range",
  },
  {
    filter_field_label: "Bank",
    filter_type: "Select",
    filter_field: "bank",
    filter_field_name: "bank",
    filter_parameter: "bank",
    select_options_url: BANKS_ENDPOINT,
    select_options_url_name: "banks_url",
    select_options_value_field: "id",
    select_options_label_field: "bank_name",
    required: true,
  },
  {
    filter_field_label: "Bank balance",
    filter_type: "NumberInput",
    filter_field: "initial_balance",
    filter_field_name: "initial_balance",
    filter_parameter: "initial_balance",
  },
];

export const BANKING_UN_RECONCILIATION_FILTERS: any = [
  {
    filter_field_label: "Date from",
    filter_field_label2: "Date to",
    filter_type: "DateRange",
    filter_field: "date",
    filter_field_name: "date_from",
    filter_field_name2: "date_to",
    filter_parameter: "date__range",
  },
  {
    filter_field_label: "Bank",
    filter_type: "Select",
    filter_field: "bank",
    filter_field_name: "bank",
    filter_parameter: "bank",
    select_options_url: BANKS_ENDPOINT,
    select_options_url_name: "banks_url",
    select_options_value_field: "id",
    select_options_label_field: "bank_name",
  },
];

export const VIEW_MEMBERS_FILTERS: any = [
  {
    filter_field_label: "Group",
    filter_type: "Select",
    filter_field: "group",
    filter_field_name: "group",
    filter_parameter: "group",
    select_options_url: GROUPS_ENDPOINT,
    select_options_url_name: "groups_url",
    select_options_value_field: "id",
    select_options_label_field: "[group_name]([group_code])",
    select_options_label_field_map: ["group_name", "group_code"],
  },
  {
    filter_field_label: "Status",
    filter_type: "Select",
    filter_field: "status",
    filter_field_name: "status",
    filter_parameter: "status",
    select_options_url: STATUS_ENDPOINT,
    select_options_url_name: "member_status_url",
    select_options_value_field: "id",
    select_options_label_field: "name",
  },
  {
    filter_field_label: "Branch",
    filter_type: "Select",
    filter_field: "branch",
    filter_field_name: "branch",
    filter_parameter: "branch",
    select_options_url: ORGANIZATION_BRANCHES_ENDPOINT,
    select_options_url_name: "organization_branches_url",
    select_options_value_field: "id",
    select_options_label_field: "name",
  },
];

export const GENERIC_GROUP_FILTER: any = [
  {
    filter_field_label: "Group",
    filter_type: "Select",
    filter_field: "group",
    filter_field_name: "group",
    filter_parameter: "group",
    select_options_url: GROUPS_ENDPOINT,
    select_options_url_name: "groups_url",
    select_options_value_field: "id",
    select_options_label_field: "[group_name]([group_code])",
    select_options_label_field_map: ["group_name", "group_code"],
  },
];

export const VIEW_MEMBER_GROUPS_FILTER: any = [
  {
    filter_field_label: "Group",
    filter_type: "Select",
    filter_field: "group_name",
    filter_field_name: "group_name",
    filter_parameter: "group_name",
    select_options_url: GROUPS_ENDPOINT,
    select_options_url_name: "groups_url",
    select_options_value_field: "id",
    select_options_label_field: "[group_name]([group_code])",
    select_options_label_field_map: ["group_name", "group_code"],
  },
];

export const DETAILED_LOAN_BALANCES_FILTERS: any = [
  {
    filter_field_label: "Date from",
    filter_field_label2: "Date to",
    filter_type: "DateRange",
    filter_field: "date",
    filter_field_name: "date_from",
    filter_field_name2: "date_to",
    filter_parameter: "date__range",
  },
  {
    filter_field_label: "Loan product",
    filter_type: "Select",
    filter_field: "loan_type",
    filter_field_name: "loan_type",
    filter_parameter: "loan_type",
    select_options_url: LOAN_PRODUCTS_ENDPOINT,
    select_options_url_name: "loan_products_url",
    select_options_value_field: "id",
    select_options_label_field: "product_name",
  },
  {
    filter_field_label: "Group",
    filter_type: "Select",
    filter_field: "group",
    filter_field_name: "group",
    filter_parameter: "group",
    select_options_url: GROUPS_ENDPOINT,
    select_options_url_name: "groups_url",
    select_options_value_field: "id",
    select_options_label_field: "[group_name]([group_code])",
    select_options_label_field_map: ["group_name", "group_code"],
  },
  {
    filter_field_label: "Branch",
    filter_type: "Select",
    filter_field: "branch",
    filter_field_name: "branch",
    filter_parameter: "branch",
    select_options_url: ORGANIZATION_BRANCHES_ENDPOINT,
    select_options_url_name: "organization_branches_url",
    select_options_value_field: "id",
    select_options_label_field: "name",
  },
];

export const LOAN_PORTFOLIO_DETAILS_FILTERS: any = [
  {
    filter_field_label: "Date to",
    filter_type: "Date",
    filter_field: "date_to",
    filter_field_name: "date_to",
    filter_parameter: "date_to",
  },
  {
    filter_field_label: "Loan product",
    filter_type: "Select",
    filter_field: "loan_type",
    filter_field_name: "loan_type",
    filter_parameter: "loan_type",
    select_options_url: LOAN_PRODUCTS_ENDPOINT,
    select_options_url_name: "loan_products_url",
    select_options_value_field: "id",
    select_options_label_field: "product_name",
  },
  {
    filter_field_label: "Branch",
    filter_type: "Select",
    filter_field: "branch",
    filter_field_name: "branch",
    filter_parameter: "branch",
    select_options_url: ORGANIZATION_BRANCHES_ENDPOINT,
    select_options_url_name: "organization_branches_url",
    select_options_value_field: "id",
    select_options_label_field: "name",
  },
  {
    filter_field_label: "Group",
    filter_type: "Select",
    filter_field: "group",
    filter_field_name: "group",
    filter_parameter: "group",
    select_options_url: GROUPS_ENDPOINT,
    select_options_url_name: "groups_url",
    select_options_value_field: "id",
    select_options_label_field: "[group_name]([group_code])",
    select_options_label_field_map: ["group_name", "group_code"],
  },
];
export const LOAN_PORTFOLIO_SUMMARY_FILTERS: any = [
  {
    filter_field_label: "Date to",
    filter_type: "Date",
    filter_field: "date_to",
    filter_field_name: "date_to",
    filter_parameter: "date_to",
  },
  {
    filter_field_label: "Loan product",
    filter_type: "Select",
    filter_field: "loan_type",
    filter_field_name: "loan_type",
    filter_parameter: "loan_type",
    select_options_url: LOAN_PRODUCTS_ENDPOINT,
    select_options_url_name: "loan_products_url",
    select_options_value_field: "id",
    select_options_label_field: "product_name",
  },
  {
    filter_field_label: "Branch",
    filter_type: "Select",
    filter_field: "branch",
    filter_field_name: "branch",
    filter_parameter: "branch",
    select_options_url: ORGANIZATION_BRANCHES_ENDPOINT,
    select_options_url_name: "organization_branches_url",
    select_options_value_field: "id",
    select_options_label_field: "name",
  },
  {
    filter_field_label: "Group",
    filter_type: "Select",
    filter_field: "group",
    filter_field_name: "group",
    filter_parameter: "group",
    select_options_url: GROUPS_ENDPOINT,
    select_options_url_name: "groups_url",
    select_options_value_field: "id",
    select_options_label_field: "[group_name]([group_code])",
    select_options_label_field_map: ["group_name", "group_code"],
  },
];

export const LOAN_DISBURSEMENT_REPORT_FILTERS: any = [
  {
    filter_field_label: "Date from",
    filter_field_label2: "Date to",
    filter_type: "DateRange",
    filter_field: "date",
    filter_field_name: "date_from",
    filter_field_name2: "date_to",
    filter_parameter: "date__range",
  },
  {
    filter_field_label: "Branch",
    filter_type: "Select",
    filter_field: "branch",
    filter_field_name: "branch",
    filter_parameter: "branch",
    select_options_url: ORGANIZATION_BRANCHES_ENDPOINT,
    select_options_url_name: "organization_branches_url",
    select_options_value_field: "id",
    select_options_label_field: "name",
  },
  {
    filter_field_label: "Group",
    filter_type: "Select",
    filter_field: "group",
    filter_field_name: "group",
    filter_parameter: "group",
    select_options_url: GROUPS_ENDPOINT,
    select_options_url_name: "groups_url",
    select_options_value_field: "id",
    select_options_label_field: "[group_name]([group_code])",
    select_options_label_field_map: ["group_name", "group_code"],
  },
];
