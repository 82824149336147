import React, { Component } from "react";
import { getUrlData, label } from "../utils/componentActions";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Portlet extends Component {
  render() {
    const { props } = this;
    return (
      <div className="card mb-5 pb-3">
        <div className="card-header bg-green text-center p-2">
          <span className="font-weight-bold text-white">
            {label(this.props.header_label, props)}
          </span>
        </div>
        <div className="card-body">{this.props.children}</div>
      </div>
    );
  }
}

Portlet.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  system_labels_data: PropTypes.instanceOf(Object).isRequired,
  system_labels_dict_url: PropTypes.instanceOf(Object),
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    dataByUrl,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(Portlet));
