import React, { Component } from "react";

import { LOAN_PORTFOLIO_DETAILS_ENDPOINT } from "../../constants/Constants";
import { LOAN_PORTFOLIO_DETAILS_FILTERS } from "../../components/DataTableFilterFields";
import { LOAN_PORTFOLIO_DETAILS_COLUMNS } from "../../components/DataTableColumns";
import ViewDataTable from "../../components/ViewDataTable";

/**
 * LoanPortfolioDetails component
 * @extends Component
 */

class LoanPortfolioDetails extends Component {
  /**
   * Renders LoanPortfolioDetails component
   * @return {ReactElement}
   */
  render() {
    return (
      <ViewDataTable
        table_filters={LOAN_PORTFOLIO_DETAILS_FILTERS}
        data_source_url={LOAN_PORTFOLIO_DETAILS_ENDPOINT}
        columns={LOAN_PORTFOLIO_DETAILS_COLUMNS}
        totals
        basic_table
        paginated={false}
        allow_print
        allow_refresh
        wait_for_props
        wrapper_class="table-responsive"
      />
    );
  }
}

export default LoanPortfolioDetails;
