import {
  API_ENDPOINT,
  BANKS_ENDPOINT,
  CHART_OF_ACCOUNTS_ENDPOINT,
  CURRENCIES_ENDPOINT,
  ORGANIZATION_ENDPOINT,
  PAYMENT_MODES_ENDPOINT,
} from "../../constants/Constants";
import React, { Component } from "react";
import {
  delay,
  extractResponseError,
  fetchUrlData,
  formDataToJSON,
  getUrlData,
  label,
  lookup,
  pushHistory,
} from "../../utils/componentActions";

import $ from "jquery";
import Data from "currency-codes/data";
import FormActivityIndicator from "../../components/FormActivityIndicator";
import FormFeedbackMessage from "../../components/FormFeedbackMessage";
import PageLoadingIndicator from "../../components/PageLoadingIndicator";
import PropTypes from "prop-types";
import Select from "react-select-oss";
import { connect } from "react-redux";
import { countries } from "countries-list";
import { postAPIRequest } from "../../utils/APIRequests";
import { withRouter } from "react-router-dom";

class SystemSetup extends Component {
  constructor(props) {
    super(props);
    const organization_types: any = [
      { value: "microfinance", label: label("Microfinance", this.props) },
      { value: "sacco", label: label("SACCO", this.props) },
      { value: "chama", label: label("Chama", this.props) },
      { value: "other", label: label("Other", this.props) },
    ];
    const system_features: any = [
      { value: "loan_management", label: label("Loan management", this.props) },
      {
        value: "savings_shares",
        label: label("Savings & Shares management", this.props),
      },
      { value: "book_keeping", label: label("Book keeping", this.props) },
      { value: "invoicing", label: label("Invoicing", this.props) },
      {
        value: "income_expenses",
        label: label("Income & Expenses management", this.props),
      },
      { value: "bulk_sms", label: label("Bulk SMS", this.props) },
      { value: "payroll", label: label("Payroll management", this.props) },
    ];
    const currencies_list: any = Data.map((currency) => ({
      value: currency.code,
      label: currency.currency,
    }));
    this.state = {
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
      organization_types,
      system_features,
      location_country_code: "",
      loading: true,
      selected_features: null,
      currencies_list,
      selected_currency: null,
    };
  }

  componentDidMount() {
    lookup((country) => {
      this.setState({
        loading: false,
        location_country_code: country,
      });
    });
    const organization_url: string = `${ORGANIZATION_ENDPOINT}i/`;
    fetchUrlData("organization_url", organization_url, this.props);
    fetchUrlData("currencies_url", CURRENCIES_ENDPOINT, this.props);
    fetchUrlData("payment_modes_url", PAYMENT_MODES_ENDPOINT, this.props);
    fetchUrlData("banks_url", BANKS_ENDPOINT, this.props);
    fetchUrlData(
      "chart_of_accounts_url",
      CHART_OF_ACCOUNTS_ENDPOINT,
      this.props
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps, _) {
    const { organization_data } = nextProps;
    const organization: any = organization_data.items;
    if (!organization_data.isFetching) {
      if (organization.system_features === []) {
        this.setState({
          feedback_message: label(
            "You need to update your configurations to continue with operations",
            this.props
          ),
        });
      }
    }
  }

  getRequestMethodUrl(base_url, data) {
    const { items } = data;
    let request_method: string = "POST";
    let url: any = base_url;
    if (items.length > 0) {
      request_method = "PUT";
      url = `${url}${items[0].id}/`;
    }
    return [url, request_method];
  }

  handleUpdateOrganization = (payload, callback) => {
    const { organization_data } = this.props;
    const organization: any = organization_data.items;
    const organization_url: string = `${API_ENDPOINT}${ORGANIZATION_ENDPOINT}${organization.id}/`;
    postAPIRequest(
      organization_url,
      () => {
        callback();
      },
      (results) => {
        const feedback_message: any = extractResponseError(results);
        this.setState({
          feedback_message,
          feedback_type: "danger",
          activity: false,
        });
      },
      payload,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
      },
      "PUT"
    );
  };

  handleCreateUpdateItem = (payload, url, request_method, callback = null) => {
    postAPIRequest(
      url,
      (results) => {
        if (callback) {
          callback(results);
        }
      },
      (results) => {
        const feedback_message: any = extractResponseError(results);
        this.setState({
          feedback_message,
          feedback_type: "danger",
          activity: false,
        });
      },
      payload,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
      },
      request_method
    );
  };

  handleGetAccounts = (type) => {
    const { chart_of_accounts_data } = this.props;
    const chart_of_accounts: any = chart_of_accounts_data.items;
    return chart_of_accounts.filter((account) => account.account_type === type);
  };

  handleRefreshAccounts = () => {
    fetchUrlData(
      "chart_of_accounts_url",
      CHART_OF_ACCOUNTS_ENDPOINT,
      this.props,
      true
    );
  };

  handleCreateAccounts = (bankCallback) => {
    const chart_of_accounts_url: any =
      API_ENDPOINT + CHART_OF_ACCOUNTS_ENDPOINT;
    const assets_accounts: any = this.handleGetAccounts(1);
    const capital_accounts: any = this.handleGetAccounts(2);
    const liabilities_accounts: any = this.handleGetAccounts(3);
    const income_accounts: any = this.handleGetAccounts(4);
    const expenses_accounts: any = this.handleGetAccounts(5);
    if (assets_accounts.length === 0) {
      this.handleCreateUpdateItem(
        {
          account_type: 1,
          account_name: "Assets",
          gl_code: 1,
          manual_entries_allowed: "yes",
        },
        chart_of_accounts_url,
        "POST",
        bankCallback
      );
    } else {
      bankCallback(assets_accounts[0]);
    }
    if (capital_accounts.length === 0) {
      this.handleCreateUpdateItem(
        {
          account_type: 2,
          account_name: "Capital",
          gl_code: 2,
          manual_entries_allowed: "yes",
        },
        chart_of_accounts_url,
        "POST",
        this.handleRefreshAccounts()
      );
    }
    if (liabilities_accounts.length === 0) {
      this.handleCreateUpdateItem(
        {
          account_type: 3,
          account_name: "Liabilities",
          gl_code: 3,
          manual_entries_allowed: "yes",
        },
        chart_of_accounts_url,
        "POST",
        this.handleRefreshAccounts()
      );
    }
    if (income_accounts.length === 0) {
      this.handleCreateUpdateItem(
        {
          account_type: 4,
          account_name: "Income",
          gl_code: 4,
          manual_entries_allowed: "yes",
        },
        chart_of_accounts_url,
        "POST",
        this.handleRefreshAccounts()
      );
    }
    if (expenses_accounts.length === 0) {
      this.handleCreateUpdateItem(
        {
          account_type: 5,
          account_name: "Expenses",
          gl_code: 5,
          manual_entries_allowed: "yes",
        },
        chart_of_accounts_url,
        "POST",
        this.handleRefreshAccounts()
      );
    }
  };

  handleRefreshData = () => {
    const organization_url: string = `${ORGANIZATION_ENDPOINT}i/`;
    fetchUrlData("organization_url", organization_url, this.props, true);
    fetchUrlData("currencies_url", CURRENCIES_ENDPOINT, this.props, true);
    fetchUrlData("payment_modes_url", PAYMENT_MODES_ENDPOINT, this.props, true);
    fetchUrlData("banks_url", BANKS_ENDPOINT, this.props, true);
  };

  getSelectedCurrency = () => {
    const { currencies_data } = this.props;
    const currencies: any = currencies_data.items;
    const location_country: any = countries[this.state.location_country_code];
    let { selected_currency } = this.state;
    if (!selected_currency) {
      selected_currency = this.state.currencies_list.find(
        (currency) =>
          currency.value ===
          (
            currencies[0] || {
              code: location_country.currency,
            }
          ).code
      );
    }
    return selected_currency;
  };

  handleConfigure(e) {
    e.preventDefault();
    this.setState({
      activity: true,
    });
    const { organization_data } = this.props;
    const organization: any = organization_data.items;
    const formData: any = new FormData($("form#form-configure")[0]);
    const payload: any = formDataToJSON(formData, {});
    const selected_features: any = this.state.selected_features || [];
    if (
      selected_features.length === 0 &&
      organization.system_features.length === 0
    ) {
      this.setState({
        activity: false,
        feedback_type: "danger",
        feedback_message: label(
          "You need to select at least one system feature",
          this.props
        ),
      });
    } else {
      if (selected_features.length > 0) {
        payload.system_features = selected_features.map(
          (feature) => feature.value
        );
      } else {
        payload.system_features = organization.system_features;
      }
      const organization_payload: any = {
        name: payload.organization_name,
        system_features: payload.system_features,
      };
      const payment_mode_payload: any = {
        name: payload.payment_mode,
        description: payload.payment_mode,
        is_cash_payment: "2",
      };
      const { payment_modes_data, currencies_data, banks_data } = this.props;
      const selected_currency: any = this.getSelectedCurrency();
      const currency_payload: any = {
        name: selected_currency.label,
        code: selected_currency.value,
      };
      let payment_modes_url: any = API_ENDPOINT + PAYMENT_MODES_ENDPOINT;
      let currencies_url: any = API_ENDPOINT + CURRENCIES_ENDPOINT;
      let banks_url: any = API_ENDPOINT + BANKS_ENDPOINT;
      let payment_modes_request_method;
      let currencies_request_method;
      let banks_method;
      [payment_modes_url, payment_modes_request_method] =
        this.getRequestMethodUrl(payment_modes_url, payment_modes_data);
      [currencies_url, currencies_request_method] = this.getRequestMethodUrl(
        currencies_url,
        currencies_data
      );
      [banks_url, banks_method] = this.getRequestMethodUrl(
        banks_url,
        banks_data
      );
      const banks: any = banks_data.items;
      this.handleUpdateOrganization(organization_payload, () =>
        this.handleCreateUpdateItem(
          payment_mode_payload,
          payment_modes_url,
          payment_modes_request_method,
          () =>
            this.handleCreateUpdateItem(
              currency_payload,
              currencies_url,
              currencies_request_method,
              () =>
                this.handleCreateAccounts((asset_account) => {
                  const bank_payload: any = {
                    bank_name: payload.bank,
                    gl_account: (banks[0] || {}).gl_account || asset_account.id,
                  };
                  this.handleCreateUpdateItem(
                    bank_payload,
                    banks_url,
                    banks_method,
                    () =>
                      this.setState(
                        {
                          activity: false,
                          feedback_type: "success",
                          feedback_message:
                            "Configurations update was successful," +
                            " redirecting you to home...",
                        },
                        async () => {
                          await delay(3000);
                          await this.handleRefreshData();
                          await pushHistory("/", this.props);
                        }
                      )
                  );
                })
            )
        )
      );
    }
  }

  handleFieldChange(field_value, name) {
    this.setState({
      [name]: field_value,
    });
  }

  render() {
    const {
      organization_data,
      currencies_data,
      payment_modes_data,
      banks_data,
    } = this.props;
    if (
      organization_data.isFetching ||
      currencies_data.isFetching ||
      payment_modes_data.isFetching ||
      banks_data.isFetching ||
      this.state.loading
    ) {
      return <PageLoadingIndicator />;
    }
    const payment_modes_list: any = [
      { value: "Cash", label: label("Cash", this.props) },
      { value: "Cheque", label: label("Cheque", this.props) },
      { value: "Credit card", label: label("Credit card", this.props) },
      { value: "Mobile payment", label: label("Mobile payment", this.props) },
      { value: "Bank transfer", label: label("Bank transfer", this.props) },
      { value: "E-wallet", label: label("E-wallet", this.props) },
      { value: "Prepaid card", label: label("Prepaid card", this.props) },
      { value: "Direct deposit", label: label("Direct deposit", this.props) },
    ];
    const organization: any = organization_data.items;
    const payment_modes: any = payment_modes_data.items;
    const banks: any = banks_data.items;
    const selected_features: any = this.state.system_features.filter(
      (feature) => (organization.system_features || []).includes(feature.value)
    );
    const selected_organization_type: any = this.state.organization_types.find(
      (type) => organization.organization_type === type.value
    );
    const selected_currency: any = this.getSelectedCurrency();
    const selected_payment_method: any = payment_modes_list.find(
      (mode) => mode.value === (payment_modes[0] || { name: "Cash" }).name
    );
    let feedback_message: string = "";
    if (this.state.feedback_message) {
      feedback_message = (
        <FormFeedbackMessage
          feedback_message={this.state.feedback_message}
          feedback_type={this.state.feedback_type}
        />
      );
    }
    let configure_button: any = (
      <div className="col-12">
        <button
          className="btn btn-green btn-block text-white"
          type="submit"
          key={1}
        >
          {label("Configure", this.props)}
        </button>
      </div>
    );
    if (this.state.activity) {
      configure_button = <FormActivityIndicator />;
    }

    return (
      <div
        className="vw-100 vh-100 container-fluid row
             justify-content-center align-content-center mx-0"
      >
        <div className="col-md-6">
          <div className="card">
            <div className="card-header bg-green text-center p-2">
              <span className="h4 font-weight-bold text-white">
                {label("System Setup", this.props)}
              </span>
            </div>
            <div className="card-body">
              {feedback_message}
              <form
                id="form-configure"
                className="row g-1"
                onSubmit={(e) => this.handleConfigure(e)}
              >
                <div className="col-12">
                  <label className="form-label">
                    {label("Organization type", this.props)}
                  </label>
                  <Select
                    options={this.state.organization_types}
                    name="organization_type"
                    defaultValue={selected_organization_type}
                  />
                </div>
                <div className="col-12">
                  <label className="form-label">
                    {label("Organization name", this.props)}
                  </label>
                  <input
                    className="form-control"
                    name="organization_name"
                    required
                    defaultValue={organization.name}
                  />
                </div>
                <div className="col-12">
                  <label className="form-label">
                    {label("System features", this.props)}
                  </label>
                  <Select
                    options={this.state.system_features}
                    isMulti
                    closeMenuOnSelect={false}
                    name="system_features"
                    defaultValue={selected_features}
                    onChange={(features) =>
                      this.handleFieldChange(features, "selected_features")
                    }
                  />
                </div>
                <div className="col-12">
                  <label className="form-label">
                    {label("Default currency", this.props)}
                  </label>
                  <Select
                    options={this.state.currencies_list}
                    name="currency"
                    defaultValue={selected_currency}
                    onChange={(currency) =>
                      this.handleFieldChange(currency, "selected_currency")
                    }
                  />
                </div>
                <div className="col-12">
                  <label className="form-label">
                    {label("Default bank", this.props)}
                  </label>
                  <input
                    className="form-control"
                    name="bank"
                    required
                    defaultValue={(banks[0] || {}).bank_name}
                  />
                </div>
                <div className="col-12">
                  <label className="form-label">
                    {label("Default payment method", this.props)}
                  </label>
                  <Select
                    options={payment_modes_list}
                    name="payment_mode"
                    defaultValue={selected_payment_method}
                  />
                </div>
                {configure_button}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SystemSetup.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  organization_data: PropTypes.instanceOf(Object).isRequired,
  currencies_data: PropTypes.instanceOf(Object).isRequired,
  payment_modes_data: PropTypes.instanceOf(Object).isRequired,
  banks_data: PropTypes.instanceOf(Object).isRequired,
  chart_of_accounts_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const organization_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "organization_url"
  );
  const currencies_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "currencies_url"
  );
  const payment_modes_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "payment_modes_url"
  );
  const banks_data: any = getUrlData(dataByUrl, sessionVariables, "banks_url");
  const chart_of_accounts_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "chart_of_accounts_url"
  );
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    organization_data,
    currencies_data,
    payment_modes_data,
    banks_data,
    chart_of_accounts_data,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(SystemSetup));
