import React, { Component } from "react";

import { VIEW_LOAN_CHARGES_PATH } from "../../../constants/ClientPaths";
import VIewAddPortlet from "../../../components/VIewAddPortlet";
import { withRouter } from "react-router-dom";

class LoanCharges extends Component {
  render() {
    const pills: any = [
      {
        label: "View Loan Charges",
        path: VIEW_LOAN_CHARGES_PATH,
      },
    ];
    return (
      <VIewAddPortlet header_label="Loan charges" pills={pills}>
        {this.props.children}
      </VIewAddPortlet>
    );
  }
}

export default withRouter(LoanCharges);
