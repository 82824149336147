import { ACTIVE, COMPLETE } from "../../constants/Status";
import React, { Component } from "react";

import { DETAILED_LOAN_BALANCES_ENDPOINT } from "../../constants/Constants";
import { DETAILED_LOAN_BALANCES_FILTERS } from "../../components/DataTableFilterFields";
import { DETAILED_LOAN_REPORTS_COLUMNS } from "../../components/DataTableColumns";
import Portlet from "../../components/Portlet";
import PropTypes from "prop-types";
import ViewDataTable from "../../components/ViewDataTable";

class DetailedLoanBalances extends Component {
  getStatus() {
    const { match } = this.props;
    const { status } = match.params;
    return status === "complete" ? COMPLETE : ACTIVE;
  }

  render() {
    return (
      <Portlet header_label="Detailed loan balances">
        <div className="row">
          <div className="col table-responsive">
            <ViewDataTable
              table_filters={DETAILED_LOAN_BALANCES_FILTERS}
              data_source_url={DETAILED_LOAN_BALANCES_ENDPOINT}
              columns={DETAILED_LOAN_REPORTS_COLUMNS}
              totals
              basic_table
              paginated={false}
              allow_print
              allow_refresh
              wait_for_props
              extra_get_params={`status=${this.getStatus()}`}
            />
          </div>
        </div>
      </Portlet>
    );
  }
}

DetailedLoanBalances.propTypes = {
  match: PropTypes.instanceOf(Object),
};

DetailedLoanBalances.defaultProps = {
  match: {},
};

export default DetailedLoanBalances;
