import React, { Component } from "react";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormActivityIndicator from "../../../components/FormActivityIndicator";
import { getUrlData, label } from "../../../utils/componentActions";
import { connect } from "react-redux";

class ConfigureProductsButtons extends Component {
  constructor(props) {
    super(props);
    const pills: string[] = [
      "details",
      "currency",
      "terms",
      "settings",
      "charges",
      "accounting",
    ];
    this.state = {
      pills,
    };
  }

  handlePrevious(e) {
    e.preventDefault();
    const { active_pill } = this.props.state;
    const { pills } = this.state;
    const active_pill_index: any = pills.indexOf(active_pill);
    const previous_pill: any = pills[active_pill_index - 1];
    this.props.handleSubmitConfiguration(previous_pill);
  }

  handleNext(e) {
    e.preventDefault();
    const { active_pill } = this.props.state;
    const { pills } = this.state;
    const active_pill_index: any = pills.indexOf(active_pill);
    const next_pill_index: any = active_pill_index + 1;
    if (next_pill_index + 1 <= pills.length) {
      const next_pill: any = pills[next_pill_index];
      this.props.handleSubmitConfiguration(next_pill);
    } else {
      this.props.handleSubmitConfiguration();
    }
  }

  render() {
    let configuration_buttons: any = (
      <div className="d-flex justify-content-center">
        <button
          className="btn btn-green text-white"
          onClick={(e) => this.handlePrevious(e)}
        >
          <FontAwesomeIcon icon={faArrowLeft} /> {label("Previous", this.props)}
        </button>
        <button
          className="btn btn-green ml-4 text-white"
          onClick={(e) => this.handleNext(e)}
        >
          {label("Next", this.props)} <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    );
    if (this.props.state.activity) {
      configuration_buttons = <FormActivityIndicator />;
    } else if (this.props.state.active_pill === "details") {
      configuration_buttons = (
        <div className="d-flex justify-content-center">
          <button className="btn btn-light disabled" disabled>
            <FontAwesomeIcon icon={faArrowLeft} />{" "}
            {label("Previous", this.props)}
          </button>
          <button
            className="btn btn-green ml-4 text-white"
            onClick={(e) => this.handleNext(e)}
          >
            {label("Next", this.props)} <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      );
    } else if (this.props.state.active_pill === "accounting") {
      configuration_buttons = (
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-green text-white"
            onClick={(e) => this.handlePrevious(e)}
          >
            <FontAwesomeIcon icon={faArrowLeft} />{" "}
            {label("Previous", this.props)}
          </button>
          <button
            className="btn btn-green ml-3 text-white"
            onClick={(e) => this.handleNext(e)}
          >
            {label("Finish", this.props)}{" "}
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      );
    }
    return <div>{configuration_buttons}</div>;
  }
}

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(ConfigureProductsButtons);
