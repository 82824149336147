import {
  COLLATERAL_ENDPOINT,
  STATUS_ENDPOINT,
  MEMBERS_ENDPOINT,
  NON_MEMBERS_ENDPOINT,
} from "../../constants/Constants";
import React, { Component } from "react";
import {
  fetchUrlData,
  getSelect2Options,
  getUrlData,
} from "../../utils/componentActions";

import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

/**
 * This component is used to create and edit a collateral
 */
class RegisterCollateral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collateral: {
        member_type: 1,
      },
    };
  }

  /**
   * This method is called when the component is mounted
   */
  componentDidMount() {
    const collateral_id: any = this.props.match.params.id;
    if (collateral_id) {
      const collateral_url: string = `${COLLATERAL_ENDPOINT}${collateral_id}/`;
      fetchUrlData("collateral_url", collateral_url, this.props);
    }
    fetchUrlData("status_url", `${STATUS_ENDPOINT}?member=1`, this.props);
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  /**
   * Update the collateral object when the member type is changed
   * @param {object} selected - The event object
   */
  handleMemberTypeChange(selected) {
    this.setState((prevState) => ({
      collateral: {
        ...prevState.collateral,
        member_type: selected.target.value,
      },
    }));
  }

  /**
   * Render the component.
   */
  render() {
    const { props, state } = this;
    const { collateral_data, status_data } = props;
    const { collateral, member_type } = state;
    const status: any = status_data.items;
    const status_options: any = getSelect2Options(status, "id", "name");
    const form_classes: string = "row g-3";
    let member_non_member_field = (
      <FormFields
        key={2}
        field_type="select2_async"
        field_label="Member"
        props={props}
        field_name="member"
        wrapper_div_classes="col-4"
        data_object={collateral}
        endpoint={MEMBERS_ENDPOINT}
        value_key="id"
        label_key="full_name"
        required
      />
    );
    if (collateral.member_type === "2") {
      member_non_member_field = (
        <FormFields
          key={2}
          field_type="select2_async"
          field_label="Non-member"
          props={props}
          field_name="non_member"
          wrapper_div_classes="col-4"
          data_object={collateral}
          endpoint={NON_MEMBERS_ENDPOINT}
          value_key="id"
          label_key="non_member_name"
          required
        />
      );
    }
    const form_items: any = [
      <FormFields
        key={1}
        field_type="select"
        field_label="Member type"
        props={props}
        field_name="member_type"
        wrapper_div_classes="col-4"
        data_object={collateral}
        onChange={(selected) => this.handleMemberTypeChange(selected)}
        required
      >
        <option value="1">Member</option>
        <option value="2">Non-member</option>
      </FormFields>,
      member_non_member_field,
      <FormFields
        key={3}
        field_type="text_input"
        field_label="Collateral ID number"
        props={props}
        field_name="collateral_id_no"
        wrapper_div_classes="col-4"
        data_object={collateral}
        required
      />,
      <FormFields
        key={4}
        field_type="number_input"
        field_label="Value"
        props={props}
        field_name="value"
        wrapper_div_classes="col-4"
        data_object={collateral}
        required
      />,
      <FormFields
        key={5}
        field_type="select"
        field_label="Collateral type"
        props={props}
        field_name="collateral_type"
        wrapper_div_classes="col-4"
        data_object={collateral}
        required
      >
        <option value="movable">Movable</option>
        <option value="immovable">Immovable</option>
      </FormFields>,
      <FormFields
        key={6}
        field_type="select2"
        field_label="Status"
        props={props}
        field_name="status"
        wrapper_div_classes="col-4"
        select2_options={status_options}
        select2_default_first
        data_object={collateral}
        required
      />,
      <FormFields
        key={7}
        field_type="textarea"
        field_label="Description"
        props={props}
        field_name="description"
        wrapper_div_classes="col-4"
        data_object={collateral}
        required
      />,
      <FormFields
        key={8}
        field_type="textarea"
        field_label="Comment"
        props={props}
        field_name="comment"
        wrapper_div_classes="col-4"
        data_object={collateral}
        required
      />,
    ];
    return (
      <CreateEditForm
        loading={collateral_data.isFetching}
        form_items={form_items}
        form_classes={form_classes}
        action_object={collateral_data.items}
        setState={this.updateState}
        action_object_name="collateral"
        state={state}
        action_object_endpoint={COLLATERAL_ENDPOINT}
        form_id="form-account-details"
        object_display_name="collateral"
      />
    );
  }
}

RegisterCollateral.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  collateral_data: PropTypes.instanceOf(Object).isRequired,
  status_data: PropTypes.instanceOf(Object).isRequired,
};

/**
 * Map the state to props.
 */
function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const collateral_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "collateral_url"
  );
  const status_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "status_url"
  );

  return {
    sessionVariables,
    collateral_data,
    status_data,
  };
}

export default connect(mapStateToProps)(withRouter(RegisterCollateral));
