import React, { Component } from "react";

import { PENDING_LOANS_COLUMNS } from "../../components/DataTableColumns";
import ViewAppliedLoans from "./ViewAppliedLoans";
import { withRouter } from "react-router-dom";

class ViewPendingLoans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 5,
    };
  }

  render() {
    return <ViewAppliedLoans status={6} columns={PENDING_LOANS_COLUMNS} />;
  }
}

export default withRouter(ViewPendingLoans);
