import {
  BANKS_ENDPOINT,
  BANK_DETAILS_ENDPOINT,
} from "../../constants/Constants";
import React, { Component } from "react";
import { fetchUrlData, getUrlData } from "../../utils/componentActions";

import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class RegisterBankDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      member_bank: {},
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
    };
  }

  componentDidMount() {
    fetchUrlData("banks_url", BANKS_ENDPOINT, this.props);
    const bank_details_id: any = this.props.match.params.id;
    if (bank_details_id) {
      const member_bank_url: string = `${BANK_DETAILS_ENDPOINT}${bank_details_id}/`;
      fetchUrlData("member_bank_url", member_bank_url, this.props);
    }
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const { banks_data, member_bank_data } = props;
    const banks: any = banks_data.items;
    const { member_bank } = this.state;
    let selected_member;
    if (member_bank.member) {
      selected_member = {
        value: member_bank.member,
        label: member_bank.member_full_name,
      };
    }
    const banks_options: any = banks.map((bank) => ({
      value: bank.id,
      label: bank.bank_name,
    }));
    const form_classes: string = "row g-3";
    const overwrite_form_data: any = [
      { name: "bank", value: member_bank.bank || "" },
    ];
    const form_items: any = [
      <FormFields
        key={1}
        field_type="member"
        field_label="Member"
        props={props}
        field_name="member"
        wrapper_div_classes="col-4"
        data_object={member_bank}
        required
        selected_member={selected_member}
      />,
      <FormFields
        key={2}
        field_type="select2_creatable"
        field_label="Bank"
        props={props}
        field_name="bank"
        wrapper_div_classes="col-4"
        select2_create_field_name="bank_name"
        updateState={this.updateState}
        update_payload
        data_object_name="member_bank"
        data_object={member_bank}
        select2_options={banks_options}
      />,
      <FormFields
        key={3}
        field_type="text_input"
        field_label="Account number"
        props={props}
        required
        field_name="account_number"
        wrapper_div_classes="col-4"
        data_object={member_bank}
      />,
    ];
    return (
      <CreateEditForm
        loading={banks_data.isFetching || member_bank_data.isFetching}
        form_items={form_items}
        form_classes={form_classes}
        action_object={member_bank_data.items}
        setState={this.updateState}
        overwrite_form_data={overwrite_form_data}
        action_object_name="member_bank"
        state={this.state}
        payload={member_bank}
        action_object_endpoint={BANK_DETAILS_ENDPOINT}
        object_display_name="member bank"
      />
    );
  }
}

RegisterBankDetails.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  banks_data: PropTypes.instanceOf(Object).isRequired,
  member_bank_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const banks_data: any = getUrlData(dataByUrl, sessionVariables, "banks_url");
  const member_bank_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "member_bank_url"
  );

  return {
    sessionVariables,
    dataByUrl,
    banks_data,
    member_bank_data,
  };
}

export default connect(mapStateToProps)(withRouter(RegisterBankDetails));
