import {
  RECEIPT_TEMPLATES_PATH,
  SMS_NOTIFICATION_SETTINGS_PATH,
  SMS_NOTIFICATION_TEMPLATES_PATH,
  TRANSACTIONAL_SETTINGS_PATH,
} from "../../constants/ClientPaths";
import React, { Component } from "react";

import VIewAddPortlet from "../../components/VIewAddPortlet";
import { withRouter } from "react-router-dom";

class FinancialYearClosing extends Component {
  render() {
    const pills: any = [
      {
        label: "SMS notifications",
        path: SMS_NOTIFICATION_SETTINGS_PATH,
      },
      {
        label: "SMS notification templates",
        path: SMS_NOTIFICATION_TEMPLATES_PATH,
      },
      {
        label: "Transactional settings",
        path: TRANSACTIONAL_SETTINGS_PATH,
      },
      {
        label: "Receipt templates",
        path: RECEIPT_TEMPLATES_PATH,
      },
    ];
    return (
      <VIewAddPortlet header_label="Settings" pills={pills}>
        {this.props.children}
      </VIewAddPortlet>
    );
  }
}

export default withRouter(FinancialYearClosing);
