import React, { Component } from "react";

import { VIEW_GROUPS_PATH } from "../constants/ClientPaths";
import VIewAddPortlet from "../components/VIewAddPortlet";
import { withRouter } from "react-router-dom";

class Currencies extends Component {
  render() {
    const pills: any = [
      {
        label: "View groups",
        path: VIEW_GROUPS_PATH,
      },
    ];
    return (
      <VIewAddPortlet header_label="Groups" pills={pills}>
        {this.props.children}
      </VIewAddPortlet>
    );
  }
}

export default withRouter(Currencies);
