import {
  ABSOLUTE_API_ENDPOINT,
  API_ENDPOINT,
  CLIENT_BASE_URL,
  LOAN_PRODUCTS_ENDPOINT,
  LOGOUT_ENDPOINT,
  MEMBERS_LOGIN_ENDPOINT,
  ORGANIZATION_ENDPOINT,
  SAVINGS_PRODUCTS_ENDPOINT,
  SYSTEM_LABELS_ENDPOINT,
  USERS_ENDPOINT,
} from "../constants/Constants";
import {
  ACTIVITY_LOG_PATH,
  BILLING_INVOICES_PATH,
  CONFIGURE_LOAN_PRODUCT_PATH,
  CONFIGURE_SAVINGS_PRODUCT_PATH,
  INDEX_PATH,
  LOGIN_PATH,
  SMS_NOTIFICATION_SETTINGS_PATH,
  SYSTEM_SETUP_PATH,
  VIEW_LOAN_PRODUCTS_PATH,
  VIEW_USERS_PATH,
} from "../constants/ClientPaths";
import React, { Component } from "react";
import {
  dynamicSort,
  fetchUrlData,
  generateID,
  getUrlData,
  invalidateSessionData,
  label,
  objGetField,
  pushHistory,
  thousandsFormat,
} from "../utils/componentActions";
import {
  faBell,
  faBriefcase,
  faCog,
  faCreditCard,
  faInfoCircle,
  faSignOutAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { getAPIRequest, postAPIRequest } from "../utils/APIRequests";

import $ from "jquery";
import BootstrapIcon from "./BootstrapIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../logo.ico";
import ModalHOC from "./ModalHOC";
import PageLoadingIndicator from "./PageLoadingIndicator";
import PropTypes from "prop-types";
import { Toast } from "bootstrap";
import ToastNotification from "./ToastNotification";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tour_steps: [],
      show_invoice_modal: false,
      show_toast: {},
    };
  }

  componentDidMount() {
    if (localStorage.member) {
      this.loadDashboardMembersData();
    } else {
      this.getCurrentUser();
    }
  }

  loadDashboardMembersData() {
    const path_name: any = this.props.location.pathname || "";
    const id: any = localStorage.member;
    const token: any = localStorage.auth_token;
    const user_url: string = `${MEMBERS_LOGIN_ENDPOINT}${id}/?member=${id}&token=${token}`;
    const current_user_url: string = `${API_ENDPOINT}${user_url}`;
    getAPIRequest(
      current_user_url,
      () => {
        this.handleLoadInitialData(user_url);
      },
      () => {
        this.props.history.push(
          `${CLIENT_BASE_URL}${LOGIN_PATH}/?next=${path_name}`
        );
      },
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
      }
    );
  }

  handleLogout(e) {
    e.preventDefault();
    const logout_url: any = API_ENDPOINT + LOGOUT_ENDPOINT;
    postAPIRequest(
      logout_url,
      () => {
        // intentionally empty.
      },
      () => {
        // intentionally empty.
      },
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
      }
    );
    localStorage.clear();
    invalidateSessionData(this);
    pushHistory(LOGIN_PATH, this.props);
  }

  handleLoadInitialData(user_url) {
    let system_labels_url: any = SYSTEM_LABELS_ENDPOINT;
    if (localStorage.member) {
      system_labels_url += `?member=${localStorage.member}`;
    }
    fetchUrlData("system_labels_url", system_labels_url, this.props);
    fetchUrlData(
      "system_labels_key_dict_url",
      `${SYSTEM_LABELS_ENDPOINT}?key_dict=true`,
      this.props
    );
    fetchUrlData("organization_url", `${ORGANIZATION_ENDPOINT}i/`, this.props);
    fetchUrlData("current_user_url", user_url, this.props);
    fetchUrlData(
      "loan_products_ids_url",
      `${LOAN_PRODUCTS_ENDPOINT}?fields=id`,
      this.props
    );
    fetchUrlData(
      "savings_products_ids_url",
      `${SAVINGS_PRODUCTS_ENDPOINT}?fields=id`,
      this.props
    );
  }

  getCurrentUser() {
    const path_name: any = this.props.location.pathname || "";
    const user_url: string = `${USERS_ENDPOINT}i/`;
    const current_user_url: string = `${API_ENDPOINT}${user_url}`;
    getAPIRequest(
      current_user_url,
      () => {
        this.handleLoadInitialData(user_url);
      },
      () => {
        this.props.history.push(
          `${CLIENT_BASE_URL}${LOGIN_PATH}/?next=${path_name}`
        );
      },
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
      }
    );
  }

  showInvoiceModal = () => {
    this.setState({
      show_invoice_modal: true,
    });
  };

  hideInvoiceModal = () => {
    this.setState({
      show_invoice_modal: false,
    });
  };

  componentDidUpdate(_, __, ___) {
    const toastElList: any = [].slice.call(document.querySelectorAll(".toast"));
    const { show_toast } = this.state;
    toastElList.forEach((toastEl, key) => {
      toastEl.addEventListener("hidden.bs.toast", () => {
        show_toast[key] = false;
        this.setState({
          show_toast,
        });
      });
      const toastInstance: any = new Toast(toastEl, {
        animation: true,
        autohide: false,
      });
      if (objGetField(show_toast, key, true)) {
        toastInstance.show();
      }
    });
    $("li.has-mega-menu").each(function () {
      const set: number = 10; // Number of links to display in each column
      const buffer: null[] = [];
      const dropdown: any = $(".dropdown-menu", this);
      const children: any = dropdown.children();
      const cols: any = Math.ceil(children.length / set);
      const col_class: string = `col col-md-${
        cols >= 5 ? "2" : cols === 4 ? "3" : cols === 3 ? "4" : "x"
      }`;
      const container_class: string = `px-0 container container-${
        cols === 2
          ? "sm"
          : cols === 3
          ? "md"
          : cols === 4
          ? "lg"
          : cols >= 5
          ? "xl"
          : "x"
      }`;

      for (let i: number = 0; i < cols; i++) {
        buffer.push(`<div class="${col_class}">`);
        children.slice(i * set, (i + 1) * set).each(function () {
          buffer.push($(this).prop("outerHTML"));
        });
        buffer.push("</div>");
      }

      dropdown.html(
        `<div class="${container_class}"><div class="row">${buffer.join(
          "\n"
        )}</div></div>`
      );
    });
  }

  createDropdown = (main_menu, system_labels) => {
    let longest_row: number = 0;
    const submenu_chunks: null[] = [];
    let i: number = 0;
    let j: number = 0;
    let temp: object = null;
    const chunk_number: number = 12;

    const canvas: any = document.createElement("canvas");
    const ctx: any = canvas.getContext("2d");
    ctx.font = "25px Arial";

    system_labels.sort(dynamicSort("position"));
    const submenus: any = system_labels.filter(
      (submenu) =>
        submenu.original_parent === main_menu.id &&
        submenu.label_type === "sub_menu"
    );
    for (i = 0, j = submenus.length; i < j; i += chunk_number) {
      temp = submenus.slice(i, i + chunk_number);
      submenu_chunks.push(temp);
    }
    let row_width: number = 0;
    const chunks_count: any = submenu_chunks.length;
    const sub_menu: any = submenu_chunks.map((chunk, chunk_key) => {
      let longest_width: any = ctx.measureText(main_menu.label).width;
      const submenu_column: any = chunk.map((column, sub_key) => {
        const { width } = ctx.measureText(column.label);
        if (width > longest_width) {
          longest_width = width;
        }
        let { route } = column;
        let link_type: string = "hard";
        if (column.v2_route) {
          route = column.v2_route;
          link_type = "soft";
        }
        if (localStorage.member && column.member_portal_route) {
          route = column.member_portal_route;
          link_type = "soft";
        }
        return (
          <a
            className="dropdown-item"
            href="#"
            key={sub_key}
            onClick={() => pushHistory(route, this.props, link_type)}
          >
            <BootstrapIcon icon="link-45deg" /> {column.label}
          </a>
        );
      });
      row_width += longest_width;
      let column_class: string = "col";
      if (chunks_count > 1) {
        column_class = "col-md-auto";
      }
      return (
        <div className={column_class} key={chunk_key}>
          {submenu_column}
        </div>
      );
    });
    if (row_width > longest_row) {
      longest_row = row_width;
    }
    return { sub_menu, longest_row };
  };

  changeLanguage = (id, language) => {
    let users_url: any = `${API_ENDPOINT}${USERS_ENDPOINT}${id}/`;
    if (localStorage.member) {
      users_url = `${API_ENDPOINT}${MEMBERS_LOGIN_ENDPOINT}${id}/?member=${id}&token=${localStorage.auth_token}`;
    }
    postAPIRequest(
      users_url,
      () => {
        window.location.reload(true);
      },
      () => {},
      {
        language,
      },
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.token}`,
      },
      "PATCH"
    );
  };

  render() {
    const {
      organization_data,
      system_labels_data,
      current_user_data,
      loan_products_data,
      savings_products_data,
    } = this.props;
    const system_labels: any = system_labels_data.items;
    const organization: any = organization_data.items;
    const user: any = current_user_data.items;
    const loan_products: any = loan_products_data.items;
    const savings_products: any = savings_products_data.items;
    const { pathname } = this.props.history.location;
    const products_paths: any = [
      CONFIGURE_SAVINGS_PRODUCT_PATH,
      CONFIGURE_LOAN_PRODUCT_PATH,
    ];

    if (
      organization_data.isFetching ||
      system_labels.isFetching ||
      current_user_data.isFetching ||
      loan_products_data.isFetching ||
      savings_products_data.isFetching
    ) {
      return <PageLoadingIndicator />;
    }
    if (
      (user.username || "").split("@")[0] === organization.name &&
      (organization.system_features || []).length === 0
    ) {
      this.props.history.push(SYSTEM_SETUP_PATH);
    } else if (
      (organization.system_features || []).includes("loan_management") &&
      loan_products.length === 0 &&
      !products_paths.includes(pathname) &&
      !loan_products_data.didInvalidate
    ) {
      this.props.history.push(CONFIGURE_LOAN_PRODUCT_PATH);
    } else if (
      (organization.system_features || []).includes("savings_shares") &&
      savings_products.length === 0 &&
      !products_paths.includes(pathname) &&
      !savings_products_data.didInvalidate
    ) {
      this.props.history.push(CONFIGURE_SAVINGS_PRODUCT_PATH);
    }

    const main_menus: any = system_labels.filter(
      (system_label) => system_label.label_type === "main_menu"
    );
    const main_menus_count: any = main_menus.length;
    const dropdowns: any = main_menus.map((main_menu, key) => {
      let menu_direction_class: string = "dropdown-menu-left";
      if (key > main_menus_count / 2) {
        menu_direction_class = "dropdown-menu-right";
      }
      const dropdown_id: any = generateID(10);
      const { sub_menu, longest_row } = this.createDropdown(
        main_menu,
        system_labels
      );
      return (
        <li className="nav-item dropdown" key={main_menu.key}>
          <a
            className="nav-link dropdown-toggle text-dark menu-dropdown-toggle"
            data-display="static"
            href="#"
            id={dropdown_id}
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div className="d-flex align-items-center">
              <BootstrapIcon icon={main_menu.v2_label_icon} />
              <span className="ml-1">{main_menu.label}</span>
            </div>
          </a>
          <div
            className={`dropdown-menu bg-brown ${menu_direction_class}`}
            aria-labelledby={dropdown_id}
          >
            <div className="row" style={{ width: longest_row }}>
              {sub_menu}
            </div>
          </div>
        </li>
      );
    });
    let admin_menu: any = <div />;
    if ([true, "True"].includes(user.is_admin)) {
      admin_menu = (
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle text-white"
            href="#"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            ref={(c) => (this.admin_menu = c)}
            aria-expanded="false"
          >
            {label("Admin", this.props)}
          </a>
          <div
            className="dropdown-menu dropdown-menu-right bg-brown"
            aria-labelledby="admin-menu"
          >
            <a
              className="dropdown-item"
              href="#"
              onClick={() => pushHistory(VIEW_LOAN_PRODUCTS_PATH, this.props)}
            >
              <FontAwesomeIcon icon={faBriefcase} />
              <span className="ml-1">{label("Products", this.props)}</span>
            </a>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => pushHistory(VIEW_USERS_PATH, this.props)}
            >
              <FontAwesomeIcon icon={faUser} />
              <span className="ml-1">
                {label("User management", this.props)}
              </span>
            </a>
            <a
              className="dropdown-item"
              href="#"
              onClick={() =>
                pushHistory(
                  "/registration/organization_details",
                  this.props,
                  "hard"
                )
              }
            >
              <FontAwesomeIcon icon={faUser} />
              <span className="ml-1">
                {label("Organization details", this.props)}
              </span>
            </a>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => pushHistory(ACTIVITY_LOG_PATH, this.props)}
            >
              <FontAwesomeIcon icon={faUser} />
              <span className="ml-1">{label("Audit report", this.props)}</span>
            </a>
            <a
              className="dropdown-item"
              href="#"
              onClick={() =>
                pushHistory(SMS_NOTIFICATION_SETTINGS_PATH, this.props)
              }
            >
              <FontAwesomeIcon icon={faCog} />
              <span className="ml-1">{label("Settings", this.props)}</span>
            </a>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => pushHistory(SYSTEM_SETUP_PATH, this.props)}
            >
              <FontAwesomeIcon icon={faCog} />
              <span className="ml-1">{label("System Setup", this.props)}</span>
            </a>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => pushHistory(BILLING_INVOICES_PATH, this.props)}
            >
              <FontAwesomeIcon icon={faCreditCard} />
              <span className="ml-1">
                {label("System Subscription", this.props)}
              </span>
            </a>
            <div className="dropdown-divider" />
            <a className="dropdown-item" href="#">
              <FontAwesomeIcon icon={faInfoCircle} />
              <span className="ml-1">
                {label("Client no", this.props)}: {organization.client_no}
              </span>
            </a>
          </div>
        </li>
      );
    }
    const language_menu: any = (
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle text-white"
          href="#"
          role="button"
          id="language-menu"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {user.language_name}
        </a>
        <div
          className="dropdown-menu dropdown-menu-right bg-brown"
          aria-labelledby="language-menu"
        >
          <a
            className="dropdown-item"
            href="#"
            onClick={() => this.changeLanguage(user.id, "en")}
          >
            <span className="ml-1">English</span>
          </a>

          <a
            className="dropdown-item"
            href="#"
            onClick={() => this.changeLanguage(user.id, "fr")}
          >
            <span className="ml-1">Français</span>
          </a>

          <a
            className="dropdown-item"
            href="#"
            onClick={() => this.changeLanguage(user.id, "es")}
          >
            <span className="ml-1">Español</span>
          </a>
        </div>
      </li>
    );
    let invoice_payment: any = <div />;
    let notifications_count: number = 0;
    const notification_toasts: null[] = [];
    const subscription_invoice_due_date: any =
      organization.subscription_invoice_due_date ||
      moment().format("YYYY-MM-DD");
    if (organization.subscription_payment_url) {
      let dismissible: boolean = true;
      let modal_show: any = this.state.show_invoice_modal;
      notifications_count += 1;
      if (moment() > moment(subscription_invoice_due_date).add(24, "hours")) {
        dismissible = false;
        modal_show = true;
      }
      const pay_invoice_button: any = (
        <button
          type="button"
          className="btn btn-block btn-outline-primary"
          onClick={this.showInvoiceModal}
        >
          <BootstrapIcon icon="receipt" /> Pay now
        </button>
      );
      const invoice_notification_toast: any = {
        toast_title: "System subscription",
        toast_time: moment(subscription_invoice_due_date).format("DD-MMM-YYYY"),
        toast_content: pay_invoice_button,
      };
      notification_toasts.push(invoice_notification_toast);
      invoice_payment = (
        <ModalHOC
          modal_size="modal-xl"
          dismissible={dismissible}
          modal_show={modal_show}
          modalCloseCallback={this.hideInvoiceModal}
          body_classes="vh-80"
          modal_only
          data_target="subscription-payment"
          button_classes="btn btn-outline-primary"
          modal_title="Subscription payment"
        >
          <iframe
            src={`${ABSOLUTE_API_ENDPOINT}${organization.subscription_payment_url}`}
            name="subscription_payment"
            height="100%"
            width="100%"
            title="Subscription payment"
          />
        </ModalHOC>
      );
    }
    let profile_link: string = "/profile/";
    let profile_link_type: string = "hard";
    if (localStorage.member) {
      profile_link = "/member/profile/";
      profile_link_type = "soft";
    }

    return (
      <div>
        {invoice_payment}
        <nav className="navbar navbar-expand-lg navbar-dark bg-charcoal py-0 navbar-expand-lg py-md-0">
          <a
            className="navbar-brand"
            href="#"
            onClick={() => pushHistory(INDEX_PATH, this.props)}
          >
            <img src={Logo} width="30" height="30" alt="" />
            <span className="ml-1">{organization.name}</span>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              {admin_menu}
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle text-white"
                  href="#"
                  id="user-menu"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {user.username || user.first_name}
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right bg-brown"
                  aria-labelledby="user-menu"
                >
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() =>
                      pushHistory(profile_link, this.props, profile_link_type)
                    }
                  >
                    <FontAwesomeIcon icon={faUser} />
                    <span className="ml-1">{label("Profile", this.props)}</span>
                  </a>
                  <a className="dropdown-item" href="#">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <span className="ml-1">
                      {label("Client no", this.props)}: {organization.client_no}
                    </span>
                  </a>
                  <a className="dropdown-item" href="#">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <span className="ml-1">
                      {label("SMS bal", this.props)}:{" "}
                      {thousandsFormat(organization.sms_units)}
                    </span>
                  </a>
                  <div className="dropdown-divider" />
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={(e) => this.handleLogout(e)}
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} />
                    <span className="ml-1">{label("Logout", this.props)}</span>
                  </a>
                </div>
              </li>
              {language_menu}
              <a
                className="nav-link dropdown-toggle menu-notification"
                href="#"
                id="menu-notification"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <FontAwesomeIcon
                  icon={faBell}
                  className="badge-wrapper text-white"
                />
                <span className="badge rounded-pill bg-danger notification-badge">
                  {notifications_count}
                </span>
              </a>
            </ul>
          </div>
        </nav>
        <nav className="navbar navbar-light bg-brown py-0 navbar-expand-lg py-md-0">
          <ul className="navbar-nav flex-wrap">{dropdowns}</ul>
        </nav>
        <div className="container-fluid mt-3">
          <ToastNotification toast_items={notification_toasts} />
          {this.props.children}
        </div>
      </div>
    );
  }
}

Menu.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  system_labels_data: PropTypes.instanceOf(Object).isRequired,
  organization_data: PropTypes.instanceOf(Object).isRequired,
  current_user_data: PropTypes.instanceOf(Object).isRequired,
  dataByUrl: PropTypes.instanceOf(Object).isRequired,
  loan_products_data: PropTypes.instanceOf(Object).isRequired,
  savings_products_data: PropTypes.instanceOf(Object).isRequired,
  system_labels_key_dict_data: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );
  const organization_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "organization_url"
  );
  const current_user_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "current_user_url"
  );
  const loan_products_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "loan_products_ids_url"
  );
  const savings_products_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "savings_products_ids_url"
  );

  return {
    sessionVariables,
    system_labels_data,
    organization_data,
    current_user_data,
    dataByUrl,
    loan_products_data,
    savings_products_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(Menu));
