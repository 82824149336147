import React, { Component } from "react";
import {
  getActiveNav,
  getUrlData,
  label,
  toggleActiveNav,
} from "../utils/componentActions";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Pills extends Component {
  constructor(props) {
    super(props);
    const { pathname } = this.props.history.location;
    this.state = {
      active_nav: pathname,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const previous_param_id: any = prevProps.param_id;
    const { param_id } = this.props;
    if (previous_param_id !== param_id) {
      const { pathname } = this.props.history.location;
      this.setState({
        active_nav: pathname,
      });
    }
  }

  render() {
    const { props } = this;
    const { param_id } = props;
    const pills: any = this.props.pills.map((pill, key) => {
      let { path } = pill;
      if (this.props.append_id && param_id) {
        path += param_id;
      }
      return (
        <li className="nav-item" key={key}>
          <a
            className={`nav-link ${getActiveNav(pill.path, this)}`}
            href="#"
            onClick={(e) => toggleActiveNav(e, path, this)}
          >
            {label(pill.label, props)}
          </a>
        </li>
      );
    });
    return <ul className="nav nav-pills mb-3">{pills}</ul>;
  }
}

Pills.propTypes = {
  param_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pills: PropTypes.instanceOf(Array),
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  system_labels_data: PropTypes.instanceOf(Object).isRequired,
  system_labels_dict_url: PropTypes.instanceOf(Object),
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    dataByUrl,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(Pills));
