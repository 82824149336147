import React, { Component } from "react";
import { fetchUrlData, getUrlData } from "../../utils/componentActions";

import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import PropTypes from "prop-types";
import { RECEIPT_TEMPLATES_ENDPOINT } from "../../constants/Constants";
import { RECEIPT_TEMPLATES_PATH } from "../../constants/ClientPaths";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ReceiptTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {},
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
    };
  }

  componentDidMount() {
    fetchUrlData(
      "receipt_templates_url",
      `${RECEIPT_TEMPLATES_ENDPOINT}i/`,
      this.props
    );
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const { receipt_templates_data } = props;
    const { settings } = this.state;
    const form_classes: string = "row g-3";
    const form_items: any = [
      <FormFields
        key={1}
        field_type="textarea"
        field_label="Client payment receipt template"
        props={props}
        field_name="receipt_template"
        wrapper_div_classes="col-4"
        data_object={settings}
        textarea_rows={10}
      />,
      <FormFields
        key={2}
        field_type="textarea"
        field_label="Income receipt template"
        props={props}
        field_name="income_receipt_template"
        wrapper_div_classes="col-4"
        data_object={settings}
        textarea_rows={10}
      />,
      <FormFields
        key={3}
        field_type="textarea"
        field_label="Payment voucher template"
        props={props}
        field_name="payment_voucher_template"
        wrapper_div_classes="col-4"
        data_object={settings}
        textarea_rows={10}
      />,
      <FormFields
        key={4}
        field_type="textarea"
        field_label="Billing invoice extra header"
        props={props}
        field_name="billing_invoice_extra_header"
        wrapper_div_classes="col-4"
        data_object={settings}
        textarea_rows={10}
      />,
    ];
    return (
      <CreateEditForm
        loading={receipt_templates_data.isFetching}
        form_items={form_items}
        form_classes={form_classes}
        action_object={receipt_templates_data.items}
        setState={this.updateState}
        action_object_name="settings"
        state={this.state}
        action_object_endpoint={RECEIPT_TEMPLATES_ENDPOINT}
        fetch_data_urls={[
          {
            url_name: "receipt_templates_url",
            url: `${RECEIPT_TEMPLATES_ENDPOINT}i/`,
          },
        ]}
        base_path={RECEIPT_TEMPLATES_PATH}
        obj_id_field="id"
        object_display_name="receipt templates"
        push_id_to_path
      />
    );
  }
}

ReceiptTemplates.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  receipt_templates_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const receipt_templates_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "receipt_templates_url"
  );

  return {
    sessionVariables,
    receipt_templates_data,
  };
}

export default connect(mapStateToProps)(withRouter(ReceiptTemplates));
