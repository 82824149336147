import React, { Component } from "react";

import { PAYMENT_MODES_ENDPOINT } from "../../constants/Constants";
import PropTypes from "prop-types";
import { REGISTER_PAYMENT_MODES_PATH } from "../../constants/ClientPaths";
import { VIEW_PAYMENT_MODES_COLUMNS } from "../../components/DataTableColumns";
import ViewDataTable from "../../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ViewPaymentModes extends Component {
  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  render() {
    return (
      <ViewDataTable
        handleSetTableNode={this.handleSetTableNode}
        allow_refresh
        columns={VIEW_PAYMENT_MODES_COLUMNS}
        data_source_url={PAYMENT_MODES_ENDPOINT}
        editable
        creatable
        edit_url={REGISTER_PAYMENT_MODES_PATH}
        data_source_url_name="paginated_payment_modes_url"
      />
    );
  }
}

ViewPaymentModes.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ViewPaymentModes));
