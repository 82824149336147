import {
  APPLIED_LOANS_ENDPOINT,
  MEMBER_ACCOUNTS_ENDPOINT,
  OUTBOX_ENDPOINT,
} from "../constants/Constants";
import React, { Component } from "react";
import { fetchUrlData, getUrlData } from "../utils/componentActions";

import CreateEditForm from "../components/CreateEditForm";
import FormFields from "../components/FormFields";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import merge from "lodash/merge";
import { withRouter } from "react-router";

class EmailStatement extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      loading: true,
      email_statement: {},
      statement_binary: null,
    };
    this.state = merge({}, this.initialState);
  }

  componentDidMount() {
    this.props.onRef(this);
    if (this.props.loan_id) {
      const applied_loan_url: string = `${APPLIED_LOANS_ENDPOINT}${this.props.loan_id}/`;
      fetchUrlData("applied_loan_url", applied_loan_url, this.props);
    }
    if (this.props.account_id) {
      const member_account_url: string = `${MEMBER_ACCOUNTS_ENDPOINT}${this.props.account_id}/`;
      fetchUrlData("member_account_url", member_account_url, this.props);
    }
  }

  handleGeneratePdf = () => {
    const export_buttons: any = document.getElementsByClassName(
      "tableexport-caption"
    );
    export_buttons[0].setAttribute("data-html2canvas-ignore", true);
    const width: any = document.querySelector(".table-export").offsetWidth;
    const height: any = document.querySelector(".table-export").offsetHeight;
    html2canvas(document.querySelector(".table-export"), {
      width,
      height,
    }).then((canvas) => {
      document.body.appendChild(canvas);
      const img: any = canvas.toDataURL("image/jpeg");
      let pdf: any = new jsPDF("p", "px", [canvas.height, canvas.width]);
      pdf.addImage(img, "JPEG", 5, 5, canvas.height, canvas.width);
      if (canvas.width > canvas.height) {
        pdf = new jsPDF("l", "px", [canvas.width, canvas.height]);
        pdf.addImage(img, "JPEG", 5, 5, canvas.width, canvas.height);
      }
      const binary: any = pdf.output();
      const statement_binary: any = window.btoa(binary);
      this.setState({
        statement_binary,
        loading: false,
      });
    });
  };

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const { applied_loan_data, member_account_data } = props;
    let action_account: any = applied_loan_data.items;
    let file_name_suffix: string = "loan account statement";
    if (this.props.account_id) {
      action_account = member_account_data.items;
      file_name_suffix = "account statement";
    }
    const form_classes: string = "row g-1";
    const { email_statement } = this.state;
    email_statement.recipients = action_account.member_personal_email;
    const extra_form_data: any = [
      { name: "attachment", value: this.state.statement_binary },
      {
        name: "filename",
        value: `${action_account.member_name} ${file_name_suffix}`,
      },
      { name: "filetype", value: file_name_suffix },
    ];
    const form_items: any = [
      <FormFields
        key={1}
        field_type="email"
        field_label="Recipient"
        props={props}
        field_name="recipients"
        wrapper_div_classes="col-12"
        data_object={email_statement}
      />,
    ];
    return (
      <CreateEditForm
        loading={this.state.loading}
        form_items={form_items}
        form_classes={form_classes}
        action_object={email_statement}
        setState={this.updateState}
        action_object_name="email_statement"
        state={this.state}
        action_object_endpoint={OUTBOX_ENDPOINT}
        payload={email_statement}
        form_id="email-statement"
        save_button_label="Send statement"
        extra_form_data={extra_form_data}
        object_display_name="email statement"
      />
    );
  }
}

EmailStatement.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  applied_loan_data: PropTypes.instanceOf(Object).isRequired,
  member_account_data: PropTypes.instanceOf(Object).isRequired,
  loan_id: PropTypes.string,
  account_id: PropTypes.string,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const applied_loan_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "applied_loan_url"
  );
  const member_account_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "member_account_url"
  );

  return {
    sessionVariables,
    applied_loan_data,
    member_account_data,
  };
}

export default connect(mapStateToProps)(withRouter(EmailStatement));
