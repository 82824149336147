import React, { Component } from "react";

import { GENERIC_GROUP_FILTER } from "../../components/DataTableFilterFields";
import { MEMBER_GROUP_ENDPOINT } from "../../constants/Constants";
import PropTypes from "prop-types";
import { REGISTER_MEMBER_GROUP_DETAILS_PATH } from "../../constants/ClientPaths";
import { VIEW_MEMBER_GROUPS_COLUMNS } from "../../components/DataTableColumns";
import ViewDataTable from "../../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class ViewMemberGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table_node: null,
    };
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  render() {
    return (
      <ViewDataTable
        columns={VIEW_MEMBER_GROUPS_COLUMNS}
        csv_export
        allow_refresh
        creatable
        editable
        edit_url={REGISTER_MEMBER_GROUP_DETAILS_PATH}
        data_source_url={MEMBER_GROUP_ENDPOINT}
        table_filters={GENERIC_GROUP_FILTER}
        handleSetTableNode={this.handleSetTableNode}
        data_source_url_name="paginated_member_groups_url"
      />
    );
  }
}

ViewMemberGroups.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ViewMemberGroups));
