import {
  MPAY_TRANSACTIONS_COLUMNS,
  MPAY_VIEW_TRANSACTION_COLUMNS,
} from "../components/DataTableColumns";
import React, { Component } from "react";

import CreateEditForm from "../components/CreateEditForm";
import FormFields from "../components/FormFields";
import { MPAY_TRANSACTIONS_ENDPOINT } from "../constants/Constants";
import { MPAY_VIEW_TRANSACTIONS_FILTERS } from "../components/DataTableFilterFields";
import ModalHOC from "../components/ModalHOC";
import PropTypes from "prop-types";
import ViewDataTable from "../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ViewMpayTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_row_obj: {},
    };
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  handleTableRowSelect = (row) => {
    this.setState({
      selected_row_obj: row,
    });
  };

  render() {
    const { selected_row_obj } = this.state;

    const form_items: any = [
      <FormFields
        key={1}
        props={this.props}
        field_type="text_input"
        field_label="Account number"
        field_name="bill_ref_number"
        data_object={selected_row_obj}
        wrapper_div_classes="col"
        required
      />,
    ];

    const data_source_url: string = `${MPAY_TRANSACTIONS_ENDPOINT}?limit=5&offset=0&ordering=-transaction_timestamp`;

    return (
      <>
        <ViewDataTable
          handleSetTableNode={this.handleSetTableNode}
          table_filters={MPAY_VIEW_TRANSACTIONS_FILTERS}
          handleTableRowSelect={this.handleTableRowSelect}
          allow_refresh
          select_mode="radio"
          clickToSelect
          columns={MPAY_TRANSACTIONS_COLUMNS}
          extra_get_params="ordering=-transaction_timestamp"
          data_source_url={MPAY_TRANSACTIONS_ENDPOINT}
          data_source_url_name="paginated_mpay_transactions"
        />
        <ModalHOC
          modal_only
          modal_title="Transaction details"
          data_target="view-transaction-details"
          modal_size="modal-lg"
        >
          <ViewDataTable
            paginated={false}
            basic_table
            csv_export={false}
            vertical_headers
            component_data_provided
            select_mode="radio"
            columns={MPAY_VIEW_TRANSACTION_COLUMNS}
            data_items={selected_row_obj}
          />
        </ModalHOC>

        <ModalHOC
          modal_only
          modal_title="Complete Payment"
          data_target="complete-payment"
          modal_size="modal-md"
        >
          <CreateEditForm
            loading={false}
            form_items={form_items}
            datatable_source_url_name="paginated_mpay_transactions"
            datatable_source_url={data_source_url}
            action_object={selected_row_obj}
            state={this.state}
            action_object_endpoint={MPAY_TRANSACTIONS_ENDPOINT}
            action_object_name="selected_row_obj"
            update_request_method="PATCH"
            action_object_id={selected_row_obj.id}
          />
        </ModalHOC>

        <ModalHOC
          modal_only
          modal_title="Check transaction status"
          data_target="check-transaction-status"
          modal_size="modal-md"
        >
          <CreateEditForm
            form_id="check-transaction-status-form"
            loading={false}
            form_items={[]}
            datatable_source_url_name="paginated_mpay_transactions"
            datatable_source_url={data_source_url}
            action_object={selected_row_obj}
            state={this.state}
            action_object_endpoint={`${MPAY_TRANSACTIONS_ENDPOINT}fetch-transaction-status/`}
            action_object_name="selected_row_obj"
            extra_form_data={[{ name: "transaction_id", value: selected_row_obj.id }]}
            save_button_label="Check status"
          />
        </ModalHOC>
      </>
    );
  }
}

ViewMpayTransactions.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ViewMpayTransactions));
