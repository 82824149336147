import {
  APPLIED_LOANS_ENDPOINT,
  REPAYMENT_SCHEDULE_ENDPOINT,
} from "../../../constants/Constants";
import React, { Component } from "react";

import CreateEditForm from "../../../components/CreateEditForm";
import FormFields from "../../../components/FormFields";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchUrlData } from "../../../utils/componentActions";
import merge from "lodash/merge";
import { withRouter } from "react-router";

class RescheduleLoan extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      reschedule_loan: {},
    };
    this.state = merge({}, this.initialState);
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  resetState = () => {
    const loan_repayment_schedule_url: string = `${REPAYMENT_SCHEDULE_ENDPOINT}?loan=${this.props.loan_id}`;
    fetchUrlData(
      "loan_repayment_schedule_url",
      loan_repayment_schedule_url,
      this.props,
      true
    );
    const state_object: any = merge({}, this.initialState);
    const { reschedule_loan } = state_object;
    reschedule_loan.reset_fields = true;
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const form_classes: string = "row g-1";
    const { reschedule_loan } = this.state;
    const applied_loan_url: string = `${APPLIED_LOANS_ENDPOINT}${props.loan_id}/`;
    const extra_form_data: any = [{ name: "reschedule_loan", value: true }];
    const form_items: any = [
      <FormFields
        key={1}
        field_type="number_input"
        field_label="Interest rate"
        props={props}
        step="0.001"
        field_name="interest"
        wrapper_div_classes="col-12"
        data_object={reschedule_loan}
        required
      />,
      <FormFields
        key={2}
        field_type="number_input"
        field_label="No of repayments"
        props={props}
        field_name="no_of_repayments"
        wrapper_div_classes="col-12"
        data_object={reschedule_loan}
        required
      />,
      <FormFields
        key={3}
        field_type="date"
        field_label="Date of next repayment"
        props={props}
        field_name="date_of_next_repayment"
        wrapper_div_classes="col-12"
        data_object={reschedule_loan}
        required
      />,
    ];
    return (
      <CreateEditForm
        loading={false}
        form_items={form_items}
        form_classes={form_classes}
        action_object={reschedule_loan}
        setState={this.updateState}
        action_object_name="reschedule_loan"
        state={this.state}
        action_object_endpoint={applied_loan_url}
        payload={reschedule_loan}
        resetState={this.resetState}
        request_method="PATCH"
        form_id="reschedule-loan"
        datatable_source_url={applied_loan_url}
        datatable_source_url_name="applied_loan_url"
        extra_form_data={extra_form_data}
        object_display_name="schedule"
      />
    );
  }
}

RescheduleLoan.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(RescheduleLoan));
