import {
  MEMBER_ACCOUNTS_ENDPOINT,
  REPAYMENT_SCHEDULE_ENDPOINT,
} from "../../../constants/Constants";
import React, { Component } from "react";

import CreateEditForm from "../../../components/CreateEditForm";
import FormFields from "../../../components/FormFields";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchUrlData } from "../../../utils/componentActions";
import merge from "lodash/merge";
import { withRouter } from "react-router";

class ReverseAccount extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      reverse_account: {},
    };
    this.state = merge({}, this.initialState);
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  resetState = () => {
    const loan_repayment_schedule_url: string = `${REPAYMENT_SCHEDULE_ENDPOINT}?loan=${this.props.loan_id}`;
    fetchUrlData(
      "loan_repayment_schedule_url",
      loan_repayment_schedule_url,
      this.props,
      true
    );
    const state_object: any = merge({}, this.initialState);
    const { reverse_account } = state_object;
    reverse_account.reset_fields = true;
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const member_account_url: string = `${MEMBER_ACCOUNTS_ENDPOINT}${props.account_id}/`;
    const { reverse_account } = this.state;
    const form_classes: string = "row g-1";
    const extra_form_data: any = [{ name: "reversal", value: true }];
    const form_items: any = [
      <FormFields
        key={1}
        field_type="textarea"
        field_label="Reverse reason"
        props={props}
        field_name="reversal_reason"
        wrapper_div_classes="col-12"
        data_object={reverse_account}
      />,
    ];
    return (
      <CreateEditForm
        loading={false}
        form_items={form_items}
        form_classes={form_classes}
        action_object={reverse_account}
        setState={this.updateState}
        action_object_name="reverse_account"
        state={this.state}
        action_object_endpoint={member_account_url}
        payload={reverse_account}
        resetState={this.resetState}
        request_method="PATCH"
        form_id="reverse-account"
        datatable_source_url={member_account_url}
        datatable_source_url_name="member_account_url"
        extra_form_data={extra_form_data}
        object_display_name="account"
      />
    );
  }
}

ReverseAccount.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  account_id: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ReverseAccount));
