import React, { Component } from "react";

import { REJECTED_LOANS_COLUMNS } from "../../components/DataTableColumns";
import ViewAppliedLoans from "./ViewAppliedLoans";
import { withRouter } from "react-router-dom";

class ViewRejectedLoans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 5,
    };
  }

  render() {
    return <ViewAppliedLoans status={10} columns={REJECTED_LOANS_COLUMNS} />;
  }
}

export default withRouter(ViewRejectedLoans);
