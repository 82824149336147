module.exports = {
  postAPIRequest(
    url,
    successCallback,
    errorCallback,
    payload,
    headers,
    method = "POST"
  ) {
    fetch(url, {
      // credentials: 'include',
      method,
      body: JSON.stringify(payload),
      headers,
    })
      .then((response) => {
        if (response.ok) {
          return response;
        }
        throw response;
      })
      .then((response) => response.json())
      .then((json) => {
        successCallback(json);
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development" && error.status >= 500) {
          errorCallback(error.statusText);
        } else {
          error.json().then((body) => {
            errorCallback(body);
          });
        }
      });
  },

  postFormAPIRequest(
    url,
    successCallback,
    errorCallback,
    payload,
    headers,
    method = "POST"
  ) {
    fetch(url, {
      // credentials: 'include',
      method,
      body: payload,
      headers,
    })
      .then((response) => {
        if (response.ok) {
          return response;
        }
        throw response;
      })
      .then((response) => response.json())
      .then((json) => {
        successCallback(json);
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development" && error.status >= 500) {
          errorCallback(error.statusText);
        } else {
          error.json().then((body) => {
            errorCallback(body);
          });
        }
      });
  },

  getAPIRequest(url, successCallback, errorCallback, headers) {
    fetch(url, {
      headers,
    })
      .then((response) => {
        if (response.ok) {
          return response;
        }
        throw response;
      })
      .then((response) => response.json())
      .then((json) => {
        successCallback(json);
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development" && error.status >= 500) {
          errorCallback(error.statusText);
        } else {
          error.json().then((body) => {
            errorCallback(body);
          });
        }
      });
  },
};
