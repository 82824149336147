import React, { Component } from "react";
import {
  VIEW_ACTIVE_LOANS_PATH,
  VIEW_COMPLETED_LOANS_PATH,
  VIEW_PENDING_LOANS_PATH,
  VIEW_PROCESSED_LOANS_PATH,
  VIEW_REJECTED_LOANS_PATH,
  VIEW_REVERSED_LOANS_PATH,
  VIEW_WRITTEN_OFF_LOANS_PATH,
} from "../../constants/ClientPaths";
import {
  getActiveNav,
  getUrlData,
  label,
  toggleActiveNav,
} from "../../utils/componentActions";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class ViewLoans extends Component {
  constructor(props) {
    super(props);
    const { pathname } = this.props.history.location;
    this.state = {
      active_nav: pathname,
    };
  }

  render() {
    const { props } = this;
    return (
      <div className="card">
        <div className="card-header bg-green text-center p-2">
          <span className="font-weight-bold text-white">
            {label("View Loans", props)}
          </span>
        </div>
        <div className="card-body">
          <ul className="nav nav-pills">
            <li className="nav-item">
              <a
                className={`nav-link ${getActiveNav(
                  VIEW_ACTIVE_LOANS_PATH,
                  this
                )}`}
                href="#"
                onClick={(e) =>
                  toggleActiveNav(e, VIEW_ACTIVE_LOANS_PATH, this)
                }
              >
                {label("Active loans", props)}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${getActiveNav(
                  VIEW_COMPLETED_LOANS_PATH,
                  this
                )}`}
                href="#"
                onClick={(e) =>
                  toggleActiveNav(e, VIEW_COMPLETED_LOANS_PATH, this)
                }
              >
                {label("Completed loans", props)}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${getActiveNav(
                  VIEW_REJECTED_LOANS_PATH,
                  this
                )}`}
                href="#"
                onClick={(e) =>
                  toggleActiveNav(e, VIEW_REJECTED_LOANS_PATH, this)
                }
              >
                {label("Rejected loans", props)}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${getActiveNav(
                  VIEW_REVERSED_LOANS_PATH,
                  this
                )}`}
                href="#"
                onClick={(e) =>
                  toggleActiveNav(e, VIEW_REVERSED_LOANS_PATH, this)
                }
              >
                {label("Reversed loans", props)}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${getActiveNav(
                  VIEW_PENDING_LOANS_PATH,
                  this
                )}`}
                href="#"
                onClick={(e) =>
                  toggleActiveNav(e, VIEW_PENDING_LOANS_PATH, this)
                }
              >
                {label("Pending loans", props)}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${getActiveNav(
                  VIEW_WRITTEN_OFF_LOANS_PATH,
                  this
                )}`}
                href="#"
                onClick={(e) =>
                  toggleActiveNav(e, VIEW_WRITTEN_OFF_LOANS_PATH, this)
                }
              >
                {label("Written off loans", props)}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${getActiveNav(
                  VIEW_PROCESSED_LOANS_PATH,
                  this
                )}`}
                href="#"
                onClick={(e) =>
                  toggleActiveNav(e, VIEW_PROCESSED_LOANS_PATH, this)
                }
              >
                {label("Processed loans", props)}
              </a>
            </li>
          </ul>
          <div className="col mt-2">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(ViewLoans));
