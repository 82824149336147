import React, { Component } from "react";

import DataTableActionForm from "../../components/DataTableActionForm";
import { EXPENSES_ENDPOINT } from "../../constants/Constants";
import FormFields from "../../components/FormFields";
import PropTypes from "prop-types";
import { RECEIVE_PAYMENTS_PATH } from "../../constants/ClientPaths";
import { VIEW_RECEIVED_PAYMENTS_COLUMNS } from "../../components/DataTableColumns";
import ViewDataTable from "../../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ApproveExpenses extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  render() {
    const { props } = this;
    const reject_form_items: any = [
      <FormFields
        key={1}
        field_type="textarea"
        field_label="Rejection reason"
        props={props}
        field_name="rejection_reason"
        wrapper_div_classes="col-4 pb-3"
        data_object={{}}
        required
        autofocus
      />,
    ];
    const actions: any = [
      {
        name: "approve",
        btn_classes: "btn btn-green text-white",
        noun: "approval",
        icon: "file-earmark-check",
        target: "expense",
        plural: "expenses",
        form_items: [],
        extra_form_data: [
          { name: "status", value: 9 },
          { name: "bulk", value: true },
        ],
      },
      {
        name: "reject",
        btn_classes: "btn btn-danger ml-2",
        noun: "rejection",
        icon: "x",
        target: "expense",
        plural: "expenses",
        form_items: reject_form_items,
        multiple_form_items: reject_form_items,
        extra_form_data: [
          { name: "status", value: 10 },
          { name: "bulk", value: true },
        ],
      },
    ];
    const paginated_pending_payments_url: string = `${EXPENSES_ENDPOINT}?initial=1&status=6&limit=5&offset=0`;
    return (
      <DataTableActionForm
        actions={actions}
        table_node={this.state.table_node}
        ids_post_field="payments"
        datatable_action_endpoint={EXPENSES_ENDPOINT}
        request_method="POST"
        datatable_source_url={paginated_pending_payments_url}
        datatable_source_url_name="paginated_pending_expenses"
      >
        <ViewDataTable
          handleSetTableNode={this.handleSetTableNode}
          columns={VIEW_RECEIVED_PAYMENTS_COLUMNS}
          data_source_url={EXPENSES_ENDPOINT}
          select_mode="checkbox"
          edit_url={RECEIVE_PAYMENTS_PATH}
          extra_get_params="initial=1&status=6"
          data_source_url_name="paginated_pending_expenses"
        />
      </DataTableActionForm>
    );
  }
}

ApproveExpenses.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ApproveExpenses));
