import {
  APPLIED_LOANS_ENDPOINT,
  GROUPS_ENDPOINT,
  LOAN_PRODUCTS_ENDPOINT,
  MEMBERS_ENDPOINT,
} from "../../constants/Constants";
import React, { Component } from "react";
import {
  fetchUrlData,
  getFieldFromListObj,
  getSelect2Options,
  getUrlData,
} from "../../utils/componentActions";

import { BULK_LOAN_APPLICATION_COLUMNS } from "../../components/DataTableColumns";
import CreateEditForm from "../../components/CreateEditForm";
import DataTable from "../../components/DataTable";
import FormFields from "../../components/FormFields";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class BulkLoanApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applied_loan: {},
      page: 1,
      sizePerPage: 5,
      table_node: null,
      group: "",
      selected_group: null,
      charges: null,
    };
  }

  componentDidMount() {
    fetchUrlData("groups_url", GROUPS_ENDPOINT, this.props);
    fetchUrlData("loan_products_url", LOAN_PRODUCTS_ENDPOINT, this.props);
    const paginated_active_members_url: string = `${MEMBERS_ENDPOINT}?status=1&limit=5&offset=0`;
    fetchUrlData(
      "paginated_active_members_url",
      paginated_active_members_url,
      this.props
    );
  }

  UNSAFE_componentWillUpdate(nextProps, nextState, _) {
    const previous_group: any = this.state.group;
    const { group } = nextState;
    if (previous_group !== group) {
      const paginated_active_members_url: string = `${MEMBERS_ENDPOINT}?status=1&group=${group}&limit=5&offset=0`;
      fetchUrlData(
        "paginated_active_members_url",
        paginated_active_members_url,
        this.props
      );
    }
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  handleTableChange = (type, change_obj) => {
    const { sizePerPage } = change_obj;
    const { page } = change_obj;
    const search_text: any = change_obj.searchText;
    const offset: any = (page - 1) * sizePerPage;
    const { group } = this.state;
    const get_params: string = `status=1&group=${group}&limit=${sizePerPage}&offset=${offset}&search=${search_text}`;
    const paginated_active_members_url: string = `${MEMBERS_ENDPOINT}?${get_params}`;
    fetchUrlData(
      "paginated_active_members_url",
      paginated_active_members_url,
      this.props
    );
    this.setState(() => ({
      page,
      sizePerPage,
    }));
  };

  handleFieldChange(selected, field_name) {
    selected = selected || { value: "" };
    this.setState({
      [field_name]: selected.value,
      [`selected_${field_name}`]: selected,
    });
  }

  render() {
    const { props } = this;
    const { loan_products_data, paginated_active_members_data, groups_data } =
      props;
    const form_classes: string = "row g-3";
    const { applied_loan } = this.state;
    const loan_products: any = loan_products_data.items;
    const groups: any = groups_data.items;
    const paginated_active_members: any = paginated_active_members_data.items;
    const { selected_product } = this.state;
    const groups_options: any = getSelect2Options(groups, "id", "group_name");
    const loan_product_options: any = getSelect2Options(
      loan_products,
      "id",
      "product_name"
    );
    const extra_form_data: any = [{ name: "bulk", value: true }];
    let charges: null[] = [];
    if (selected_product) {
      charges = getFieldFromListObj(
        loan_products,
        "charges_list",
        "id",
        selected_product.value
      );
      if (this.state.charges) {
        applied_loan.charges = this.state.charges;
      } else {
        applied_loan.charges = getFieldFromListObj(
          loan_products,
          "charges",
          "id",
          selected_product.value
        );
      }
      extra_form_data.push({
        name: "charges",
        value: applied_loan.charges,
        type: "array",
      });
    }

    const charges_options_value_field: string =
      "[name] - [charge_time_type] - [charge_calculation] - Amount [amount]";
    const charges_options_field_map: string[] = [
      "name",
      "charge_time_type",
      "charge_calculation",
      "amount",
    ];
    const charges_options: any = getSelect2Options(
      charges,
      "id",
      charges_options_value_field,
      charges_options_field_map
    );
    const form_items: any = [
      <FormFields
        key={1}
        field_type="select2"
        field_label="Loan product"
        props={props}
        field_name="loan_type"
        wrapper_div_classes="col-4"
        onChange={(selected) =>
          this.updateState({ selected_product: selected })
        }
        data_object={applied_loan}
        required
        select2_options={loan_product_options}
      />,
      <FormFields
        key={2}
        field_type="select2_multi"
        field_label="Charges"
        props={props}
        field_name="charges"
        wrapper_div_classes="col-4"
        onChange={(selected, selected_ids) =>
          this.setState({
            charges: selected_ids,
          })
        }
        data_object={applied_loan}
        select2_options={charges_options}
      />,
      <FormFields
        key={3}
        field_type="number_input"
        field_label="No of repayments"
        props={props}
        field_name="no_of_repayments"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
        required
      />,
      <FormFields
        key={4}
        field_type="number_input"
        field_label="Interest rate"
        props={props}
        field_name="interest_rate"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
      />,
      <FormFields
        key={5}
        field_type="date"
        field_label="Date of loan application"
        props={props}
        field_name="date_of_loan_application"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
        required
      />,
      <FormFields
        key={6}
        field_type="date"
        field_label="Date of first repayment"
        props={props}
        field_name="date_of_first_repayment"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
        required
      />,
      <FormFields
        key={7}
        field_type="select"
        field_label="Is a top-up?"
        props={props}
        field_name="loan_topup"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={8}
        field_type="select"
        field_label="Create standing order?"
        props={props}
        field_name="create_repayment_standing_order"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      <FormFields
        key={9}
        field_type="select2"
        field_label="Group"
        props={props}
        field_name="group"
        wrapper_div_classes="col-4"
        onChange={(selected) => this.handleFieldChange(selected, "group")}
        isClearable
        data_object={applied_loan}
        select2_options={groups_options}
      />,
      <FormFields
        key={10}
        field_type="file"
        field_label="Loans CSV"
        props={props}
        field_name="loans_csv"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
      />,
      <DataTable
        handleTableChange={this.handleTableChange}
        key={11}
        component_data={paginated_active_members}
        data_columns={BULK_LOAN_APPLICATION_COLUMNS}
        page={this.state.page}
        handleSetTableNode={this.handleSetTableNode}
        loading={paginated_active_members.isFetching}
      />,
    ];
    return (
      <CreateEditForm
        loading={loan_products_data.isFetching}
        form_items={form_items}
        form_classes={form_classes}
        action_object={applied_loan}
        setState={this.updateState}
        action_object_name="applied_loan"
        state={this.state}
        action_object_endpoint={APPLIED_LOANS_ENDPOINT}
        object_display_name="applied loan"
        payload={applied_loan}
        content_type="multipart/form-data; boundary=----"
        post_form
        extra_form_data={extra_form_data}
      />
    );
  }
}

BulkLoanApplication.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  loan_products_data: PropTypes.instanceOf(Object).isRequired,
  paginated_active_members_data: PropTypes.instanceOf(Object).isRequired,
  groups_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const loan_products_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "loan_products_url"
  );
  const paginated_active_members_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "paginated_active_members_url"
  );
  const groups_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "groups_url"
  );

  return {
    sessionVariables,
    loan_products_data,
    paginated_active_members_data,
    groups_data,
  };
}

export default connect(mapStateToProps)(withRouter(BulkLoanApplication));
