import {
  GROUPS_ENDPOINT,
  MEMBER_GROUP_ENDPOINT,
} from "../../constants/Constants";
import React, { Component } from "react";
import {
  fetchUrlData,
  getSelectedOptions,
  getUrlData,
  label,
} from "../../utils/componentActions";

import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import MembersSelectDropdown from "../../components/MembersSelectDropdown";
import PropTypes from "prop-types";
import Select from "react-select-oss";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class RegisterGroupDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      member_group: {},
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
    };
  }

  componentDidMount() {
    fetchUrlData("groups_url", GROUPS_ENDPOINT, this.props);
    const member_group_id: any = this.props.match.params.id;
    if (member_group_id) {
      const member_group_url: string = `${MEMBER_GROUP_ENDPOINT}${member_group_id}/`;
      fetchUrlData("member_group_url", member_group_url, this.props);
    }
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const { groups_data, member_group_data } = props;
    const groups: any = groups_data.items;
    const group_options: any = groups.map((group) => ({
      value: group.id,
      label: group.group_name,
    }));
    const { member_group } = this.state;
    let selected_member;
    if (member_group.member) {
      selected_member = {
        value: member_group.member,
        label: member_group.full_name,
      };
    }
    const form_classes: string = "row g-3";
    let status_field: string = "";
    if (this.props.match.params.id) {
      status_field = (
        <FormFields
          key={3}
          field_type="select"
          field_label="Status"
          props={props}
          field_name="status"
          wrapper_div_classes="col-6"
          data_object={member_group}
        >
          <option value={1}>Active</option>
          <option value={13}>Deleted</option>
        </FormFields>
      );
    }
    const form_items: any = [
      <div className="col-6" key={1}>
        <label className="form-label required">{label("Member", props)}</label>
        <MembersSelectDropdown
          field_name="member"
          defaultValue={selected_member}
        />
      </div>,
      <div className="col-6" key={2}>
        <label className="form-label required">{label("Group", props)}</label>
        <Select
          options={group_options}
          name="group_name"
          defaultValue={getSelectedOptions(
            member_group.group_name,
            group_options,
            true
          )}
        />
      </div>,
      status_field,
    ];
    return (
      <CreateEditForm
        loading={groups_data.isFetching || member_group_data.isFetching}
        form_items={form_items}
        form_classes={form_classes}
        action_object={member_group_data.items}
        setState={this.updateState}
        action_object_name="member_group"
        state={this.state}
        action_object_endpoint={MEMBER_GROUP_ENDPOINT}
        object_display_name="member group"
      />
    );
  }
}

RegisterGroupDetails.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  groups_data: PropTypes.instanceOf(Object).isRequired,
  member_group_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const groups_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "groups_url"
  );
  const member_group_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "member_group_url"
  );
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    dataByUrl,
    groups_data,
    member_group_data,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(RegisterGroupDetails));
