import {
  APPLIED_LOANS_ENDPOINT,
  MEMBER_APPLIED_LOANS_ENDPOINT,
} from "../../constants/Constants";

import PropTypes from "prop-types";
import React from "react";
import { VIEW_LOAN_ACCOUNT_DETAILS_COLUMNS } from "../../components/DataTableColumns";
import ViewDataTable from "../../components/ViewDataTable";
import { withRouter } from "react-router";

function ViewLoanAccountDetails(props) {
  const { match } = props;
  const { params } = match;
  const { id } = params;
  const { member, auth_token } = localStorage;
  let applied_loan_url: string = `${APPLIED_LOANS_ENDPOINT}${id}/`;
  if (member) {
    applied_loan_url = `${MEMBER_APPLIED_LOANS_ENDPOINT}${id}/?token=${auth_token}&member=${member}&loan=${id}`;
  }
  return (
    <ViewDataTable
      paginated={false}
      allow_refresh
      basic_table
      vertical_headers
      columns={VIEW_LOAN_ACCOUNT_DETAILS_COLUMNS}
      data_source_url={applied_loan_url}
      data_source_url_name="applied_loan_url"
    />
  );
}

ViewLoanAccountDetails.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default withRouter(ViewLoanAccountDetails);
