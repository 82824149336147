import {
  APPLIED_LOANS_ENDPOINT,
  REPAYMENT_SCHEDULE_ENDPOINT,
} from "../../../constants/Constants";
import { DISBURSEMENT_DETAILS_COLUMNS } from "../../../components/DataTableColumns";
import React, { Component } from "react";
import ViewDataTable from "../../../components/ViewDataTable";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class DisbursementDetails extends Component {
  render() {
    const applied_loan_url: string = `${APPLIED_LOANS_ENDPOINT}${this.props.loan_id}/`;

    return (
      <ViewDataTable
        paginated={false}
        basic_table
        vertical_headers
        columns={DISBURSEMENT_DETAILS_COLUMNS}
        data_source_url={applied_loan_url}
        allow_print={true}
        csv_export={false}
        data_source_url_name="applied_loan_url"
      />
    );
  }
}

DisbursementDetails.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(DisbursementDetails));
