import React, { Component } from "react";
import { fetchUrlData, getUrlData } from "../../utils/componentActions";

import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import PropTypes from "prop-types";
import { SMS_NOTIFICATION_TEMPLATES_ENDPOINT } from "../../constants/Constants";
import { SMS_NOTIFICATION_TEMPLATES_PATH } from "../../constants/ClientPaths";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class SMSNotificationTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {},
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
    };
  }

  componentDidMount() {
    fetchUrlData(
      "sms_notification_templates_url",
      `${SMS_NOTIFICATION_TEMPLATES_ENDPOINT}i/`,
      this.props
    );
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const { sms_notification_templates_data } = props;
    const { settings } = this.state;
    const form_items: any = [
      <FormFields
        key={1}
        field_type="textarea"
        field_label="Loan application"
        props={props}
        field_name="sms_on_loan_application_template"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={2}
        field_type="textarea"
        field_label="Loan guarantor"
        props={props}
        field_name="sms_on_loan_guarantor_template"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={3}
        field_type="textarea"
        field_label="Loan guarantee"
        props={props}
        field_name="sms_on_loan_guarantee_template"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={4}
        field_type="textarea"
        field_label="Loan approval"
        props={props}
        field_name="sms_on_loan_approval_template"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={5}
        field_type="textarea"
        field_label="Loan rejection"
        props={props}
        field_name="sms_on_loan_rejection_template"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={6}
        field_type="textarea"
        field_label="Loan processing"
        props={props}
        field_name="sms_on_loan_processing_template"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={7}
        field_type="textarea"
        field_label="Loan reversal"
        props={props}
        field_name="sms_on_loan_reversal_template"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={8}
        field_type="textarea"
        field_label="Loan disbursement"
        props={props}
        field_name="sms_on_loan_disbursement_template"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={9}
        field_type="textarea"
        field_label="Loan payment"
        props={props}
        field_name="sms_on_loan_payment_template"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={10}
        field_type="textarea"
        field_label="Loan completion"
        props={props}
        field_name="sms_on_loan_completion_template"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={11}
        field_type="textarea"
        field_label="User to approve loan"
        props={props}
        field_name="sms_on_user_to_approve_template"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={12}
        field_type="textarea"
        field_label="User has approved loan"
        props={props}
        field_name="sms_on_user_has_approved_template"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={14}
        field_type="textarea"
        field_label="Loan due payment"
        props={props}
        field_name="sms_on_loans_due_payment_template"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={15}
        field_type="textarea"
        field_label="Loan overdue payment"
        props={props}
        field_name="sms_on_loans_overdue_payment_template"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={16}
        field_type="textarea"
        field_label="Billing invoice"
        props={props}
        field_name="sms_on_billing_invoice_template"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={17}
        field_type="textarea"
        field_label="Member account contribution"
        props={props}
        field_name="sms_on_member_contribution_template"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={18}
        field_type="textarea"
        field_label="Realtime loan penalties"
        props={props}
        field_name="sms_on_realtime_loan_penalties_template"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={19}
        field_type="textarea"
        field_label="Defaulted loans"
        props={props}
        field_name="sms_on_defaulted_loans_template"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={20}
        field_type="textarea"
        field_label="Notify member CRB listing"
        props={props}
        field_name="sms_to_notify_crb_listing"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={21}
        field_type="textarea"
        field_label="Guarantor defaulted loans"
        props={props}
        field_name="sms_on_defaulted_loans_guarantor_template"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={22}
        field_type="textarea"
        field_label="Loan overpayment"
        props={props}
        field_name="sms_on_loan_overpayment_template"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={23}
        field_type="textarea"
        field_label="Debt recovery"
        props={props}
        field_name="sms_on_loan_application_template"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
      <FormFields
        key={13}
        field_type="textarea"
        field_label="Member registration"
        props={props}
        field_name="sms_on_member_registration_template"
        wrapper_div_classes="col-4"
        data_object={settings}
      />,
    ];
    const form_classes: string = "row g-3";
    return (
      <CreateEditForm
        loading={sms_notification_templates_data.isFetching}
        form_items={form_items}
        form_classes={form_classes}
        action_object={sms_notification_templates_data.items}
        setState={this.updateState}
        action_object_name="settings"
        state={this.state}
        action_object_endpoint={SMS_NOTIFICATION_TEMPLATES_ENDPOINT}
        fetch_data_urls={[
          {
            url_name: "sms_notification_templates_url",
            url: `${SMS_NOTIFICATION_TEMPLATES_ENDPOINT}i/`,
          },
        ]}
        base_path={SMS_NOTIFICATION_TEMPLATES_PATH}
        obj_id_field="id"
        object_display_name="sms templates"
        push_id_to_path
      />
    );
  }
}

SMSNotificationTemplates.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  sms_notification_templates_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const sms_notification_templates_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "sms_notification_templates_url"
  );

  return {
    sessionVariables,
    sms_notification_templates_data,
  };
}

export default connect(mapStateToProps)(withRouter(SMSNotificationTemplates));
