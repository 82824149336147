import {
  APPLIED_LOANS_ENDPOINT,
  BANKS_ENDPOINT,
  CURRENCIES_ENDPOINT,
  LOAN_DISBURSEMENT_ENDPOINT,
  MEMBER_ACCOUNTS_ENDPOINT,
  PAYMENT_MODES_ENDPOINT,
} from "../../constants/Constants";
import React, { Component } from "react";
import {
  fetchUrlData,
  findObject,
  getSelect2Options,
  getUrlData,
} from "../../utils/componentActions";

import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import ModalHOC from "../../components/ModalHOC";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class DisburseLoan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applied_loan: {},
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
      selected_member: null,
      disburse_to_savings: false,
      receipt_modal_show: false,
      transaction_details: {},
    };
  }

  componentDidMount() {
    fetchUrlData("currencies_url", CURRENCIES_ENDPOINT, this.props);
    fetchUrlData("payment_modes_url", PAYMENT_MODES_ENDPOINT, this.props);
    fetchUrlData("banks_url", BANKS_ENDPOINT, this.props);
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  updatePayload(field, value, update_state = false) {
    const { applied_loan } = this.state;
    applied_loan[field] = value;
    const state_object: { applied_loan: any } = {
      applied_loan,
    };
    if (update_state) {
      state_object[field] = value;
    }
    this.setState(state_object);
  }

  handleChangeLoan(select_obj) {
    const { processed_member_loans_data } = this.props;
    const processed_member_loans: any = processed_member_loans_data.items;
    const applied_loan: any = findObject(
      processed_member_loans,
      "id",
      select_obj.value
    );
    this.setState({
      applied_loan,
    });
  }

  handleChangeMember(select_obj) {
    const member_id: any = select_obj.value;
    const processed_member_loans_url: string = `${APPLIED_LOANS_ENDPOINT}?member=${member_id}&status=11`;
    const member_accounts_url: string = `${MEMBER_ACCOUNTS_ENDPOINT}?member=${member_id}`;
    fetchUrlData(
      "processed_member_loans_url",
      processed_member_loans_url,
      this.props
    );
    fetchUrlData("member_accounts_url", member_accounts_url, this.props);
    this.setState({
      selected_member: select_obj,
    });
  }

  handleTopUpMemberChange(select_obj) {
    const member_id: any = select_obj.value;
    const active_topup_loans_url: string = `${APPLIED_LOANS_ENDPOINT}?member=${member_id}&status=1`;
    fetchUrlData("active_topup_loans_url", active_topup_loans_url, this.props);
  }

  render() {
    const { props } = this;
    const {
      processed_member_loans_data,
      currencies_data,
      payment_modes_data,
      banks_data,
      member_accounts_data,
      active_topup_loans_data,
    } = props;
    const processed_member_loans: any = processed_member_loans_data.items;
    const currencies: any = currencies_data.items;
    const payment_modes: any = payment_modes_data.items;
    const banks: any = banks_data.items;
    const member_accounts: any = member_accounts_data.items;
    const active_topup_loans: any = active_topup_loans_data.items;
    const loan_option_value_field: string =
      "[loan_name]-[loan_reference_no]([approved_amount])";
    const loan_option_field_map: string[] = [
      "loan_name",
      "loan_reference_no",
      "approved_amount",
    ];
    const topup_loan_option_value_field: string =
      "[loan_name]-[loan_reference_no]([approved_amount])([disbursement_date])";
    const topup_loan_option_field_map: string[] = [
      "loan_name",
      "loan_reference_no",
      "approved_amount",
      "disbursement_date",
    ];
    const member_accounts_options_value_field: string =
      "[savings_deposits_account_name]-[account_number]";
    const member_accounts_options_field_map: string[] = [
      "savings_deposits_account_name",
      "account_number",
    ];
    const processed_member_loans_options: any = getSelect2Options(
      processed_member_loans,
      "id",
      loan_option_value_field,
      loan_option_field_map
    );
    const topup_loans_options: any = getSelect2Options(
      active_topup_loans,
      "id",
      topup_loan_option_value_field,
      topup_loan_option_field_map
    );
    const currencies_options: any = getSelect2Options(currencies, "id", "name");
    const payment_modes_options: any = getSelect2Options(
      payment_modes,
      "id",
      "name"
    );
    const banks_options: any = getSelect2Options(banks, "id", "bank_name");
    const member_accounts_options: any = getSelect2Options(
      member_accounts,
      "id",
      member_accounts_options_value_field,
      member_accounts_options_field_map
    );
    const { applied_loan } = this.state;
    const { selected_member } = this.state;
    let disburse_to_savings_fields: string = "";
    let offset_loan_fields: string = "";
    let loan_fund_options: object = null;
    if (![undefined, ""].includes(applied_loan.loan_fund)) {
      const loan_fund: any = findObject(
        banks,
        "gl_account",
        parseInt(applied_loan.loan_fund)
      );
      if (loan_fund) {
        loan_fund_options = getSelect2Options([loan_fund], "id", "bank_name");
      }
    }
    if (this.state.disburse_to_savings) {
      disburse_to_savings_fields = [
        <FormFields
          key={14}
          field_type="select2"
          field_label="Member account"
          props={props}
          field_name="member_account"
          wrapper_div_classes="col-4"
          data_object={applied_loan}
          required
          select2_options={member_accounts_options}
        />,
      ];
    }
    if (this.state.offset_loan) {
      offset_loan_fields = [
        <FormFields
          key={15}
          field_type="member"
          field_label="Member to top-up"
          props={props}
          field_name="topup_member"
          wrapper_div_classes="col-4"
          onChange={(selected) => this.handleTopUpMemberChange(selected)}
          data_object={applied_loan}
          required
        />,
        <FormFields
          key={16}
          field_type="select2_multi"
          field_label="Loans to top-up"
          props={props}
          field_name="loans_to_offset"
          wrapper_div_classes="col-4"
          data_object={applied_loan}
          required
          update_payload
          updateState={this.updateState}
          data_object_name="applied_loan"
          select2_options={topup_loans_options}
        />,
        <FormFields
          key={17}
          field_type="select"
          field_label="Top-up effect"
          props={props}
          field_name="topup_effect"
          wrapper_div_classes="col-4"
          data_object={applied_loan}
        >
          <option value="clear_previous_loans">Clear previous loans</option>
          <option value="increase_previous_loan">Increase previous loan</option>
        </FormFields>,
      ];
    }
    const form_classes: string = "row g-3";
    const loans_to_offset: any = (applied_loan.loans_to_offset || []).join(",");
    const form_items: any = [
      <FormFields
        key={1}
        field_type="member"
        field_label="Member"
        props={props}
        field_name="member"
        wrapper_div_classes="col-4"
        selected_member={selected_member}
        onChange={(selected) => this.handleChangeMember(selected)}
        data_object={applied_loan}
        required
      />,
      <FormFields
        key={2}
        field_type="select2"
        field_label="Loan"
        props={props}
        field_name="loan"
        wrapper_div_classes="col-4"
        onChange={(selected) => this.handleChangeLoan(selected)}
        data_object={applied_loan}
        required
        select2_options={processed_member_loans_options}
      />,
      <FormFields
        key={3}
        field_type="date"
        field_label="Disbursement date"
        props={props}
        field_name="disbursement_date"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
        required
      />,
      <FormFields
        key={4}
        field_type="number_input"
        field_label="Disbursement amount"
        props={props}
        field_name="disbursement_amount"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
        required
        data_object_field="approved_amount"
      />,
      <FormFields
        key={5}
        field_type="select2"
        field_label="Currency"
        props={props}
        field_name="currency"
        wrapper_div_classes="col-4"
        select2_default_first
        data_object={applied_loan}
        required
        select2_options={currencies_options}
      />,
      <FormFields
        key={6}
        field_type="text_input"
        field_label="Cheque no"
        props={props}
        field_name="cheque"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
      />,
      <FormFields
        key={7}
        field_type="select2"
        field_label="Payment mode"
        props={props}
        field_name="payment_type"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
        required
        select2_default_first
        select2_options={payment_modes_options}
      />,
      <FormFields
        key={8}
        field_type="text_input"
        field_label="Transaction code"
        props={props}
        field_name="receipt_no"
        wrapper_div_classes="col-4"
        autofill_timestamp
        data_object={applied_loan}
        required
        autofill_prefix="DIS-"
      />,
      <FormFields
        key={9}
        field_type="select2"
        field_label="Bank"
        props={props}
        field_name="bank"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
        required
        select2_default_first
        select2_options={loan_fund_options || banks_options}
      />,
      <FormFields
        key={10}
        field_type="textarea"
        field_label="Comment"
        props={props}
        field_name="comment"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
      />,
      <FormFields
        key={11}
        field_type="select"
        field_label="Deduct disbursement charges from amount"
        props={props}
        field_name="deduct_charges_from_disbursement_amount"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
      >
        <option value="yes">Yes</option>
        <option value="no">No</option>
      </FormFields>,
      <FormFields
        key={12}
        field_type="select"
        field_label="Disburse to savings"
        props={props}
        field_name="disburse_to_savings"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
        onChange={(e) =>
          this.updatePayload("disburse_to_savings", e.target.value, true)
        }
      >
        <option value="0">No</option>
        <option value="1">Yes</option>
      </FormFields>,
      disburse_to_savings_fields,
      <FormFields
        key={13}
        field_type="select"
        field_label="Top-up another loan"
        props={props}
        field_name="offset_loan"
        wrapper_div_classes="col-4"
        data_object={applied_loan}
        onChange={(e) =>
          this.updatePayload("offset_loan", e.target.value, true)
        }
      >
        <option value={false}>No</option>
        <option value>Yes</option>
      </FormFields>,
      offset_loan_fields,
      <FormFields
        key={18}
        field_type="checkbox"
        checkbox_field_label="Print receipt"
        props={props}
        field_name="print_receipt"
        wrapper_div_classes="col-4 pt-4-2"
        data_object={applied_loan}
        update_payload
        data_object_name="loan_disbursement"
        updateState={this.updateState}
      />,
    ];
    return (
      <CreateEditForm
        loading={processed_member_loans_data.isFetching}
        form_items={form_items}
        form_classes={form_classes}
        action_object={applied_loan}
        setState={this.updateState}
        action_object_name="applied_loan"
        state={this.state}
        action_object_endpoint={LOAN_DISBURSEMENT_ENDPOINT}
        invalidate_object_endpoint={APPLIED_LOANS_ENDPOINT}
        object_display_name="loan disbursement"
        payload={{ loans_to_offset }}
      />
    );
  }
}

DisburseLoan.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  processed_member_loans_data: PropTypes.instanceOf(Object).isRequired,
  currencies_data: PropTypes.instanceOf(Object).isRequired,
  payment_modes_data: PropTypes.instanceOf(Object).isRequired,
  banks_data: PropTypes.instanceOf(Object).isRequired,
  member_accounts_data: PropTypes.instanceOf(Object).isRequired,
  active_topup_loans_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const processed_member_loans_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "processed_member_loans_url"
  );
  const currencies_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "currencies_url"
  );
  const payment_modes_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "payment_modes_url"
  );
  const banks_data: any = getUrlData(dataByUrl, sessionVariables, "banks_url");
  const member_accounts_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "member_accounts_url"
  );
  const active_topup_loans_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "active_topup_loans_url"
  );

  return {
    sessionVariables,
    processed_member_loans_data,
    currencies_data,
    payment_modes_data,
    banks_data,
    member_accounts_data,
    active_topup_loans_data,
  };
}

export default connect(mapStateToProps)(withRouter(DisburseLoan));
