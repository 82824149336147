import React, { Component } from "react";
import { getUrlData, label } from "../utils/componentActions";

import Pills from "./Pills";
import Portlet from "./Portlet";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class ViewAddPortlet extends Component {
  constructor(props) {
    super(props);
    const { pathname } = this.props.history.location;
    this.state = {
      active_nav: pathname,
    };
  }

  render() {
    const { props } = this;
    return (
      <Portlet header_label={label(props.header_label, props)}>
        <Pills pills={props.pills} />
        {props.children}
      </Portlet>
    );
  }
}

ViewAddPortlet.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  system_labels_data: PropTypes.instanceOf(Object).isRequired,
  system_labels_dict_url: PropTypes.instanceOf(Object),
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    dataByUrl,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(ViewAddPortlet));
