import React, { Component } from "react";

import CreateEditForm from "../components/CreateEditForm";
import FormFields from "../components/FormFields";
import { MPAY_ADD_PAYBILL_PATH } from "../constants/ClientPaths";
import { MPAY_BUSINESS_NUMBERS_COLUMNS } from "../components/DataTableColumns";
import { MPAY_BUSINESS_NUMBERS_ENDPOINT } from "../constants/Constants";
import ModalHOC from "../components/ModalHOC";
import PropTypes from "prop-types";
import ViewDataTable from "../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ViewMpayTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_row_obj: {},
    };
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  handleTableRowSelect = (row) => {
    this.setState({
      selected_row_obj: row,
    });
  };

  render() {
    const { selected_row_obj }: any = this.state;

    const api_password_form_items: any = [
      <FormFields
        key={1}
        props={this.props}
        field_type="text_input"
        field_label="PayBill username"
        field_name="username"
        data_object={selected_row_obj}
        wrapper_div_classes="col"
        required
      />,
      <FormFields
        key={2}
        props={this.props}
        field_type="password"
        field_label="PayBill password"
        field_name="initiator_pass"
        data_object={selected_row_obj}
        wrapper_div_classes="col"
        required
      />,
    ];

    return (
      <>
        <ViewDataTable
          handleSetTableNode={this.handleSetTableNode}
          handleTableRowSelect={this.handleTableRowSelect}
          allow_refresh
          creatable
          editable
          edit_url={MPAY_ADD_PAYBILL_PATH}
          select_mode="radio"
          clickToSelect
          columns={MPAY_BUSINESS_NUMBERS_COLUMNS}
          data_source_url={MPAY_BUSINESS_NUMBERS_ENDPOINT}
          data_source_url_name="paginated_mpay_business_numbers"
        />
        <ModalHOC
          modal_only
          modal_title="Update PayBill password"
          data_target="change-api-password"
          modal_size="modal-md"
        >
          <CreateEditForm
            form_items={api_password_form_items}
            action_object={selected_row_obj}
            state={this.state}
            action_object_endpoint={MPAY_BUSINESS_NUMBERS_ENDPOINT}
            action_object_name="selected_row_obj"
            update_request_method="PATCH"
            action_object_id={selected_row_obj.id}
          />
        </ModalHOC>
      </>
    );
  }
}

ViewMpayTransactions.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ViewMpayTransactions));
