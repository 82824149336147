import React, { Component } from "react";

import { VIEW_COLLATERALS_PATH } from "../../constants/ClientPaths";
import VIewAddPortlet from "../../components/VIewAddPortlet";
import { withRouter } from "react-router-dom";

/**
 * This component is used to display the Collaterals portlet
 */
class Collaterals extends Component {
  /**
   * Render the component
   */
  render() {
    const pills: any = [
      {
        label: "View collaterals",
        path: VIEW_COLLATERALS_PATH,
      },
    ];
    return (
      <VIewAddPortlet header_label="Collaterals" pills={pills}>
        {this.props.children}
      </VIewAddPortlet>
    );
  }
}

export default withRouter(Collaterals);
