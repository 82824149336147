import React, { Component } from "react";

import { CLIENT_PAYMENTS_ENDPOINT } from "../../../constants/Constants";
import CreateEditForm from "../../../components/CreateEditForm";
import FormFields from "../../../components/FormFields";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import merge from "lodash/merge";
import { withRouter } from "react-router";

class WriteOffLoan extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      write_off: {},
    };
    this.state = merge({}, this.initialState);
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  resetState = () => {
    const state_object: any = merge({}, this.initialState);
    const { write_off } = state_object;
    write_off.reset_fields = true;
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const { write_off } = this.state;
    const extra_form_data: any = [
      { name: "write_off", value: true },
      { name: "loan", value: props.loan_id },
    ];
    const form_items: any = [
      <FormFields
        key={1}
        field_type="number_input"
        field_label="Write-off amount"
        props={props}
        field_name="amount"
        wrapper_div_classes="col-12"
        data_object={{}}
        required
      />,
      <FormFields
        key={2}
        field_type="date"
        field_label="Write-off date"
        props={props}
        field_name="date"
        wrapper_div_classes="col-12"
        data_object={{}}
        required
      />,
      <FormFields
        key={3}
        field_type="textarea"
        field_label="Reason"
        props={props}
        field_name="write_off_reason"
        wrapper_div_classes="col-12"
        data_object={write_off}
      />,
    ];
    const form_classes: string = "row g-1";
    return (
      <CreateEditForm
        loading={false}
        form_items={form_items}
        form_classes={form_classes}
        action_object={{}}
        setState={this.updateState}
        action_object_name="write_off"
        state={this.state}
        action_object_endpoint={CLIENT_PAYMENTS_ENDPOINT}
        payload={write_off}
        resetState={this.resetState}
        extra_form_data={extra_form_data}
        form_id="write-off-loan"
        object_display_name="write-off"
      />
    );
  }
}

WriteOffLoan.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  loan_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(WriteOffLoan));
