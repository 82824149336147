import {
  NEXT_OF_KIN_ENDPOINT,
  NEXT_OF_KIN_RELATIONSHIP_ENDPOINT,
} from "../../constants/Constants";
import React, { Component } from "react";
import {
  fetchUrlData,
  getSelect2Options,
  getUrlData,
} from "../../utils/componentActions";

import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import ModalHOC from "../../components/ModalHOC";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class RegisterNextOfKin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      next_of_kin: {},
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
      relationship: {},
    };
  }

  componentDidMount() {
    fetchUrlData(
      "next_of_kin_relationships_url",
      NEXT_OF_KIN_RELATIONSHIP_ENDPOINT,
      this.props
    );
    const next_of_kin_id: any = this.props.match.params.id;
    if (next_of_kin_id) {
      const next_of_kin_url: string = `${NEXT_OF_KIN_ENDPOINT}${next_of_kin_id}/`;
      fetchUrlData("next_of_kin_url", next_of_kin_url, this.props);
    }
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const { next_of_kin_data, next_of_kin_relationships_data } = props;
    const { next_of_kin } = this.state;
    const next_of_kin_relationships: any = next_of_kin_relationships_data.items;
    const next_of_kin_relationship_options: any = getSelect2Options(
      next_of_kin_relationships,
      "id",
      "relationship"
    );
    let selected_member;
    if (next_of_kin.member) {
      selected_member = {
        value: next_of_kin.member,
        label: next_of_kin.member_name,
      };
    }
    const relationship_form_items: any = [
      <FormFields
        key={1}
        field_type="text_input"
        field_label="Relationship"
        props={props}
        field_name="relationship"
        wrapper_div_classes="col"
        data_object={{}}
        required
      />,
    ];
    const relationship_form_classes: string = "row g-1";
    const relationship_form: any = (
      <CreateEditForm
        modal_id="relationship-modal"
        loading={
          next_of_kin_data.isFetching ||
          next_of_kin_relationships_data.isFetching
        }
        datatable_source_url_name="next_of_kin_relationships_url"
        datatable_source_url={NEXT_OF_KIN_RELATIONSHIP_ENDPOINT}
        form_id="form-relationship"
        form_items={relationship_form_items}
        form_classes={relationship_form_classes}
        action_object={next_of_kin_data.items}
        setState={this.updateState}
        action_object_name="relationship"
        state={this.state}
        action_object_endpoint={NEXT_OF_KIN_RELATIONSHIP_ENDPOINT}
        object_display_name="relationship"
      />
    );
    const relationship_modal: any = (
      <div className="row mb-3">
        <ModalHOC
          button_icon="plus"
          button_label="Add relationship"
          modal_only
          data_target="relationship-modal"
          button_classes="btn btn-outline-primary"
          modal_title="Add relationship"
        >
          {relationship_form}
        </ModalHOC>
      </div>
    );
    const relationship_modal_button: any = (
      <div className="row mb-3">
        <ModalHOC
          button_icon="plus"
          button_label="Add relationship"
          button_only
          data_target="relationship-modal"
          button_classes="btn btn-outline-primary"
          modal_title="Add relationship"
        >
          {relationship_form}
        </ModalHOC>
      </div>
    );
    const form_items: any = [
      <FormFields
        key={1}
        field_type="member"
        field_label="Member"
        props={props}
        field_name="member"
        wrapper_div_classes="col-4"
        data_object={next_of_kin}
        required
        selected_member={selected_member}
      />,
      <FormFields
        key={2}
        field_type="text_input"
        field_label="First name"
        props={props}
        field_name="first_name"
        wrapper_div_classes="col-4"
        data_object={next_of_kin}
        required
      />,
      <FormFields
        key={3}
        field_type="text_input"
        field_label="Middle name"
        props={props}
        field_name="middle_name"
        wrapper_div_classes="col-4"
        data_object={next_of_kin}
      />,
      <FormFields
        key={4}
        field_type="text_input"
        field_label="Last name"
        props={props}
        field_name="last_name"
        wrapper_div_classes="col-4"
        data_object={next_of_kin}
        required
      />,
      <FormFields
        key={5}
        field_type="select2"
        field_label="Relationship"
        props={props}
        field_name="relationship"
        wrapper_div_classes="col-4"
        data_object={next_of_kin}
        required
        bottom_append={relationship_modal_button}
        select2_options={next_of_kin_relationship_options}
      />,
      <FormFields
        key={6}
        field_type="number_input"
        field_label="Percentage"
        props={props}
        field_name="percentage"
        wrapper_div_classes="col-4"
        data_object={next_of_kin}
        required
      />,
      <FormFields
        key={7}
        field_type="date"
        field_label="Date of birth"
        props={props}
        field_name="date_of_birth"
        wrapper_div_classes="col-4"
        data_object={next_of_kin}
      />,
      <FormFields
        key={8}
        field_type="text_input"
        field_label="Personal identification no"
        props={props}
        field_name="id_no"
        wrapper_div_classes="col-4"
        data_object={next_of_kin}
      />,
      <FormFields
        key={9}
        field_type="mobile_no"
        field_label="Mobile no"
        props={props}
        field_name="mobile_no"
        wrapper_div_classes="col-4"
        data_object={next_of_kin}
      />,
      <FormFields
        key={10}
        field_type="file"
        field_label="Picture"
        props={props}
        field_name="picture"
        wrapper_div_classes="col-4"
        data_object={next_of_kin}
      />,
      <FormFields
        key={11}
        field_type="textarea"
        field_label="Comment"
        props={props}
        field_name="comment"
        wrapper_div_classes="col-4"
        data_object={next_of_kin}
      />,
    ];
    const form_classes: string = "row g-3";
    return (
      <CreateEditForm
        loading={
          next_of_kin_data.isFetching ||
          next_of_kin_relationships_data.isFetching
        }
        form_items={form_items}
        form_classes={form_classes}
        action_object={next_of_kin_data.items}
        setState={this.updateState}
        action_object_name="next_of_kin"
        state={this.state}
        action_object_endpoint={NEXT_OF_KIN_ENDPOINT}
        bottom_append={relationship_modal}
        post_form
        content_type="multipart/form-data; boundary=----"
        object_display_name="next of kin"
      />
    );
  }
}

RegisterNextOfKin.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  next_of_kin_data: PropTypes.instanceOf(Object).isRequired,
  next_of_kin_relationships_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const next_of_kin_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "next_of_kin_url"
  );
  const next_of_kin_relationships_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "next_of_kin_relationships_url"
  );

  return {
    sessionVariables,
    next_of_kin_data,
    next_of_kin_relationships_data,
  };
}

export default connect(mapStateToProps)(withRouter(RegisterNextOfKin));
