import {
  ORGANIZATION_ENDPOINT,
  TEMPLATES_ENDPOINT,
  USERS_ENDPOINT,
} from "../constants/Constants";
import React, { Component } from "react";
import {
  fetchUrlData,
  formatDate,
  getUrlData,
  thousandsFormat,
} from "../utils/componentActions";

import BootstrapIcon from "../components/BootstrapIcon";
import PropTypes from "prop-types";
import ReactToPrint from "react-to-print";
import { connect } from "react-redux";
import moment from "moment";
import numspell from "numspell";
import { withRouter } from "react-router";

class PaymentReceipt extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    fetchUrlData("templates_url", `${TEMPLATES_ENDPOINT}i/`, this.props);
    fetchUrlData("organization_url", `${ORGANIZATION_ENDPOINT}i/`, this.props);
    fetchUrlData("current_user_url", `${USERS_ENDPOINT}i/`, this.props);
  }

  numberToWords = (number) => (number ? numspell("en")(number) : "");

  render() {
    const { templates_data, organization_data, current_user_data } = this.props;
    const templates: any = templates_data.items;
    const organization: any = organization_data.items;
    const current_user: any = current_user_data.items;
    const { payment_type } = this.props;
    let receipt_template;
    if (payment_type === "income") {
      receipt_template = templates.income_receipt_template || "";
    } else if (payment_type === "expense" || payment_type === "disbursement") {
      receipt_template = templates.payment_voucher_template || "";
    }
    const transaction_details: any = this.props.transaction_details || {};
    receipt_template = receipt_template.replace(
      "{this.props.organization.logo}",
      organization.logo || ""
    );
    receipt_template = receipt_template.replace(
      "{this.props.organization.reports_header}",
      organization.reports_header || ""
    );
    receipt_template = receipt_template.replace(
      "{this.props.transaction_code}",
      transaction_details.transaction_code || ""
    );
    receipt_template = receipt_template.replace(
      "{this.props.cheque_number}",
      transaction_details.cheque || ""
    );
    receipt_template = receipt_template.replace(
      "{formattedDate(this.props.date)}",
      formatDate(transaction_details.date, "DD-MMM-YYYY")
    );
    receipt_template = receipt_template.replace(
      "{this.props.time}",
      moment().format("LLLL")
    );
    receipt_template = receipt_template.replace(
      "{this.props.payee}",
      transaction_details.payee || ""
    );
    receipt_template = receipt_template.replace(
      "{this.props.payee_address}",
      transaction_details.payee_address || ""
    );
    receipt_template = receipt_template.replace(
      "{this.props.payer}",
      transaction_details.payer || ""
    );
    receipt_template = receipt_template.replace(
      "{this.props.selected_member_name}",
      transaction_details.member_name || ""
    );
    receipt_template = receipt_template.replace(
      "{this.props.selected_member_no}",
      transaction_details.member_no || ""
    );
    receipt_template = receipt_template.replace("{products}", "");
    receipt_template = receipt_template.replace(
      "{numberWithCommas(this.props.amount)}",
      thousandsFormat(transaction_details.amount)
    );
    receipt_template = receipt_template.replace(
      "{this.props.amount_in_words}",
      this.numberToWords(Math.trunc(transaction_details.amount))
    );
    receipt_template = receipt_template.replace(
      "{this.props.username}",
      current_user.username || ""
    );
    receipt_template = receipt_template.replace(
      "{this.props.payment_mode}",
      transaction_details.payment_type_name || ""
    );
    receipt_template = receipt_template.replace(
      "{this.props.description}",
      transaction_details.description || ""
    );
    receipt_template = receipt_template.replace(
      "{this.props.user_first_name}",
      current_user.first_name
    );
    receipt_template = receipt_template.replace(
      "{this.props.user_last_name}",
      current_user.last_name
    );
    receipt_template = receipt_template.replace(
      "{this.props.member_street}",
      transaction_details.member_street || ""
    );
    receipt_template = receipt_template.replace(
      "{this.props.member_estate}",
      transaction_details.member_estate || ""
    );
    receipt_template = receipt_template.replace(
      "{this.props.member_postal_address}",
      transaction_details.member_postal_address || ""
    );
    receipt_template = receipt_template.replace(
      "{this.props.member_city_or_town}",
      transaction_details.member_city_or_town || ""
    );
    receipt_template = receipt_template.replace(
      "{this.props.member_county_or_state}",
      transaction_details.member_county_or_state || ""
    );
    return (
      <>
        <ReactToPrint
          trigger={() => (
            <button className="btn btn-outline-primary mb-3" type="button">
              Print <BootstrapIcon icon="printer" classes="text-primary" />
            </button>
          )}
          content={() => this.print_receipt}
        />
        <div
          dangerouslySetInnerHTML={{ __html: receipt_template }}
          ref={(el) => (this.print_receipt = el)}
        />
      </>
    );
  }
}

PaymentReceipt.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  templates_data: PropTypes.instanceOf(Object).isRequired,
  organization_data: PropTypes.instanceOf(Object).isRequired,
  transaction_details: PropTypes.instanceOf(Object),
  current_user_data: PropTypes.instanceOf(Object).isRequired,
  payment_type: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const templates_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "templates_url"
  );
  const organization_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "organization_url"
  );
  const current_user_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "current_user_url"
  );

  return {
    sessionVariables,
    templates_data,
    organization_data,
    current_user_data,
  };
}

export default connect(mapStateToProps)(withRouter(PaymentReceipt));
