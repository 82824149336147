import { API_ENDPOINT, LOGIN_ENDPOINT } from "../constants/Constants";
import React, { Component } from "react";
import {
  delay,
  extractResponseError,
  formDataToJSON,
  pushHistory,
} from "../utils/componentActions";

import $ from "jquery";
import FormActivityIndicator from "../components/FormActivityIndicator";
import { postAPIRequest } from "../utils/APIRequests";
import { withRouter } from "react-router-dom";

class FormOTP extends Component {
  handleSubmitOTP(e) {
    e.preventDefault();
    this.props.handleUpdateState({
      activity: true,
    });
    const formData: any = new FormData($("form#form-otp")[0]);
    let payload: any = {
      username: this.props.username,
      password: this.props.password,
    };
    payload = formDataToJSON(formData, payload);
    const login_url: any = API_ENDPOINT + LOGIN_ENDPOINT;
    postAPIRequest(
      login_url,
      async (results) => {
        const feedback_message: string = "Log in successful, redirecting...";
        this.props.handleUpdateState(
          {
            activity: false,
            feedback_message,
            feedback_type: "success",
          },
          async () => {
            await delay(1000);
            pushHistory("/", this.props);
          }
        );
      },
      (results) => {
        const feedback_message: any = extractResponseError(results);
        this.props.handleUpdateState({
          activity: false,
          feedback_message,
          feedback_type: "danger",
        });
      },
      payload,
      {
        "Content-Type": "application/json",
      }
    );
  }

  render() {
    let login_buttons: any = (
      <div className="col-12">
        <button className="btn btn-green btn-block text-white" type="submit">
          Login
        </button>
      </div>
    );
    if (this.props.activity) {
      login_buttons = <FormActivityIndicator />;
    }
    return (
      <form
        id="form-otp"
        className="row g-1"
        onSubmit={(e) => this.handleSubmitOTP(e)}
      >
        <div className="col-12">
          <label className="form-label">OTP</label>
          <input className="form-control" type="number" name="otp" />
        </div>
        {login_buttons}
      </form>
    );
  }
}

export default withRouter(FormOTP);
