import React, { Component } from "react";
import {
  VIEW_LOAN_ACCOUNT_CHARGES_PATH,
  VIEW_LOAN_ACCOUNT_CLIENT_STATEMENT_PATH,
  VIEW_LOAN_ACCOUNT_DETAILS,
  VIEW_LOAN_ACCOUNT_GUARANTORS_PATH,
  VIEW_LOAN_ACCOUNT_MINI_STATEMENT_PATH,
  VIEW_LOAN_ACCOUNT_PENDING_ACTIONS,
  VIEW_LOAN_ACCOUNT_STATEMENT_PATH,
  VIEW_LOAN_ACCOUNT_TRANSACTIONS_PATH,
  VIEW_LOAN_REPAYMENT_SCHEDULE_PATH,
} from "../../constants/ClientPaths";
import {
  VIEW_LOAN_ACCOUNT_LEFT_COLUMNS,
  VIEW_LOAN_ACCOUNT_RIGHT_COLUMNS,
} from "../../components/DataTableColumns";
import {
  fetchUrlData,
  getSelect2Options,
  getUrlData,
  pushHistory,
} from "../../utils/componentActions";

import { APPLIED_LOANS_ENDPOINT } from "../../constants/Constants";
import FormFields from "../../components/FormFields";
import LoanAccountActions from "./LoanAccountActions";
import Pills from "../../components/Pills";
import Portlet from "../../components/Portlet";
import PropTypes from "prop-types";
import ViewDataTable from "../../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ViewLoanAccount extends Component {
  constructor(props) {
    super(props);
  }

  handleMemberChange(selected) {
    const member_id: any = selected.value;
    const statuses: string = "1,6,7,9,11,14";
    const member_loans_url: string = `${APPLIED_LOANS_ENDPOINT}?member=${member_id}&status__in=${statuses}`;
    fetchUrlData("member_loans_url", member_loans_url, this.props, true);
  }

  handleLoanChange(selected) {
    pushHistory(
      `${VIEW_LOAN_ACCOUNT_STATEMENT_PATH}${selected.value}`,
      this.props
    );
  }

  render() {
    const { props } = this;
    const { member_loans_data } = props;
    const member_loans: any = member_loans_data.items;
    const loan_option_value_field: string =
      "[loan_name]-[loan_reference_no]([approved_amount])([disbursement_date])([status_name])";
    const loan_option_field_map: string[] = [
      "loan_name",
      "loan_reference_no",
      "approved_amount",
      "disbursement_date",
      "status_name",
    ];
    const loans_options: any = getSelect2Options(
      member_loans,
      "id",
      loan_option_value_field,
      loan_option_field_map
    );
    const pills: any = [
      {
        label: "Account details",
        path: VIEW_LOAN_ACCOUNT_DETAILS,
      },
      {
        label: "Repayment schedule",
        path: VIEW_LOAN_REPAYMENT_SCHEDULE_PATH,
      },
      {
        label: "Account statement",
        path: VIEW_LOAN_ACCOUNT_STATEMENT_PATH,
      },
      {
        label: "Mini statement",
        path: VIEW_LOAN_ACCOUNT_MINI_STATEMENT_PATH,
      },
      {
        label: "Client statement",
        path: VIEW_LOAN_ACCOUNT_CLIENT_STATEMENT_PATH,
      },
      {
        label: "Charges",
        path: VIEW_LOAN_ACCOUNT_CHARGES_PATH,
      },
      {
        label: "Transactions",
        path: VIEW_LOAN_ACCOUNT_TRANSACTIONS_PATH,
      },
      {
        label: "Guarantors",
        path: VIEW_LOAN_ACCOUNT_GUARANTORS_PATH,
      },
      {
        label: "Pending actions",
        path: VIEW_LOAN_ACCOUNT_PENDING_ACTIONS,
      },
    ];
    const loan_id: any = props.computedMatch.params.id;
    const applied_loan_url: string = `${APPLIED_LOANS_ENDPOINT}${loan_id}/`;
    const filter_form_items: any = [
      <FormFields
        key={1}
        field_type="member"
        field_label="Member"
        props={props}
        field_name="member"
        wrapper_div_classes="col-4 pb-3"
        onChange={(selected) => this.handleMemberChange(selected)}
        data_object={{}}
        required
      />,
      <FormFields
        key={2}
        field_type="select2"
        field_label="Loan"
        props={props}
        field_name="loan"
        wrapper_div_classes="col-4 pb-3"
        select2_options={loans_options}
        onChange={(selected) => this.handleLoanChange(selected)}
        data_object={{}}
        required
      />,
    ];
    let loan_account_statements: any = <div />;
    let loan_account_actions: any = <div />;
    if (loan_id) {
      loan_account_statements = (
        <>
          <div className="row">
            <div className="col table-responsive">
              <ViewDataTable
                paginated={false}
                basic_table
                vertical_headers
                columns={VIEW_LOAN_ACCOUNT_LEFT_COLUMNS}
                data_source_url={applied_loan_url}
                csv_export={false}
                data_source_url_name="applied_loan_url"
              />
            </div>
            <div className="col table-responsive">
              <ViewDataTable
                paginated={false}
                basic_table
                vertical_headers
                columns={VIEW_LOAN_ACCOUNT_RIGHT_COLUMNS}
                data_source_url={applied_loan_url}
                csv_export={false}
                data_source_url_name="applied_loan_url"
              />
            </div>
          </div>
          <Pills pills={pills} append_id param_id={loan_id} />
          {this.props.children}
        </>
      );
      loan_account_actions = <LoanAccountActions loan_id={loan_id} />;
    }
    return (
      <Portlet header_label="Loan account">
        <div className="row g-3">{filter_form_items}</div>
        {loan_account_actions}
        {loan_account_statements}
      </Portlet>
    );
  }
}

ViewLoanAccount.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  member_loans_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const member_loans_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "member_loans_url"
  );

  return {
    sessionVariables,
    member_loans_data,
  };
}

export default connect(mapStateToProps)(withRouter(ViewLoanAccount));
