import { init as initApm } from "@elastic/apm-rum";

const apm: any = initApm({
  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: "Jisort",
  // Set the version of your application
  // Used on the APM Server to find the right sourcemap
  serviceVersion: "",
  // Set custom APM Server URL (default: http://localhost:8200)
  serverUrl: "https://apm.jisort.com",
  // For distributed tracing to different origin (CORS)
  // distributedTracingOrigins: ['http://localhost:8080'],
});

export default apm;
