import React, { Component } from "react";

import BootstrapIcon from "./BootstrapIcon";

export default class FormFeedbackMessage extends Component {
  render() {
    let icon: any = <BootstrapIcon icon="info-circle" />;
    const { feedback_type } = this.props;
    if (feedback_type === "danger") {
      icon = <BootstrapIcon icon="exclamation-triangle" />;
    } else if (feedback_type === "success") {
      icon = <BootstrapIcon icon="check-circle" />;
    }
    let feedback_message: any = <div />;
    if (this.props.feedback_message) {
      feedback_message = (
        <div className={`alert alert-${feedback_type}`} role="alert">
          {icon} {this.props.feedback_message}
        </div>
      );
    }
    return feedback_message;
  }
}
