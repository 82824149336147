import React, { Component } from "react";

import DataTableActionForm from "../../components/DataTableActionForm";
import { EXPENSES_ENDPOINT } from "../../constants/Constants";
import FormFields from "../../components/FormFields";
import { MAKE_PAYMENT_PATH } from "../../constants/ClientPaths";
import PropTypes from "prop-types";
import { VIEW_EXPENSES_COLUMNS } from "../../components/DataTableColumns";
import { VIEW_EXPENSES_FILTERS } from "../../components/DataTableFilterFields";
import ViewDataTable from "../../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ViewExpenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      action_buttons: [],
    };
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  handleSetActionButtons = (action_buttons) => {
    this.setState({
      action_buttons,
    });
  };

  render() {
    const { props } = this;
    const reverse_form_items: any = [
      <FormFields
        key={1}
        field_type="textarea"
        field_label="Reversal reason"
        props={props}
        field_name="reverse_reason"
        wrapper_div_classes="col-4 pb-3"
        data_object={{}}
        required
        autofocus
      />,
    ];
    const actions: any = [
      {
        name: "reverse",
        btn_classes: "btn btn-danger ml-2",
        noun: "reversal",
        icon: "file-earmark-minus",
        target: "payment",
        plural: "payments",
        form_items: reverse_form_items,
        multiple_form_items: reverse_form_items,
        extra_form_data: [{ name: "status", value: 12 }],
      },
    ];
    const made_payments_url: string = `${EXPENSES_ENDPOINT}?initial=1&ordering=-date&limit=5&offset=0`;
    return (
      <DataTableActionForm
        actions={actions}
        table_node={this.state.table_node}
        ids_post_field="payments"
        datatable_action_endpoint={EXPENSES_ENDPOINT}
        request_method="POST"
        datatable_source_url={made_payments_url}
        handleSetActionButtons={this.handleSetActionButtons}
        datatable_source_url_name="paginated_expenses"
      >
        <ViewDataTable
          handleSetTableNode={this.handleSetTableNode}
          table_filters={VIEW_EXPENSES_FILTERS}
          action_buttons={this.state.action_buttons}
          allow_refresh
          creatable
          editable
          columns={VIEW_EXPENSES_COLUMNS}
          data_source_url={EXPENSES_ENDPOINT}
          edit_url={MAKE_PAYMENT_PATH}
          handleTableRowSelect={this.handleTableRowSelect}
          select_mode="checkbox"
          extra_get_params="initial=1&ordering=-date"
          data_source_url_name="paginated_expenses"
        />
      </DataTableActionForm>
    );
  }
}

ViewExpenses.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ViewExpenses));
