import * as stickyThead from "stickythead";

import React, { Component } from "react";
import { getUrlData, label, objGetField } from "../utils/componentActions";

import BootstrapIcon from "./BootstrapIcon";
import ComponentLoadingIndicator from "./ComponentLoadingIndicator";
import PropTypes from "prop-types";
import ReactToPrint from "react-to-print";
import TableExport from "tableexport";
import { connect } from "react-redux";
import { withRouter } from "react-router";

require("tableexport/dist/css/tableexport.min.css");

class BasicDataTable extends Component {
  /**
   * Setup table export
   * @returns {TableExport}
   */
  setupTableExport() {
    if (document.getElementsByClassName("tableexport-caption").length > 0) {
      return;
    }
    const path: any = this.props.location.pathname || "spreadsheet";
    const csv_filename_arr: any = path.split("/");
    let csv_filename: any = csv_filename_arr[csv_filename_arr.length - 1];
    if (parseInt(csv_filename).toString() === csv_filename) {
      csv_filename = csv_filename_arr[csv_filename_arr.length - 2];
    }
    TableExport(document.getElementsByClassName("table-export"), {
      headers: true,
      footers: true,
      formats: ["xlsx", "xls", "csv", "txt"],
      filename: csv_filename,
      bootstrap: false,
      exportButtons: true,
      position: "top",
      ignoreRows: null,
      ignoreCols: null,
      trimWhitespace: true,
      RTL: false,
      sheetname: csv_filename,
    });
    TableExport.prototype.defaultButton = "btn btn-outline-success mr-2";
    TableExport.prototype.formatConfig.xlsx.buttonContent = label(
      "Export to xlsx",
      this.props
    );
    TableExport.prototype.formatConfig.xls.buttonContent = label(
      "Export to xls",
      this.props
    );
    TableExport.prototype.formatConfig.csv.buttonContent = label(
      "Export to csv",
      this.props
    );
    TableExport.prototype.formatConfig.txt.buttonContent = label(
      "Export to txt",
      this.props
    );
  }

  componentDidMount() {
    this.setupTableExport();
  }

  onRefChange = (table_node) => {
    this.basic_table = table_node;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.setupTableExport();
    const table_node: any = this.basic_table;
    if (table_node) {
      if (table_node.querySelector("thead")) {
        stickyThead.apply([table_node]);
      }
    }
  }

  render() {
    const { props } = this;
    const { organization_data } = props;
    const organization: any = organization_data.items;
    const { reports_header } = organization;
    let table_rows;
    let table_header;
    let table_footer;
    let table_export_class: string = "";
    let tenant_header: string = "";
    if (this.props.csv_export) {
      table_export_class = "table-export";
    }
    if (this.props.loading) {
      return <ComponentLoadingIndicator />;
    } else if (this.props.vertical_headers) {
      table_rows = props.data_columns.map((column, key) => {
        const formatter: any =
          column.formatter ||
          function (cell) {
            return cell;
          };
        return (
          <tr key={key}>
            <td className="text-grayish">{label(column.text, props)}</td>
            <td>
              {formatter(
                props.component_data[column.dataField],
                props.component_data
              )}
            </td>
          </tr>
        );
      });
    } else {
      const parent_table_header: any = props.headers.map(
        (header, parentKey) => {
          const header_columns: any = header.map((column, key) => {
            const classes: any = column.classes || "";
            const formatter: any =
              column.formatter ||
              function (headerColumn, row) {
                return headerColumn.label;
              };
            return (
              <th
                colSpan={column.colSpan}
                key={key}
                className={`${classes} text-grayish table-white`}
              >
                {column.preTranslated
                  ? formatter(column, props.component_data[0] || {}, props)
                  : label(
                      formatter(column, props.component_data[0] || {}, props),
                      props
                    )}
              </th>
            );
          });
          return <tr key={parentKey}>{header_columns}</tr>;
        }
      );
      table_header = props.data_columns.map((column, key) => (
        <th key={key} className="text-grayish table-white">
          {label(column.text, props)}
        </th>
      ));
      if (objGetField(this.props, "display_tenant_header", true)) {
        tenant_header = (
          <table className="table table-hover table-bordered">
            <tr>
              <th
                colSpan={props.data_columns.length}
                className="text-center text-grayish"
                style={{ whiteSpace: "pre-wrap" }}
              >
                <div>
                  <img
                    src={organization.logo}
                    width={70}
                    height={70}
                    alt="logo"
                  />
                </div>
                {reports_header}
              </th>
            </tr>
          </table>
        );
      }
      table_header = (
        <thead>
          {parent_table_header}
          <tr>{table_header}</tr>
        </thead>
      );
      const totals_list: null[] = [];
      table_rows = props.component_data.map((row, row_key) => {
        const columns: any = props.data_columns.map((column, column_key) => {
          const formatter: any =
            column.formatter ||
            function (cell) {
              return cell;
            };
          const dataProcessor: any =
            column.dataProcessor ||
            function (cell) {
              return cell;
            };
          const cell_data: any = dataProcessor(row[column.dataField]);
          if (column.totals) {
            const current_total: any = totals_list[column_key] || 0;
            totals_list[column_key] =
              current_total + (parseFloat(cell_data) || 0);
          } else {
            totals_list[column_key] = "";
          }
          return <td key={column_key}>{formatter(cell_data, row)}</td>;
        });
        return <tr key={row_key}>{columns}</tr>;
      });
      const totals_columns: any = totals_list.map((column, key) => {
        const formatter: any =
          props.data_columns[key].formatter ||
          function (cell) {
            return cell;
          };
        return (
          <td key={key} className="font-weight-bold text-grayish">
            {formatter(column, {})}
          </td>
        );
      });
      if (this.props.totals) {
        table_footer = (
          <tfoot>
            <tr>{totals_columns}</tr>
          </tfoot>
        );
      }
    }
    let refresh_button: string = "";
    let print_button: string = "";
    if (this.props.allow_refresh) {
      const handleRefresh: any = this.props.handleRefresh || function () {};
      refresh_button = (
        <button
          className="btn btn-outline-primary my-2"
          onClick={handleRefresh}
        >
          <BootstrapIcon icon="arrow-clockwise" /> {label("Refresh", props)}
        </button>
      );
    }
    if (this.props.allow_print) {
      print_button = (
        <ReactToPrint
          trigger={() => (
            <button className="btn btn-outline-primary my-2 mr-2" type="button">
              {label("Print", props)}{" "}
              <BootstrapIcon icon="printer" classes="text-primary" />
            </button>
          )}
          content={() => this.print_table}
        />
      );
    }
    return (
      <div>
        {print_button}
        {refresh_button}
        <div ref={(print_table) => (this.print_table = print_table)}>
          {tenant_header}
          <table
            className={`table table-hover table-bordered ${table_export_class}`}
            ref={this.onRefChange}
          >
            {table_header}
            <tbody>{table_rows}</tbody>
            {table_footer}
          </table>
        </div>
      </div>
    );
  }
}

BasicDataTable.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  organization_data: PropTypes.instanceOf(Object).isRequired,
  system_labels_data: PropTypes.instanceOf(Object).isRequired,
  system_labels_key_dict_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const organization_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "organization_url"
  );
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    organization_data,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(BasicDataTable));
