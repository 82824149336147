import React, { Component } from "react";

import { MEMBERS_ENDPOINT } from "../../constants/Constants";
import PropTypes from "prop-types";
import { REGISTER_MEMBER_DETAILS_PATH } from "../../constants/ClientPaths";
import { VIEW_MEMBERS_COLUMNS } from "../../components/DataTableColumns";
import { VIEW_MEMBERS_FILTERS } from "../../components/DataTableFilterFields";
import ViewDataTable from "../../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class ViewMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table_node: null,
    };
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  render() {
    return (
      <ViewDataTable
        columns={VIEW_MEMBERS_COLUMNS}
        csv_export
        allow_refresh
        creatable
        editable
        edit_url={REGISTER_MEMBER_DETAILS_PATH}
        data_source_url={MEMBERS_ENDPOINT}
        table_filters={VIEW_MEMBERS_FILTERS}
        handleSetTableNode={this.handleSetTableNode}
        data_source_url_name="paginated_members_url"
      />
    );
  }
}

ViewMembers.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ViewMembers));
