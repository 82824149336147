import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import ModalHOC from "../../components/ModalHOC";
import MultipleFormFields from "../../components/MultipleFormFields";
import {
  CHART_OF_ACCOUNTS_ENDPOINT,
  OBJECT_STORAGE_ENDPOINT,
  OBJECT_STORAGE_MEDIA_BUCKET,
  ORGANIZATION_BRANCHES_ENDPOINT,
} from "../../constants/Constants";
import {
  fetchUrlData,
  getSelect2Options,
  getUrlData,
} from "../../utils/componentActions";

class RegisterChartOfAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chart_of_account: {},
      chart_of_accounts_upload: {},
      enable_additional_information: false,
    };
  }

  componentDidMount() {
    const account_id: any = this.props.match.params.id;
    if (account_id) {
      const chart_of_account_url: string = `${CHART_OF_ACCOUNTS_ENDPOINT}${account_id}/?all=true`;
      fetchUrlData("chart_of_account_url", chart_of_account_url, this.props);
    }
    fetchUrlData(
      "organization_branches_url",
      ORGANIZATION_BRANCHES_ENDPOINT,
      this.props
    );
    this.getChartOfAccountGroups(1);
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  getChartOfAccountGroups = (selected_account_type) => {
    const url: string = `${CHART_OF_ACCOUNTS_ENDPOINT}?account_usage=gl_group&account_type=${selected_account_type}`;
    fetchUrlData("chart_of_account_groups_url", url, this.props);
  };

  toggleAdditionalFields = (e) => {
    const { checked } = e.target;
    this.setState({
      enable_additional_information: checked,
    });
  };

  render() {
    const { props } = this;
    const {
      chart_of_account_data,
      organization_branches_data,
      chart_of_account_groups_data,
    } = props;
    const { chart_of_account, enable_additional_information } = this.state;
    const organization_branches: any = organization_branches_data.items;
    const organization_branch_options: any = getSelect2Options(
      organization_branches,
      "id",
      "name"
    );
    const chart_of_account_groups: any = chart_of_account_groups_data.items;
    const chart_of_account_groups_options: any = getSelect2Options(
      chart_of_account_groups,
      "id",
      "account_name"
    );
    const accounts_template_url: string = `${OBJECT_STORAGE_ENDPOINT}/${OBJECT_STORAGE_MEDIA_BUCKET}/accounts.csv`;
    const additional_fields_attributes = [
      {
        field_type: "text_input",
        field_name: "additional_metadata_field_name",
        field_label: "Field name",
        div_cols: 3,
      },
      {
        field_type: "text_input",
        field_name: "additional_metadata_field_value",
        field_label: "Field value",
        div_cols: 3,
      },
      {
        field_type: "checkbox",
        field_name: "additional_metadata_field_use_as_payee",
        field_label: "Use as payee?",
        div_cols: 1,
      },
    ];
    let additional_metadata = [];
    if (Array.isArray(chart_of_account.additional_metadata)) {
      additional_metadata = chart_of_account.additional_metadata;
    }
    additional_metadata.forEach((metadata, metadata_index) => {
      chart_of_account[`additional_metadata_field_name_${metadata_index}`] =
        metadata.field_name;
      chart_of_account[`additional_metadata_field_value_${metadata_index}`] =
        metadata.field_value;
      chart_of_account[
        `additional_metadata_field_use_as_payee_${metadata_index}`
      ] = metadata.use_as_payee;
    });
    if (additional_metadata.length > 0) {
      chart_of_account.enable_additional_information = true;
    }
    let additional_fields = <div />;
    if (enable_additional_information || additional_metadata.length > 0) {
      additional_fields = (
        <MultipleFormFields
          key={13}
          fields_attributes={additional_fields_attributes}
          initial_no_of_rows={additional_metadata.length || 1}
          data_object={chart_of_account}
        />
      );
    }
    const chart_of_accounts_upload_form_items: any = [
      <FormFields
        key={1}
        field_type="file"
        field_label="CSV file"
        props={props}
        field_name="accounts_csv"
        wrapper_div_classes="col"
        data_object={{}}
        required
        template_url={accounts_template_url}
      />,
    ];
    const chart_of_accounts_upload_form_classes: string = "row g-3";
    const chart_of_accounts_upload_form: any = (
      <CreateEditForm
        loading={false}
        form_items={chart_of_accounts_upload_form_items}
        form_classes={chart_of_accounts_upload_form_classes}
        action_object={{}}
        setState={this.updateState}
        save_button_label="Upload"
        action_object_name="chart_of_accounts_upload"
        state={this.state}
        action_object_endpoint={CHART_OF_ACCOUNTS_ENDPOINT}
        post_form
        object_display_name="chart of accounts upload"
        content_type="multipart/form-data; boundary=----"
      />
    );
    const form_items: any = [
      <FormFields
        key={1}
        field_type="select"
        field_label="Account type"
        props={props}
        field_name="account_type"
        wrapper_div_classes="col-4"
        data_object={chart_of_account}
        onChange={(selected) =>
          this.getChartOfAccountGroups(selected.target.value)
        }
        required
      >
        <option value={1}>Assets</option>
        <option value={2}>Liabilities</option>
        <option value={3}>Capital</option>
        <option value={4}>Income</option>
        <option value={5}>Expenses</option>
      </FormFields>,
      <FormFields
        key={2}
        field_type="select"
        field_label="Account usage"
        props={props}
        field_name="account_usage"
        wrapper_div_classes="col-4"
        data_object={chart_of_account}
        required
      >
        <option value="gl_account">GL account</option>
        <option value="gl_group">GL group</option>
      </FormFields>,
      <FormFields
        key={3}
        field_type="select2"
        field_label="GL group"
        props={props}
        field_name="gl_group"
        wrapper_div_classes="col-4"
        select2_options={chart_of_account_groups_options}
        data_object={chart_of_account}
        isClearable
      />,
      <FormFields
        key={4}
        field_type="text_input"
        field_label="Name"
        props={props}
        field_name="account_name"
        wrapper_div_classes="col-4"
        data_object={chart_of_account}
        required
      />,
      <FormFields
        key={5}
        field_type="text_input"
        field_label="Account code"
        props={props}
        field_name="gl_code"
        wrapper_div_classes="col-4"
        data_object={chart_of_account}
        required
      />,
      <FormFields
        key={6}
        field_type="select"
        field_label="Manual entries allowed?"
        props={props}
        field_name="manual_entries_allowed"
        wrapper_div_classes="col-4"
        data_object={chart_of_account}
      >
        <option value="yes">Yes</option>
        <option value="no">No</option>
      </FormFields>,
      <FormFields
        key={7}
        field_type="select"
        field_label="Overdraft allowed?"
        props={props}
        field_name="overdraft_allowed"
        wrapper_div_classes="col-4"
        data_object={chart_of_account}
      >
        <option value>Yes</option>
        <option value={false}>No</option>
      </FormFields>,
      <FormFields
        key={8}
        field_type="select"
        field_label="Appears on group performance sheet?"
        props={props}
        field_name="appears_on_group_performance"
        wrapper_div_classes="col-4"
        data_object={chart_of_account}
      >
        <option value>Yes</option>
        <option value={false}>No</option>
      </FormFields>,
      <FormFields
        key={9}
        field_type="textarea"
        field_label="Description"
        props={props}
        field_name="description"
        wrapper_div_classes="col-4"
        data_object={chart_of_account}
      />,
      <FormFields
        key={10}
        field_type="select2"
        field_label="Branch"
        props={props}
        field_name="branch"
        wrapper_div_classes="col-4"
        select2_options={organization_branch_options}
        isClearable
        data_object={chart_of_account}
      />,
      <FormFields
        key={11}
        field_type="select"
        field_label="Status"
        props={props}
        field_name="status"
        wrapper_div_classes="col-4"
        data_object={chart_of_account}
      >
        <option value={1}>Active</option>
        <option value={2}>Inactive</option>
      </FormFields>,
      <FormFields
        key={12}
        field_type="checkbox"
        checkbox_field_label="Additional information?"
        props={props}
        field_name="enable_additional_information"
        wrapper_div_classes="col-4"
        data_object={chart_of_account}
        onChange={(e) => this.toggleAdditionalFields(e)}
      />,
      additional_fields,
    ];
    const form_classes: string = "row g3";
    return (
      <div>
        <div className="row mb-3">
          <ModalHOC
            button_icon="upload"
            button_label="Upload chart of accounts"
            button_classes="btn btn-outline-primary"
            modal_title="Upload chart of accounts"
          >
            {chart_of_accounts_upload_form}
          </ModalHOC>
        </div>
        <CreateEditForm
          loading={chart_of_account_data.isFetching}
          form_items={form_items}
          form_classes={form_classes}
          action_object={chart_of_account_data.items}
          setState={this.updateState}
          action_object_name="chart_of_account"
          state={this.state}
          action_object_endpoint={CHART_OF_ACCOUNTS_ENDPOINT}
          action_object_get_params="all=true"
          form_id="form-account-details"
          object_display_name="chart of account"
        />
      </div>
    );
  }
}

RegisterChartOfAccount.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  chart_of_account_data: PropTypes.instanceOf(Object).isRequired,
  organization_branches_data: PropTypes.instanceOf(Object).isRequired,
  chart_of_account_groups_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const chart_of_account_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "chart_of_account_url"
  );
  const organization_branches_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "organization_branches_url"
  );
  const chart_of_account_groups_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "chart_of_account_groups_url"
  );

  return {
    sessionVariables,
    chart_of_account_data,
    organization_branches_data,
    chart_of_account_groups_data,
  };
}

export default connect(mapStateToProps)(withRouter(RegisterChartOfAccount));
