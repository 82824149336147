import React, { Component } from "react";

import { BANKING_RECONCILIATION_COLUMNS } from "../../components/DataTableColumns";
import { BANKING_RECONCILIATION_FILTERS } from "../../components/DataTableFilterFields";
import DataTableActionForm from "../../components/DataTableActionForm";
import { GENERAL_LEDGER_ENDPOINT } from "../../constants/Constants";
import PropTypes from "prop-types";
import ViewDataTable from "../../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ReconcileBankingTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_row_obj: {},
    };
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  handleTableRowSelect = (row) => {
    this.setState({
      selected_row_obj: row,
    });
  };

  render() {
    const actions: any = [
      {
        name: "reconcile",
        btn_classes: "btn btn-primary",
        noun: "reconciling",
        icon: "file-earmark-check",
        target: "transaction",
        plural: "transactions",
        form_items: [],
        multiple_form_items: [],
        extra_form_data: [{ name: "reconcile", value: true }],
      },
    ];
    const extra_get_params: string =
      "ordering=-date&banking_reconciliation=true";
    const data_source_url: string = `${GENERAL_LEDGER_ENDPOINT}?limit=5&offset=0&${extra_get_params}`;
    return (
      <DataTableActionForm
        actions={actions}
        table_node={this.state.table_node}
        ids_post_field="transactions"
        datatable_action_endpoint={GENERAL_LEDGER_ENDPOINT}
        request_method="POST"
        datatable_source_url={data_source_url}
        datatable_source_url_name="paginated_unreconciled_banking_ledgers"
      >
        <ViewDataTable
          handleSetTableNode={this.handleSetTableNode}
          table_filters={BANKING_RECONCILIATION_FILTERS}
          handleTableRowSelect={this.handleTableRowSelect}
          allow_refresh
          select_mode="checkbox"
          columns={BANKING_RECONCILIATION_COLUMNS}
          extra_get_params={extra_get_params}
          data_source_url={GENERAL_LEDGER_ENDPOINT}
          data_source_url_name="paginated_unreconciled_banking_ledgers"
          wait_for_props
        />
      </DataTableActionForm>
    );
  }
}

ReconcileBankingTransactions.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(
  withRouter(ReconcileBankingTransactions)
);
