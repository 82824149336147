import {
  RECONCILE_BANKING_TRANSACTIONS_PATH,
  VIEW_RECONCILED_TRANSACTIONS_PATH,
} from "../../constants/ClientPaths";
import React, { Component } from "react";

import VIewAddPortlet from "../../components/VIewAddPortlet";
import { withRouter } from "react-router-dom";

class BankingReconciliation extends Component {
  render() {
    const pills: any = [
      {
        label: "Reconcile transactions",
        path: RECONCILE_BANKING_TRANSACTIONS_PATH,
      },
      {
        label: "View reconciled transactions",
        path: VIEW_RECONCILED_TRANSACTIONS_PATH,
      },
    ];
    return (
      <VIewAddPortlet header_label="Banking reconciliation" pills={pills}>
        {this.props.children}
      </VIewAddPortlet>
    );
  }
}

export default withRouter(BankingReconciliation);
