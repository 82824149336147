import {
  PERMISSIONS_APPS_ENDPOINT,
  PERMISSIONS_ENDPOINT,
  USERS_ENDPOINT,
  USER_GROUPS_ENDPOINT,
} from "../../constants/Constants";
import React, { Component } from "react";
import {
  fetchUrlData,
  getSelect2Options,
  getUrlData,
  label,
} from "../../utils/componentActions";

import DataTableActionForm from "../../components/DataTableActionForm";
import FormFields from "../../components/FormFields";
import { PERMISSIONS_COLUMNS } from "../../components/DataTableColumns";
import PropTypes from "prop-types";
import { USER_DETAILS_PATH } from "../../constants/ClientPaths";
import ViewDataTable from "../../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ManagePermissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 5,
      permissions: {},
      extra_get_params: null,
      user_or_group: "user",
      selected_user_or_group: null,
    };
  }

  componentDidMount() {
    const users_url: string = `${USERS_ENDPOINT}?fields=id,username,email,branch,phone_number,is_admin`;
    fetchUrlData("users_url", users_url, this.props);
    fetchUrlData("user_groups_url", USER_GROUPS_ENDPOINT, this.props);
    fetchUrlData("permissions_apps_url", PERMISSIONS_APPS_ENDPOINT, this.props);
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  updateState = (state_object) => {
    this.setState(state_object);
  };

  handleUserChange(select_obj) {
    const user_group_permissions_url: string = `${USERS_ENDPOINT}${select_obj.value}/?perm=true`;
    fetchUrlData(
      "user_group_permissions_url",
      user_group_permissions_url,
      this.props,
      true
    );
    this.setState({
      selected_user_or_group: select_obj.value,
    });
  }

  handleGroupChange(select_obj) {
    const user_group_permissions_url: string = `${USER_GROUPS_ENDPOINT}${select_obj.value}/?perm=true`;
    fetchUrlData(
      "user_group_permissions_url",
      user_group_permissions_url,
      this.props,
      true
    );
    this.setState({
      selected_user_or_group: select_obj.value,
    });
  }

  handleModuleChange(select_obj) {
    let extra_get_params: object = null;
    if (select_obj) {
      extra_get_params = `app=${select_obj.value}`;
    }
    this.setState({
      extra_get_params,
    });
  }

  toggleUserGroup(toggle_to) {
    this.setState({
      user_or_group: toggle_to,
    });
  }

  render() {
    const { props } = this;
    const {
      users_data,
      user_permissions_data,
      permissions_apps_data,
      user_groups_data,
    } = props;
    const users: any = users_data.items;
    const user_groups: any = user_groups_data.items;
    const user_permissions: any = user_permissions_data.items;
    const permissions_apps: any = permissions_apps_data.items;
    const permissions_app_options: any = getSelect2Options(
      permissions_apps,
      "app",
      "friendly_name"
    );
    const user_options: any = getSelect2Options(users, "id", "username");
    const user_group_options: any = getSelect2Options(
      user_groups,
      "id",
      "name"
    );
    const data_source_url: any = PERMISSIONS_ENDPOINT;
    const data_source_url_name: string = "paginated_permissions_url";
    const { extra_get_params } = this.state;
    const { permissions } = this.state;
    let toggle_to: string = "group";
    const { user_or_group } = this.state;
    let datatable_action_endpoint: any = USERS_ENDPOINT;
    let user_group_permissions_url: string = `${USERS_ENDPOINT}${permissions[user_or_group]}/?perm=true`;
    if (this.state.user_or_group === "group") {
      toggle_to = "user";
      datatable_action_endpoint = USER_GROUPS_ENDPOINT;
      user_group_permissions_url = `${USER_GROUPS_ENDPOINT}${permissions[user_or_group]}/?perm=true`;
    }
    const toggle_to_group_append: any = (
      <div className="mb-3">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="toggle-assign"
          onChange={(e) => this.toggleUserGroup(toggle_to)}
        />
        <label className="form-check-label ml-1" htmlFor="toggle-assign">
          {label("Assign to group", props)}
        </label>
      </div>
    );
    let selected_permissions: null[] = [];
    if (this.state.selected_user_or_group) {
      selected_permissions = user_permissions.map(
        (permission) => permission[0]
      );
    }
    let user_group_select_field: any = (
      <FormFields
        key={1}
        field_type="select2"
        field_label="User"
        props={props}
        field_name="user"
        wrapper_div_classes="col-4"
        update_payload
        updateState={this.updateState}
        data_object_name="permissions"
        bottom_append={toggle_to_group_append}
        onChange={(selected) => this.handleUserChange(selected)}
        data_object={permissions}
        select2_options={user_options}
      />
    );
    if (user_or_group === "group") {
      user_group_select_field = (
        <FormFields
          key={1}
          field_type="select2"
          field_label="Group"
          props={props}
          field_name="group"
          wrapper_div_classes="col-4"
          update_payload
          updateState={this.updateState}
          data_object_name="permissions"
          bottom_append={toggle_to_group_append}
          onChange={(selected) => this.handleGroupChange(selected)}
          data_object={permissions}
          select2_options={user_group_options}
        />
      );
    }
    const form_items: any = [
      user_group_select_field,
      <FormFields
        key={2}
        field_type="select2"
        field_label="Filter by module"
        props={props}
        field_name="module"
        wrapper_div_classes="col-4"
        updateState={this.updateState}
        isClearable
        onChange={(selected) => this.handleModuleChange(selected)}
        data_object={permissions}
        select2_options={permissions_app_options}
      />,
    ];
    const actions: any = [
      {
        name: "assign permissions",
        btn_classes: "btn btn-green text-white",
        noun: "assign",
        icon: "key",
        target: "user permission",
        plural: "user permissions",
        form_items,
        multiple_form_items: form_items,
        extra_form_data: [{ name: "perm", value: true }],
      },
    ];
    return (
      <DataTableActionForm
        actions={actions}
        table_node={this.state.table_node}
        ids_post_field="permissions"
        datatable_action_endpoint={datatable_action_endpoint}
        request_method="PATCH"
        allow_empty_select
        confirm_action={false}
        clear_row_selection={false}
        fetch_data_urls={[
          {
            url_name: "user_group_permissions_url",
            url: user_group_permissions_url,
          },
        ]}
        patch_action_id={permissions[user_or_group]}
      >
        <ViewDataTable
          columns={PERMISSIONS_COLUMNS}
          extra_get_params={extra_get_params}
          data_source_url={data_source_url}
          data_source_url_name={data_source_url_name}
          edit_url={USER_DETAILS_PATH}
          selected_rows={selected_permissions}
          handleSetTableNode={this.handleSetTableNode}
          select_mode="checkbox"
        />
      </DataTableActionForm>
    );
  }
}

ManagePermissions.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  users_data: PropTypes.instanceOf(Object).isRequired,
  user_permissions_data: PropTypes.instanceOf(Object).isRequired,
  permissions_apps_data: PropTypes.instanceOf(Object).isRequired,
  user_groups_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const users_data: any = getUrlData(dataByUrl, sessionVariables, "users_url");
  const user_groups_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "user_groups_url"
  );
  const user_permissions_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "user_group_permissions_url"
  );
  const permissions_apps_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "permissions_apps_url"
  );

  return {
    sessionVariables,
    users_data,
    user_permissions_data,
    permissions_apps_data,
    user_groups_data,
  };
}

export default connect(mapStateToProps)(withRouter(ManagePermissions));
