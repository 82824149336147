import {
  ORGANIZATION_BRANCHES_ENDPOINT,
  USERS_ENDPOINT,
  USER_GROUPS_ENDPOINT,
} from "../../constants/Constants";
import React, { Component } from "react";
import {
  fetchUrlData,
  getSelect2Options,
  getUrlData,
} from "../../utils/componentActions";

import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
    };
  }

  componentDidMount() {
    const user_id: any = this.props.match.params.id;
    if (user_id) {
      const user_url: string = `${USERS_ENDPOINT}${user_id}/`;
      fetchUrlData("user_url", user_url, this.props);
    }
    fetchUrlData("current_user_url", `${USERS_ENDPOINT}i/`, this.props);
    fetchUrlData(
      "organization_branches_url",
      ORGANIZATION_BRANCHES_ENDPOINT,
      this.props
    );
    fetchUrlData("user_groups_url", USER_GROUPS_ENDPOINT, this.props);
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const {
      user_data,
      current_user_data,
      organization_branches_data,
      user_groups_data,
    } = props;
    const form_classes: string = "row g-3";
    const { user } = this.state;
    const current_user: any = current_user_data.items;
    const user_groups: any = user_groups_data.items;
    const organization_branches: any = organization_branches_data.items;
    const user_id: any = this.props.match.params.id;
    const organization_branches_options: any = getSelect2Options(
      organization_branches,
      "id",
      "name"
    );
    const user_groups_options: any = getSelect2Options(
      user_groups,
      "id",
      "name"
    );
    let is_admin_field: string = "";
    let is_active_field: string = "";
    const overwrite_form_data: any = [
      { name: "groups", value: user.groups || [] },
    ];
    if (
      ["True", true, "true"].includes(current_user.is_admin) &&
      current_user.id !== user.id
    ) {
      is_admin_field = [
        <FormFields
          key={6}
          field_type="select"
          field_label="Is admin?"
          props={props}
          field_name="is_admin"
          wrapper_div_classes="col-4"
          data_object={user}
        >
          <option value="False">No</option>
          <option value="True">Yes</option>
        </FormFields>,
      ];
    }
    if (current_user.id !== user.id) {
      is_active_field = (
        <FormFields
          key={7}
          field_type="select"
          field_label="Is active?"
          props={props}
          field_name="is_active"
          wrapper_div_classes="col-4"
          data_object={user}
        >
          <option value>Yes</option>
          <option value={false}>No</option>
        </FormFields>
      );
    }
    let form_items: any = [
      <FormFields
        key={1}
        field_type="text_input"
        field_label="Username"
        props={props}
        field_name="username"
        wrapper_div_classes="col-4"
        data_object={user}
        required
      />,
      <FormFields
        key={2}
        field_type="email"
        field_label="Email"
        props={props}
        field_name="email"
        wrapper_div_classes="col-4"
        data_object={user}
        required
      />,
      <FormFields
        key={3}
        field_type="mobile_no"
        field_label="Phone number"
        props={props}
        field_name="phone_number"
        wrapper_div_classes="col-4"
        data_object={user}
        required
      />,
      <FormFields
        key={4}
        field_type="password"
        field_label="Password"
        props={props}
        field_name="password"
        wrapper_div_classes="col-4"
        data_object={user}
        required
      />,
      <FormFields
        key={5}
        field_type="password"
        field_label="Confirm password"
        props={props}
        field_name="confirm_password"
        wrapper_div_classes="col-4"
        data_object={user}
        required
      />,
      is_active_field,
      is_admin_field,
      <FormFields
        key={8}
        field_type="select2"
        field_label="Branch"
        props={props}
        field_name="branch"
        wrapper_div_classes="col-4"
        data_object_field="branch_id"
        isClearable
        update_payload
        updateState={this.updateState}
        data_object_name="user"
        data_object={user}
        select2_options={organization_branches_options}
      />,
    ];
    if (user_id) {
      form_items = [
        <FormFields
          key={1}
          field_type="text_input"
          field_label="Username"
          props={props}
          field_name="username"
          wrapper_div_classes="col-4"
          data_object={user}
          required
        />,
        <FormFields
          key={2}
          field_type="email"
          field_label="Email"
          props={props}
          field_name="email"
          wrapper_div_classes="col-4"
          data_object={user}
          required
        />,
        <FormFields
          key={3}
          field_type="mobile_no"
          field_label="Phone number"
          props={props}
          field_name="phone_number"
          wrapper_div_classes="col-4"
          data_object={user}
          required
        />,
        <FormFields
          key={4}
          field_type="text_input"
          field_label="First name"
          props={props}
          field_name="first_name"
          wrapper_div_classes="col-4"
          data_object={user}
        />,
        <FormFields
          key={5}
          field_type="text_input"
          field_label="Last name"
          props={props}
          field_name="last_name"
          wrapper_div_classes="col-4"
          data_object={user}
        />,
        is_active_field,
        is_admin_field,
        <FormFields
          key={10}
          field_type="select"
          field_label="User type"
          props={props}
          field_name="user_type"
          wrapper_div_classes="col-4"
          data_object={user}
        >
          <option value="staff">Staff</option>
          <option value="jisort">Support</option>
          <option value="api_initiator">API</option>
        </FormFields>,
        <FormFields
          key={8}
          field_type="select2"
          field_label="Branch"
          props={props}
          field_name="branch"
          wrapper_div_classes="col-4"
          data_object_field="branch_id"
          isClearable
          update_payload
          updateState={this.updateState}
          data_object_name="user"
          data_object={user}
          select2_options={organization_branches_options}
        />,
        <FormFields
          key={9}
          field_type="select2_multi"
          field_label="Groups"
          props={props}
          field_name="groups"
          wrapper_div_classes="col-4"
          isClearable
          update_payload
          updateState={this.updateState}
          data_object_name="user"
          data_object={user}
          select2_options={user_groups_options}
        />,
      ];
    }
    return (
      <CreateEditForm
        loading={user_data.isFetching}
        form_items={form_items}
        form_classes={form_classes}
        action_object={user_data.items}
        setState={this.updateState}
        action_object_name="user"
        state={this.state}
        action_object_endpoint={USERS_ENDPOINT}
        update_request_method="PATCH"
        overwrite_form_data={overwrite_form_data}
        fetch_data_urls={[
          { url_name: "current_user_url", url: `${USERS_ENDPOINT}i/` },
        ]}
        object_display_name="user"
      />
    );
  }
}

UserDetails.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  user_data: PropTypes.instanceOf(Object).isRequired,
  current_user_data: PropTypes.instanceOf(Object).isRequired,
  organization_branches_data: PropTypes.instanceOf(Object).isRequired,
  user_groups_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const user_data: any = getUrlData(dataByUrl, sessionVariables, "user_url");
  const current_user_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "current_user_url"
  );
  const organization_branches_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "organization_branches_url"
  );
  const user_groups_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "user_groups_url"
  );

  return {
    sessionVariables,
    user_data,
    current_user_data,
    organization_branches_data,
    user_groups_data,
  };
}

export default connect(mapStateToProps)(withRouter(UserDetails));
