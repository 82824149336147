import React, { Component } from "react";
import {
  extractResponseError,
  fetchUrlData,
  formDataToJSON,
  getUrlData,
  invalidateSessionData,
  label,
  pushHistory,
} from "../utils/componentActions";
import { postAPIRequest, postFormAPIRequest } from "../utils/APIRequests";

import $ from "jquery";
import { API_ENDPOINT } from "../constants/Constants";
import BootstrapIcon from "./BootstrapIcon";
import ComponentLoadingIndicator from "./ComponentLoadingIndicator";
import FormActivityIndicator from "./FormActivityIndicator";
import FormFeedbackMessage from "./FormFeedbackMessage";
import { Modal } from "bootstrap";
import PropTypes from "prop-types";
import _ from "lodash";
import { capitalize } from "underscore.string";
import { connect } from "react-redux";
import merge from "lodash/merge";
import { withRouter } from "react-router-dom";

class CreateEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activity: false,
      feedback_type: "primary",
      feedback_message: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const previous_action_object: any =
      this.props.state[this.props.action_object_name];
    const { action_object } = nextProps;
    const { base_path } = this.props;
    const { obj_id_field } = this.props;
    let id_path_param: any = this.props.match.params.id;
    const action_object_initial_state: any =
      this.props.action_object_initial_state || {};
    if (
      this.props.push_id_to_path &&
      base_path &&
      Object.keys(action_object).length
    ) {
      const current_path: any = this.props.history.location.pathname;
      const new_path: any = base_path + action_object[obj_id_field];
      if (current_path !== new_path && action_object[obj_id_field]) {
        id_path_param = action_object[obj_id_field];
        pushHistory(new_path, this.props);
      }
    }
    if (
      (Object.keys(previous_action_object).length === 0 ||
        _.isEqual(action_object_initial_state, previous_action_object)) &&
      Object.keys(action_object).length > 0 &&
      id_path_param
    ) {
      this.props.setState({
        [this.props.action_object_name]: merge(
          previous_action_object,
          action_object
        ),
      });
    }
  }

  handleDetailsSubmit(e) {
    e.preventDefault();
    this.setState({
      activity: true,
    });
    if (this.props.beforeSubmit) {
      this.props.beforeSubmit();
    }
    const form_id: any = this.props.form_id || "form-object-action";
    const form = $(`form#${form_id}`);
    const formData: any = new FormData(form[0]);
    const checkboxes = form.find("input[type=checkbox]");
    $.each(checkboxes, (key, val) => {
      formData.append($(val).attr("name"), $(val).is(":checked"));
    });
    let payload: any = this.props.payload || {};
    if (this.props.table_node) {
      const selected_rows: any =
        this.props.table_node.selectionContext.selected;
      payload[this.props.ids_post_field] = selected_rows.join(",");
      if (selected_rows.length === 0) {
        this.setState({
          activity: false,
          feedback_message: `Select ${this.props.action_target} to ${this.props.action_name}`,
          feedback_type: "danger",
        });
        return;
      }
    }
    const extra_form_data: any = this.props.extra_form_data || [];
    const overwrite_form_data: any = this.props.overwrite_form_data || [];
    extra_form_data.forEach((data) => {
      if (data.type === "array") {
        data.value.forEach((el) => {
          formData.append(data.name, el);
        });
      } else {
        formData.append(data.name, data.value);
      }
    });
    overwrite_form_data.forEach((data) => {
      formData.set(data.name, data.value);
    });
    payload = { ...payload };
    payload = this.props.post_form
      ? formData
      : formDataToJSON(formData, payload);
    if (this.props.field_array) {
      const { field_array } = this.props;
      const field_keys: any = this.props.field_array_keys;
      const field_array_length: any = field_array.length;
      field_array.forEach((field, index) => {
        const data_payload: any = { ...payload };
        if (field !== null) {
          field_keys.forEach((key) => {
            data_payload[key] = field[key];
          });
        }
        this.postData(form_id, data_payload, index + 1 === field_array_length);
      });
    } else {
      this.postData(form_id, payload, true);
    }
  }

  postData(form_id, payload, done) {
    const modal_id: any = this.props.modal_id || null;
    const { action_object_endpoint, success_callback_data } = this.props;
    let action_object_url: any = API_ENDPOINT + action_object_endpoint;
    const invalidate_object_endpoint: any =
      this.props.invalidate_object_endpoint || action_object_endpoint;
    const { match, action_object_id } = this.props;
    const _action_object_id: any = match.params.id || action_object_id;
    const { action_object_get_params } = this.props;
    let request_method: any = this.props.request_method || "POST";
    const content_type: any = this.props.content_type || "application/json";
    const fetch_data_urls: any = this.props.fetch_data_urls || [];
    const successCallback: any = this.props.successCallback || function () {};
    let postAPIRequestHandler: any = postAPIRequest;
    if (this.props.post_form) {
      postAPIRequestHandler = postFormAPIRequest;
    }
    if (_action_object_id) {
      action_object_url = `${action_object_url}${_action_object_id}/`;
      request_method = this.props.update_request_method || "PUT";
    }
    if (action_object_get_params) {
      action_object_url = `${action_object_url}?${action_object_get_params}`;
    }
    postAPIRequestHandler(
      action_object_url,
      (results) => {
        if (done) {
          this.setState(
            {
              activity: false,
              feedback_message: `${capitalize(
                this.props.object_display_name,
                true
              )} details successfully saved`,
              feedback_type: "success",
            },
            async () => {
              if (modal_id) {
                const formModal: any = new Modal.getInstance(
                  document.getElementById(modal_id)
                );
                await formModal.hide();
              }
              await (this.props.datatable_source_url_name &&
              this.props.datatable_source_url
                ? fetchUrlData(
                    this.props.datatable_source_url_name,
                    this.props.datatable_source_url,
                    this.props,
                    true
                  )
                : invalidateSessionData(this, invalidate_object_endpoint));
              if (success_callback_data === "payload") {
                await successCallback(payload);
              } else {
                await successCallback(results);
              }
              fetch_data_urls.forEach((fetch_data_url) => {
                fetchUrlData(
                  fetch_data_url.url_name,
                  fetch_data_url.url,
                  this.props,
                  true
                );
              });
              const formObject: any = $(`form#${form_id}`)[0];
              if (this.props.resetState && !action_object_id) {
                this.props.resetState();
              } else if (!action_object_id && !modal_id && formObject) {
                formObject.reset();
              }
              if (!modal_id) {
                $("html, body").animate({ scrollTop: 0 }, 200);
              }
            }
          );
        }
      },
      (results) => {
        if (done) {
          const feedback_message: any = extractResponseError(results);
          this.setState({
            activity: false,
            feedback_message,
            feedback_type: "danger",
          });
        }
      },
      payload,
      {
        "Content-Type": content_type,
        Authorization: `Bearer ${localStorage.token}`,
      },
      request_method
    );
  }

  render() {
    const { props, state } = this;
    const {
      loading,
      bottom_append,
      save_button_label,
      submit_button_disabled,
    } = props;
    if (this.props.loading) {
      return <ComponentLoadingIndicator />;
    }
    let form_button: any = (
      <div className="col-12">
        <button
          className="btn btn-green text-white btn-block"
          type="submit"
          disabled={this.props.submit_button_disabled || false}
        >
          <BootstrapIcon icon="cursor" />{" "}
          {label(save_button_label || "Save", this.props)}
        </button>
      </div>
    );
    if (this.state.activity) {
      form_button = <FormActivityIndicator />;
    }
    const feedback_message: any = (
      <FormFeedbackMessage
        feedback_message={
          this.state.feedback_message || this.props.feedback_message
        }
        feedback_type={this.state.feedback_type || this.props.feedback_type}
      />
    );
    const form_id: any = this.props.form_id || "form-object-action";
    return (
      <div>
        {feedback_message}
        <form
          id={form_id}
          className={this.props.form_classes}
          onSubmit={(e) => this.handleDetailsSubmit(e)}
        >
          {this.props.form_items || this.props.children}
          {form_button}
        </form>
        {bottom_append}
      </div>
    );
  }
}

CreateEditForm.defaultProps = {
  action_object_id: "",
  match: {},
  beforeSubmit: () => {},
  bottom_append: <div />,
  success_callback_data: "response",
};

CreateEditForm.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(Object),
  action_object: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  base_path: PropTypes.string,
  obj_id_field: PropTypes.string,
  push_id_to_path: PropTypes.bool,
  action_object_name: PropTypes.string,
  form_id: PropTypes.string,
  payload: PropTypes.instanceOf(Object),
  table_node: PropTypes.instanceOf(Object),
  ids_post_field: PropTypes.string,
  extra_form_data: PropTypes.instanceOf(Array),
  overwrite_form_data: PropTypes.instanceOf(Array),
  post_form: PropTypes.bool,
  action_object_endpoint: PropTypes.string,
  invalidate_object_endpoint: PropTypes.string,
  content_type: PropTypes.string,
  fetch_data_urls: PropTypes.instanceOf(Array),
  update_request_method: PropTypes.string,
  object_display_name: PropTypes.string,
  datatable_source_url_name: PropTypes.string,
  datatable_source_url: PropTypes.string,
  loading: PropTypes.bool,
  bottom_append: PropTypes.instanceOf(Object),
  save_button_label: PropTypes.string,
  form_classes: PropTypes.string,
  form_items: PropTypes.instanceOf(Array),
  successCallback: PropTypes.func,
  success_callback_data: PropTypes.string,
  resetState: PropTypes.func,
  submit_button_disabled: PropTypes.bool,
  feedback_message: PropTypes.string,
  feedback_type: PropTypes.string,
  request_method: PropTypes.string,
  action_object_initial_state: PropTypes.instanceOf(Object),
  action_object_get_params: PropTypes.string,
  action_object_id: PropTypes.string,
  match: PropTypes.instanceOf(Object),
  field_array: PropTypes.instanceOf(Array),
  field_array_keys: PropTypes.instanceOf(Array),
  children: PropTypes.node,
  action_name: PropTypes.string,
  action_target: PropTypes.string,
  modal_id: PropTypes.string,
  beforeSubmit: PropTypes.func,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    dataByUrl,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(CreateEditForm));
