import React, { Component } from "react";

import { APPROVE_CLIENT_PAYMENTS_COLUMNS } from "../components/DataTableColumns";
import { CLIENT_PAYMENTS_ENDPOINT } from "../constants/Constants";
import DataTableActionForm from "../components/DataTableActionForm";
import FormFields from "../components/FormFields";
import PropTypes from "prop-types";
import { VIEW_CLIENT_PAYMENTS_FILTERS } from "../components/DataTableFilterFields";
import ViewDataTable from "../components/ViewDataTable";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class ApproveClientPayments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table_node: null,
      selected_group: null,
    };
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  handleFieldChange(selected, field_name) {
    selected = selected || { value: "" };
    this.setState({
      [field_name]: selected.value,
      [`selected_${field_name}`]: selected,
    });
  }

  render() {
    const { props } = this;
    const extra_get_params: string = "initial=1&status=6&ordering=-date";
    const reject_form_items: any = [
      <FormFields
        key={1}
        field_type="textarea"
        field_label="Rejection reason"
        props={props}
        field_name="rejection_reason"
        wrapper_div_classes="col-4 pb-3"
        data_object={{}}
        required
        autofocus
      />,
    ];
    const actions: any = [
      {
        name: "approve",
        btn_classes: "btn btn-green text-white",
        noun: "approval",
        icon: "file-earmark-check",
        target: "payment",
        plural: "payments",
        form_items: [],
        multiple_form_items: [],
        extra_form_data: [
          { name: "status", value: 9 },
          { name: "bulk", value: true },
        ],
      },
      {
        name: "reject",
        btn_classes: "btn btn-danger ml-2",
        noun: "rejection",
        icon: "x",
        target: "payment",
        plural: "payments",
        form_items: reject_form_items,
        multiple_form_items: reject_form_items,
        extra_form_data: [
          { name: "status", value: 10 },
          { name: "bulk", value: true },
        ],
      },
    ];
    const approve_payments_url: string = `${CLIENT_PAYMENTS_ENDPOINT}?limit=5&offset=0&initial=1&status=6&ordering=-date`;
    return (
      <DataTableActionForm
        actions={actions}
        table_node={this.state.table_node}
        ids_post_field="payments"
        datatable_action_endpoint={CLIENT_PAYMENTS_ENDPOINT}
        request_method="POST"
        datatable_source_url={approve_payments_url}
        datatable_source_url_name="paginated_member_account_payments"
      >
        <ViewDataTable
          key={6}
          table_filters={VIEW_CLIENT_PAYMENTS_FILTERS}
          handleSetTableNode={this.handleSetTableNode}
          allow_refresh
          columns={APPROVE_CLIENT_PAYMENTS_COLUMNS}
          data_source_url={CLIENT_PAYMENTS_ENDPOINT}
          select_mode="checkbox"
          extra_get_params={extra_get_params}
          data_source_url_name="paginated_member_account_payments"
        />
      </DataTableActionForm>
    );
  }
}

ApproveClientPayments.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ApproveClientPayments));
