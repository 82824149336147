import {
  APPLIED_LOANS_ENDPOINT,
  GENERAL_LEDGER_ENDPOINT,
  MEMBER_APPLIED_LOANS_ENDPOINT,
} from "../constants/Constants";
import {
  APPROVE_LOAN_PATH,
  VIEW_ACTIVE_LOANS_PATH,
  VIEW_COMPLETED_LOANS_PATH,
} from "../constants/ClientPaths";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React, { Component } from "react";
import {
  faAddressCard,
  faArchive,
  faChartBar,
  faClock,
  faHourglass,
} from "@fortawesome/free-solid-svg-icons";
import {
  fetchUrlData,
  getUrlData,
  label,
  thousandsFormat,
} from "../utils/componentActions";

import ComponentLoadingIndicator from "../components/ComponentLoadingIndicator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import StatisticsPanel from "./StatisticsPanel";
import ViewAppliedLoans from "../loans/view/ViewAppliedLoans";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";

class StaffDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const active_complete_fields: string = "id,status";
    const pending_fields: string =
      "id,member_name,member_no,loan_name,amount,date_of_loan_application,member_no,loan_reference_no,status";
    let loan_count_url: any = APPLIED_LOANS_ENDPOINT;
    const member_count_url: string = "/registration/members/?fields=id";
    const income_expenses_url: any = GENERAL_LEDGER_ENDPOINT;
    const month_1_start: any = moment().startOf("month").format("YYYY-MM-DD");
    const month_1_end: any = moment().endOf("month").format("YYYY-MM-DD");
    const month_2_start: any = moment(month_1_start)
      .subtract(1, "months")
      .format("YYYY-MM-DD");
    const month_2_end: any = moment(month_1_end)
      .subtract(1, "months")
      .format("YYYY-MM-DD");
    const month_3_start: any = moment(month_1_start)
      .subtract(2, "months")
      .format("YYYY-MM-DD");
    const month_3_end: any = moment(month_1_end)
      .subtract(2, "months")
      .format("YYYY-MM-DD");
    const month_1_params: string = `date_from=${month_1_start}&date_to${month_1_end}&totals=true&account_group_in=4,5`;
    const month_2_params: string = `date_from=${month_2_start}&date_to${month_2_end}&totals=true&account_group_in=4,5`;
    const month_3_params: string = `date_from=${month_3_start}&date_to${month_3_end}&totals=true&account_group_in=4,5`;
    if (localStorage.member) {
      loan_count_url = `${MEMBER_APPLIED_LOANS_ENDPOINT}?token=${localStorage.auth_token}&member=${localStorage.member}`;
    }
    fetchUrlData("member_count_url", member_count_url, this.props);
    fetchUrlData(
      "active_complete_loans_count_url",
      `${loan_count_url}?status__in=1,7&fields=${active_complete_fields}`,
      this.props
    );
    fetchUrlData(
      "pending_loans_url",
      `${loan_count_url}?status=6&fields=${pending_fields}`,
      this.props
    );
    fetchUrlData(
      "month_1_income_expenses_url",
      `${income_expenses_url}?${month_1_params}`,
      this.props
    );
    fetchUrlData(
      "month_2_income_expenses_url",
      `${income_expenses_url}?${month_2_params}`,
      this.props
    );
    fetchUrlData(
      "month_3_income_expenses_url",
      `${income_expenses_url}?${month_3_params}`,
      this.props
    );
  }

  render() {
    const {
      member_count_data,
      active_complete_loans_count_data,
      pending_loans_data,
      month_1_income_expenses_data,
      month_2_income_expenses_data,
      month_3_income_expenses_data,
    } = this.props;
    if (
      member_count_data.isFetching ||
      active_complete_loans_count_data.isFetching ||
      pending_loans_data.isFetching ||
      month_1_income_expenses_data.isFetching ||
      month_2_income_expenses_data.isFetching ||
      month_3_income_expenses_data.isFetching
    ) {
      return <ComponentLoadingIndicator />;
    }
    const member_count: any = member_count_data.items;
    const active_complete_loans_count: any =
      active_complete_loans_count_data.items;
    const pending_loans: any = pending_loans_data.items;
    const month_1_income_expenses: any = month_1_income_expenses_data.items;
    const month_2_income_expenses: any = month_2_income_expenses_data.items;
    const month_3_income_expenses: any = month_3_income_expenses_data.items;
    const active_loans_count: any = active_complete_loans_count.filter(
      (loans) => loans.status === 1
    );
    const completed_loans_count: any = active_complete_loans_count.filter(
      (loans) => loans.status === 7
    );
    const nowrap: { whiteSpace: string } = { whiteSpace: "nowrap" };
    const pending_loans_columns: any = [
      {
        dataField: "id",
        text: "ID",
        hidden: true,
      },
      {
        dataField: "member_name",
        text: "Member name",
      },
      {
        dataField: "loan_name",
        text: "Loan name",
      },
      {
        dataField: "amount",
        text: "Applied amount",
        formatter: thousandsFormat,
      },
      {
        dataField: "member_no",
        text: "Member no",
      },
      {
        dataField: "loan_reference_no",
        text: "Loan reference no",
      },
      {
        dataField: "date_of_loan_application",
        text: "Application date",
        formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
      },
    ];
    pending_loans_columns.forEach((column) => {
      column.headerStyle = nowrap;
      column.style = nowrap;
    });
    const total_income_expenses: any = function (income_expenses, type) {
      let total_income_expenses_: number = 0;
      income_expenses.forEach((ledger) => {
        if (ledger.account_group === type) {
          total_income_expenses_ =
            type === 4
              ? total_income_expenses_ + ledger.credit - ledger.debit
              : total_income_expenses_ + ledger.debit - ledger.credit;
        }
      });
      return total_income_expenses_;
    };

    const month_1_total_income: any = total_income_expenses(
      month_1_income_expenses,
      4
    );
    const month_1_total_expenses: any = total_income_expenses(
      month_1_income_expenses,
      5
    );
    const month_2_total_income: any = total_income_expenses(
      month_2_income_expenses,
      4
    );
    const month_2_total_expenses: any = total_income_expenses(
      month_2_income_expenses,
      5
    );
    const month_3_total_income: any = total_income_expenses(
      month_3_income_expenses,
      4
    );
    const month_3_total_expenses: any = total_income_expenses(
      month_3_income_expenses,
      5
    );
    const month_profit_loss: any = function (
      month_total_income,
      month_total_expenses
    ) {
      const month_diff: any = month_total_income - month_total_expenses;
      let month_profit: number = 0;
      let month_loss: number = 0;
      if (month_diff > 0) {
        month_profit = month_diff;
      } else {
        month_loss = Math.abs(month_diff);
      }
      return [month_profit, month_loss];
    };
    const [month_1_profit, month_1_loss] = month_profit_loss(
      month_1_total_income,
      month_1_total_expenses
    );
    const [month_2_profit, month_2_loss] = month_profit_loss(
      month_2_total_income,
      month_2_total_expenses
    );
    const [month_3_profit, month_3_loss] = month_profit_loss(
      month_3_total_income,
      month_3_total_expenses
    );
    const month_1_name: any = moment().startOf("month").format("MMMM-YYYY");
    const month_2_name: any = moment()
      .subtract(1, "months")
      .format("MMMM-YYYY");
    const month_3_name: any = moment()
      .subtract(2, "months")
      .format("MMMM-YYYY");
    const data: any = [
      {
        name: month_3_name,
        Income: month_3_total_income,
        Expenses: month_3_total_expenses,
        Profit: month_3_profit,
        Loss: month_3_loss,
      },
      {
        name: month_2_name,
        Income: month_2_total_income,
        Expenses: month_2_total_expenses,
        Profit: month_2_profit,
        Loss: month_2_loss,
      },
      {
        name: month_1_name,
        Income: month_1_total_income,
        Expenses: month_1_total_expenses,
        Profit: month_1_profit,
        Loss: month_1_loss,
      },
    ];
    return (
      <>
        <div className="row">
          <StatisticsPanel
            label_name="All members"
            link_label="View members"
            number={member_count.length}
            bg_color="blue"
            icon={faAddressCard}
            url="/members/viewMembers"
            url_type="soft"
          />

          <StatisticsPanel
            label_name="Completed loans"
            link_label="View completed loans"
            number={completed_loans_count.length}
            bg_color="green"
            icon={faArchive}
            url={VIEW_COMPLETED_LOANS_PATH}
            url_type="soft"
          />

          <StatisticsPanel
            label_name="Active loans"
            link_label="View active loans"
            number={active_loans_count.length}
            bg_color="yellow"
            icon={faClock}
            url={VIEW_ACTIVE_LOANS_PATH}
            url_type="soft"
          />

          <StatisticsPanel
            label_name="Pending loans"
            link_label="Approve loans"
            number={pending_loans.length}
            bg_color="red"
            icon={faHourglass}
            url={APPROVE_LOAN_PATH}
            url_type="soft"
          />
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="card mt-3">
              <div className="card-header bg-green p-2">
                <span className="text-white">
                  <FontAwesomeIcon icon={faChartBar} />
                  <span className="ml-1">
                    {label(
                      "Income VS Expenses (last three months)",
                      this.props
                    )}
                  </span>
                </span>
              </div>
              <div className="card-body">
                <ResponsiveContainer width="99%" aspect={1.6}>
                  <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey="Income"
                      name={label("Income", this.props)}
                      fill="#337AB7"
                    />
                    <Bar
                      dataKey="Expenses"
                      name={label("Expenses", this.props)}
                      fill="#F0AD4E"
                    />
                    <Bar
                      dataKey="Profit"
                      name={label("Profit", this.props)}
                      fill="#5CB85C"
                    />
                    <Bar
                      dataKey="Loss"
                      name={label("Loss", this.props)}
                      fill="#D9534F"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card mt-3">
              <div className="card-header bg-green p-2">
                <span className="text-white">
                  <FontAwesomeIcon icon={faHourglass} />
                  <span className="ml-1">
                    {label("Pending loans", this.props)}
                  </span>
                </span>
              </div>
              <div className="card-body">
                <ViewAppliedLoans status={6} columns={pending_loans_columns} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

StaffDashboard.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  member_count_data: PropTypes.instanceOf(Object).isRequired,
  active_complete_loans_count_data: PropTypes.instanceOf(Object).isRequired,
  pending_loans_data: PropTypes.instanceOf(Object).isRequired,
  month_1_income_expenses_data: PropTypes.instanceOf(Object).isRequired,
  month_2_income_expenses_data: PropTypes.instanceOf(Object).isRequired,
  month_3_income_expenses_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const member_count_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "member_count_url"
  );
  const active_complete_loans_count_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "active_complete_loans_count_url"
  );
  const pending_loans_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "pending_loans_url"
  );
  const month_1_income_expenses_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "month_1_income_expenses_url"
  );
  const month_2_income_expenses_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "month_2_income_expenses_url"
  );
  const month_3_income_expenses_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "month_3_income_expenses_url"
  );
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    member_count_data,
    active_complete_loans_count_data,
    pending_loans_data,
    month_1_income_expenses_data,
    month_2_income_expenses_data,
    month_3_income_expenses_data,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(StaffDashboard));
