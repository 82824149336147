import { getUrlData, label } from "../utils/componentActions";

import MemberDashboard from "./MemberDashboard";
import React from "react";
import StaffDashboard from "./StaffDashboard";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

function LendingDashboard(props) {
  let display: any = <StaffDashboard />;
  if (localStorage.member) {
    display = <MemberDashboard />;
  }
  return (
    <div className="card">
      <div className="card-header bg-green text-center p-2">
        <span className="font-weight-bold text-white">
          {label("Dashboard", props)}
        </span>
      </div>
      <div className="card-body">{display}</div>
    </div>
  );
}

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    dataByUrl,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(LendingDashboard));
