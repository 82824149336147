import React, { Component } from "react";

import { APPLIED_LOANS_ENDPOINT } from "../../constants/Constants";
import DataTableActionForm from "../../components/DataTableActionForm";
import FormFields from "../../components/FormFields";
import PropTypes from "prop-types";
import ViewAppliedLoans from "../view/ViewAppliedLoans";
import { connect } from "react-redux";
import moment from "moment";
import { thousandsFormat } from "../../utils/componentActions";
import { withRouter } from "react-router";

class ReverseLoan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 5,
    };
  }

  handleSetTableNode = (tableNode) => {
    this.setState({
      table_node: tableNode,
    });
  };

  render() {
    const { props } = this;
    const non_reversed_loans_columns: any = [
      {
        dataField: "id",
        text: "Loan ID",
      },
      {
        dataField: "member_name",
        text: "Member name",
      },
      {
        dataField: "member_no",
        text: "Member no",
      },
      {
        dataField: "loan_name",
        text: "Loan name",
      },
      {
        dataField: "loan_reference_no",
        text: "Loan reference no",
      },
      {
        dataField: "amount",
        text: "Applied amount",
        formatter: thousandsFormat,
      },
      {
        dataField: "approved_amount",
        text: "Approved amount",
        formatter: thousandsFormat,
      },
      {
        dataField: "no_of_repayments",
        text: "No of repayments",
      },
      {
        dataField: "date_of_loan_application",
        text: "Application date",
        formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
      },
      {
        dataField: "disbursement_date",
        text: "Disbursement date",
        formatter: (cell) => moment(cell).format("DD-MMM-YYYY"),
      },
      {
        dataField: "status_name",
        text: "Status",
      },
    ];
    const applied_loans_url: string = `${APPLIED_LOANS_ENDPOINT}?status__in=9&limit=5&offset=0`;
    const applied_loans_url_name: string = "applied_loans_url";
    const process_form_items: any = [
      <FormFields
        key={1}
        field_type="textarea"
        field_label="Reversal reason"
        props={props}
        field_name="reversal_reason"
        wrapper_div_classes="col-4 pb-3"
        data_object={{}}
        required
      />,
    ];
    const actions: any = [
      {
        name: "reverse",
        btn_classes: "btn btn-green text-white",
        noun: "reversal",
        icon: "file-earmark-minus",
        target: "loan",
        plural: "loans",
        form_items: process_form_items,
        multiple_form_items: process_form_items,
        extra_form_data: [
          { name: "status", value: 12 },
          { name: "reversal", value: true },
        ],
      },
    ];
    return (
      <DataTableActionForm
        actions={actions}
        table_node={this.state.table_node}
        ids_post_field="loan_ids"
        datatable_action_endpoint={APPLIED_LOANS_ENDPOINT}
        request_method="POST"
        datatable_source_url={applied_loans_url}
        datatable_source_url_name={applied_loans_url_name}
      >
        <ViewAppliedLoans
          status={[1, 6, 7, 9, 11, 14, 22]}
          columns={non_reversed_loans_columns}
          csv_export={false}
          select_mode="checkbox"
          handleSetTableNode={this.handleSetTableNode}
        />
      </DataTableActionForm>
    );
  }
}

ReverseLoan.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;

  return {
    sessionVariables,
  };
}

export default connect(mapStateToProps)(withRouter(ReverseLoan));
