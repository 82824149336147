import {
  BANKS_ENDPOINT,
  CHART_OF_ACCOUNTS_ENDPOINT,
  CURRENCIES_ENDPOINT,
  MOBILE_PAYMENTS_BUSINESS_NUMBERS_ENDPOINT,
  PAYMENT_MODES_ENDPOINT,
  USERS_ENDPOINT,
} from "../../constants/Constants";
import React, { Component } from "react";
import {
  fetchUrlData,
  getSelect2Options,
  getUrlData,
} from "../../utils/componentActions";

import CreateEditForm from "../../components/CreateEditForm";
import FormFields from "../../components/FormFields";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class Class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bank: {},
    };
  }

  componentDidMount() {
    const bank_id: any = this.props.match.params.id;
    if (bank_id) {
      const bank_url: string = `${BANKS_ENDPOINT}${bank_id}/`;
      fetchUrlData("bank_url", bank_url, this.props);
    }
    fetchUrlData(
      "asset_accounts_url",
      `${CHART_OF_ACCOUNTS_ENDPOINT}?account_type=1`,
      this.props
    );
    fetchUrlData("users_url", USERS_ENDPOINT, this.props);
    fetchUrlData(
      "business_numbers_url",
      MOBILE_PAYMENTS_BUSINESS_NUMBERS_ENDPOINT,
      this.props
    );
    fetchUrlData("currencies_url", CURRENCIES_ENDPOINT, this.props);
    fetchUrlData("payment_modes_url", PAYMENT_MODES_ENDPOINT, this.props);
  }

  updateState = (state_object) => {
    this.setState(state_object);
  };

  render() {
    const { props } = this;
    const {
      asset_accounts_data,
      bank_data,
      users_data,
      business_numbers_data,
      payment_modes_data,
      currencies_data,
    } = props;
    const { bank } = this.state;
    const asset_accounts: any = asset_accounts_data.items;
    const users: any = users_data.items;
    const business_numbers: any = business_numbers_data.items;
    const payment_modes: any = payment_modes_data.items;
    const currencies: any = currencies_data.items;
    const asset_accounts_options: any = getSelect2Options(
      asset_accounts,
      "id",
      "account_name"
    );
    const users_options: any = getSelect2Options(users, "id", "username");
    const business_number_options: any = getSelect2Options(
      business_numbers,
      "id",
      "number"
    );
    const payment_mode_options: any = getSelect2Options(
      payment_modes,
      "id",
      "name"
    );
    const currency_options: any = getSelect2Options(currencies, "id", "name");
    const form_classes: string = "row g-3";
    const form_items: any = [
      <FormFields
        key={1}
        field_type="text_input"
        field_label="Name"
        props={props}
        field_name="bank_name"
        wrapper_div_classes="col-4"
        data_object={bank}
        required
      />,
      <FormFields
        key={2}
        field_type="select2"
        field_label="GL account"
        props={props}
        field_name="gl_account"
        wrapper_div_classes="col-4"
        select2_options={asset_accounts_options}
        select2_default_first
        data_object={bank}
        required
      />,
      <FormFields
        key={3}
        field_type="select2"
        field_label="Assign to cashier"
        props={props}
        field_name="user"
        wrapper_div_classes="col-4"
        select2_options={users_options}
        isClearable
        data_object={bank}
      />,
      <FormFields
        key={4}
        field_type="select2"
        field_label="Mobile payments number"
        props={props}
        field_name="business_number"
        wrapper_div_classes="col-4"
        select2_options={business_number_options}
        isClearable
        data_object={bank}
      />,
      <FormFields
        key={5}
        field_type="select2"
        field_label="Currency"
        props={props}
        field_name="currency"
        wrapper_div_classes="col-4"
        select2_options={currency_options}
        isClearable
        data_object={bank}
      />,
      <FormFields
        key={6}
        field_type="select2"
        field_label="Payment mode"
        props={props}
        field_name="payment_type"
        wrapper_div_classes="col-4"
        select2_options={payment_mode_options}
        isClearable
        data_object={bank}
      />,
    ];
    return (
      <CreateEditForm
        loading={bank_data.isFetching}
        form_items={form_items}
        form_classes={form_classes}
        action_object={bank_data.items}
        setState={this.updateState}
        action_object_name="bank"
        state={this.state}
        action_object_endpoint={BANKS_ENDPOINT}
        form_id="form-account-details"
        object_display_name="bank"
      />
    );
  }
}

Class.propTypes = {
  sessionVariables: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  bank_data: PropTypes.instanceOf(Object).isRequired,
  asset_accounts_data: PropTypes.instanceOf(Object).isRequired,
  users_data: PropTypes.instanceOf(Object).isRequired,
  business_numbers_data: PropTypes.instanceOf(Object).isRequired,
  currencies_data: PropTypes.instanceOf(Object).isRequired,
  payment_modes_data: PropTypes.instanceOf(Object).isRequired,
};

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const bank_data: any = getUrlData(dataByUrl, sessionVariables, "bank_url");
  const asset_accounts_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "asset_accounts_url"
  );
  const users_data: any = getUrlData(dataByUrl, sessionVariables, "users_url");
  const business_numbers_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "business_numbers_url"
  );
  const currencies_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "currencies_url"
  );
  const payment_modes_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "payment_modes_url"
  );

  return {
    sessionVariables,
    bank_data,
    asset_accounts_data,
    users_data,
    business_numbers_data,
    currencies_data,
    payment_modes_data,
  };
}

export default connect(mapStateToProps)(withRouter(Class));
