import React, { Component } from "react";
import {
  VIEW_LOAN_PRODUCTS_PATH,
  VIEW_SAVINGS_PRODUCTS_PATH,
  VIEW_SHARES_PRODUCTS_PATH,
} from "../../../constants/ClientPaths";
import {
  getActiveNav,
  getUrlData,
  label,
  toggleActiveNav,
} from "../../../utils/componentActions";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class ViewProducts extends Component {
  constructor(props) {
    super(props);
    const { pathname } = this.props.history.location;
    this.state = {
      active_nav: pathname,
    };
  }

  render() {
    const { props } = this;
    return (
      <div className="card">
        <div className="card-header bg-green text-center p-2">
          <span className="font-weight-bold text-white">
            {label("Products", props)}
          </span>
        </div>
        <div className="card-body">
          <ul className="nav nav-pills">
            <li className="nav-item">
              <a
                className={`nav-link ${getActiveNav(
                  VIEW_LOAN_PRODUCTS_PATH,
                  this
                )}`}
                href="#"
                onClick={(e) =>
                  toggleActiveNav(e, VIEW_LOAN_PRODUCTS_PATH, this)
                }
              >
                {label("Loan Products", props)}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${getActiveNav(
                  VIEW_SAVINGS_PRODUCTS_PATH,
                  this
                )}`}
                href="#"
                onClick={(e) =>
                  toggleActiveNav(e, VIEW_SAVINGS_PRODUCTS_PATH, this)
                }
              >
                {label("Savings Products", props)}
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${getActiveNav(
                  VIEW_SHARES_PRODUCTS_PATH,
                  this
                )}`}
                href="#"
                onClick={(e) =>
                  toggleActiveNav(e, VIEW_SHARES_PRODUCTS_PATH, this)
                }
              >
                {label("Shares Products", props)}
              </a>
            </li>
          </ul>
          <div className="col mt-2 mb-5 pb-2">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { sessionVariables, dataByUrl } = state;
  const system_labels_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_url"
  );
  const system_labels_key_dict_data: any = getUrlData(
    dataByUrl,
    sessionVariables,
    "system_labels_key_dict_url"
  );

  return {
    sessionVariables,
    system_labels_data,
    system_labels_key_dict_data,
  };
}

export default connect(mapStateToProps)(withRouter(ViewProducts));
